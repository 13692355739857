import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

const CustomTable = ({ total, page, setPage, pageSize, setPageSize, ...rest }) => {
  const { t } = useTranslation();

  const props = {
    ...rest,
    pagination: {
      pageSize: pageSize,
      current: page,
      total,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
      showTotal: (total, range) => (
        <span style={{ left: 0, position: "absolute" }}>
          {t("dataTable", { rangeA: range[0], rangeB: range[1], total })}
        </span>
      ),
    },
  };

  return <Table async {...props} />;
};

export default CustomTable;

import Search from "antd/es/transfer/search";
import { debounce } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ProductSearchFilter.scss";

const SearchFilters = ({ placeholder, handleChange, options = [], setValue = "" }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [onSearch, setOnSearch] = useState(false);

  useEffect(() => {
    if (!onSearch) return;

    const debouncedAction = debounce(() => {
      handleChange(searchValue.toLowerCase());
    }, 500);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [searchValue, onSearch]);

  useEffect(() => {
    if (setValue) {
      setSearchValue(setValue);
      setOnSearch(true);
    } else {
      setSearchValue("");
      setOnSearch(false);
    }
  }, [setValue]);

  return (
    <div className="search-filters">
      <Search
        type="text"
        placeholder={t(placeholder)}
        value={searchValue}
        allowClear
        onChange={({ target }) => {
          setSearchValue(target.value);
          setOnSearch(true);
        }}
        options={options}
      />
    </div>
  );
};

export default SearchFilters;

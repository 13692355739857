import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Select } from "antd";
import { debounce } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import TagCustom from "../../atoms/TagCustom/TagCustom";
import * as districtActions from "../../../actions/district.action";
import "./AssignCoverageArea.scss";

const { Option } = Select;

const AssignCoverageArea = ({ id, setAssignedSellerId }) => {
  const { t } = useTranslation();
  const [districtOptions, setDistrictOptions] = useState([]);
  const [sellerId, setSellerId] = useState(undefined);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const { districtUnassigned } = useSelector((state) => state.districtReducer);

  useEffect(() => {
    dispatch(districtActions.unassigned(id));
  }, []);

  useEffect(() => {
    if (districtUnassigned) {
      const result = districtUnassigned?.data.map((district) => ({
        value: district?.id,
        label: district?.companyName,
        tag: district?.internalDistrict,
      }));
      setDistrictOptions(result);
    }
  }, [districtUnassigned]);

  useEffect(() => {
    const _params = { ...params };

    if (searchString) _params.search = searchString;
    else delete _params.search;
    dispatch(districtActions.unassigned(id, params));
    setParams(_params);
  }, [searchString]);

  const debounceSearch = useRef(
    debounce(async (criteria) => {
      setSearchString(criteria);
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  const handleSearch = (value) => {
    debounceSearch(value);
  };

  const handleSubmit = async () => {
    const value = { areaId: id };
    setLoading(true);
    const isSuccess = await dispatch(districtActions.assignToDistrict(sellerId, value));
    if (isSuccess) {
      dispatch(districtActions.assigned(id, { $limit: 0 }));
      dispatch(districtActions.unassigned(id));
      setSellerId(undefined);
      setLoading(false);
    }
  };

  const renderOption = ({ value, label, tag }) => (
    <Option
      className="assign-coverage-area-select-option"
      key={value}
      value={value}
      label={label}
      onMouseDown={(e) => e.preventDefault()}
    >
      <span className="label">{label}</span>
      {tag && <TagCustom className="float-right full-rounded" text={tag} type="secondary" />}
    </Option>
  );

  return (
    <div className="assign-coverage-area">
      <Row gutter={12}>
        <Col span={16}>
          <Select
            allowClear
            showSearch
            className="select"
            placeholder={t("searchSeller")}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            value={sellerId}
            onChange={(value) => {
              setAssignedSellerId(value);
              setSellerId(value);
            }}
            onSearch={(value) => handleSearch(value)}
          >
            {districtOptions.map((option) => renderOption(option))}
          </Select>
        </Col>
        <Col span={8}>
          <ButtonPrimary
            className={!sellerId && "disabled-button"}
            disabled={!sellerId ? true : false}
            text="addSeller"
            loading={loading}
            block={true}
            icon={<PlusOutlined />}
            handleClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AssignCoverageArea;

import { Col, DatePicker, Form, Input, Modal, Row, Select, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../actions/order.action";
import { OrderStatus } from "../../../constants";
import useAmplitudeContext from "../../../hooks/useAmplitudeContext";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import { UpdateStatusCell } from "../../organisms/UpdateStatusCell";
import Confirmation from "../../organisms/EditOrder/Confirmation";

const { Text, Title } = Typography;

const UpdateOrdersStatusModal = ({
  isLoading,
  refetch,
  handleUpdateStatusToWaiting,
  handleUpdateStatusToCancel,
  handleUpdateStatus,
  isMultipleUpdates,
  currentRole,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [_, setStatus] = useState(OrderStatus.ORDER_CREATED.value);
  const [form] = Form.useForm();
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const { newStatus, updateStatusOpen, selectedOrders } = useSelector(
    ({ orderReducer }) => orderReducer,
  );

  useEffect(() => {
    if (selectedOrders) {
      const { orderId, status } = selectedOrders[0];
      setStatus(status);
      setData({ orderId, status });
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (selectedOrders) {
      const { orderId } = selectedOrders[0];
      dispatch(orderActions.getOrderDetail(orderId));
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (newStatus) setStatus(newStatus);
  }, [newStatus, data]);

  useEffect(() => {
    if (updateStatusOpen) setModalOpen(true);
    else setModalOpen(false);
  }, [updateStatusOpen]);

  useEffect(() => {
    const onMessage = async (message) => {
      if (!message) {
        return;
      }

      if (!message.data) {
        return;
      }

      const { data } = message;

      if (data.type === 'handleUpdateStatus') {
        const isSuccess = await dispatch(orderActions.updateStatus(data.payload.id, { orderStatus: OrderStatus.ORDER_RECEIVED.value }));
        if (isSuccess) {
          refetch();
        }
      }
    };

    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [refetch])


  const handleCancel = () => {
    dispatch(orderActions.setUpdateOrderStatusCancel());
    setStatus(data?.status);
    localStorage.removeItem("order-list/order/oldStatus");
    localStorage.removeItem("order-list/order/newStatus");
  };

  const render =(status, orderId) => {
    switch (status) {
      case OrderStatus.WAITING_FOR_PICK_UP.value:
        return currentRole === "BASKIT_ADMIN" ? (
          <EstimatedPickUpDate
            isLoading={isLoading}
            form={form}
            setModalOpen={setModalOpen}
            handleFinish={() => {
              let { estimatePickupDate, deliveryType } = form.getFieldsValue();
              estimatePickupDate = dayjs(estimatePickupDate).format("YYYY-MM-DD HH:mm:ss.SSS Z");
              handleUpdateStatusToWaiting({ estimatePickupDate, deliveryType });
              trackAmplitudeEvent("ORDER_STATUS_UPDATE", {
                order_id: orderId,
                order_status_from: localStorage.getItem("order-list/order/oldStatus"),
                order_status_end: localStorage.getItem("order-list/order/newStatus"),
              });
            }}
            handleCancel={handleCancel}
          />
        ) : (
          <ConfirmUpdate
            isLoading={isLoading}
            newStatus={newStatus}
            handleFinish={() => {
              trackAmplitudeEvent("ORDER_STATUS_UPDATE", {
                order_id: orderId,
                order_status_from: localStorage.getItem("order-list/order/oldStatus"),
                order_status_end: localStorage.getItem("order-list/order/newStatus"),
              });
              handleUpdateStatus();
            }}
            handleCancel={handleCancel}
          />
        );
      case OrderStatus.ORDER_CANCELLED.value:
      case OrderStatus.ORDER_PENDING.value:
        return (
          <ReasonCancellation
            isLoading={isLoading}
            newStatus={newStatus}
            form={form}
            setModalOpen={setModalOpen}
            handleFinish={() => {
              const { reasonId, reasonDescription } = form.getFieldsValue();
              handleUpdateStatusToCancel({ reasonId, reasonDescription });
              trackAmplitudeEvent("ORDER_STATUS_UPDATE", {
                order_id: orderId,
                order_status_from: localStorage.getItem("order-list/order/oldStatus"),
                order_status_end: localStorage.getItem("order-list/order/newStatus"),
              });
            }}
            handleCancel={handleCancel}
          />
        );
      case OrderStatus.ORDER_RECEIVED.value:
        if (currentRole === 'BASKIT_ADMIN' && !isMultipleUpdates) {
          window?.parent?.postMessage(
            {
              type: 'editOrder',
              payload: {
                id: orderId,
                status, 
              },
            },
            process.env.REACT_APP_NEW_CMS
          );

          handleCancel();
        } else if (currentRole === 'BASKIT_ADMIN' && isMultipleUpdates) {
          return (
            <Confirmation
              open={modalOpen}
              onOk={handleUpdateStatus}
              onClose={() => {
                handleCancel();
                setModalOpen(false);
              }}
            />
          )
        } else {
          return (
            <ConfirmUpdate
              isLoading={isLoading}
              newStatus={newStatus}
              handleFinish={handleUpdateStatus}
              handleCancel={handleCancel}
            />
          );
        }
      default:
        return (
          <ConfirmUpdate
            isLoading={isLoading}
            newStatus={newStatus}
            handleFinish={() => {
              trackAmplitudeEvent("ORDER_STATUS_UPDATE", {
                order_id: data?.orderId,
                order_status_from: localStorage.getItem("order-list/order/oldStatus"),
                order_status_end: localStorage.getItem("order-list/order/newStatus"),
              });
              handleUpdateStatus();
            }}
            handleCancel={handleCancel}
          />
        );
    }
  };

  return (
    <Modal
      className="update-orders-status-modal"
      centered
      open={modalOpen}
      onCancel={handleCancel}
      width={"600px"}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose={true}
      afterClose={() => {
        form.resetFields();
        handleCancel();
      }}
    >
      {newStatus === "" ? (
        <UpdateOrderStatus data={data} handleCancel={handleCancel} />
      ) : (
        render(selectedOrders?.[0]?.status, selectedOrders?.[0]?.orderId)
      )}
    </Modal>
  );
};

const UpdateOrderStatus = ({ data, handleCancel }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={24} className="text-center">
        <Title level={4}>{t("changeOrderStatus")}</Title>
      </Col>
      <Col span={24} className="mt-30 text-center">
        <Text>{t("changeOrderMessage")}</Text>
      </Col>
      <Col span={16} offset={4} className="mt-10 mb-40">
        <UpdateStatusCell initialStatus={data?.status} orderId={data?.orderId} />
      </Col>
      <Col span={8} offset={16}>
        <ButtonDefault text="Cancel" block={true} handleClick={handleCancel} />
      </Col>
    </Row>
  );
};

const EstimatedPickUpDate = ({ isLoading, form, handleFinish, handleCancel }) => {
  const { t } = useTranslation();
  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  return (
    <Row gutter={12}>
      <Col span={24} className="text-center">
        <Title level={4}>{t("estimatePickUpMessage")}</Title>
      </Col>
      <Col span={24} className="mt-30">
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            name="estimatePickupDate"
            rules={[{ required: true, message: t("Please select your date!") }]}
          >
            <DatePicker disabledDate={disabledDate} className="full-width" size="large" />
          </Form.Item>
          <Form.Item
            name="deliveryType"
            rules={[{ required: true, message: t("Please select your delivery!") }]}
          >
            <Select
              size="large"
              placeholder="Select delivery method"
              options={[
                { label: "Baskit Delivery", value: "BASKIT_DELIVERY" },
                { label: "Seller Delivery", value: "SELLER_DELIVERY" },
              ]}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Row gutter={12}>
              <Col span={12}>
                <ButtonDefault text="Cancel" block={true} handleClick={handleCancel} />
              </Col>
              <Col span={12}>
                <ButtonPrimary
                  text="updateStatus"
                  htmlType="submit"
                  block={true}
                  loading={isLoading}
                />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const ReasonCancellation = ({ isLoading, newStatus, form, handleFinish, handleCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reasonOptions, setReasonOptions] = useState([]);
  const { reasons } = useSelector(({ orderReducer }) => orderReducer);

  useEffect(() => {
    if (newStatus) {
      const type = newStatus === OrderStatus.ORDER_CANCELLED.value ? "CANCEL" : "PENDING";
      dispatch(orderActions.reasons({ type }));
    }
  }, [newStatus]);

  useEffect(() => {
    if (reasons) {
      const options = reasons?.data.map(({ id, name }) => ({ value: id, label: name }));
      setReasonOptions(options);
    }
  }, [reasons]);

  return (
    <Row gutter={12}>
      <Col span={24} className="text-center">
        <Title level={4}>{t("cancellationReasonMessage")}</Title>
      </Col>
      <Col span={24} className="mt-30">
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            name="reasonId"
            rules={[{ required: true, message: t("Please select your reason!") }]}
          >
            <Select options={reasonOptions} />
          </Form.Item>
          <Form.Item name="reasonDescription">
            <Input.TextArea allowClear showCount />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Row gutter={12}>
              <Col span={12}>
                <ButtonDefault text="Cancel" block={true} handleClick={handleCancel} />
              </Col>
              <Col span={12}>
                <ButtonPrimary
                  text="updateStatus"
                  htmlType="submit"
                  block={true}
                  loading={isLoading}
                />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const ConfirmUpdate = ({ isLoading, newStatus, handleFinish, handleCancel }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={24} className="text-center">
        <Title level={4}>{t("confirmationUpdate")}</Title>
      </Col>
      <Col span={24} className="mt-30 mb-30 text-center">
        <Text>{t("confirmationUpdateMessage")}</Text>{" "}
        <Text strong>{t(OrderStatus[newStatus]?.label ?? "")}</Text>
      </Col>
      <Col span={12}>
        <ButtonDefault text="Cancel" block={true} handleClick={handleCancel} />
      </Col>
      <Col span={12}>
        <ButtonPrimary
          text="Update Status"
          htmlType="submit"
          block={true}
          loading={isLoading}
          handleClick={handleFinish}
        />
      </Col>
    </Row>
  );
};

export default UpdateOrdersStatusModal;

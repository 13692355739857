import { Divider, Space, ConfigProvider, Button, TreeSelect } from "antd";
import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import "./MultipleTreeSelect.scss";

const { SHOW_ALL } = TreeSelect;

const MultipleTreeSelect = ({
  treeData = [],
  label = "",
  className = "",
  handleChange = () => {},
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [values, setValues] = useState([]);

  const handleSelectOpen = () => {
    if (!dropdownOpen) setDropdownOpen(true);
  };

  const setSelectValues = (values) => {
    handleChange(values);
    setDropdownOpen(false);
  };

  const handleApply = () => {
    setSelectValues(values);
    setDropdownOpen(false);
  };

  const handleReset = () => {
    setValues([]);
    setSelectValues([]);
  };

  const treeSelectProps = {
    treeData: treeData,
    value: values,
    treeCheckable: true,
    maxTagCount: "responsive",
    treeDefaultExpandAll: true,
    showCheckedStrategy: SHOW_ALL,
    placeholder: label,
    defaultOpen: dropdownOpen,
    open: dropdownOpen,
    onFocus: handleSelectOpen,
    onClick: handleSelectOpen,
    onBlur: () => setDropdownOpen(false),
    onChange: (values) => setValues(values),
  };

  return (
    <TreeSelect
      className={`multiple-tree-select ${className}`}
      {...treeSelectProps}
      dropdownRender={(menu) => (
        <div
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {menu}
          <Divider style={{ margin: "8px 0px" }} />
          <Space
            className="buttons-wrapper"
            direction="vertical"
            size="small"
            style={{ width: "100%", marginBottom: "4px" }}
          >
            <ConfigProvider theme={{ token: { colorPrimary: "#247cff" } }}>
              <Button type="primary" block onClick={handleApply}>
                {t("Apply")}
              </Button>
            </ConfigProvider>

            <Button
              block
              type="link"
              onClick={handleReset}
              icon={isEmpty(values) ? <CloseOutlined /> : <UndoOutlined />}
            >
              {t(isEmpty(values) ? "Close" : "Reset")}
            </Button>
          </Space>
        </div>
      )}
    />
  );
};

export default MultipleTreeSelect;

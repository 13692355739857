import { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

const useUnsavedChangesWarning = (message = "Are you sure you want to leave this page?") => {
  const [isDirty, setDirty] = useState(false);

  const handleBeforeUnload = (event) => {
    if (isDirty) {
      const confirmationMessage = message;
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  const setupBeforeUnload = () => {
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  const removeBeforeUnload = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };

  useEffect(() => {
    setupBeforeUnload();

    return () => {
      removeBeforeUnload();
    };
  }, [isDirty, message]);

  const routerPrompt = <Prompt when={isDirty} message={message} />;
  const onDirty = () => setDirty(true);
  const onPristine = () => {
    setDirty(false);
    removeBeforeUnload();
  };

  return { routerPrompt, onDirty, onPristine };
};

export default useUnsavedChangesWarning;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FileZipOutlined } from "@ant-design/icons";
import { Col, Empty, Row } from "antd";
import { isEmpty } from "lodash-es";
import MainContent from "../../organisms/Content/Content";
import MainBreadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import InvoiceCard from "../../molecules/InvoiceCard/InvoiceCard";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import * as orderActions from "../../../actions/order.action";
import "./_style.scss";

const Invoice = ({ orderId, headerProps, mainBreadcrumbItems }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const data = [
    { label: "Invoice", value: "INVOICE" },
    { label: "Delivery Note", value: "DELIVERY_NOTE" },
  ];

  const handleDownloadOrderInvoice = async (type) => {
    setSelectedFile(type);
    setIsLoading(true);
    const isSuccess = await dispatch(orderActions.downloadOrderInvoice(orderId, { type }));
    if (isSuccess) setIsLoading(false);
    else setIsLoading(false);
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="invoice-wrapper">
        <div id="invoice-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id="invoice-action">
          <Row>
            <Col offset={20} span={4}>
              <ButtonPrimary
                text="Download All Invoices"
                icon={<FileZipOutlined />}
                block={true}
                // handleClick={handleDownloadAllInvoices}
              />
            </Col>
          </Row>
        </div>
        {isEmpty(data) ? (
          <div className="mt-50">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : (
          <div id="invoice-card">
            {data.map(({ value, label }) => (
              <InvoiceCard
                key={value}
                title={label}
                isLoading={selectedFile === value && isLoading}
                handleClick={() => {
                  handleDownloadOrderInvoice(value);
                }}
              />
            ))}
          </div>
        )}
      </div>
    </MainContent>
  );
};

export default Invoice;

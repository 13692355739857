import React from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";
import { GOOGLE_MAPS_URL } from "../../../constants";

const center = { lat: -4.5715305, lng: 116.785467 };
const zoom = 4
const MapsComponent = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
  console.log(props);
  return (
    <GoogleMap
    defaultZoom={props.zoom ? props.zoom : zoom}
    defaultCenter={ props.location.lat && props.location.lng ? props.location : center}
  >
    {props.isMarkerShown && <Marker position={props.location.lat && props.location.lng ? props.location : center} onClick={props.onMarkerClick} />}
  </GoogleMap>);
}
);

export default MapsComponent;
import React, { useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { toSentenceCase } from "../../../utils/Functions";
import { useDispatch } from "react-redux";
import FormItemNumberIDR from "../../atoms/FormItemNumberIDR/FormItemNumberIDR";
import FormItemNumber from "../../atoms/FormItemNumber/FormItemNumber";
import "./SingleProductModal.scss";

const { Text } = Typography;

const SingleProductModal = ({
  modalOpen,
  setModalOpen,
  isLoading,
  data = {},
  buttonText = "Submit",
  handleAfterClose = () => {},
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (data && modalOpen) {
      form.setFieldsValue({
        price: data?.basePrice ?? 0,
        stock: data?.stock ?? 0,
      });
    }
  }, [data, modalOpen]);

  return (
    <Modal
      className="single-product-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      afterClose={handleAfterClose}
      width={400}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row>
        <Col span={24}>
          <div className="modal-image-wrapper">
            <Image
              width={160}
              height={160}
              preview={false}
              src={data?.image ?? `${window.location.origin}/images/baskit-product.jpg`}
              fallback={`${window.location.origin}/images/baskit-product.jpg`}
            />
          </div>
        </Col>
        <Col span={24}>
          <div className="modal-title-wrapper">
            <Text>{data?.fullName ?? ""}</Text>
            <div>
              <Text>{t("Packaging")} : </Text>
              <Text>{toSentenceCase(data?.packType ?? "-")}</Text>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Form form={form} id="update-product" layout="vertical" onFinish={(fields)=> handleSubmit(fields)}>
            <FormItemNumberIDR name="price" label={"Price"} 
            rules={[
              {
                required: true,
                message: 'Please input your price',
              },
              {
                type: 'number',
                min: 0,
                message: 'Minimum price is 0',
              },
            ]} 
            />
            <FormItemNumber name="stock" label={t("Update Stock")}
            rules={[
              {
                required: true,
                message: 'Please input your stock',
              },
              {
                type: 'number',
                min: 0,
                message: 'Minimum stock is 0',
              },
            ]}
            />
            <Form.Item>
              <Button
                type="primary"
                style={{ height: 44 }}
                htmlType="submit"
                block
                loading={isLoading}
              >
                {t(buttonText)}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default SingleProductModal;

import { useCallback, useEffect, useState } from "react";
import { filterParams, parseParamsString } from "../utils/Functions";
import { ErrorNotification } from "../utils/Notification";
import { httpClient } from "../utils/HttpClient";

const useOptions = (params, type = "company_type") => {
  const apiList = {
    company_type: "company/type",
  };

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const paramsString = parseParamsString(filterParams(params));
      const apiUrl = `${process.env.REACT_APP_API_URL_NEW_V2}${apiList[type]}${paramsString}`;
      const response = await httpClient.get(apiUrl);

      const { data, totalData, totalPage } = response?.data;
      setData(data);
      setTotalData(totalData);
      setTotalPage(totalPage);
    } catch (error) {
      ErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  }, [params, type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, totalData, totalPage, isLoading };
};

export default useOptions;

export function determineSellingPrice(qty, priceTiers) {
  const priceTier = getPriceTier(qty, priceTiers);

  return priceTier?.sellingPrice;
}

export function determineMarginValue(qty, priceTiers, basePrice) {
  const priceTier = getPriceTier(qty, priceTiers);

  const marginValue = priceTier.sellingPrice - basePrice;

  return Math.ceil(marginValue);
}

export function getPriceTier(qty, priceTiers) {
  const maxQty = priceTiers.reduce((prev, curr) => {
    return prev > curr.max ? prev : curr.max;
  }, 0);

  const priceTier = priceTiers.find((tier) => {
    return qty >= tier.min && qty <= tier.max;
  });

  if (qty > maxQty) {
    return priceTiers[priceTiers.length - 1];
  }

  return priceTier || priceTiers[0];
}
import {
  CART_FETCHING,
  CART_SUCCESS,
  CART_FAILED,
  CART_CLEAR,
  CART_CREATE_FETCHING,
  CART_CREATE_FAILED,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { ErrorMessage, SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setCartStateToFetching = () => ({
  type: CART_FETCHING,
});

export const setCartStateToFailed = () => ({
  type: CART_FAILED,
});

export const setCartStateToClear = () => ({
  type: CART_CLEAR,
});

export const setCartStateToSuccess = (payload) => ({
  type: CART_SUCCESS,
  payload,
});

export const setCreateCartStateToFetching = () => ({
  type: CART_CREATE_FETCHING,
});

export const setCreateCartStateToFailed = () => ({
  type: CART_CREATE_FAILED,
});

export const index = (params) => {
  return async (dispatch) => {
    dispatch(setCartStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `cart${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setCartStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCartStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const create = (values) => {
  return async (dispatch) => {
    dispatch(setCreateCartStateToFetching());
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL_NEW_V2 + "cart", values);
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCreateCartStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const update = (id, values) => {
  return async (dispatch) => {
    dispatch(setCreateCartStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `cart/${id}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCreateCartStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const remove = (id) => {
  return async (dispatch) => {
    dispatch(setCreateCartStateToFetching());
    try {
      const response = await httpClient.delete(process.env.REACT_APP_API_URL_NEW_V2 + `cart/${id}`);
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCreateCartStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

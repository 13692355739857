import { Col } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useAmplitudeContext from "../../../hooks/useAmplitudeContext";
import { getCurrentRole } from "../../../utils/Functions";
import ButtonCoditional from "../../atoms/ButtonConditional";
import "./_style.scss";

const PeriodSelectorOrder = ({ options, periodeState, colSpan }) => {
  const { t } = useTranslation();
  const currentRole = getCurrentRole();
  const [periode, setPeriode] = periodeState;
  const { trackAmplitudeEvent } = useAmplitudeContext();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      let trackedPeriode = periode;
      if (
        periode === "" ||
        periode.length === 0 ||
        JSON.stringify(periode) === JSON.stringify([""])
      ) {
        trackedPeriode = ["All"];
      }
      trackAmplitudeEvent("ORDER_STATUS_FILTER_CLICK", {
        order_status: trackedPeriode,
      });
    }
  }, [periode, trackAmplitudeEvent]);

  return (
    <Col id="period-selector-order-button" span={colSpan ?? 16}>
      {options.map(({ label, value }) => {
        const specialCondition = value === "ORDER_CREATED" && currentRole === "ADMIN";
        const active = periode.includes(value);
        const handleClick = specialCondition
          ? () => setPeriode([value, "WAITING_FOR_PICK_UP"])
          : () => setPeriode([value]);
        return (
          <ButtonCoditional key={value} text={t(label)} active={active} handleClick={handleClick} />
        );
      })}
    </Col>
  );
};

export default PeriodSelectorOrder;

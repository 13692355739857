import { Button, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ButtonPrimaryActionTable = ({
  title = "",
  text = "",
  className = "",
  icon,
  size = "middle",
  disabled = false,
  danger = false,
  handleClick,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(title)}>
      <Button
        className={className}
        type="primary"
        disabled={disabled}
        size={size}
        danger={danger}
        htmlType="button"
        icon={icon}
        onClick={handleClick}
      >
        {t(text)}
      </Button>
    </Tooltip>
  );
};

export default ButtonPrimaryActionTable;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonCoditional from "../../components/atoms/ButtonConditional/ButtonConditional";
import { useTranslation } from "react-i18next";
import { setDashboardRecordType, setStatusSelect } from "../../actions/dashboard.action";
import { Checkbox } from "antd";

/**
 * @typedef {import("../../reducers/dashboard.reducer").DashboardState} DashboardState
 * @typedef {import("../../actions/dashboard.action").RecordType} RecordType
 */

const RecordTypeSelector = () => {
  /** @type {DashboardState} */
  const { recordType, selectAll, selectOnline, selectOffline } = useSelector(
    (state) => state.dashboardReducer,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /** @type {(recordType:RecordType) => void} */
  const onSelectRecordType = (recordType) => {
    dispatch(setDashboardRecordType(recordType));
  };

  return (
    <div id="record-type-selector">
      <div id="button-type-selector">
        <ButtonCoditional
          text={t("transaction")}
          active={recordType === "transaction"}
          handleClick={() => onSelectRecordType("transaction")}
        />
        <ButtonCoditional
          text={t("income")}
          active={recordType === "income"}
          handleClick={() => onSelectRecordType("income")}
        />
      </div>
      <div id="status-selector">
        <span style={{ marginRight: "16px" }}>{t("see")}:</span>
        <Checkbox
          checked={selectAll}
          onChange={(e) => {
            dispatch(
              setStatusSelect({
                selectAll: e.target.checked,
                selectOnline,
                selectOffline,
              }),
            );
          }}
        >
          {t("all")}
        </Checkbox>
        <Checkbox
          checked={selectOnline}
          onChange={(e) => {
            dispatch(
              setStatusSelect({
                selectAll,
                selectOnline: e.target.checked,
                selectOffline,
              }),
            );
          }}
        >
          Online
        </Checkbox>
        <Checkbox
          checked={selectOffline}
          onChange={(e) => {
            dispatch(
              setStatusSelect({
                selectAll,
                selectOnline,
                selectOffline: e.target.checked,
              }),
            );
          }}
        >
          Offline
        </Checkbox>
      </div>
    </div>
  );
};

export default RecordTypeSelector;

import React from "react";
import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./CartEmpty.scss";

const { Title, Text } = Typography;

const CartEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className='cart-empty-wrapper'>
      <div className='cart-empty'>
        <div className='cart-empty__header'>
          <Image width={80} preview={false} src={`${window.location.origin}/images/cart.svg`} />
        </div>
        <div className='cart-empty__content'>
          <Title className='title' level={4}>
            {t("Your cart is still empty")}
          </Title>
          <Text>{t("You don't have a shopping list yet") + ","}</Text>
          <br />
          <Text>{t("Let's fill it up with the products you desire") + "."}</Text>
        </div>
      </div>
    </div>
  );
};

export default CartEmpty;

import React, { useState } from "react";
import { Col, DatePicker, Row } from "antd";
import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";
import ButtonApplyReset from "../ButtonApplyReset/ButtonApplyReset";
import "./SingleRangePicker.scss";

const { RangePicker } = DatePicker;

const SingleRangePicker = ({
  allowClear = false,
  showTime = false,
  picker = "date",
  format = "DD-MM-YYYY",
  handleChange,
}) => {
  const [selectedDate, setSelectedDate] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleReset = () => {
    handleChange([]);
    setSelectedDate({});
    setIsOpen(false);
  };

  const handleApply = () => {
    handleChange(selectedDate.dateString);
    setIsOpen(false);
  };

  return (
    <RangePicker
      className='single-range-picker'
      value={selectedDate.date}
      open={isOpen}
      allowClear={allowClear}
      picker={picker}
      showTime={showTime}
      onOpenChange={(value) => {
        if (value) setIsOpen(value);
      }}
      format={format}
      onChange={(date, dateString) => setSelectedDate({ date, dateString })}
      renderExtraFooter={() => {
        return (
          <div className='single-range-picker-extra-footer float-right'>
            <Row gutter={12}>
              <Col span={12}>
                <ButtonApplyReset
                  text={isEmpty(selectedDate) ? "Close" : "Reset"}
                  type='link'
                  block={true}
                  icon={isEmpty(selectedDate) ? <CloseOutlined /> : <UndoOutlined />}
                  handleClick={handleReset}
                />
              </Col>
              <Col span={12}>
                <ButtonApplyReset text='Apply' type='primary' block={true} handleClick={handleApply} />
              </Col>
            </Row>
          </div>
        );
      }}
    />
  );
};

export default SingleRangePicker;

import swal from "sweetalert";
import { httpClient } from "../utils/HttpClient";
import {
  AREA_INTERNAL_REGION_FETCHING,
  AREA_INTERNAL_REGION_FAILED,
  AREA_INTERNAL_REGION_SUCCESS,
  AREA_PROVINCES_FETCHING,
  AREA_PROVINCES_FAILED,
  AREA_PROVINCES_SUCCESS,
  AREA_CITIES_FETCHING,
  AREA_CITIES_FAILED,
  AREA_CITIES_SUCCESS,
  AREA_DISTRICTS_FETCHING,
  AREA_DISTRICTS_FAILED,
  AREA_DISTRICTS_SUCCESS,
} from "../constants";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { parseParamsString } from "../utils/Functions";
import { SuccessMessage } from "../utils/Message";

export const setAreaInternalRegionStateToFetching = () => ({
  type: AREA_INTERNAL_REGION_FETCHING,
});

export const setAreaInternalRegionStateToFailed = () => ({
  type: AREA_INTERNAL_REGION_FAILED,
});

export const setAreaInternalRegionStateToSuccess = (payload) => ({
  type: AREA_INTERNAL_REGION_SUCCESS,
  payload,
});

export const setAreaProvincesStateToFetching = () => ({
  type: AREA_PROVINCES_FETCHING,
});

export const setAreaProvincesStateToFailed = () => ({
  type: AREA_PROVINCES_FAILED,
});

export const setAreaProvincesStateToSuccess = (payload) => ({
  type: AREA_PROVINCES_SUCCESS,
  payload,
});

export const setAreaCitiesStateToFetching = () => ({
  type: AREA_CITIES_FETCHING,
});

export const setAreaCitiesStateToFailed = () => ({
  type: AREA_CITIES_FAILED,
});

export const setAreaCitiesStateToSuccess = (payload) => ({
  type: AREA_CITIES_SUCCESS,
  payload,
});

export const setAreaDistrictStateToFetching = () => ({
  type: AREA_DISTRICTS_FETCHING,
});

export const setAreaDistrictStateToFailed = () => ({
  type: AREA_DISTRICTS_FAILED,
});

export const setAreaDistrictStateToSuccess = (payload) => ({
  type: AREA_DISTRICTS_SUCCESS,
  payload,
});

export const internalRegion = (params = null) => {
  return async (dispatch) => {
    dispatch(setAreaInternalRegionStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `area/internal-region${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setAreaInternalRegionStateToSuccess({ data, totalData, totalPage }));
      return true;
    } catch (error) {
      dispatch(setAreaInternalRegionStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const provinces = (params = {}) => {
  return async (dispatch) => {
    dispatch(setAreaProvincesStateToFetching());
    try {
      const paramsString = parseParamsString({ type: "PROVINCE", ...params });
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `area${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setAreaProvincesStateToSuccess({ data, totalData, totalPage }));
      return true;
    } catch (error) {
      dispatch(setAreaProvincesStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const cities = (params = {}) => {
  return async (dispatch) => {
    dispatch(setAreaCitiesStateToFetching());
    try {
      const paramsString = parseParamsString({ type: "REGENCY", ...params });
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `area${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setAreaCitiesStateToSuccess({ data, totalData, totalPage }));
      return true;
    } catch (error) {
      dispatch(setAreaCitiesStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const districts = (params = {}) => {
  return async (dispatch) => {
    dispatch(setAreaDistrictStateToFetching());
    try {
      const paramsString = parseParamsString({ type: "DISTRICT", ...params });
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `area${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setAreaDistrictStateToSuccess({ data, totalData, totalPage }));
      return true;
    } catch (error) {
      dispatch(setAreaDistrictStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

import { useEffect, useState } from "react";
import { server } from "../constants";

const useLoggedInUser = () => {
  const [loggedInUser, setLoggedInUser] = useState({});

  useEffect(() => {
    const cookieValue = localStorage.getItem(server.ABOUT_USER);

    if (cookieValue && cookieValue !== 'undefined') {
      const userObject = JSON.parse(cookieValue);
      setLoggedInUser(userObject);
    } else setLoggedInUser({});
  }, []);

  return loggedInUser || {};
};

export default useLoggedInUser;

import React, { useEffect, useState } from "react";
import { Col, Divider, List, Modal, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import InputNumberIDR from "../../atoms/InputNumberIDR/InputNumberIDR";
import InputNumberCustom from "../../atoms/InputNumberCustom/InputNumberCustom";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ImageCustom from "../../atoms/Image";
import "./AddMultipleProductModal.scss";

const { Title, Text } = Typography;

const AddMultipleProductModal = ({
  modalOpen,
  setModalOpen,
  selectedProductIDs,
  selectedProducts,
  isLoading,
  handleUpdateSelectedProducts,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="add-multiple-product-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1000}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Row>
        <Col span={24}>
          <Title level={4}>{t("The list of added products")}</Title>
        </Col>
        <Col span={24}>
          <div className="add-multiple-product-modal-content">
            {selectedProductIDs && (
              <List
                itemLayout="horizontal"
                dataSource={selectedProductIDs}
                renderItem={(productID) => (
                  <ListItem
                    key={productID}
                    productID={productID}
                    selectedProducts={selectedProducts}
                    handleUpdateSelectedProducts={handleUpdateSelectedProducts}
                  />
                )}
              />
            )}
          </div>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={8} offset={16}>
          <ButtonPrimary
            text="Save Product Data"
            block={true}
            loading={isLoading}
            handleClick={handleSubmit}
          />
        </Col>
      </Row>
    </Modal>
  );
};

const ListItem = ({ productID, selectedProducts, handleUpdateSelectedProducts }) => {
  const { t } = useTranslation();
  const { image, fullName, packType } = selectedProducts[productID];
  const [price, setPrice] = useState(selectedProducts[productID]?.price);
  const [stock, setStock] = useState(selectedProducts[productID]?.stock);
  const [onInputingPrice, setOnInputingPrice] = useState(false);
  const [onInputingStock, setOnInputingStock] = useState(false);

  useEffect(() => {
    if (!onInputingPrice) return;

    const debouncedAction = debounce(() => {
      const _price = price === null ? 0 : price;
      handleUpdateSelectedProducts(_price, "price", productID);
    }, 500);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [price, onInputingPrice]);

  useEffect(() => {
    if (!onInputingStock) return;

    const debouncedAction = debounce(() => {
      const _stock = stock === null ? 0 : stock;
      handleUpdateSelectedProducts(_stock, "stock", productID);
    }, 500);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [stock, onInputingStock]);

  return (
    <List.Item>
      <Row gutter={12} className="full-width">
        <Col span={3} className="center-flex">
          <ImageCustom className="list-item-image" width={120} src={image} />
        </Col>
        <Col span={9}>
          <Space direction="vertical">
            <Text>{fullName}</Text>
            <Space>
              <Text>{t("Packaging")} : </Text>
              <Text strong>{packType || "-"}</Text>
            </Space>
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{t("Price")}</Text>
            <div className="input-number-wrapper">
              <InputNumberIDR
                defaultValue={price}
                handleChange={(value) => {
                  setPrice(value);
                  setOnInputingPrice(true);
                }}
              />
            </div>
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical">
            <Text strong>{t("Add new stock quantity")}</Text>
            <div className="input-number-wrapper">
              <InputNumberCustom
                defaultValue={stock}
                handleChange={(value) => {
                  setStock(value);
                  setOnInputingStock(true);
                }}
              />
            </div>
          </Space>
        </Col>
      </Row>
    </List.Item>
  );
};

export default AddMultipleProductModal;

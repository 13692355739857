import React from "react";
import { Col, Modal, Row, Typography } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "../atoms/ButtonSecondary/ButtonSecondary";
import LeafletMap from "../atoms/Maps/LeafletMap";

const { Text } = Typography;

const MapModal = ({
  modalOpen,
  setModalOpen,
  address,
  latitude = -6.175392,
  longitude = 106.827153,
  zoom = 17,
}) => {
  const { t } = useTranslation();
  const markers = [
    {
      geocode: [latitude, longitude],
      popUp: `The current location is at latitude: ${latitude} and longitude: ${longitude}.`,
    },
  ];

  const handleOpenGoogleMaps = () => {
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <Modal
      className="map-modal"
      title={t("map")}
      centered
      width={1000}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Row gutter={12}>
        <Col span={24} className="mb-10">
          <div className="leaflet-map-wrapper">
            <LeafletMap markers={markers} center={[latitude, longitude]} zoom={zoom} />
          </div>
        </Col>
        <Col span={18} className="flex-center-align">
          {address && (
            <div>
              <EnvironmentFilled /> <Text>{address}</Text>
            </div>
          )}
        </Col>
        <Col span={6}>
          <ButtonSecondary
            className="mt-10"
            text="viewOnGoogleMaps"
            icon={<EnvironmentFilled />}
            block
            handleClick={handleOpenGoogleMaps}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default MapModal;

import React, { useEffect, useMemo, useState } from "react";
import { Col, Divider, Empty, Image, Row, Space, Typography } from "antd";
import { isEmpty } from "lodash-es";
import { EnvironmentFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { filterParams, formatRupiahNotRounded } from "../../utils/Functions";
import { useDispatch, useSelector } from "react-redux";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import SalesEmpty from "../../components/atoms/SalesEmpty/SalesEmpty";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewMapModal from "../../components/molecules/ViewMapModal/ViewMapModal";
import ButtonSecondary from "../../components/atoms/ButtonSecondary/ButtonSecondary";
import CustomLink from "../../components/atoms/CustomLink/CustomLink";
import ImageCustom from "../../components/atoms/Image";
// import * as merchantActions from "../../actions/merchant.action";
import * as companyActions from "../../actions/company.action";

const { Text, Title } = Typography;

const Information = ({ id, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [gmvSummaryData, setGmvSummaryData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [salesMeta, setSalesMeta] = useState({});
  const [salesLoading, setSalesLoading] = useState(true);
  const [loadingInfiniteScroll, setLoadingInfiniteScroll] = useState(false);

  const [limit, setLimit] = useState(15);
  const params = useMemo(() => {
    const paramsConstruct = {
      $page: 1,
      $limit: limit,
    };

    return filterParams(paramsConstruct);
  }, [limit]);

  const { merchantGmv } = useSelector((state) => state.merchantReducer);
  const { relatedSales } = useSelector((state) => state.companyReducer);

  useEffect(() => {
    dispatch(companyActions.relatedSales(id, params));
    // dispatch(merchantActions.getMerchantGMV(id));
  }, [limit]);

  useEffect(() => {
    if (merchantGmv) {
      setGmvSummaryData(merchantGmv);
    }
  }, [merchantGmv]);

  useEffect(() => {
    if (relatedSales) {
      const { data, meta } = relatedSales;
      setSalesData(data);
      setSalesMeta(meta);
    }
    setSalesLoading(false);
  }, [relatedSales]);

  const loadMoreData = () => {
    if (loadingInfiniteScroll) return;

    setLoadingInfiniteScroll(true);
    setLimit(limit + 15);
  };

  const isPDFFile = (file) => {
    const data = file.split(".");
    if (data[1].toLowerCase() == "pdf") return true;
    return false;
  };

  return (
    <div id="information">
      <Row gutter={12}>
        <Col span={12}>
          <div id="seller-information">
            <Title className="pt-20 pl-30 pr-30" level={5}>
              {t("Seller Information")}
            </Title>
            <Divider />
            <Space direction="vertical" className="full-width pl-30 pr-30">
              <ListItem label="Owner's Name" value={data?.detail?.ownerName} />
              <ListItem label="Email Address" value={data?.email} />
              <ListItem label="Phone No." value={data?.phone} />
            </Space>
            <Divider />
            <Space direction="vertical" className="full-width pl-30 pr-30">
              <ListItem label="Bank" value={data?.bank} />
              <ListItem label="Bank Account" value={data?.backCode} />
            </Space>
            <Divider />
            <Space direction="vertical" className="full-width pb-20 pl-30 pr-30">
              <ListItem label="Total Earnings" value={formatRupiahNotRounded(data?.totalIncome)} />
            </Space>
          </div>

          <div id="document-uploads">
            <Title className="pt-20 pl-30 pr-30" level={5}>
              {t("Document Uploads")}
            </Title>
            <Divider />
            <div id="image-group" className="pb-20 pl-30 pr-30">
              {isEmpty(data?.documents) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <>
                  <Image.PreviewGroup>
                    {data?.documents.map(({ label, filename }, index) => {
                      if (!isPDFFile)
                        return (
                          <ImageWithLabel
                            key={index}
                            width={150}
                            src={process.env.REACT_APP_API_URL + filename}
                            label={label}
                            preview={true}
                          />
                        );
                    })}
                  </Image.PreviewGroup>
                  <div className="card-custom-pdf-wrapper">
                    {data?.documents.map(({ label, filename }, index) => {
                      if (isPDFFile)
                        return (
                          <ImageWithLabel
                            className="card-custom-pdf"
                            key={index}
                            width={110}
                            src={`${window.location.origin}/images/pdf-icon.svg`}
                            label={label}
                            preview={false}
                            handleClick={() =>
                              window.open(
                                `${process.env.REACT_APP_API_URL}files/company/${filename}`,
                              )
                            }
                          />
                        );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>

          <div id="gmv-summary">
            <Title className="pt-20 pl-30 pr-30" level={5}>
              {t("GMV")}
            </Title>
            <Divider />
            <Space direction="vertical" className="full-width pb-20 pl-30 pr-30">
              <ListItem
                label="Total"
                value={formatRupiahNotRounded(gmvSummaryData?.gmv?.total ?? 0)}
              />
              <ListItem
                className="text-primary"
                label="Online"
                value={formatRupiahNotRounded(gmvSummaryData?.gmv?.online ?? 0)}
              />
              <ListItem
                className="text-secondary"
                label="Offline"
                value={formatRupiahNotRounded(gmvSummaryData?.gmv?.offline ?? 0)}
              />
            </Space>
          </div>

          <div id="mtd-summary">
            <Title className="pt-20 pl-30 pr-30" level={5}>
              {t("MTD")}
            </Title>
            <Divider />
            <Space direction="vertical" className="full-width pb-20 pl-30 pr-30">
              <ListItem
                label="Total"
                value={formatRupiahNotRounded(gmvSummaryData?.mtd?.total ?? 0)}
              />
              <ListItem
                className="text-primary"
                label="Online"
                value={formatRupiahNotRounded(gmvSummaryData?.mtd?.online ?? 0)}
              />
              <ListItem
                className="text-secondary"
                label="Offline"
                value={formatRupiahNotRounded(gmvSummaryData?.mtd?.offline ?? 0)}
              />
            </Space>
          </div>
        </Col>
        <Col span={12}>
          <div id="merchant-information">
            <Title className="pt-20 pl-30 pr-30" level={5}>
              {t("Merchant Information")}
            </Title>
            <Divider />
            <Space direction="vertical" className="full-width pl-30 pr-30">
              <ListItem label="Merchant Name" value={data?.companyName} />
              <ListItem label="Merchant Address" value={data?.detail?.address} />
              <Row>
                <Col span={15} offset={9}>
                  <ButtonSecondary
                    text={t("View Map")}
                    icon={<EnvironmentFilled />}
                    handleClick={() => setModalOpen(true)}
                  />
                </Col>
              </Row>
              <ListItem label="Region" value={data?.region} />
              <ListItem label="District" value={data?.districtNo} />
              <ListItem label="Sub-district" value={data?.subdistrict} />
            </Space>
            <Divider />
            <Space direction="vertical" className="full-width pb-20 pl-30 pr-30">
              <ListItem label="Partnership Type" value={data?.type} />
              <ListItem label="Merchant ID" value={data?.merchantId} />
              <ListItem label="Status" value={data?.status} />
            </Space>
          </div>

          <div id="related-sales">
            <Title className="pt-20 pl-30 pr-30" level={5}>
              {t("Related Sales")}
            </Title>
            <Divider />
            <div id="related-sales-content" className="pb-20 pl-30 pr-30">
              {salesLoading ? (
                <SpinLoaderContent />
              ) : isEmpty(salesData) ? (
                <SalesEmpty />
              ) : (
                <InfiniteScroll
                  dataLength={salesData?.length}
                  next={loadMoreData}
                  hasMore={salesData?.length < salesMeta?.totalData}
                  scrollableTarget="related-sales-content"
                  loader={<SpinLoaderContent />}
                >
                  <Space direction="vertical">
                    {salesData.map(({ id, profile }, index) => (
                      <ListItemSales
                        key={index}
                        index={index + 1}
                        id={id}
                        value={`${profile?.firstName} ${profile?.lastName}`}
                      />
                    ))}
                  </Space>
                </InfiniteScroll>
              )}
            </div>
          </div>
        </Col>
        <Col span={24}>
          <ViewMapModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            address={data?.address}
            coordinate={data?.lat === 0 ? undefined : { lat: data?.lat, lng: data?.lon }}
          />
        </Col>
      </Row>
    </div>
  );
};

const ListItem = ({ className = "", label, value }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={8}>
        <Text className={className}>{t(label)}</Text>
      </Col>
      <Col span={1} className="text-center">
        :
      </Col>
      <Col span={15}>
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

const ListItemSales = ({ index, id, value }) => {
  return (
    <Space direction="horizontal" size="large">
      <div className="link-number">
        <Text>{index}.</Text>
      </div>
      <CustomLink className="underline" text={value} path={`/sales-store/${id}`} />
    </Space>
  );
};

const ImageWithLabel = ({ className, width, src, label, preview, handleClick }) => (
  <div className="image-container">
    <ImageCustom
      className={className}
      width={width}
      src={src}
      alt={label}
      preview={preview}
      handleClick={handleClick}
    />
    <div className="image-label text-center" style={{ width: width }}>
      <Text ellipsis strong>
        {label}
      </Text>
    </div>
  </div>
);

export default Information;

import {
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { create } from "zustand";
import * as orderActions from "../../../actions/order.action";
import { OrderStatus } from "../../../constants";
import useAmplitudeContext from "../../../hooks/useAmplitudeContext";
import { filterParams, getCurrentRole, parseDayToDate } from "../../../utils/Functions";
import { httpClient } from "../../../utils/HttpClient";
import { SuccessNotification } from "../../../utils/Notification";
import ButtonCustom from "../../atoms/ButtonCustom/ButtonCustom";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryActionTable from "../../atoms/ButtonPrimaryActionTable";
import ProductSearchFilter from "../../atoms/ProductSearchFilter/ProductSearchFilter";
import PeriodSelector from "../../molecules/PeriodSelector/PeriodSelector";
import PeriodSelectorOrder from "../../molecules/PeriodSelectorOrder/PeriodSelectorOrder";
import UpdateOrdersStatusModal from "../../molecules/UpdateOrdersStatusModal/UpdateOrdersStatusModal";
import "./_style.scss";
import { columnsDefault } from "./helper";

const { Text } = Typography;

const defaultColumnsOptions = {
  serialNo: true,
  date: true,
  updatedAt: true,
  merchantName: false,
  orderID: true,
  customer: true,
  total: true,
  marginValue: false,
  sales: false,
  status: true,
  transactionDetails: true,
};

const useSelectedRowsStore = create((set) => ({
  selectedRows: [],
  selectedRowKeys: [],
  isLoading: false,
  actions: {
    setRows: (rows) => set(() => ({ selectedRows: rows })),
    clearRows: () => set(() => ({ selectedRows: [] })),
    setKeys: (keys) => set(() => ({ selectedRowKeys: keys })),
    clearKeys: () => set(() => ({ selectedRowKeys: [] })),
    setIsLoading: (status) => set(() => ({ isLoading: status })),
  },
}));

let orderOptions = Object.keys(OrderStatus).map((key) => ({
  label: OrderStatus[key].label,
  value: OrderStatus[key].value,
}));

orderOptions.unshift({ label: "All", value: "" });

const sortValues = {
  ascend: "ASC",
  descend: "DESC",
};

const OrderList = ({
  companyId,
  columnsList,
  columns,
  periode: initialPeriode = { label: "today", value: parseDayToDate("today") },
  orderFilter = {},
  scroll = { x: 1500, y: "60vh" },
  link = {},
  onRow = undefined,
}) => {
  const selectedRows = useSelectedRowsStore((state) => state.selectedRows);
  const selectedRowsLoading = useSelectedRowsStore((state) => state.isLoading);
  const selectedRowKeys = useSelectedRowsStore((state) => state.selectedRowKeys);
  const clearSelectedRows = useSelectedRowsStore((state) => state.actions).clearRows;
  const clearSelectedRowKeys = useSelectedRowsStore((state) => state.actions).clearKeys;
  const setSelectedRows = useSelectedRowsStore((state) => state.actions).setRows;
  const setSelectedRowKeys = useSelectedRowsStore((state) => state.actions).setKeys;
  const setSelectedRowsLoading = useSelectedRowsStore((state) => state.actions).setIsLoading;
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const bulkDownload = useCallback(
    async (ids, type) => {
      setSelectedRowsLoading(true);

      const params = new URLSearchParams();
      params.append("type", type);
      ids.forEach((id) => {
        params.append("ids", id);
      });

      try {
        const response = await httpClient.get(
          `${process.env.REACT_APP_API_URL_NEW_V2}order/invoice/bulk?${params.toString()}`,
          {
            responseType: "blob",
          },
        );

        const blob = new Blob([response.data], { type: "application/zip" });
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.setAttribute("download", type === "INVOICE" ? "invoices.zip" : "invoices_and_do.zip");
        a.click();
        SuccessNotification(response);
        setSelectedRowsLoading(false);
        return true;
      } catch (error) {
        setSelectedRowsLoading(false);
        return false;
      }
    },
    [setSelectedRowsLoading],
  );

  const downloadItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div role="button" onClick={() => bulkDownload(selectedRowKeys, "INVOICE")}>
            Download all invoices
          </div>
        ),
      },
      {
        key: 2,
        label: (
          <div role="button" onClick={() => bulkDownload(selectedRowKeys, "DELIVERY_NOTE")}>
            Download all invoices & delivery order
          </div>
        ),
      },
    ],
    [selectedRowKeys],
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [statusOrder, setStatusOrder] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [periode, setPeriode] = useState(initialPeriode);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState({});
  const [isSelectMultiple, setIsSelectMultiple] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isMultipleUpdates, setIsMultipleUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState([]);
  const currentRole = getCurrentRole();
  const hasTrackedRef = React.useRef(false);
  const dataRef = useRef(data);

  const { newStatus, selectedOrders, orderMeta, orderList, detail } = useSelector(
    (state) => state.orderReducer,
  );

  const columnOptions = columns ?? defaultColumnsOptions;
  const columnsHeader = columnsList ? columnsList : columnsDefault;
  const columnInfo = columnsHeader.filter(({ key }) => key && (columnOptions?.[key] ?? false));

  const params = useMemo(() => {
    const { start, end } = periode?.value;
    let orderValue = [];
    let sortValue = [];

    if (!isEmpty(sortFilter)) {
      sortFilter.forEach(({ order, sort }) => {
        orderValue.push(order);
        sortValue.push(sort);
      });
    } else {
      orderValue.push("createdAt");
      sortValue.push("DESC");
    }

    let paramsConstruct = {
      ...orderFilter,
      $page: page,
      $limit: pageSize,
      $order: orderValue.join(","),
      $sort: sortValue.join(","),
      start,
      end,
      companyId,
      search: searchValue,
    };

    if (statusOrder.length > 0 && statusOrder[0] !== "") {
      paramsConstruct.status = statusOrder;
    } else {
      delete paramsConstruct["status"];
    }

    return filterParams(paramsConstruct);
  }, [orderFilter, page, pageSize, periode, searchValue, statusOrder, sortFilter]);

  useEffect(() => {
    if (statusOrder === "") handleCloseMultipleSelect();
    handleResetMultipleSelect();
    setPage(1);
  }, [statusOrder, periode]);

  useEffect(() => {
    setLoading(true);
    dispatch(orderActions.orderList(params));
  }, [params]);

  const refetch = useCallback(async () => {
    setLoading(true);
    dispatch(orderActions.orderList(params));
  }, [params, dispatch]);

  useEffect(() => {
    if (orderList) {
      const { data, meta } = orderList;
      const result = data.filter((order) => order.status !== "ORDER_FINISHED");
      setData(result);
      setMeta(meta);
      setLoading(false);
    }
  }, [orderList]);

  useEffect(() => {
    if (selectedOrders) {
      const { orderId, status } = selectedOrders[0];
      setSelectedOrder({ orderId, status });
      setIsMultipleUpdates(selectedOrders.length > 1);
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (!hasTrackedRef.current) {
      trackAmplitudeEvent("ORDER_LIST_PAGE_VIEW");
      hasTrackedRef.current = true;
    }
  }, [trackAmplitudeEvent]);

  const dataSource = useMemo(() => {
    return data.map((data, index) => ({
      key: data.id,
      id: data?.id,
      serialNo: (page - 1) * pageSize + (index + (orderMeta?.start_from ?? 1)),
      date: data?.createdDate,
      updatedAt: data?.updatedAt,
      merchantName: data?.companyName,
      orderID: data?.orderCode,
      detailsPath: link.details ? link.details(data?.id) : "",
      customer: data?.customerName,
      total: data?.grandTotal,
      marginValue: data?.marginValueTotal,
      sales: data?.salesName,
      status: data?.status,
      deliveryType: data?.deliveryType,
      transactionDetails: link.invoice
        ? link.invoice(data?.id)
        : `/merchant-list/detail/${companyId}/invoice/${data?.id}`,
      isSelectMultiple: isSelectMultiple,
    }));
  }, [data, companyId, isSelectMultiple, currentRole]);

  const isOrderIncluded = (orderId, orders, key = "id") => {
    return orders.some((order) => order?.[key] === orderId);
  };

  const handleMultipleEdit = () => {
    const newSelectedOrders = data
      .filter((order) => isOrderIncluded(order?.id, selectedRows))
      .map((order) => ({ orderId: order?.id, status: order?.status }));
    dispatch(
      orderActions.setUpdateOrderStatus({
        selectedOrders: newSelectedOrders,
        newStatus: "",
        updateStatusOpen: true,
      }),
    );
  };

  const setOrderList = (isNewData = true) => {
    const orders = data.filter((order) => !isOrderIncluded(order?.id, selectedOrders, "orderId"));
    if (isNewData) dispatch(orderActions.setOrderListStateToSuccess({ data: orders, meta }));
    else dispatch(orderActions.setOrderListStateToSuccess({ data, meta }));
    handleResetMultipleSelect();
  };

  const handleUpdateStatus = useCallback(
    async (values) => {
      setOrderList();
      setIsLoading(true);

      let isSuccess = false;
      if (currentRole === "ADMIN") {
        isSuccess = await handleUpdateStatusWithDefaultDeliveryType(values);
      } else {
        isSuccess = await dispatch(orderActions.updateStatus(selectedOrder?.orderId, values));
      }

      if (!isSuccess) setOrderList(false);
      setIsLoading(false);
      refetch();
      dispatch(orderActions.setUpdateOrderStatusClear());
    },
    [currentRole, selectedOrder],
  );

  const handleUpdateStatusWithDefaultDeliveryType = useCallback(
    async (values) => {
      const newStatus = values.orderStatus;
      if (newStatus === "ORDER_PICKED_UP") {
        const defaultValues = {
          orderStatus: newStatus,
          deliveryType: "SELLER_DELIVERY",
          estimatePickupDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS Z"),
        };

        return dispatch(orderActions.updateStatus(selectedOrder?.orderId, defaultValues));
      }

      return dispatch(orderActions.updateStatus(selectedOrder?.orderId, values));
    },
    [currentRole, selectedOrder],
  );

  const handleUpdateStatusToWaiting = async (fields) => {
    const { estimatePickupDate, deliveryType } = fields;
    const values = {
      orderStatus: newStatus,
      estimatePickupDate,
      deliveryType,
    };
    handleUpdateStatus(values);
  };

  const handleUpdateStatusToCancel = async (fields) => {
    const { reasonId, reasonDescription } = fields;
    let values = { orderStatus: newStatus, reasonId };
    if (reasonDescription) values.reasonDescription = reasonDescription;

    handleUpdateStatus(values);
  };

  const handleUpdateStatusBulk = async (values) => {
    setOrderList();
    setIsLoading(true);
    const isSuccess = await dispatch(orderActions.updateStatusBulk({ data: values }));
    if (!isSuccess) setOrderList(false);
    setIsLoading(false);
    dispatch(orderActions.setUpdateOrderStatusClear());
  };

  const handleMultipleUpdatesStatusToWaiting = async (fields) => {
    const { estimatePickupDate, deliveryType } = fields;
    const values = selectedOrders.map(({ orderId }) => ({
      orderId,
      orderStatus: newStatus,
      estimatePickupDate,
      deliveryType,
    }));

    handleUpdateStatusBulk(values);
  };

  const handleMultipleUpdatesStatusToCancel = async (fields) => {
    const { reasonId, reasonDescription } = fields;

    const values = selectedOrders.map(({ orderId }) => {
      let order = { orderId, orderStatus: newStatus, reasonId };
      if (reasonDescription) order.reasonDescription = reasonDescription;
      return order;
    });

    handleUpdateStatusBulk(values);
  };

  const handleMultipleUpdatesStatus = async () => {
    const values = selectedOrders.map(({ orderId }) => ({
      orderId,
      orderStatus: newStatus,
    }));

    handleUpdateStatusBulk(values);
  };

  const handleResetMultipleSelect = () => {
    clearSelectedRows();
    clearSelectedRowKeys();
  };

  const handleCloseMultipleSelect = () => {
    handleResetMultipleSelect();
    setIsSelectMultiple(false);
  };

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const debouncedTrackSearch = useMemo(
    () =>
      debounce((value) => {
        trackAmplitudeEvent("ORDER_SEARCH_ID", {
          search_term: value,
          order_list: dataRef.current.map((order) => order.orderCode),
        });
      }, 5000),
    [trackAmplitudeEvent],
  );

  const productSearchFilterProps = {
    placeholder: "Search by merchant name, customer name or order ID",
    handleChange: (value) => {
      setSearchValue(value);
      setPage(1);
      debouncedTrackSearch(value);
    },
  };

  const paginationProps = {
    pageSize: pageSize,
    current: page,
    total: meta?.totalData,
    showSizeChanger: true,
    onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
    onChange: (page) => setPage(page),
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: useCallback((newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(newSelectedRows);
    }, []),
  };

  const handleTableChange = (_, __, sorter) => {
    let newSortFilter = [];
    if (Array.isArray(sorter)) {
      newSortFilter = sorter
        .reverse()
        .map(({ columnKey, order }) => ({ order: columnKey, sort: sortValues[order] }));
    } else {
      const { order, columnKey } = sorter;
      if (order !== undefined) newSortFilter.push({ order: columnKey, sort: sortValues[order] });
    }
    setSortFilter(newSortFilter);
  };

  const tableProps = {
    onRow,
    loading,
    dataSource,
    columns: columnInfo,
    pagination: paginationProps,
    scroll,
    size: "small",
    rowKey: "id",
    rowSelection: isSelectMultiple
      ? {
          type: "checkbox",
          ...rowSelection,
        }
      : undefined,
    onChange: handleTableChange,
  };

  const exceptionOptions =
    currentRole === "ADMIN"
      ? [OrderStatus.ORDER_FINISHED.value, OrderStatus.WAITING_FOR_PICK_UP.value]
      : [OrderStatus.ORDER_FINISHED.value];
  const filteredOptions = orderOptions.filter((option) => !exceptionOptions.includes(option.value));

  return (
    <div id="order-list-template-wrapper">
      <div id="order-list-template-filter">
        <Row gutter={12}>
          <Col span={8}>
            <ProductSearchFilter {...productSearchFilterProps} />
          </Col>
          <PeriodSelector periodeState={[periode, setPeriode]} colSpan={16} />
        </Row>
      </div>
      <div id="order-list-template-filter-order">
        <Row gutter={12}>
          <PeriodSelectorOrder
            options={filteredOptions}
            periodeState={[statusOrder, setStatusOrder]}
            colSpan={17}
          />
          {isSelectMultiple ? (
            <Col
              span={7}
              style={{ display: "flex", gap: 8, flexWrap: "wrap", justifyContent: "flex-end" }}
            >
              <ButtonCustom
                className="button-cancel-selected-orders divided"
                style={{ maxWidth: "120px" }}
                block={true}
                color="#BDE3FF"
                handleClick={handleCloseMultipleSelect}
              >
                <span>{selectedRows.length + " " + t("Selected")}</span>
                <CloseOutlined />
              </ButtonCustom>

              <Dropdown menu={{ items: downloadItems }}>
                <a onClick={(e) => e.preventDefault()}>
                  <ButtonPrimary
                    className={isEmpty(selectedRows) ? "divided disabled-button" : "divided"}
                    text="Download"
                    block={true}
                    icon={<DownloadOutlined />}
                    loading={selectedRowsLoading}
                    disabled={isEmpty(selectedRows) ? true : false}
                  />
                </a>
              </Dropdown>
              <ButtonPrimary
                className={
                  isEmpty(selectedRows) || statusOrder === "" || isEmpty(statusOrder?.[0])
                    ? "divided disabled-button"
                    : "divided"
                }
                text="Edit"
                block={true}
                icon={<EditOutlined />}
                disabled={
                  isEmpty(selectedRows) || statusOrder === "" || isEmpty(statusOrder?.[0])
                    ? true
                    : false
                }
                handleClick={handleMultipleEdit}
              />
            </Col>
          ) : (
            <Col span={7}>
              <ButtonPrimary
                className="float-right"
                text="Select Multiple"
                handleClick={() => setIsSelectMultiple(true)}
              />
            </Col>
          )}
        </Row>
      </div>
      <div id="order-list-template-content">
        <Table async {...tableProps} />
      </div>
      <div>
        <UpdateOrdersStatusModal
          isLoading={isLoading}
          currentRole={currentRole}
          refetch={refetch}
          isMultipleUpdates={isMultipleUpdates}
          handleUpdateStatusToWaiting={
            isMultipleUpdates ? handleMultipleUpdatesStatusToWaiting : handleUpdateStatusToWaiting
          }
          handleUpdateStatusToCancel={
            isMultipleUpdates ? handleMultipleUpdatesStatusToCancel : handleUpdateStatusToCancel
          }
          handleUpdateStatus={() => {
            if (isMultipleUpdates) handleMultipleUpdatesStatus();
            else handleUpdateStatus({ orderStatus: newStatus });
          }}
        />
      </div>
    </div>
  );
};

export const DateComponent = React.memo(({ value }) => (
  <Space className="date-cell" size="small" direction="vertical">
    <Text>{dayjs(value).format("DD MMM YYYY")}</Text>
    <Text>{dayjs(value).format("HH:mm")}</Text>
  </Space>
));

export const Actions = React.memo(({ link, disabled = false, newTab = false }) => {
  const history = useHistory();

  const handleClick = () => {
    if (newTab) window.open(link);
    else history.push(link);
  };

  return (
    <div className="flex-center">
      <ButtonPrimaryActionTable
        text="viewInvoice"
        title="viewInvoice"
        icon={<FileSearchOutlined />}
        disabled={disabled}
        handleClick={handleClick}
      />
    </div>
  );
});

export default OrderList;

import {
  FileTextOutlined,
  HomeOutlined,
  InboxOutlined,
  PicLeftOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserRoles, server } from "../../../constants";
const dataFromLS = localStorage.getItem(server.ABOUT_USER) === 'undefined' || !localStorage.getItem(server.ABOUT_USER) ?  '{ "firstName": "" }' : localStorage.getItem(server.ABOUT_USER);
const aboutUser = JSON.parse?.(dataFromLS);
const firstName = aboutUser?.firstName || '';

export const items = [
  // ADMIN & BUYER HOME
  {
    title: "Home",
    path: "/home",
    icon: <HomeOutlined />,
    permission: [UserRoles.ADMIN, UserRoles.SALESMAN],
  },
  // BASKIT ADMIN HOME
  {
    title: firstName ?? "User",
    path: "/home",
    icon: <UserOutlined />,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    title: "Product",
    path: "/product",
    icon: <InboxOutlined />,
    subMenu: [
      { title: "Product List", path: "/product-list" },
      { title: "Add Product", path: "/add-product" },
    ],
    permission: [UserRoles.ADMIN],
  },
  {
    title: "Order",
    path: "/order",
    icon: <ShoppingOutlined />,
    subMenu: [
      { title: "POS", path: "/pos" },
      { title: "Order List", path: "/order-list" },
    ],
    permission: [UserRoles.ADMIN],
  },
  { title: "Customer", path: "/customer", icon: <TeamOutlined />, permission: [UserRoles.ADMIN] },
  {
    title: "My Account",
    icon: <SettingOutlined />,
    subMenu: [
      { title: "Account", path: "/my-account" },
      { title: "Receipt Settings", path: "/setting-receipt" },
    ],
    permission: [UserRoles.ADMIN],
  },
  {
    title: "Account Manager",
    path: "/my-acount",
    icon: <PicLeftOutlined />,
    subMenu: [
      { title: "Seller", path: "/seller" },
      { title: "Sales", path: "/sales" },
      { title: "coverageArea", path: "/coverage-area" },
    ],
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    title: "Merchant Management",
    path: "/merchant-management",
    icon: <ShopOutlined />,
    subMenu: [{ title: "Merchant List", path: "/merchant-list" }],
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    title: "Transaction Management",
    path: "/transaction-management",
    icon: <FileTextOutlined />,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    title: "Buyer Management",
    path: "/buyer-management",
    icon: <TeamOutlined />,
    permission: [UserRoles.BASKIT_ADMIN],
  },
];

import CustomLink from "../../components/atoms/CustomLink/CustomLink";
import TagCustom from "../../components/atoms/TagCustom/TagCustom";
import { SellerStatus } from "../../constants";
import { t } from "../../utils/Functions";

export const getType = (status) => {
  switch (status) {
    case SellerStatus.ACTIVE.value:
      return "success";
    case SellerStatus.CHURNED.value:
      return "danger";
    case SellerStatus.DROPPED.value:
      return "warning";
    default:
      return "neutral";
  }
};

export const columns = [
  {
    title: t("Name"),
    dataIndex: "name",
    key: "name",
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <TagCustom
        className="strong"
        text={status ? SellerStatus[status]?.label : "undefined"}
        type={getType(status)}
      />
    ),
  },
  {
    title: t("Store name"),
    dataIndex: "storeName",
    key: "storeName",
    render: (data) => <CustomLink text={data?.companyName} path={`/seller/${data?.id}`} />,
  },
  {
    title: t("Registration Date"),
    dataIndex: "registrationDate",
    key: "registrationDate",
  },
  // {
  //   title: t("Action"),
  //   dataIndex: "action",
  //   key: "action",
  // },
];

// @ts-check
import { Image } from 'antd';
import React from 'react';

const Spacer = ({
  bar = true,
  size = 2,
}) => (
  <tr>
    <td colSpan={6}>
      <div className={`spacer-${size}${bar ? ' bar' : ''}`}></div>
    </td>
  </tr>
);

/**
 *
 * @param {Partial<BaskitApp.ReceiptSettings>} props
 * @returns {JSX.Element}
 */
const ReceiptPreview = ({
  storeName = '',
  storeAddress = '',
  storePhone = '',
  storeInitial = 'AAA',
  size = '80mm',
  receiptNumber = true,
}) => {
  return (
    <div className="flex-center">
      { size === '210mm' ?
        <Image preview={false} src="/images/coming-soon.svg" /> :
        <div style={{ width: size, padding: 8, border: '1px solid black', borderRadius: 12 }}>
          <table>
            <tbody>
              <tr>
                <td colSpan={6} className="text-center receipt-title">
                  {storeName}
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="text-center receipt-header">
                  {storeAddress}
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="text-center receipt-header">
                  {storePhone}
                </td>
              </tr>
              { receiptNumber &&
                <tr className="order-info">
                  <td colSpan={6}>
                    <div className="flex-between">
                      <span>
                        No Struk
                      </span>
                      <span>
                        {storeInitial} 00001-[dd]/[mm]/[yy]
                      </span>
                    </div>
                  </td>
                </tr>
              }
              <tr className="order-info">
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Order ID</span>
                    <span>#1234567</span>
                  </div>
                </td>
              </tr>
              <tr className="order-info">
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Status</span>
                    <span>Belum Dibayar</span>
                  </div>
                </td>
              </tr>
              <Spacer />
              <tr className="customer-info-header">
                <td colSpan={3}>Nama</td>
                <td colSpan={3}>Alamat</td>
              </tr>
              <tr>
                <td colSpan={3}>[Nama Pelanggan]</td>
                <td colSpan={3}>[Alamat Pelanggan]</td>
              </tr>
              <tr className="customer-info-header">
                <td colSpan={3}>No. Telp</td>
                <td colSpan={3}>Tanggal</td>
              </tr>
              <tr>
                <td colSpan={3}>[Nomor Telepon Pelanggan]</td>
                <td colSpan={3}>[Tanggal Transaksi]</td>
              </tr>
              <Spacer />
              <tr className="detail-info info-header">
                <td colSpan={2}>Item</td>
                <td colSpan={2}>Qty</td>
                <td colSpan={2}>Price</td>
              </tr>
              <tr className="detail-info">
                <td colSpan={2}>Pulpy Orange  300ml x12</td>
                <td colSpan={2}>2</td>
                <td colSpan={2}>Rp. 78.000</td>
              </tr>
              <tr className="detail-info">
                <td colSpan={2}>Air mineral Aqua botol karton 1500ml</td>
                <td colSpan={2}>10</td>
                <td colSpan={2}>Rp. 54.000</td>
              </tr>
              <tr className="detail-info">
                <td colSpan={2}>Coca-cola kaleng 1500ml</td>
                <td colSpan={2}>8</td>
                <td colSpan={2}>Rp. 82.000</td>
              </tr>
              <Spacer />
              <tr>
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Total</span>
                    <span>Rp 214.000</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Potongan Harga</span>
                    <span>Rp 0</span>
                  </div>
                </td>
              </tr>
              <Spacer bar={false} size={1} />
              <tr className="order-total">
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Total Pembayaran</span>
                    <span>Rp 214.000</span>
                  </div>
                </td>
              </tr>
              <Spacer bar={false} size={1} />
              <tr>
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Tunai</span>
                    <span>Rp 250.000</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div className="flex-between">
                    <span>Uang Kembali</span>
                    <span>Rp 36.000</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="text-center">
                  <span style={{
                      fontStyle: 'italic',
                    }}
                  >
                    Powered By
                  </span>
                  <svg width="701" height="243" viewBox="0 0 701 243" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: '4px',
                      height: '8px',
                      width: 'auto',
                    }}
                  >
                    <path d="M38.3006 39.9319C31.4887 39.9319 25.5811 39.9779 19.6738 39.9209C9.88875 39.8269 4.93061 34.8339 4.87062 25.1089C4.77065 9.48887 5.32438 8.92788 20.7248 8.92688L63.1882 8.92188H69.1962V68.7059C72.0594 67.6909 74.303 66.9669 76.4944 66.1059C102.147 56.0549 127.338 61.2739 146.251 81.0009C157.399 92.6279 162.505 106.893 163.346 122.901C164.302 141.18 161.424 158.469 149.588 173.023C135.505 190.342 117.166 197.999 94.675 196.459C68.9086 194.695 45.0733 173.79 39.8138 146.641C38.8437 141.387 38.3632 136.055 38.3778 130.712C38.2378 102.774 38.3075 74.8339 38.3045 46.8949L38.3006 39.9319ZM132.015 128.754C132.015 127.375 132.091 125.841 132.004 124.317C131.085 108.217 122.041 96.8729 107.211 93.1799C94.7245 90.0699 80.676 96.4969 73.6451 108.58C68.8885 116.756 68.8465 125.792 69.4803 134.761C71.2448 159.617 97.0951 173.48 117.258 160.469C128.713 153.084 132.514 141.954 132.015 128.754V128.754Z" fill="black"/>
                    <path d="M461.712 127.485C461.712 145.96 461.511 162.771 461.771 179.575C462.008 194.717 447.428 200.045 436.323 195.699C431.716 193.899 430.666 189.561 430.678 184.774C430.761 147.66 430.776 110.545 430.724 73.4306C430.724 52.2106 430.724 30.9916 430.724 9.35156H461.514V111.752C466.179 106.582 469.812 102.622 473.373 98.5936C483.493 87.1346 493.525 75.5936 503.749 64.2316C505.148 62.6716 507.432 60.9846 509.341 60.9406C522.109 60.6506 534.888 60.7896 549.542 60.7896L497.151 118.564C510.757 135.234 524.202 151.618 537.539 168.09C541.56 173.058 547.226 177.719 545.318 185.011C544.354 188.693 541.977 192.911 538.939 194.96C531.824 199.748 521.275 197.567 515.713 191.016C499.641 172.082 483.615 153.112 467.635 134.104C466.059 132.244 464.387 130.469 461.712 127.485Z" fill="black"/>
                    <path d="M256.868 191.138C243.957 196.963 229.823 198.238 215.098 196.251C198.171 193.971 182.727 178.567 180.376 161.463C178.654 148.945 179.705 137.056 186.367 126.123C194.844 112.211 208.083 105.377 223.588 103.249C234.485 101.749 245.612 101.91 256.627 101.329C256.114 95.9582 252.906 92.8422 247.195 92.7972C234.966 92.6972 222.735 92.7562 210.506 92.7502C208.428 92.7502 206.35 92.7502 203.873 92.7502V61.5502C204.189 61.3892 204.673 60.9252 205.164 60.9252C219.301 60.9042 233.475 60.2882 247.567 61.0912C272.6 62.5162 288.737 79.4592 289.14 104.53C289.54 129.377 289.373 154.235 289.185 179.087C289.077 193.254 277.196 201.266 263.892 196.3C261.576 195.431 259.735 193.294 256.868 191.138ZM256.877 133.131C248.418 133.131 239.862 132.79 231.356 133.285C227.487 133.51 223.542 134.998 219.934 136.597C212.337 139.967 209.065 147.619 211.084 156.173C212.768 163.309 216.072 165.427 225.725 165.514C228.014 165.534 230.313 165.597 232.593 165.428C250.113 164.131 260.042 151.099 256.877 133.131Z" fill="black"/>
                    <path d="M347.301 197.109C339.078 197.109 330.854 197.156 322.631 197.096C313.949 197.033 311.278 194.433 311.383 185.969C311.43 182.182 311.083 178.1 312.341 174.678C314.986 167.49 321.275 165.184 328.453 165.145C342.605 165.068 356.762 164.888 370.906 165.218C376.405 165.346 378.452 163.28 378.699 158.031C379.399 143.075 378.97 142.531 363.996 142.517C357.498 142.51 350.978 142.703 344.493 142.348C324.45 141.254 310.166 126.007 310.231 105.948C310.248 100.797 309.796 95.507 310.777 90.521C314.23 72.993 328.354 61.379 346.589 60.931C359.969 60.602 373.361 60.731 386.747 60.804C394.937 60.85 398.583 64.704 398.567 72.835C398.542 85.457 392.062 92.1491 379.18 92.4161C369.241 92.6221 359.291 92.3951 349.347 92.4661C341.702 92.5211 341.107 93.383 341.202 103.258C341.268 110.258 342.102 110.974 349.176 111.139C359.473 111.379 369.87 110.985 380.031 112.312C396.918 114.512 409.982 130.012 409.973 146.997C409.973 154.957 410.131 163.213 408.19 170.816C404.122 186.747 389.196 196.847 371.401 197.126C363.371 197.252 355.337 197.147 347.308 197.147L347.301 197.109Z" fill="black"/>
                    <path d="M637.316 92.633H621.681V60.904H636.692C638.591 53.778 634.472 45.416 641.251 40.604C645.24 37.773 650.593 36.337 655.538 35.527C660.62 34.693 666.535 38.937 667.645 44.127C668.744 49.251 668.461 54.669 668.838 60.796C674.452 60.796 679.915 60.809 685.379 60.796C690.554 60.781 696.157 60.196 698.912 65.896C701.972 72.225 700.737 78.685 697.404 84.496C694.105 90.257 688.439 92.53 681.972 92.727C677.774 92.855 673.575 92.751 668.944 92.751C668.702 94.281 668.538 95.8223 668.454 97.369C668.425 115.539 668.425 133.709 668.454 151.878C668.476 159.517 670.139 161.629 677.551 163.285C681.255 164.112 685.04 164.621 688.814 165.075C695.664 165.9 699.082 169.483 699.281 176.297C699.304 177.062 699.281 177.827 699.281 178.597C699.288 190.534 695.496 195.436 683.585 195.708C671.615 195.981 660.151 193.088 650.705 184.854C641.259 176.62 637.472 165.87 637.354 153.746C637.176 133.677 637.316 113.614 637.316 92.633Z" fill="black"/>
                    <path d="M569.696 60.7676H600.207C600.452 62.058 600.616 63.3626 600.699 64.6736C600.718 103.14 600.709 141.605 600.671 180.067C600.65 190.007 595.599 195.485 585.682 196.95C575.519 198.45 569.772 193.699 569.717 183.422C569.617 165.243 569.695 147.063 569.696 128.883C569.696 108.597 569.696 88.3126 569.696 68.0286V60.7676Z" fill="black"/>
                    <path d="M70.2027 242.23C59.8058 242.179 52.0321 234.316 52.271 224.082C52.5189 213.482 60.2086 206.31 71.2253 206.411C81.2423 206.503 88.6931 214.111 88.7201 224.282C88.7511 234.93 81.1634 242.284 70.2027 242.23Z" fill="black"/>
                    <path d="M600.572 6.07812C600.572 14.4321 601.126 22.4941 600.293 30.4101C599.975 33.4291 596.939 37.9261 594.342 38.5841C588.2 40.0663 581.843 40.4409 575.569 39.6901C573.405 39.4421 570.347 34.9241 570.114 32.1531C569.397 23.6531 569.853 15.0531 569.853 6.07812H600.572Z" fill="black"/>
                    <path d="M127.239 242.156C116.464 242.156 109.344 235.101 109.355 224.426C109.366 213.533 116.319 206.448 127.024 206.426C138.111 206.399 145.382 213.553 145.351 224.461C145.323 234.993 137.993 242.154 127.239 242.156Z" fill="black"/>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};

export default ReceiptPreview;
import React from "react";
import { Button, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import "./ButtonCustom.scss";

const ButtonCustom = ({
  children,
  text,
  className = "",
  disabled,
  block = false,
  color = "#E89B4C",
  height = "44px",
  type = "primary",
  htmlType = "button",
  danger = false,
  icon,
  handleClick,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <ConfigProvider theme={{ token: { colorPrimary: color } }}>
      <Button
        className={"button-custom " + className}
        type={type}
        disabled={disabled}
        htmlType={htmlType}
        block={block}
        danger={danger}
        onClick={handleClick}
        icon={icon}
        style={{ height: height, ...style }}
      >
        {text === undefined ? children : t(text)}
      </Button>
    </ConfigProvider>
  );
};

export default ButtonCustom;

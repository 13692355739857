import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchSKUTransaction } from "../../actions/dashboard.action";
import { isEmpty } from "lodash-es";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";

const { Text, Title } = Typography;
const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} className="sku-transaction-title" textAnchor="middle">
        {payload.value}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const renderActiveShapeDefault = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} className="sku-transaction-title" textAnchor="middle">
        0
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const COLORS = ["#0A8080", "#F7CE2C", "#F24822"];

const SKUTransaction = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  const { period, skuTransaction } = useSelector((state) => state.dashboardReducer);
  const dataDefault = [{ name: "Default Transaction", value: 100 }];

  useEffect(() => {
    // if (period) dispatch(fetchSKUTransaction({ periode: period }));
    // else {
    //   setTotal(0);
    //   setData([]);
    // }
  }, [period]);

  useEffect(() => {
    if (!isEmpty(skuTransaction)) {
      const { successful, waiting, canceled } = skuTransaction;
      const _total = successful + waiting + canceled;
      const _data = [
        { name: "successful", value: successful },
        { name: "waiting", value: waiting },
        { name: "canceled", value: canceled },
      ];

      setData(_data);
      setTotal(_total);
      setLoading(false);
    }
  }, [skuTransaction]);

  return (
    <div id="sku-transaction">
      <Title level={4} className="text-center">
        {t("SKUs Transaction")}
      </Title>
      <Divider />

      {loading ? (
        <SpinLoaderContent />
      ) : (
        <>
          <ResponsiveContainer width="100%" height={230}>
            <PieChart>
              {total ? (
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              ) : (
                <Pie
                  activeIndex={0}
                  activeShape={renderActiveShapeDefault}
                  data={dataDefault}
                  innerRadius={70}
                  outerRadius={100}
                  fill="#e1e1e1"
                  dataKey="value"
                />
              )}
            </PieChart>
          </ResponsiveContainer>
          <div className="sku-transaction-label">
            <ItemLable name="Transaction Successful" value={data[0]?.value || 0} status="success" />
            <ItemLable name="Waiting for Payment" value={data[1]?.value || 0} status="warning" />
            <ItemLable name="Transaction Cancelled" value={data[2]?.value || 0} status="danger" />
          </div>
        </>
      )}
    </div>
  );
};

const ItemLable = ({ name, value, status }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={12}>
        <Text className={`sku-transaction-label-point-${status}`}>{t(name)}</Text>
      </Col>
      <Col span={12} className="text-right">
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

export default SKUTransaction;

import * as Nominatim from 'nominatim-browser';
import { useState } from 'react';
import { debounce } from "lodash-es";

function usePlacesService(option) {
  const [placePredictions, setPlacePredictions] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getPlacePredictions = debounce(async ({ input }) => {
    setIsLoading(true);
    const results = await Nominatim.geocode({
      q: input,
      addressdetails: true,
    });
    setIsLoading(false);
    setPlacePredictions(results);
    return results;
  }, option.debounce);

  return {
    placePredictions,
    getPlacePredictions, // search
    isPlacePredictionsLoading: isLoading,
  }
}

export default usePlacesService;

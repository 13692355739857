import { httpClient } from "./../utils/HttpClient";
import swal from "sweetalert";
import {
  SHOP_FETCHING,
  SHOP_FAILED,
  SHOP_CLEAR,
  SHOP_SUCCESS,
  SHOP_UPDATE_ORDER,
  SHOP_UPDATE_PAYMENT,
  SHOP_LIST_SUCCESS,
  server,
  SHOP_PAYMENT_TYPE_FAILED,
  SHOP_PAYMENT_TYPE_FETCHING,
  SHOP_PAYMENT_TYPE_SUCCESS,
} from "../constants";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { parseParamsString } from "../utils/Functions";

const setStateShoptoUpdateOrder = (payload) => ({
  type: SHOP_UPDATE_ORDER,
  payload: payload,
});
export const setShopStateToFetching = () => ({
  type: SHOP_FETCHING,
});
export const setShopStateToFailed = () => ({
  type: SHOP_FAILED,
});
export const setShopStateToClear = () => ({
  type: SHOP_CLEAR,
});
export const setShopStateToSuccess = (payload) => ({
  type: SHOP_SUCCESS,
  payload,
});
export const setShopListStateToSuccess = (payload) => ({
  type: SHOP_LIST_SUCCESS,
  payload,
});

export const setPaymentTypeToFailed = () => ({
  type: SHOP_PAYMENT_TYPE_FAILED,
});

export const setPaymentTypeToFetching = () => ({
  type: SHOP_PAYMENT_TYPE_FETCHING,
});

export const setPaymentTypeToSuccess = (payload) => ({
  type: SHOP_PAYMENT_TYPE_SUCCESS,
  payload,
});

const doUpdateOrder = (dispatch, getState, orderLines) => {
  // debugger;
  let totalPrice = 0;
  let taxAmt = 0;
  for (let item of orderLines) {
    totalPrice += item.price * item.qty;
  }
  taxAmt = (totalPrice * getState().orderReducer.tax) / 100;

  dispatch(
    setStateShoptoUpdateOrder({
      orderLines,
      totalPrice: totalPrice + taxAmt,
      taxAmt,
      subtotalPrice: totalPrice,
    }),
  );
};

export const setOrder = (payload) => {
  return (dispatch, getState) => {
    doUpdateOrder(dispatch, getState, payload);
  };
};

export const setTotal = () => {
  return (dispatch, getState) => {
    let orderLines = getState().shopReducer.mOrderLines;

    doUpdateOrder(dispatch, getState, orderLines);
  };
};

export const addOrder = (item, qty) => {
  return (dispatch, getState) => {
    let orderLines = getState().shopReducer.mOrderLines;
    let index = orderLines.findIndex((i) => i._id === item._id);
    if (index === -1) {
      item.qty = qty;
      orderLines.unshift(item);
    } else {
      orderLines[index].qty -= -qty;
    }

    doUpdateOrder(dispatch, getState, orderLines);
  };
};

export const removeOrder = (product) => {
  return (dispatch, getState) => {
    let orderLines = getState().shopReducer.mOrderLines;
    var foundIndex = orderLines.indexOf(product);

    orderLines.map((item) => {
      if (item.product_id === product.product_id) {
        item.qty = 1;
      }
    });
    orderLines.splice(foundIndex, 1);

    doUpdateOrder(dispatch, getState, orderLines);
  };
};

export const submitPayment = (data) => {
  return (dispatch, getState) => {
    httpClient.post(server.ORDER_URL, data).then(() => {
      swal({
        title: "Your are made sale success",
        icon: "success",
        buttons: true,
      });
      getState().shopReducer.mOrderLines = [];
      dispatch({
        type: SHOP_UPDATE_PAYMENT,
        payload: {
          isPaymentMade: false,
          given: 0,
        },
      });
    });
  };
};

export const togglePaymentState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SHOP_UPDATE_PAYMENT,
      payload: {
        isPaymentMade: !getState().shopReducer.mIsPaymentMade,
        given: !getState().shopReducer.mGiven,
      },
    });
  };
};

export const getPaymentType = () => {
  return async (dispatch) => {
    dispatch(setPaymentTypeToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL_NEW_V2 + 'payment-type');
      dispatch(setPaymentTypeToSuccess(response.data.data))
      SuccessNotification(response);
    } catch (error) {
      dispatch(setPaymentTypeToFailed());
    }
  }
}

export const getCart = (source, orderType = 'OFFLINE') => {
  return async (dispatch) => {
    dispatch(setShopStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL_NEW_V2 + "order", { params: { createdBy: source, orderType }});
      dispatch(setShopListStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setShopStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const addToCart = (values, source, orderType) => {
  return async (dispatch) => {
    dispatch(setShopStateToFetching());
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL_NEW_V2 + 'cart', values);
      dispatch(setShopStateToClear());
      dispatch(getCart(source, orderType));
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setShopStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const updateCart = (values, source, orderType) => {
  return async (dispatch) => {
    dispatch(setShopStateToFetching());
    try {
      const response = await httpClient.patch(process.env.REACT_APP_API_URL_NEW_V2 + `cart/${source}`, values);
      dispatch(setShopStateToClear());
      dispatch(getCart(values.userId, orderType));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setShopStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const deleteFromCart = (source, orderType) => {
  return async (dispatch) => {
    dispatch(setShopStateToFetching());
    try {
      const response = await httpClient.delete(process.env.REACT_APP_API_URL_NEW_V2 + `cart/${source}`);
      const { data } = response.data;
      dispatch(setShopStateToClear());
      dispatch(getCart(data.userId, orderType));
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setShopStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const createOrderPOS = (source, params = null, values) => {
  return async (dispatch) => {
    dispatch(setShopStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + 'order',
        values,
      );
      dispatch(setShopStateToClear());
      SuccessNotification(response);
      return { isSuccess: true, orderId: response.data.data.id };
    } catch (error) {
      dispatch(setShopStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false, orderId: {} };
    }
  };
};

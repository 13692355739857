import React from "react";
import ProductNotFound from "../../components/atoms/ProductNotFound/ProductNotFound";

const NotFound = () => {
  const productNotFoundProps = {
    title: "Page not found",
    description: "Please try another keyword to find the page you are looking for",
  };

  return (
    <div>
      <ProductNotFound {...productNotFoundProps} />
    </div>
  );
};

export default NotFound;

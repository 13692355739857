import { Modal, Button, Form } from 'antd';
import { RetweetOutlined } from "@ant-design/icons";
import Card from './Card';
import { formatRupiah } from '../../../utils/Functions';
import useEditOrder from './useEditOrder';
import deepDiff from 'deep-diff';
import './index.scss';
import Confirmation from './Confirmation';

function EditOrder({
  orderId,
  open,
  data,
  onCancel,
  onFinish,
  forceEnableEditing,
  isMultipleUpdates,
}) {
  const {
    onClose,
    createOnCancel,
    createOnChangeNotes,
    createOnChangePrice,
    createOnChangeQty,
    changedOrderDetail,
    createOnChangeReason,
    totalPayment,
    totalMargin,
    orderDetail,
    isEditing,
    onSave,
    onReset,
    onError,
    isReset,
    form,
    reason,
  } = useEditOrder({
    data,
    onCancel,
    orderId,
    onFinish,
    forceEnableEditing,
  });

  if (isMultipleUpdates) {
    return (
      <Confirmation
        open={open}
        onOk={onFinish}
        onClose={onCancel}
      />
    );
  }

  if (!changedOrderDetail) {
    return null;
  }

  return (
    <Modal
      className='editOrder__modal'
      open={open}
      footer={null}
      maskClosable={true}
      width="70rem"
      closeIcon={<img src="/images/x-mark.svg" onClick={onClose} height={16} alt='x-mark' />}
      closable
      centered
    >
      <div className="editOrder">
        <Form
          form={form}
          onFinish={onSave}
          onFinishFailed={onError}
          autoComplete="off"
        >
          <Form.List name="orderDetail">
            {(fields) => {
              return (
                <>
                  <div className="editOrder__header">
                    <Button
                      type="primary"
                      icon={<RetweetOutlined />}
                      onClick={onReset}
                    >
                      Reset semua
                    </Button>
                    <h3>Edit Pesanan</h3>
                  </div>
                  <div className="editOrder__main">
                    {orderDetail?.map((item, index) => (
                      <Card
                        key={fields[index]?.key}
                        isReset={isReset}
                        item={item}
                        reason={reason}
                        field={fields[index]}
                        errorField={form.getFieldError(['orderDetail', fields?.[index]?.key, 'reasonId'])}
                        diff={deepDiff.diff({ qty: item?.initial?.initialQty, sellingPrice: item?.initial?.initialPrice }, { qty: changedOrderDetail?.[index]?.qty, sellingPrice: changedOrderDetail?.[index]?.sellingPrice })}
                        changedItem={changedOrderDetail?.[index]}
                        onChangeQty={createOnChangeQty(item.id)}
                        onChangePrice={createOnChangePrice(item.id)}
                        onChangeReason={createOnChangeReason(item.id)}
                        onChangeNotes={createOnChangeNotes(item.id)}
                        onCancel={createOnCancel(item.id)}
                      />
                    ))}
                  </div>
                  <div className="editOrder__footer">
                    <div className="flex-between">
                      <div className="editOrder__payment">
                        <div className="editOrder__totalPayment flex-between">
                          <span className="label">Total Pembayaran</span>
                          <span className="price">{formatRupiah(totalPayment)}</span>
                        </div>
                        <div className="editOrder__margin flex-between">
                          <span className="label">Total Margin</span>
                          <span className="price">{formatRupiah(totalMargin)}</span>
                        </div>
                      </div>
                      <div className="editOrder__info flex-between">
                        <img src={`${process.env.PUBLIC_URL}/images/exclaimation-circle.svg`} alt="info" />
                        <p>Harga dan nilai margin akan berubah sesuai dengan quantity yang dipilih</p>
                      </div>
                    </div>
                    <div className="editOrder__CTA">
                      <div className="wrapper">
                        <Button className="cancel" onClick={onClose} type="secondary" ghost>
                          Batalkan
                        </Button>
                        <Form.Item>
                          <Button disabled={!isEditing} className="save" type="primary" htmlType="submit">
                            Simpan Perubahan
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </>
              )
            }}
          </Form.List>
        </Form>
      </div>
    </Modal>
  );
}

export default EditOrder;

import React, { useEffect, useState } from "react";
import { Col, Image, Row, Space, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { formatRupiah } from "../../utils/Functions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import { fetchSKUOnBoard, loadMoreSKUONBoard, updateSKUOnBoard } from "../../actions/dashboard.action";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import SortProduct from "../../components/atoms/SortProduct/SortProduct";
import MultipleSelectCategory from "../../components/molecules/MultipleSelectCategory/MultipleSelectCategory";
import ButtonPrimaryActionTable from "../../components/atoms/ButtonPrimaryActionTable";
import SingleProductModal from "../../components/molecules/SingleProductModal/SingleProductModal";
import * as categoryActions from "../../actions/category.action";
import "./_style.scss";

const { Text, Title } = Typography;

const SKUOnBoard = () => {
  const constant = {
    HIGHEST: "highest",
    LOWEST: "lowest",
  };

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [sortProduct, setSortProduct] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [params, setParams] = useState({
    $limit: 20,
    $page: 1,
  });
  const dispatch = useDispatch();

  const { skuOnBoard } = useSelector((state) => state.dashboardReducer);
  const category = useSelector((state) => state.categoryReducer);

  useEffect(() => {
    dispatch(categoryActions.index());
    dispatch(fetchSKUOnBoard(params));
  }, []);

  useEffect(() => {
    if (!isEmpty(skuOnBoard)) {
      const { data } = skuOnBoard;
      setData(data);
      setOriginalData(data);
      setLoading(false);
    }
  }, [skuOnBoard]);

  useEffect(() => {
    console.log(category, 'category');
    if (category?.result) {
      const _categoryOptions = category?.result?.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setCategoryOptions(_categoryOptions);
    }
  }, [category]);

  useEffect(() => {
    if (sortProduct) {
      const _data = [...originalData];
      if (sortProduct === constant.HIGHEST) {
        _data.sort((a, b) => b.stock - a.stock);
        setData(_data);
      } else if (sortProduct === constant.LOWEST) {
        _data.sort((a, b) => a.stock - b.stock);
        setData(_data);
      } else {
        setData(originalData)
      }
    } else {
      setData(originalData);
    }
  }, [sortProduct, originalData]);

  useEffect(() => {
    var tableContent = document.querySelector(".ant-table-body");
    const handleScroll = (event) => {
      // checking whether a selector is well defined
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;
      if (currentScroll === maxScroll) {
        // load more data
        const _params = { ...params, $page: params.$page + 1 };
        dispatch(loadMoreSKUONBoard(_params));
        setParams(_params);
      }
    };

    tableContent.addEventListener("scroll", handleScroll);

    return () => {
      tableContent.removeEventListener("scroll", handleScroll);
    };
  }, [params.$page]);

  const handleProductSearch = (value) => {
    const _params = { ...params, $page: 1 };

    if (value) _params.search = value;
    else delete _params.search;
    dispatch(fetchSKUOnBoard(_params));
  };

  const handleMultipleSelectCategory = (values) => {
    const _params = { ...params, $page: 1 };

    if (!isEmpty(values)) _params.categoryId = values.join(",");
    else delete _params.categoryId;
    dispatch(fetchSKUOnBoard(_params));
  };

  const handleClickAction = (data) => {
    setSelectedProduct(data);
    setModalOpen(true);
  };

  const dataSource = data.map((data, index) => ({
    key: index,
    noSku: (
      <Space direction="horizontal" size="large">
        <div className="card-image-wrapper">
          <Image
            preview={false}
            src={data?.image ?? `${window.location.origin}/images/baskit-product.jpg`}
            fallback={`${window.location.origin}/images/baskit-product.jpg`}
          />
        </div>
        <Text ellipsis={true ? { tooltip: data?.sku } : false} style={{ width: "180px" }}>
          {data?.sku}
        </Text>
      </Space>
    ),
    fullName: data?.fullName,
    stock: data?.stock,
    basePrice: formatRupiah(data?.basePrice),
    action: (
      <div className="flex-center">
        <ButtonPrimaryActionTable
          text={t("updateSKU")}
          title={t("updateSKU")}
          handleClick={() => handleClickAction(data)}
        />
      </div>
    ),
  }));

  const columns = [
    {
      title: t("No. SKUs"),
      dataIndex: "noSku",
      key: "noSku",
    },
    {
      title: t("Product Name"),
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: t("Stock"),
      dataIndex: "stock",
      key: "stock",
      width: 150,
    },
    {
      title: t("Price"),
      dataIndex: "basePrice",
      key: "basePrice",
      width: 150,
    },
    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      width: 150,
    },
  ];

  const productSearchFilterProps = {
    placeholder: "Search vendor name, product, order ID",
    options: [],
    handleChange: handleProductSearch,
  };

  const multipleSelectCategoryProps = {
    options: categoryOptions,
    handleChange: handleMultipleSelectCategory,
    getPopupContainer: () => document.getElementById("home-multiple-selection-container"),
  };

  const sortProductProps = {
    placeholder: "Sort",
    handleChange: (value) => setSortProduct(value),
    options: [
      { value: constant.HIGHEST, label: "Highest Stock" },
      { value: constant.LOWEST, label: "Lowest Stock" },
    ],
    getPopupContainer: () => document.getElementById("home-multiple-selection-container"),
  };

  const tableProps = {
    loading,
    dataSource,
    columns,
    pagination: false,
  };

  const handleUpdateProduct = async (values) => {
    const { id, sellingPrice, margin } = selectedProduct;
    const { price, stock } = values;
    const dataToSend = {
      id,
      stock,
      basePrice: price,
      sellingPrice,
      margin,
    };

    dispatch(updateSKUOnBoard({ values: dataToSend, params, setModalOpen, setParams }));
  };

  const singleProductModalProps = {
    modalOpen,
    setModalOpen,
    data: selectedProduct,
    handleSubmit: handleUpdateProduct,
    buttonText: "Update data product",
  };

  return (
    <div id="sku-on-board" className="mt-20 mb-20">
      <div className="sku-on-board-filter">
        <Row gutter={12}>
          <Col span={6} className="flex-center">
            <Space direction="horizontal" size="large">
              <Title level={4} style={{ margin: 0 }}>
                {t("SKUs On Board")}
              </Title>
              <Text className="sku-number" strong>
                {skuOnBoard?.meta?.totalData} SKUs
              </Text>
            </Space>
          </Col>
          <Col span={8}>
            <ProductSearchFilter {...productSearchFilterProps} />
          </Col>
          <Col span={5}>
            <SortProduct {...sortProductProps} />
          </Col>
          <Col span={5}>
            <MultipleSelectCategory {...multipleSelectCategoryProps} />
          </Col>
        </Row>
      </div>
      <div className="sku-on-board-table">
        <Table {...tableProps} scroll={{ y: 500 }} />
      </div>
      <div>
        <SingleProductModal {...singleProductModalProps} />
      </div>
    </div>
  );
};

export default SKUOnBoard;

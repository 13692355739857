import {
  SUBCATEGORY_FETCHING,
  SUBCATEGORY_SUCCESS,
  SUBCATEGORY_FAILED,
  SUBCATEGORY_CLEAR,
  SUBCATEGORY_BY_CATEGORY_ID_FETCHING,
  SUBCATEGORY_BY_CATEGORY_ID_SUCCESS,
  SUBCATEGORY_BY_CATEGORY_ID_FAILED,
  SUBCATEGORY_OPTIONS_FETCHING,
  SUBCATEGORY_OPTIONS_SUCCESS,
  SUBCATEGORY_OPTIONS_FAILED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  subcategoryByCategoryId: null,
  isSubcategoryByCategoryIdFetching: false,
  isSubcategoryByCategoryIdError: false,
  isOptionsFetching: false,
  isOptionsError: false,
  options: null,
};

export default (state = initialState, { type, payload, subcategoryByCategoryId }) => {
  switch (type) {
    case SUBCATEGORY_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case SUBCATEGORY_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case SUBCATEGORY_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case SUBCATEGORY_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case SUBCATEGORY_BY_CATEGORY_ID_FETCHING:
      return {
        ...state,
        isSubcategoryByCategoryIdFetching: true,
        isSubcategoryByCategoryIdError: false,
        subcategoryByCategoryId: null,
      };
    case SUBCATEGORY_BY_CATEGORY_ID_FAILED:
      return {
        ...state,
        isSubcategoryByCategoryIdFetching: false,
        isSubcategoryByCategoryIdError: true,
        subcategoryByCategoryId: null,
      };
    case SUBCATEGORY_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        isSubcategoryByCategoryIdFetching: false,
        isSubcategoryByCategoryIdError: false,
        subcategoryByCategoryId,
      };
    case SUBCATEGORY_OPTIONS_FETCHING:
      return { ...state, isOptionsFetching: true, isOptionsError: false, options: null };
    case SUBCATEGORY_OPTIONS_FAILED:
      return { ...state, isOptionsFetching: false, isOptionsError: true, options: null };
    case SUBCATEGORY_OPTIONS_SUCCESS:
      return { ...state, isOptionsFetching: false, isOptionsError: false, options: payload };
    default:
      return state;
  }
};

import React from "react";
import { Button, Card, Col, Image, Row, Switch, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { formatRupiah, toSentenceCase } from "../../../utils/Functions";
import * as inventoryActions from "../../../actions/inventory.action";
import "./ProductListCard.scss";

const { Text } = Typography;

const ProductListCard = ({ data, handleClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <Card className="product-list-card" key={data?.id} hoverable>
        <Row>
          <Col span={24}>
            <Text className="ml-10">{t("Set Product")}</Text>

            <Switch
              className="float-right mr-10"
              checkedChildren={t("Active")}
              unCheckedChildren={t("Inactive")}
              defaultChecked={data.isActive}
              onChange={() => dispatch(inventoryActions.changeStatus(data?.id))}
            />
          </Col>

          <Col span={24}>
            <div className="card-image-wrapper">
              <Image
                width="100%"
                height={200}
                preview={false}
                src={data?.image ?? `${window.location.origin}/images/baskit-product.jpg`}
                fallback={`${window.location.origin}/images/baskit-product.jpg`}
              />
            </div>
          </Col>

          <Col span={24}>
            <div className="card-packaging">
              <Text strong>{toSentenceCase(data?.packType ?? "-")}</Text>
            </div>
          </Col>

          <Col span={24}>
            <div className="card-title">
              <Text className="ant-typography-ellipsis-multiple-line">{data?.fullName ?? "-"}</Text>
            </div>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={10}>
                <div className="ml-10">
                  <div>
                    <Text>{t("Price")}</Text>
                  </div>
                  <div>
                    <Text>{t("Stock")}</Text>
                  </div>
                </div>
              </Col>
              <Col span={14}>
                <div className="card-price-wrapper">
                  <div className="text-right mr-10">
                    <div>
                      <Text strong>{formatRupiah(data?.basePrice ?? 0)}</Text>
                    </div>
                    <div>
                      <Text strong style={{ marginRight: 5 }}>
                        {data?.stock ?? 0}
                      </Text>
                      <Text>{toSentenceCase(data?.packType ?? "-")}</Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <div className="card-button-wrapper">
              <Button block type="primary" htmlType="button" onClick={handleClick}>
                {t("Set prices and stock")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ProductListCard;

import { Actions, DateComponent } from "./OrderListOnline";
import { UpdateStatusCell } from "../../organisms/UpdateStatusCell";
import { formatRupiah, t } from "../../../utils/Functions";
import CustomLink from "../../atoms/CustomLink/CustomLink";
import PaymentStatusTag from "../../organisms/UpdateStatusCell/PaymentStatusTag";
import { DeliveryType } from "../../../constants";

export const columnsOffline = [
  {
    title: t("No_number"),
    dataIndex: "serialNo",
    key: "serialNo",
    width: 64,
  },
  {
    title: t("date"),
    dataIndex: "date",
    key: "date",
    width: 160,
    render: (value) => <DateComponent value={value} />,
  },
  {
    title: t("updated"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 160,
    render: (value) => <DateComponent value={value} />,
  },
  {
    title: t("merchantName"),
    dataIndex: "merchantName",
    key: "merchantName",
    width: 256,
  },
  {
    title: t("orderID"),
    dataIndex: "orderID",
    key: "orderID",
    width: 150,
    render: (_, record) => <CustomLink text={record?.orderID} path={record?.detailsPath} />,
  },
  {
    title: t("customer"),
    dataIndex: "customer",
    key: "customer",
    width: 160,
  },
  {
    title: t("grandTotal"),
    dataIndex: "total",
    key: "total",
    width: 160,
    sorter: {
      multiple: 1,
    },
    render: (value) => formatRupiah(value),
  },
  {
    title: t("marginValue"),
    dataIndex: "marginValue",
    key: "marginValue",
    width: 160,
    sorter: {
      multiple: 1,
    },
    render: (value) => formatRupiah(value),
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    fixed: "right",
    width: 256,
    render: (status) => (
      <PaymentStatusTag status={status} />
    ),
  },
];


export const columnsOnline = [
  {
    title: t("No_number"),
    dataIndex: "serialNo",
    key: "serialNo",
    width: 64,
  },
  {
    title: t("orderID"),
    dataIndex: "orderID",
    key: "orderID",
    width: 150,
    render: (_, record) => <CustomLink text={record?.orderID} path={record?.detailsPath} />,
  },
  {
    title: t("customer"),
    dataIndex: "customer",
    key: "customer",
    width: 160,
  },
  {
    title: t("date"),
    dataIndex: "date",
    key: "date",
    width: 160,
    render: (value) => <DateComponent value={value} />,
  },
  {
    title: t("updated"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 160,
    render: (value) => <DateComponent value={value} />,
  },
  {
    title: t("merchantName"),
    dataIndex: "merchantName",
    key: "merchantName",
    width: 256,
  },
  {
    title: t("grandTotal"),
    dataIndex: "total",
    key: "total",
    width: 160,
    sorter: {
      multiple: 1,
    },
    render: (value) => formatRupiah(value),
  },
  {
    title: t("deliveryType"),
    dataIndex: "deliveryType",
    key: "deliveryType",
    width: 160,
    render: (value) => DeliveryType[value]?.label || '-',
  },
  {
    title: t("marginValue"),
    dataIndex: "marginValue",
    key: "marginValue",
    width: 160,
    sorter: {
      multiple: 1,
    },
    render: (value) => formatRupiah(value),
  },
  {
    title: t("sales"),
    dataIndex: "sales",
    key: "sales",
    width: 160,
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    fixed: "right",
    width: 256,
    render: (_, record) => (
      <UpdateStatusCell
        orderId={record?.id}
        initialStatus={record?.status}
        disabled={record?.isSelectMultiple}
        deliveryType={record?.deliveryType}
      />
    ),
  },
  {
    title: t("transactionDetail"),
    dataIndex: "transactionDetails",
    key: "transactionDetails",
    fixed: "right",
    width: 160,
    render: (value, record) => (
      <Actions link={value} disabled={record?.isSelectMultiple} newTab={true} />
    ),
  },
];

export const columnsDefault = [
  {
    title: t("No_number"),
    dataIndex: "serialNo",
    key: "serialNo",
    width: 64,
  },
  {
    title: t("date"),
    dataIndex: "date",
    key: "date",
    width: 160,
    render: (value) => <DateComponent value={value} />,
  },
  {
    title: t("updated"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 160,
    render: (value) => <DateComponent value={value} />,
  },
  {
    title: t("merchantName"),
    dataIndex: "merchantName",
    key: "merchantName",
    width: 256,
  },
  {
    title: t("orderID"),
    dataIndex: "orderID",
    key: "orderID",
    width: 150,
    render: (_, record) => {
      return(
        <CustomLink
          onClick={(event) => {
            event.preventDefault();
            window?.parent?.postMessage(
              {
                type: 'transactionManagementDetail',
                payload: record?.detailsPath,
              },
              process.env.REACT_APP_NEW_CMS
            )
          }}
          text={record?.orderID} path={record?.detailsPath}
          newTab={true}
        />
      );
    },
  },
  {
    title: t("customer"),
    dataIndex: "customer",
    key: "customer",
    width: 160,
  },
  {
    title: t("grandTotal"),
    dataIndex: "total",
    key: "total",
    width: 160,
    sorter: {
      multiple: 1,
    },
    render: (value) => formatRupiah(value),
  },
  {
    title: t("marginValue"),
    dataIndex: "marginValue",
    key: "marginValue",
    width: 160,
    sorter: {
      multiple: 1,
    },
    render: (value) => formatRupiah(value),
  },
  {
    title: t("deliveryType"),
    dataIndex: "deliveryType",
    key: "deliveryType",
    width: 160,
    render: (value) => DeliveryType[value]?.label || '-',
  },
  {
    title: t("sales"),
    dataIndex: "sales",
    key: "sales",
    width: 160,
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    fixed: "right",
    width: 256,
    render: (_, record) => (
      <UpdateStatusCell
        orderId={record?.id}
        initialStatus={record?.status}
        disabled={record?.isSelectMultiple}
        deliveryType={record?.deliveryType}
      />
    ),
  },
  {
    title: t("transactionDetail"),
    dataIndex: "transactionDetails",
    key: "transactionDetails",
    fixed: "right",
    width: 160,
    render: (value, record) => (
      <Actions link={value} disabled={record?.isSelectMultiple} newTab={true} />
    ),
  },
];

export const statusMasking = (status) => {
  switch (status) {
    case 'WAITING_FOR_PICK_UP':
      return 'ORDER_CREATED'
    default:
      return status;
  }
}

import {
  DISTRICT_SEARCH_FAILED,
  DISTRICT_SEARCH_FETCHING,
  DISTRICT_SEARCH_SUCCESS,
  DISTRICT_DETAILS_FAILED,
  DISTRICT_DETAILS_FETCHING,
  DISTRICT_DETAILS_SUCCESS,
  ACTIVE_DEACTIVE_DISTRICT_FETCHING,
  ACTIVE_DEACTIVE_DISTRICT_FAILED,
  DISTRICT_ASSIGNED_FAILED,
  DISTRICT_ASSIGNED_FETCHING,
  DISTRICT_ASSIGNED_SUCCESS,
  DISTRICT_UNASSIGNED_FAILED,
  DISTRICT_UNASSIGNED_FETCHING,
  DISTRICT_UNASSIGNED_SUCCESS,
  ASSIGN_TO_DISTRICT_FETCHING,
  ASSIGN_TO_DISTRICT_FAILED,
  DELETE_FROM_DISTRICT_FETCHING,
  DELETE_FROM_DISTRICT_FAILED,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setDistrictSearchStateToFetching = () => ({
  type: DISTRICT_SEARCH_FETCHING,
});

export const setDistrictSearchStateToFailed = () => ({
  type: DISTRICT_SEARCH_FAILED,
});

export const setDistrictSearchStateToSuccess = (payload) => ({
  type: DISTRICT_SEARCH_SUCCESS,
  payload,
});

export const setDistrictDetailsStateToFetching = () => ({
  type: DISTRICT_DETAILS_FETCHING,
});

export const setDistrictDetailsStateToFailed = () => ({
  type: DISTRICT_DETAILS_FAILED,
});

export const setDistrictDetailsStateToSuccess = (payload) => ({
  type: DISTRICT_DETAILS_SUCCESS,
  payload,
});

export const setUpdateStatusDistrictStateToFetching = () => ({
  type: ACTIVE_DEACTIVE_DISTRICT_FETCHING,
});

export const setUpdateStatusDistrictStateToFailed = () => ({
  type: ACTIVE_DEACTIVE_DISTRICT_FAILED,
});

export const setDistrictAssignedStateToFetching = () => ({
  type: DISTRICT_ASSIGNED_FETCHING,
});

export const setDistrictAssignedStateToFailed = () => ({
  type: DISTRICT_ASSIGNED_FAILED,
});

export const setDistrictAssignedStateToSuccess = (payload) => ({
  type: DISTRICT_ASSIGNED_SUCCESS,
  payload,
});

export const setDistrictUnassignedStateToFetching = () => ({
  type: DISTRICT_UNASSIGNED_FETCHING,
});

export const setDistrictUnassignedStateToFailed = () => ({
  type: DISTRICT_UNASSIGNED_FAILED,
});

export const setDistrictUnassignedStateToSuccess = (payload) => ({
  type: DISTRICT_UNASSIGNED_SUCCESS,
  payload,
});

export const setAssignToDistrictStateToFetching = () => ({
  type: ASSIGN_TO_DISTRICT_FETCHING,
});

export const setAssignToDistrictStateToFailed = () => ({
  type: ASSIGN_TO_DISTRICT_FAILED,
});

export const setDeleteFromDistrictStateToFetching = () => ({
  type: DELETE_FROM_DISTRICT_FETCHING,
});

export const setDeleteFromDistrictStateToFailed = () => ({
  type: DELETE_FROM_DISTRICT_FAILED,
});

export const districtSearch = (params) => {
  return async (dispatch) => {
    dispatch(setDistrictSearchStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `district/search${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setDistrictSearchStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setDistrictSearchStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const districtDetails = (id) => {
  return async (dispatch) => {
    dispatch(setDistrictDetailsStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `district/detail/${id}`,
      );
      dispatch(setDistrictDetailsStateToSuccess(response.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setDistrictDetailsStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const updateStatus = (districtId, isActive) => {
  return async (dispatch) => {
    dispatch(setUpdateStatusDistrictStateToFetching());
    try {
      let response;
      if (isActive)
        response = await httpClient.get(
          process.env.REACT_APP_API_URL_NEW_V2 + `district/activate/${districtId}`,
        );
      else
        response = await httpClient.get(
          process.env.REACT_APP_API_URL_NEW_V2 + `district/deactivate/${districtId}`,
        );

      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setUpdateStatusDistrictStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const assigned = (id, params) => {
  return async (dispatch) => {
    dispatch(setDistrictAssignedStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `district/assigned/${id}${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setDistrictAssignedStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setDistrictAssignedStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const unassigned = (id, params) => {
  return async (dispatch) => {
    dispatch(setDistrictUnassignedStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `district/unassigned/${id}${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setDistrictUnassignedStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setDistrictUnassignedStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const assignToDistrict = (sellerId, value) => {
  return async (dispatch) => {
    dispatch(setAssignToDistrictStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/assign/district/${sellerId}`,
        value,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setAssignToDistrictStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const deleteFromDistrict = (sellerId, value) => {
  return async (dispatch) => {
    dispatch(setDeleteFromDistrictStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/delete/district/${sellerId}`,
        value,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setDeleteFromDistrictStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

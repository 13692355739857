import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Divider, Row, Space, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import { formatRupiah, toCheckLoginToken } from "../../utils/Functions";
import { server } from "../../constants";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import TagCustom from "../../components/atoms/TagCustom/TagCustom";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import ButtonDefaultActionTable from "../../components/atoms/ButtonDefaultActionTable";
import * as customerActions from "../../actions/customer.action";
import * as orderActions from "../../actions/order.action";
import "./_style.scss";

const { Text, Title } = Typography;

const CustomerDetail = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);

  const { customerOfflineDetails } = useSelector((state) => state.customerReducer);

  useEffect(() => {
    dispatch(customerActions.customerOfflineDetails(id));
  }, []);

  useEffect(() => {
    if (customerOfflineDetails) {
      setData(customerOfflineDetails);
      setLoading(false);
    }
  }, [customerOfflineDetails]);

  const checkPaymentStatus = (status) => {
    let _status = status.toLowerCase();
    let type = _status === "paid" ? "success" : _status === "canceled" ? "danger" : "warning";
    return <TagCustom className="strong" text={status} type={type} />;
  };

  const dataSource = orderData.map((data, index) => ({
    key: index,
    serialNo: index + 1,
    orderID: data?.order_id,
    noSalesOrder: data?.no_sales_order,
    paymentType: data?.payment_type,
    paymentDue: data?.payment_due,
    paymentStatus: checkPaymentStatus(data?.payment_status),
    paid: formatRupiah(data?.paid),
    tax: data?.tax,
    subTotal: formatRupiah(data?.subtotal),
    total: formatRupiah(data?.total),
    action: (
      <div className="flex-center">
        <ButtonDefaultActionTable
          title="Download PDF"
          icon={<DownloadOutlined />}
          handleClick={() => orderActions.printPDF(data._id)}
        />
      </div>
    ),
  }));

  const headerProps = {
    buttonBack: { text: "Customer Detail", status: true },
    description: "Manage your customer detail",
  };

  const mainBreadcrumbItems = [
    {
      link: "/customer",
      title: "Customer",
    },
    {
      title: "Customer Detail",
    },
  ];

  const column = [
    {
      title: t("Serial No"),
      dataIndex: "serialNo",
      key: "serialNo",
    },
    {
      title: t("Order ID"),
      dataIndex: "orderID",
      key: "orderID",
    },
    {
      title: t("No Sales Order"),
      dataIndex: "noSalesOrder",
      key: "noSalesOrder",
    },
    {
      title: t("Payment Type"),
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: t("Payment Due"),
      dataIndex: "paymentDue",
      key: "paymentDue",
    },
    {
      title: t("Payment Status"),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: t("Paid"),
      dataIndex: "paid",
      key: "paid",
    },
    {
      title: t("Tax"),
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: t("Sub Total"),
      dataIndex: "subTotal",
      key: "subTotal",
    },
    {
      title: t("Total"),
      dataIndex: "total",
      key: "total",
    },
    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
    },
  ];

  const paginationProps = {
    pageSize: 10,
    total: data.length - 1,
  };

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="customer-detail-wrapper">
        <div id="customer-detail-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id="customer-detail-content">
          <div id="customer-detail-description">
            {loading ? (
              <SpinLoaderContent />
            ) : (
              <Row gutter={12}>
                <Col span={24}>
                  <Title level={5}>
                    {t("Customer Detail")}
                    <Divider />
                  </Title>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" className="full-width">
                    <ListItem label="Customer ID" value={data?.id} />
                    <ListItem label="Name" value={data?.firstName} />
                    <ListItem label="Phone" value={data?.phone} />
                    <ListItem label="Email" value={data?.email} />
                    <ListItem label="Address" value={data?.address} />
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" className="full-width">
                    <ListItem label="Customer Type" value={data?.customer_type} />
                    <ListItem label="Newest SO" value={data?.newestSo} />
                    <ListItem label="Credit Balance" value={data?.creditBalance} />
                    <ListItem label="Total Order" value={formatRupiah(data?.totalOrder)} />
                  </Space>
                </Col>
              </Row>
            )}
          </div>
          <div id="customer-detail-table">
            <div className="table-responsive">
              <Table dataSource={dataSource} columns={column} pagination={paginationProps} />
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

const ListItem = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={6}>
        <Text>{t(label)} :</Text>
      </Col>
      <Col span={18}>
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

export default CustomerDetail;

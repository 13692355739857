import React from "react";
import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import "./FormItemNumberIDR.scss";

const FormItemNumberIDR = ({ label, name, rules, disabled = false, handleChange = () => {} }) => {
  const { t } = useTranslation();

  const formatter = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    const number = parseFloat(value);
    if (isNaN(number)) {
      return "";
    }
    return number.toLocaleString("id-ID");
  };

  const parser = (value) => {
    return value.replace(/\D/g, "");
  };

  return (
    <Form.Item label={t(label)} name={name} className='form-item-number-idr' rules={rules}>
      <InputNumber
        placeholder={t(label)}
        style={{ width: "100%" }}
        formatter={formatter}
        parser={parser}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default FormItemNumberIDR;

import { t } from "../../utils/Functions";
import { AssignmentComponent, StatusComponent } from ".";
import CustomLink from "../../components/atoms/CustomLink/CustomLink";

export const columns = [
  {
    title: t("Name"),
    dataIndex: "name",
    key: "name",
    render: (data) => (
      <div
        onClick={() => {
          window?.parent?.postMessage(
            {
              type: 'salesDetail',
              payload: data?.id
            },
            process.env.REACT_APP_NEW_CMS
          )
        }}
      >
        <CustomLink
          text={`${data?.profile?.firstName} ${data?.profile?.lastName}`}
          path={`/sales-store/${data?.id}`}
        />
      </div>
    ),
  },
  {
    title: t("Phone Number"),
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: t("Assignment"),
    dataIndex: "assignment",
    key: "assignment",
    render: (data) => <AssignmentComponent assignment={data?.assignedCount} />,
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    render: (data) => <StatusComponent id={data?.id} status={data?.status} />,
  },
];

import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

// create custom icon
const customIcon = new Icon({
  iconUrl: `${window.location.origin}/images/marker-64.png`,
  iconSize: [38, 38],
});

// useMap hook to invalidate the size the map
const Map = () => {
  const map = useMap();
  useEffect(() => {
    if (map) map.invalidateSize();
  }, [map]);
  return null;
};

const LeafletMap = ({ markers, center, zoom = 13, height = "60vh", width = "100%" }) => {
  return (
    <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} style={{ height, width }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Mapping through the markers */}
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.geocode} icon={customIcon}>
          <Popup>{marker.popUp}</Popup>
        </Marker>
      ))}

      <Map />
    </MapContainer>
  );
};

export default LeafletMap;

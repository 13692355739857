import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import FormItemAddress from "../../atoms/FormItemAddress";
import FormItemPhoneNumber from "../../atoms/FormItemPhoneNumber";
import * as customerActions from "../../../actions/customer.action";

const { Title } = Typography;

const AddCustomerModal = ({ modalOpen, setModalOpen, customerTypeOptions, setCustomerCreated }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldValue("code_phone", "+62");
  }, []);

  const handleSubmit = async () => {
    const { name, email, code_phone, phone, customer_type, address } = form.getFieldsValue();

    const dataToSend = {
      firstName: name,
      phone: code_phone + phone,
      customerTypeId: customer_type ?? "",
      email: email ?? "",
      address: address ?? "",
    };
    setIsSubmitLoading(true);

    const response = await dispatch(customerActions.createCustomerOffline(dataToSend));
    if (response.isSuccess) {
      setCustomerCreated(response.data);
      setIsSubmitLoading(false);
      setModalOpen(false);
      dispatch(customerActions.customerOffline());
    } else setIsSubmitLoading(false);
  };

  const handleAfterClose = () => {
    form.setFieldsValue({ name: "", phone: "", email: "", customer_type: "", address: "" });
  };

  return (
    <Modal
      className="add-customer-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      afterClose={() => handleAfterClose()}
      width={500}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row>
        <Col span={24}>
          <Title level={4}>{t("Add Customer List")}</Title>
        </Col>
        <Col span={24}>
          <Form layout="vertical" form={form} onFinish={handleSubmit} requiredMark="optional">
            <Form.Item
              label={t("Customer Name")}
              name="name"
              rules={[{ required: true, message: t("Please input your customer name!") }]}
            >
              <Input size="large" />
            </Form.Item>

            <FormItemPhoneNumber
              name="phone"
              label="Customer Phone"
              size="large"
              rules={[{ required: true, message: t("Please input your customer phone!") }]}
            />

            <Form.Item label={t("Customer Email")} name="email">
              <Input size="large" />
            </Form.Item>

            <Form.Item label={t("Customer Type")} name="customer_type">
              <Select options={customerTypeOptions} size="large" />
            </Form.Item>

            <FormItemAddress name="address" label="Customer Address" size="large" />

            <Form.Item wrapperCol={{ span: 24 }}>
              <Row gutter={12}>
                <Col span={12}>
                  <ButtonDefault
                    text="Cancel"
                    block={true}
                    handleClick={() => setModalOpen(false)}
                  />
                </Col>
                <Col span={12}>
                  <ButtonPrimary
                    text="Save"
                    block={true}
                    htmlType="submit"
                    loading={isSubmitLoading}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddCustomerModal;

/// <reference path="../types.d.ts"/>
export const LOGIN_FETCHING = "LOGIN_FETCHING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_AUTH_FETCHING = "LOGIN_AUTH_FETCHING";
export const LOGIN_AUTH_FAILED = "LOGIN_AUTH_FAILED";
export const LOGIN_SEND_OTP_FETCHING = "LOGIN_SEND_OTP_FETCHING";
export const LOGIN_SEND_OTP_FAILED = "LOGIN_SEND_OTP_FAILED";
//Subscription
export const SUBSCRIPTION_FETCHING = "SUBSCRIPTION_FETCHING";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_FAILED = "SUBSCRIPTION_FAILED";
// Register Page
export const REGISTER_FETCHING = "REGISTER_FETCHING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
// Stat
export const STAT_FETCHING = "STAT_FETCHING";
export const STAT_SUCCESS = "STAT_SUCCESS";
export const STAT_FAILED = "STAT_FAILED";
export const STAT_TOTAL_SALES = "STAT_TOTAL_SALES";
//Forgot password
export const FORGOT_FETCHING = "FORGOT_FETCHING";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAILED = "FORGOT_FAILED";
// POS Machine
export const POSMACHINE_FETCHING = "POSMACHINE_FETCHING";
export const POSMACHINE_SUCCESS = "POSMACHINE_SUCCESS";
export const POSMACHINE_FAILED = "POSMACHINE_FAILED";
export const POSMACHINE_CLEAR = "POSMACHINE_CLEAR";
// CATEGORY
export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAILED = "CATEGORY_FAILED";
export const CATEGORY_CLEAR = "CATEGORY_CLEAR";
export const CATEGORY_OPTIONS_FETCHING = "CATEGORY_OPTIONS_FETCHING";
export const CATEGORY_OPTIONS_SUCCESS = "CATEGORY_OPTIONS_SUCCESS";
export const CATEGORY_OPTIONS_FAILED = "CATEGORY_OPTIONS_FAILED";
// CART
export const CART_FETCHING = "CART_FETCHING";
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAILED = "CART_FAILED";
export const CART_CLEAR = "CART_CLEAR";
export const CART_CREATE_FETCHING = "CART_CREATE_FETCHING";
export const CART_CREATE_FAILED = "CART_CREATE_FAILED";
// CATALOG
export const CATALOG_FETCHING = "CATALOG_FETCHING";
export const CATALOG_SUCCESS = "CATALOG_SUCCESS";
export const CATALOG_FAILED = "CATALOG_FAILED";
export const CATALOG_CLEAR = "CATALOG_CLEAR";
// SUBCATEGORY
export const SUBCATEGORY_FETCHING = "SUBCATEGORY_FETCHING";
export const SUBCATEGORY_SUCCESS = "SUBCATEGORY_SUCCESS";
export const SUBCATEGORY_FAILED = "SUBCATEGORY_FAILED";
export const SUBCATEGORY_CLEAR = "SUBCATEGORY_CLEAR";
export const SUBCATEGORY_BY_CATEGORY_ID_FETCHING = "SUBCATEGORY_BY_CATEGORY_ID_FETCHING";
export const SUBCATEGORY_BY_CATEGORY_ID_SUCCESS = "SUBCATEGORY_BY_CATEGORY_ID_SUCCESS";
export const SUBCATEGORY_BY_CATEGORY_ID_FAILED = "SUBCATEGORY_BY_CATEGORY_ID_FAILED";
export const SUBCATEGORY_OPTIONS_FETCHING = "SUBCATEGORY_OPTIONS_FETCHING";
export const SUBCATEGORY_OPTIONS_SUCCESS = "SUBCATEGORY_OPTIONS_SUCCESS";
export const SUBCATEGORY_OPTIONS_FAILED = "SUBCATEGORY_OPTIONS_FAILED";
// UOM
export const UOM_FETCHING = "UOM_FETCHING";
export const UOM_SUCCESS = "UOM_SUCCESS";
export const UOM_FAILED = "UOM_FAILED";
export const UOM_CLEAR = "UOM_CLEAR";
export const UOM_UNIT_FETCHING = "UOM_UNIT_FETCHING";
export const UOM_UNIT_SUCCESS = "UOM_UNIT_SUCCESS";
export const UOM_UNIT_FAILED = "UOM_UNIT_FAILED";
export const UOM_PACK_TYPE_FETCHING = "UOM_PACK_TYPE_FETCHING";
export const UOM_PACK_TYPE_SUCCESS = "UOM_PACK_TYPE_SUCCESS";
export const UOM_PACK_TYPE_FAILED = "UOM_PACK_TYPE_FAILED";
//BRANCH
export const BRANCH_FETCHING = "BRANCH_FETCHING";
export const BRANCH_SUCCESS = "BRANCH_SUCCESS";
export const BRANCH_FAILED = "BRANCH_FAILED";
export const BRANCH_CLEAR = "BRANCH_CLEAR";
export const FETCHOPTION_SUCCESS = "FETCHOPTION_SUCCESS";
// SUPPLIER
export const SUPPLIER_FETCHING = "SUPPLIER_FETCHING";
export const SUPPLIER_SUCCESS = "SUPPLIER_SUCCESS";
export const SUPPLIER_FAILED = "SUPPLIER_FAILED";
export const SUPPLIER_CLEAR = "SUPPLIER_CLEAR";
// CUSTOMER
export const CUSTOMER_FETCHING = "CUSTOMER_FETCHING";
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS";
export const CUSTOMER_FAILED = "CUSTOMER_FAILED";
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR";
export const CUSTOMER_OPTIONS_SUCCESS = "CUSTOMER_OPTIONS_SUCCESS";
export const CUSTOMER_ORDER_HISTORY = "CUSTOMER_ORDER_HISTORY";
export const CUSTOMER_TYPE_SUCCESS = "CUSTOMER_TYPE_SUCCESS";
export const CREATE_CUSTOMER_OFFLINE_FETCHING = "CREATE_CUSTOMER_OFFLINE_FETCHING";
export const CREATE_CUSTOMER_OFFLINE_FAILED = "CREATE_CUSTOMER_OFFLINE_FAILED";
export const CUSTOMER_OFFLINE_FETCHING = "CUSTOMER_OFFLINE_FETCHING";
export const CUSTOMER_OFFLINE_FAILED = "CUSTOMER_OFFLINE_FAILED";
export const CUSTOMER_OFFLINE_SUCCESS = "CUSTOMER_OFFLINE_SUCCESS";
export const CUSTOMER_OFFLINE_TYPE_FETCHING = "CUSTOMER_OFFLINE_TYPE_FETCHING";
export const CUSTOMER_OFFLINE_TYPE_FAILED = "CUSTOMER_OFFLINE_TYPE_FAILED";
export const CUSTOMER_OFFLINE_TYPE_SUCCESS = "CUSTOMER_OFFLINE_TYPE_SUCCESS";
export const CUSTOMER_OFFLINE_DETAILS_FETCHING = "CUSTOMER_OFFLINE_DETAILS_FETCHING";
export const CUSTOMER_OFFLINE_DETAILS_FAILED = "CUSTOMER_OFFLINE_DETAILS_FAILED";
export const CUSTOMER_OFFLINE_DETAILS_SUCCESS = "CUSTOMER_OFFLINE_DETAILS_SUCCESS";
// Shop Page
export const SHOP_FETCHING = "SHOP_FETCHING";
export const SHOP_SUCCESS = "SHOP_SUCCESS";
export const SHOP_FAILED = "SHOP_FAILED";
export const SHOP_CLEAR = "SHOP_CLEAR";
export const SHOP_UPDATE_ORDER = "SHOP_UPDATE_ORDER";
export const SHOP_UPDATE_PAYMENT = "SHOP_UPDATE_PAYMENT";
export const SHOP_LIST_SUCCESS = "SHOP_LIST_SUCCESS";
export const SHOP_PAYMENT_TYPE_SUCCESS = "SHOP_PAYMENT_TYPE_SUCCESS";
export const SHOP_PAYMENT_TYPE_FAILED = "SHOP_PAYMENT_TYPE_FAILED";
export const SHOP_PAYMENT_TYPE_FETCHING = "SHOP_PAYMENT_TYPE_FETCHING";
// Stock Page
export const STOCK_FETCHING = "STOCK_FETCHING";
export const STOCK_SUCCESS = "STOCK_SUCCESS";
export const STOCK_FAILED = "STOCK_FAILED";
export const STOCK_CLEAR = "STOCK_CLEAR";
// PRODUCT
export const PRODUCT_FETCHING = "PRODUCT_FETCHING";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_FAILED = "PRODUCT_FAILED";
export const PRODUCT_CLEAR = "PRODUCT_CLEAR";
export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const CREATE_PRODUCT_MASTER_FETCHING = "CREATE_PRODUCT_MASTER_FETCHING";
export const CREATE_PRODUCT_MASTER_FAILED = "CREATE_PRODUCT_MASTER_FAILED";
export const PRODUCT_MASTER_FETCHING = "PRODUCT_MASTER_FETCHING";
export const PRODUCT_MASTER_SUCCESS = "PRODUCT_MASTER_SUCCESS";
export const PRODUCT_MASTER_FAILED = "PRODUCT_MASTER_FAILED";
export const PRODUCT_MASTER_APPENDED = "PRODUCT_MASTER_APPENDED";
export const UPDATE_PRODUCT_MASTER = "UPDATE_PRODUCT_MASTER";
export const PRODUCT_MASTER_DROPDOWN_SUCCESS = "PRODUCT_MASTER_DROPDOWN_SUCCESS";
export const PRODUCT_MASTER_DROPDOWN_FETCHING = "PRODUCT_MASTER_DROPDOWN_FETCHING";
// INVENTORY
export const INVENTORY_FETCHING = "INVENTORY_FETCHING";
export const INVENTORY_SUCCESS = "INVENTORY_SUCCESS";
export const INVENTORY_FAILED = "INVENTORY_FAILED";
export const INVENTORY_CLEAR = "INVENTORY_CLEAR";
export const INVENTORY_UPDATE = "INVENTORY_UPDATE";
export const INVENTORY_APPENDED = "INVENTORY_APPENDED";
export const INVENTORY_BULK_UPDATE_FETCHING = "INVENTORY_BULK_UPDATE_FETCHING";
export const INVENTORY_BULK_UPDATE_FAILED = "INVENTORY_BULK_UPDATE_FAILED";
export const CREATE_INVENTORY_FETCHING = "CREATE_INVENTORY_FETCHING";
export const CREATE_INVENTORY_FAILED = "CREATE_INVENTORY_FAILED";
export const UPDATE_INVENTORY_FETCHING = "UPDATE_INVENTORY_FETCHING";
export const UPDATE_INVENTORY_FAILED = "UPDATE_INVENTORY_FAILED";
export const CREATE_BULK_INVENTORY_FETCHING = "CREATE_BULK_INVENTORY_FETCHING";
export const CREATE_BULK_INVENTORY_FAILED = "CREATE_BULK_INVENTORY_FAILED";
export const EXPORT_INVENTORY_TO_CSV_FETCHING = "EXPORT_INVENTORY_TO_CSV_FETCHING";
export const EXPORT_INVENTORY_TO_CSV_FAILED = "EXPORT_INVENTORY_TO_CSV_FAILED";
// COMPANY
export const COMPANY_FETCHING = "COMPANY_FETCHING";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAILED = "COMPANY_FAILED";
export const COMPANY_CLEAR = "COMPANY_CLEAR";
export const COMPANY_UPDATE = "COMPANY_UPDATE";
export const COMPANY_OPTIONS_SUCCESS = "COMPANY_OPTIONS_SUCCESS";
export const COMPANY_SELLER_FETCHING = "COMPANY_SELLER_FETCHING";
export const COMPANY_SELLER_FAILED = "COMPANY_SELLER_FAILED";
export const COMPANY_SELLER_SUCCESS = "COMPANY_SELLER_SUCCESS";
export const COMPANY_SELLER_DETAILS_FETCHING = "COMPANY_SELLER_DETAILS_FETCHING";
export const COMPANY_SELLER_DETAILS_FAILED = "COMPANY_SELLER_DETAILS_FAILED";
export const COMPANY_SELLER_DETAILS_SUCCESS = "COMPANY_SELLER_DETAILS_SUCCESS";
export const COMPANY_RELATED_SALES_FETCHING = "COMPANY_RELATED_SALES_FETCHING";
export const COMPANY_RELATED_SALES_FAILED = "COMPANY_RELATED_SALES_FAILED";
export const COMPANY_RELATED_SALES_SUCCESS = "COMPANY_RELATED_SALES_SUCCESS";
export const COMPANY_APPROVAL_FETCHING = "COMPANY_APPROVAL_FETCHING";
export const COMPANY_APPROVAL_FAILED = "COMPANY_APPROVAL_FAILED";
export const COMPANY_BUYERS_FETCHING = "COMPANY_BUYERS_FETCHING";
export const COMPANY_BUYERS_FAILED = "COMPANY_BUYERS_FAILED";
export const COMPANY_BUYERS_SUCCESS = "COMPANY_BUYERS_SUCCESS";
export const COMPANY_BUYER_FETCHING = "COMPANY_BUYER_FETCHING";
export const COMPANY_BUYER_FAILED = "COMPANY_BUYER_FAILED";
export const COMPANY_BUYER_SUCCESS = "COMPANY_BUYER_SUCCESS";
export const COMPANY_TYPES_FETCHING = "COMPANY_TYPES_FETCHING";
export const COMPANY_TYPES_FAILED = "COMPANY_TYPES_FAILED";
export const COMPANY_TYPES_SUCCESS = "COMPANY_TYPES_SUCCESS";
// ORDER
export const ORDER_PRODUCT_CLEAR = "SET_PRODUCT";
export const ORDER_FETCHING = "ORDER_FETCHING";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAILED = "ORDER_FAILED";
export const ORDER_CLEAR = "ORDER_CLEAR";
export const ORDER_SET_TAX = "ORDER_SET_TAX";
export const ORDER_UPDATE = "ORDER_UPDATE";
export const ORDER_UPDATE_STATUS = "ORDER_UPDATE_STATUS";
export const ORDER_UPDATE_STATUS_CANCEL = "ORDER_UPDATE_STATUS_CANCEL";
export const ORDER_UPDATE_STATUS_CLEAR = "ORDER_UPDATE_STATUS_CLEAR";
export const ORDER_UPDATE_STATUS_EXECUTE = "ORDER_UPDATE_STATUS_EXECUTE";
export const ORDER_UPDATE_STATUS_SUCCESS = "ORDER_UPDATE_STATUS_SUCCESS";
export const ORDER_UPDATE_STATUS_ERROR = "ORDER_UPDATE_STATUS_ERROR";
export const ORDER_PRINT_PDF = "ORDER_PRINT_PDF";
export const ORDER_PAYMENT = "ORDER_PAYMENT";
export const FETCH_OPTION_SUCCESS = "FETCH_OPTION_SUCCESS";
export const ORDER_DETAIL_FETCHING = "ORDER_DETAIL_FETCHING";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAILED = "ORDER_DETAIL_FAILED";
export const ORDER_LIST_FETCHING = "ORDER_LIST_FETCHING";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILED = "ORDER_LIST_FAILED";
export const UPDATE_ORDER_FETCHING = "UPDATE_ORDER_FETCHING";
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED";
export const ORDER_DETAILS_FETCHING = "ORDER_DETAILS_FETCHING";
export const ORDER_DETAILS_FAILED = "ORDER_DETAILS_FAILED";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const DOWNLOAD_ORDER_INVOICE_FETCHING = "DOWNLOAD_ORDER_INVOICE_FETCHING";
export const DOWNLOAD_ORDER_INVOICE_FAILED = "DOWNLOAD_ORDER_INVOICE_FAILED";
export const ORDER_REASONS_FETCHING = "ORDER_REASONS_FETCHING";
export const ORDER_REASONS_SUCCESS = "ORDER_REASONS_SUCCESS";
export const ORDER_REASONS_FAILED = "ORDER_REASONS_FAILED";
export const ORDER_UPDATE_STATUS_FETCHING = "ORDER_UPDATE_STATUS_FETCHING";
export const ORDER_UPDATE_STATUS_FAILED = "ORDER_UPDATE_STATUS_FAILED";
export const ORDER_UPDATE_STATUS_BULK_FETCHING = "ORDER_UPDATE_STATUS_BULK_FETCHING";
export const ORDER_UPDATE_STATUS_BULK_FAILED = "ORDER_UPDATE_STATUS_BULK_FAILED";
export const ORDER_CREATE_FAILED = "ORDER_CREATE_FAILED";
export const ORDER_CREATE_FETCHING = "ORDER_CREATE_FETCHING";

// USER
export const USER_FETCHING = "USER_FETCHING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const USER_CLEAR = "USER_CLEAR";
export const USER_ME_FETCHING = "USER_ME_FETCHING";
export const USER_ME_FAILED = "USER_ME_FAILED";
export const USER_ME_SUCCESS = "USER_ME_SUCCESS";

export const RECEIPT_FETCHING = "RECEIPT_FETCHING";
export const RECEIPT_SUCCESS = "RECEIPT_SUCCESS";
export const RECEIPT_ERROR = "RECEIPT_ERROR";

export const SALES_FETCHING = "SALES_FETCHING";
export const SALES_SUCCESS = "SALES_SUCCESS";
export const SALES_FAILED = "SALES_FAILED";
export const SALES_CLEAR = "SALES_CLEAR";

export const SALES_ASSIGNED_FETCHING = "SALES_ASSIGNED_FETCHING";
export const SALES_ASSIGNED_SUCCESS = "SALES_ASSIGNED_SUCCESS";
export const SALES_ASSIGNED_FAILED = "SALES_ASSIGNED_FAILED";
export const SALES_ASSIGNED_CLEAR = "SALES_ASSIGNED_CLEAR";

export const SALES_UNASSIGNED_FETCHING = "SALES_UNASSIGNED_FETCHING";
export const SALES_UNASSIGNED_SUCCESS = "SALES_UNASSIGNED_SUCCESS";
export const SALES_UNASSIGNED_FAILED = "SALES_UNASSIGNED_FAILED";
export const SALES_UNASSIGNED_CLEAR = "SALES_UNASSIGNED_CLEAR";

export const SALES_DETAIL_FETCHING = "SALES_DETAIL_FETCHING";
export const SALES_DETAIL_SUCCESS = "SALES_DETAIL_SUCCESS";
export const SALES_DETAIL_FAILED = "SALES_DETAIL_FAILED";
export const SALES_DETAIL_CLEAR = "SALES_DETAIL_CLEAR";
// SELLER
export const SELLER_FETCHING = "SELLER_FETCHING";
export const SELLER_SUCCESS = "SELLER_SUCCESS";
export const SELLER_LIST_SUCCESS = "SELLER_LIST_SUCCESS";
export const SELLER_FAILED = "SELLER_FAILED";
export const SELLER_CLEAR = "SELLER_CLEAR";
// DASHBOARD
export const DASHBOARD_STATE_CHANGED = "STATE_CHANGED";
export const DASHBOARD_PERIOD_CHANGING = "PERIOD_CHANGING";
export const DASHBOARD_RECORD_CHANGING = "RECORD_CHANGING";
export const DASHBOARD_STATUS_CHANGING = "STATUS_CHANGING";
export const DASHBOARD_CHART_FETCHING = "CHART_FETCHING";
export const DASHBOARD_CHART_FETCHED = "CHART_FETCHED";
export const DASHBOARD_CHART_ERROR = "CHART_ERROR";
export const DASHBOARD_TOTAL_FETCHING = "TOTAL_FETCHING";
export const DASHBOARD_TOTAL_FETCHED = "TOTAL_FETCHED";
export const DASHBOARD_TOTAL_ERROR = "TOTAL_ERROR";
export const DASHBOARD_SKU_TRANSACTION_FETCHING = "SKU_TRANSACTION_FETCHING";
export const DASHBOARD_SKU_TRANSACTION_FETCHED = "SKU_TRANSACTION_FETCHED";
export const DASHBOARD_SKU_TRANSACTION_ERROR = "SKU_TRANSACTION_ERROR";
export const DASHBOARD_TOP_FIVE_SKU_FETCHING = "TOP_FIVE_SKU_FETCHING";
export const DASHBOARD_TOP_FIVE_SKU_FETCHED = "TOP_FIVE_SKU_FETCHED";
export const DASHBOARD_TOP_FIVE_SKU_ERROR = "TOP_FIVE_SKU_ERROR";
export const DASHBOARD_SKU_ON_BOARD_FETCHING = "SKU_ON_BOARD_FETCHING";
export const DASHBOARD_SKU_ON_BOARD_FETCHED = "SKU_ON_BOARD_FETCHED";
export const DASHBOARD_SKU_ON_BOARD_ERROR = "SKU_ON_BOARD_ERROR";
export const DASHBOARD_SKU_ON_BOARD_APPENDED = "SKU_ON_BOARD_APPENDED";
export const DASHBOARD_SKU_ON_BOARD_CLEAR = "SKU_ON_BOARD_CLEAR";
// MERCHANT
export const MERCHANT_LIST_FETCHING = "MERCHANT_LIST_FETCHING";
export const MERCHANT_LIST_SUCCESS = "MERCHANT_LIST_SUCCESS";
export const MERCHANT_LIST_FAILED = "MERCHANT_LIST_FAILED";
export const MERCHANT_DETAIL_FETCHING = "MERCHANT_DETAIL_FETCHING";
export const MERCHANT_DETAIL_SUCCESS = "MERCHANT_DETAIL_SUCCESS";
export const MERCHANT_DETAIL_FAILED = "MERCHANT_DETAIL_FAILED";
export const MERCHANT_GMV_FETCHING = "MERCHANT_GMV_FETCHING";
export const MERCHANT_GMV_SUCCESS = "MERCHANT_GMV_SUCCESS";
export const MERCHANT_GMV_FAILED = "MERCHANT_GMV_FAILED";
export const MERCHANT_SALES_FETCHING = "MERCHANT_SALES_FETCHING";
export const MERCHANT_SALES_SUCCESS = "MERCHANT_SALES_SUCCESS";
export const MERCHANT_SALES_FAILED = "MERCHANT_SALES_FAILED";
export const MERCHANT_PRODUCT_FETCHING = "MERCHANT_PRODUCT_FETCHING";
export const MERCHANT_PRODUCT_SUCCESS = "MERCHANT_PRODUCT_SUCCESS";
export const MERCHANT_PRODUCT_FAILED = "MERCHANT_PRODUCT_FAILED";
export const MERCHANT_UPLOAD_SKU_FETCHING = "MERCHANT_UPLOAD_SKU_FETCHING";
export const MERCHANT_UPLOAD_SKU_SUCCESS = "MERCHANT_UPLOAD_SKU_SUCCESS";
export const MERCHANT_UPLOAD_SKU_FAILED = "MERCHANT_UPLOAD_SKU_FAILED";
export const MERCHANT_PRODUCT_MASTER_FETCHING = "MERCHANT_PRODUCT_MASTER_FETCHING";
export const MERCHANT_PRODUCT_MASTER_SUCCESS = "MERCHANT_PRODUCT_MASTER_SUCCESS";
export const MERCHANT_PRODUCT_MASTER_FAILED = "MERCHANT_PRODUCT_MASTER_FAILED";
// DISTRICT
export const DISTRICT_SEARCH_FETCHING = "DISTRICT_SEARCH_FETCHING";
export const DISTRICT_SEARCH_SUCCESS = "DISTRICT_SEARCH_SUCCESS";
export const DISTRICT_SEARCH_FAILED = "DISTRICT_SEARCH_FAILED";
export const DISTRICT_DETAILS_FETCHING = "DISTRICT_DETAILS_FETCHING";
export const DISTRICT_DETAILS_SUCCESS = "DISTRICT_DETAILS_SUCCESS";
export const DISTRICT_DETAILS_FAILED = "DISTRICT_DETAILS_FAILED";
export const ACTIVE_DEACTIVE_DISTRICT_FETCHING = "ACTIVE_DEACTIVE_DISTRICT_FETCHING";
export const ACTIVE_DEACTIVE_DISTRICT_FAILED = "ACTIVE_DEACTIVE_DISTRICT_FAILED";
export const DISTRICT_ASSIGNED_FETCHING = "DISTRICT_ASSIGNED_FETCHING";
export const DISTRICT_ASSIGNED_SUCCESS = "DISTRICT_ASSIGNED_SUCCESS";
export const DISTRICT_ASSIGNED_FAILED = "DISTRICT_ASSIGNED_FAILED";
export const DISTRICT_UNASSIGNED_FETCHING = "DISTRICT_UNASSIGNED_FETCHING";
export const DISTRICT_UNASSIGNED_SUCCESS = "DISTRICT_UNASSIGNED_SUCCESS";
export const DISTRICT_UNASSIGNED_FAILED = "DISTRICT_UNASSIGNED_FAILED";
export const ASSIGN_TO_DISTRICT_FETCHING = "ASSIGN_TO_DISTRICT_FETCHING";
export const ASSIGN_TO_DISTRICT_FAILED = "ASSIGN_TO_DISTRICT_FAILED";
export const DELETE_FROM_DISTRICT_FETCHING = "DELETE_FROM_DISTRICT_FETCHING";
export const DELETE_FROM_DISTRICT_FAILED = "DELETE_FROM_DISTRICT_FAILED";
// UPLOAD
export const UPLOAD_FETCHING = "UPLOAD_FETCHING";
export const UPLOAD_FAILED = "UPLOAD_FAILED";

// PRICE TIER
export const PRICE_TIER_TEMPLATE_FETCHING = "PRICE_TIER_TEMPLATE_FETCHING";
export const PRICE_TIER_TEMPLATE_FAILED = "PRICE_TIER_TEMPLATE_FAILED";
export const PRICE_TIER_FETCHING = "PRICE_TIER_FETCHING";
export const PRICE_TIER_FAILED = "PRICE_TIER_FAILED";
export const PRICE_TIER_SUCCESS = "PRICE_TIER_SUCCESS";
export const PRICE_TIER_UPDATE_BULK_FETCHING = "PRICE_TIER_UPDATE_BULK_FETCHING";
export const PRICE_TIER_UPDATE_BULK_FAILED = "PRICE_TIER_UPDATE_BULK_FAILED";
export const PRICE_TIER_UPDATE_FETCHING = "PRICE_TIER_UPDATE_FETCHING";
export const PRICE_TIER_UPDATE_FAILED = "PRICE_TIER_UPDATE_FAILED";

export const HISTORY_FETCHING = "HISTORY_FETCHING";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";
export const HISTORY_FAILED = "HISTORY_FAILED";
export const HISTORY_CLEAR = "HISTORY_CLEAR";

export const AREA_INTERNAL_REGION_FETCHING = "AREA_INTERNAL_REGION_FETCHING";
export const AREA_INTERNAL_REGION_SUCCESS = "AREA_INTERNAL_REGION_SUCCESS";
export const AREA_INTERNAL_REGION_FAILED = "AREA_INTERNAL_REGION_FAILED";
export const AREA_PROVINCES_FETCHING = "AREA_PROVINCES_FETCHING";
export const AREA_PROVINCES_SUCCESS = "AREA_PROVINCES_SUCCESS";
export const AREA_PROVINCES_FAILED = "AREA_PROVINCES_FAILED";
export const AREA_CITIES_FETCHING = "AREA_CITIES_FETCHING";
export const AREA_CITIES_SUCCESS = "AREA_CITIES_SUCCESS";
export const AREA_CITIES_FAILED = "AREA_CITIES_FAILED";
export const AREA_DISTRICTS_FETCHING = "AREA_DISTRICTS_FETCHING";
export const AREA_DISTRICTS_SUCCESS = "AREA_DISTRICTS_SUCCESS";
export const AREA_DISTRICTS_FAILED = "AREA_DISTRICTS_FAILED";

export const imageUrl = "http://127.0.0.1:8081";
export const server = {
  LOGIN_URL: `login`,
  LOGIN_VALUES: `login_values`,
  REFRESH_TOKEN_URL: `refresh/token`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `product`,
  ORDER_URL: `order`,
  REPORT_URL: `report`,
  TOKEN_KEY: `token`,
  REFRESH_TOKEN_KEY: `refresh_token`,
  STAT_ENDPOINT: "stat",
  USER_ROLE: "user_role",
  USER_DATA: "user_data",
  CUSTOMER_ID: "customer_id",
  LANGUAGE: "language",
  ABOUT_USER: "about_user",
  USER_ID: "user_id",
  ALERT_MESSAGE: "alert_message",
  ROLES: "roles",
  PARAMS: 'params',
};

export const Roles = [
  { name: "Admin", credentials: "admin" },
  { name: "Staff", credentials: "staff" },
];

export const UserRoles = {
  BUYER: "BUYER",
  SALESMAN: "SALESMAN",
  ADMIN: "ADMIN",
  OPERATIONAL: "OPERATIONAL",
  BASKIT_ADMIN: "BASKIT_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const DashboardPeriod = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  LASTWEEK: "lastweek",
  LASTMONTH: "lastmonth",
};

// export const OrderStatus = /** @type {const} */ ({
//   PAID: "paid",
//   CANCELED: "canceled",
//   WAITING: "waiting",
//   FAILED: "failed",
// });

// export const OrderStatus = ({
//   PAID: "paid",
//   CANCELED: "canceled",
//   WAITING: "waiting",
//   FAILED: "failed",
// });

export const OrderStatus = {
  ORDER_CREATED: {
    label: "orderCreated",
    value: "ORDER_CREATED",
  },
  ORDER_FINISHED: {
    label: "Order Finished",
    value: "ORDER_FINISHED",
  },
  WAITING_FOR_PICK_UP: {
    label: "waitingForPickUp",
    value: "WAITING_FOR_PICK_UP",
  },
  ORDER_PICKED_UP: {
    label: "pickUp",
    value: "ORDER_PICKED_UP",
  },
  ORDER_RECEIVED: {
    label: "orderReceived",
    value: "ORDER_RECEIVED",
  },
  ORDER_PARTIAL_RECEIVED: {
    label: "orderPartialReceived",
    value: "ORDER_PARTIAL_RECEIVED",
  },
  ORDER_CANCELLED: {
    label: "canceled",
    value: "ORDER_CANCELLED",
  },
  ORDER_PENDING: {
    label: "pending",
    value: "ORDER_PENDING",
  },
  PAID: {
    label: "Order Finished",
    value: "ORDER_FINISHED",
  },
  CANCELED: {
    label: "Order Cancelled",
    value: "ORDER_CANCELLED",
  },
};

export const EDITABLE_ORDER_STATUS = [
  OrderStatus.ORDER_CREATED.value,
  OrderStatus.WAITING_FOR_PICK_UP.value,
  OrderStatus.ORDER_PICKED_UP.value,
  OrderStatus.ORDER_PENDING.value,
];

export const PaymentStatus = {
  "Waiting For Payment": {
    label: "Waiting For Payment",
    value: "WAITING_FOR_PAYMENT",
  },
  "Payment Failed": {
    label: "Payment Failed",
    value: "PAYMENT_FAILED",
  },
  "Payment Sucessful": {
    label: "Payment Sucessful",
    value: "PAYMENT_SUCCESSFUL",
  },
};

export const DeliveryType = {
  SELLER_DELIVERY: {
    label: "Seller Delivery",
    value: "SELLER_DELIVERY",
  },
  BASKIT_DELIVERY: {
    label: "Baskit Delivery",
    value: "BASKIT_DELIVERY",
  },
};

export const SellerStatus = {
  ACTIVE: { value: "ACTIVE", label: "Active" },
  INACTIVE: { value: "INACTIVE", label: "Inactive" },
  CHURNED: { value: "CHURNED", label: "Churned" },
  DROPPED: { value: "DROPPED", label: "Dropped" },
};

export const ExportSkuCsvHeader = [
  [
    "SKU ID",
    "Stock",
    "Base Price",
    "Tier 1 Min",
    "Tier 1 Max",
    "Tier 1 %",
    "Tier 2 Min",
    "Tier 2 Max",
    "Tier 2 %",
    "Tier 3 Min",
    "Tier 3 Max",
    "Tier 3 %",
    "Tier 4 Min",
    "Tier 4 Max",
    "Tier 4 %",
  ],
  [
    "String",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
    "Numerical",
  ],
];

export const OrderType = {
  ONLINE: {
    label: "Online",
    value: "ONLINE",
  },
  OFFLINE: {
    label: "Offline",
    value: "OFFLINE",
  },
  SHOP: {
    label: "Shop",
    value: "SHOP",
  },
};

export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`;
export const GOOGLE_MAP_SEARCH_URL = "https://www.google.com/maps/search/";
export const WA_SHARE_URL = "https://api.whatsapp.com/send";

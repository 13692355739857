import React, { useEffect, useState } from "react";
import { Col, Menu, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter, replaceSymbolsWithSpace } from "../../../utils/Functions";
import { debounce } from "lodash-es";
import "./CategorySidebarMenu.scss";

const SideMenuCategory = ({ menuItems, handleClick }) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isOnClick, setIsOnClick] = useState(false);

  useEffect(() => {
    if (!isOnClick) return;

    const debouncedAction = debounce(() => {
      handleClick({ ...selectedCategory });
    }, 500);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [selectedCategory, isOnClick]);

  const handleClickMenu = ({ key, item }) => {
    setIsOnClick(true);
    setSelectedCategory({ key: key, title: item.props.title });
  };

  return (
    <Menu className='sidebar-menu-category' mode='vertical' onClick={handleClickMenu}>
      <Menu.Item key='all-categories' title='All Categories' className='menu-item-multiline'>
        <Row>
          <Col span={22} className='flex-center-align'>
            <div className='title-wrapper'>All Categories</div>
          </Col>
          <Col span={2}>
            <RightOutlined />
          </Col>
          <Col span={24}></Col>
        </Row>
      </Menu.Item>
      <Menu.Divider key={1} />
      {menuItems.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Menu.Item key={item.key} title={item.title} className='menu-item-multiline'>
              <Row>
                <Col span={22} className='flex-center-align'>
                  <div className='title-wrapper'>{capitalizeFirstLetter(replaceSymbolsWithSpace(item.title))}</div>
                </Col>
                <Col span={2}>
                  <RightOutlined />
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Divider />
          </React.Fragment>
        );
      })}
    </Menu>
  );
};
export default SideMenuCategory;

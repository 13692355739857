import React, { useEffect, useState } from "react";
import { Col, Row, Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash-es";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import * as companyActions from "../../actions/company.action";

const { Text } = Typography;

const Company = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.companyReducer);

  useEffect(() => {
    dispatch(companyActions.getCompanyDetails());
  }, []);

  useEffect(() => {
    if (!isEmpty(company.result)) {
      setData(company.result);
      setLoading(false);
    }
  }, [company]);

  return (
    <div id='my-account-company-section'>
      {loading ? (
        <SpinLoaderContent />
      ) : (
        <Row gutter={12}>
          <Col span={24} className='mb-10'>
            <Text strong>{t("Company Profile")}</Text>
          </Col>
          <Col span={12}>
            <Space direction='vertical' className='full-width'>
              <ListItem label='Company Name' value={data?.companyname} />
              <ListItem label='Phone No.' value={data?.phone} />
              <ListItem label='Email' value={data?.email} />
              <ListItem label='Registration No' value={data?.registrationNo} />
              <ListItem label='Subscription Status' value={data?.subscriptionStatus} />
              <ListItem label='Trial End' value={dayjs(data?.zipCode).format("DDD, DD MMM YYYY HH:mm:ss")} />
              <ListItem label='Zip Code' value={data?.zipCode} />
              <ListItem label='State' value={data?.state} />
              <ListItem label='Country' value={data?.country} />
              <ListItem label='Address' value={data?.address} />
            </Space>
          </Col>
          <Col span={12}>
            <Space direction='vertical' className='full-width'>
              <ListItem label='Description' value={data?.description} />
            </Space>
          </Col>
          <Col span={24}>
            <ButtonPrimary
              className='float-right'
              text='Edit Company'
              icon={<EditOutlined />}
              handleClick={() => history.push("/my-account/company/update/" + data?._id)}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

const ListItem = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={6}>
        <Text>{t(label)} :</Text>
      </Col>
      <Col span={18}>
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

export default Company;

/// <reference path="../../../types.d.ts"/>
import React from "react";
import { ArrowLeftOutlined, DownOutlined, GlobalOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Image, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18next from "../../../languages/i18n";
import useLoggedInUser from "../../../hooks/useLoggedInUser";
import * as loginActions from "../../../actions/login.action";
import "./Header.scss";

const { Title, Text } = Typography;

const MainHeader = ({ title, buttonBack = {}, description, modeTablet }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const items = [
    {
      label: t("Change Language"),
      key: "change-language",
      icon: <GlobalOutlined style={{ color: "#247cff" }} />,
    },
    {
      type: "divider",
    },
    {
      label: t("Logout Account"),
      key: "logout",
      icon: <LogoutOutlined style={{ color: "#ff1717" }} />,
    },
  ];

  const handleChangeLanguage = () => {
    const language = i18n.language !== "en" ? "en" : "bh";
    i18next.changeLanguage(language);
  };

  const handleLogout = () => {
    loginActions.logout();
    return (window.location.href = "/login");
  };

  const handleMenuClick = (value) => {
    const { key } = value;
    if (key === "change-language") handleChangeLanguage();
    else handleLogout();
  };

  const headerContentTabletProps = {
    title,
    description,
    items,
    handleMenuClick,
    buttonBack,
    history,
  };

  const headerContentProps = {
    title,
    description,
    items,
    handleMenuClick,
    buttonBack,
    history,
  };

  // The app is open in iframe
  if (window.parent !== window) {
    return null;
  }

  return modeTablet ? (
    <HeaderContentTablet {...headerContentTabletProps} />
  ) : (
    <HeaderContent {...headerContentProps} />
  );
};

export const HeaderContent = ({
  title,
  description,
  buttonBack,
  items,
  handleMenuClick,
  history,
}) => {
  const { t } = useTranslation();
  const { firstName } = useLoggedInUser();

  return (
    <section id="header">
      <Row>
        <Col span={12}>
          <div>
            {buttonBack.status ? (
              <Title
                className="button-back"
                level={5}
                style={{ margin: 0 }}
                onClick={() => {
                  if (buttonBack.path) history.push(buttonBack.path);
                  else history.goBack();
                }}
              >
                <ArrowLeftOutlined className="button-back-icon" /> <span>{t(buttonBack.text)}</span>
              </Title>
            ) : (
              <Title level={5} style={{ margin: 0 }}>
                {t(title)}
              </Title>
            )}
            <Text> {t(description)}</Text>
          </div>
        </Col>
        <Col span={12} className="right-content">
          <Space>
            <Image width={30} preview={false} src={`${window.location.origin}/images/user.svg`} />
            <Dropdown
              menu={{
                items,
                onClick: handleMenuClick,
              }}
            >
              <Text strong>
                <Space>
                  <Text>{firstName}</Text>
                  <DownOutlined />
                </Space>
              </Text>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export const HeaderContentTablet = ({ title, buttonBack, history }) => {
  const { t } = useTranslation();

  return (
    <section id="header-tablet">
      {buttonBack.status ? (
        <Space direction="horizontal">
          <Button type="primary" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
            {t("Back")}
          </Button>
          <Title className="custom-title" level={5}>
            {buttonBack.text}
          </Title>
        </Space>
      ) : (
        <Title className="custom-title" level={5}>
          {title}
        </Title>
      )}
      <Divider className="custom-divider" />
    </section>
  );
};

export default MainHeader;

import React, { useEffect, useMemo, useState } from "react";
import {
  CloseOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Row, Space, Switch, Table, Tooltip } from "antd";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom/cjs/react-router-dom";
import { digitFormat, filterParams } from "../../utils/Functions";
import ButtonCustom from "../../components/atoms/ButtonCustom/ButtonCustom";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryActionTable from "../../components/atoms/ButtonPrimaryActionTable";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import UploadCSVModal from "../../components/molecules/UploadCSVModal/UploadCSVModal";
import PriceTieringModal from "../../components/molecules/PriceTieringModal/PriceTieringModal";
import EditPriceTieringModal from "../../components/molecules/EditPriceTieringModal/EditPriceTieringModal";
import TooltipCell from "../../components/atoms/TooltipCell";
import * as inventoryActions from "../../actions/inventory.action";
import * as pricetierActions from "../../actions/pricetier.action";
import * as uploadActions from "../../actions/upload.action";

const parsePriceTierCSVToArray = (file, errorData) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function (e) {
      let contents = e.target.result;
      let rows = contents.split("\n");

      let dataArray = [];
      rows.forEach(function (row, index) {
        if (index >= 2) {
          let values = row.split(",");
          let result = {
            sku: values[0]?.trim() || "",
            stock: values[1]?.trim() || "",
            price: values[2]?.trim() || "",
            firstTierMin: values[3]?.trim() || "",
            firstTierMax: values[4]?.trim() || "",
            firstTierPercentage: values[5]?.trim() || "",
            secondTierMin: values[6]?.trim() || "",
            secondTierMax: values[7]?.trim() || "",
            secondTierPercentage: values[8]?.trim() || "",
            thirdTierMin: values[9]?.trim() || "",
            thirdTierMax: values[10]?.trim() || "",
            thirdTierPercentage: values[11]?.trim() || "",
            fourthTierMin: values[12]?.trim() || "",
            fourthTierMax: values[13]?.trim() || "",
            fourthTierPercentage: values[14]?.trim() || "",
            errors: errorData,
          };

          dataArray.push(result);
        }
      });

      resolve(dataArray);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsText(file);
  });
};

export const SkuSwitch = React.memo(({ productId, isActive }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const handleChangeActiveInactive = async (value) => {
    const isSuccess = await dispatch(inventoryActions.changeStatus(productId));
    if (!isSuccess) setIsChecked(!value);
  };

  useEffect(() => setIsChecked(isActive), [isActive]);

  return (
    <Switch
      checkedChildren={t("Active")}
      unCheckedChildren={t("Inactive")}
      checked={isChecked}
      onChange={(value) => {
        handleChangeActiveInactive(value);
        setIsChecked(value);
      }}
    />
  );
});

const SKUManagement = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [priceTieringModalOpen, setPriceTieringModalOpen] = useState(false);
  const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState(false);
  const [selectedSKUs, setSelectedSKUs] = useState([]);
  const [isSelectMultiple, setIsSelectMultiple] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState([]);
  const [dataBulkUpload, setDataBulkUpload] = useState({});
  const [isBulkUploadLoading, setIsBulkUploadLoading] = useState(false);
  const [isShowBulkUploadInfo, setIsShowBulkUploadInfo] = useState(false);
  const [isUpdatePriceTierLoading, setIsUpdatePriceTierLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  const inventory = useSelector((state) => state.inventoryReducer);

  const params = useMemo(() => {
    const paramsConstruct = {
      $page: page,
      $limit: pageSize,
      companyId: id,
      search: searchValue,
    };

    return filterParams(paramsConstruct);
  }, [page, pageSize, searchValue]);

  useEffect(() => {
    setLoading(true);
    dispatch(inventoryActions.index(params));
  }, [params]);

  useEffect(() => {
    if (inventory?.result) {
      const { data, meta } = inventory?.result;
      setData(data);
      setMeta(meta);
      setLoading(false);
    }
  }, [inventory]);
  

  const handleEditPriceTier = async (inventoryId, isBasePriceTierUpdated = false, basePriceTierValues, priceTiersValues) => {
    setIsUpdatePriceTierLoading(true);
    let isUpdatedSuccess = false;
    if(isBasePriceTierUpdated){
      const values = { data: basePriceTierValues };
      const isSuccess = await dispatch(inventoryActions.updateBulk(values));
      if(isSuccess){
        const isUpdatedPriceTiersSuccess = await dispatch(pricetierActions.update(inventoryId, { priceTiers: priceTiersValues }));
        if(isUpdatedPriceTiersSuccess) {
          dispatch(inventoryActions.index(params));
          isUpdatedSuccess = true;
        } 
      }
    }else {
      const isUpdatedPriceTiersSuccess = await dispatch(pricetierActions.update(inventoryId, { priceTiers: priceTiersValues }));
      if(isUpdatedPriceTiersSuccess) isUpdatedSuccess = true;
    }
    
    setIsUpdatePriceTierLoading(false);
    return isUpdatedSuccess;
  }

  const handleExport = () => {
    const paramsExport = {
      $limit: 0,
      companyId: id,
      status: 1,
    };
    dispatch(inventoryActions.exportInventoryToCsv(paramsExport));
  };

  const handleClickDropdown = (e) => {
    if (e.key === "upload-csv") {
      setUploadCSVModalOpen(true);
    } else if (e.key === "download-csv") {
      dispatch(pricetierActions.template());
    }
  };

  const checkUpdateBulkStatus = async (file, data) => {
    const csvDetails = await parsePriceTierCSVToArray(file, data?.errors);
    const result = {
      name: file?.name,
      success: data?.success?.length ?? 0,
      errors: data?.errors?.length ?? 0,
      data: csvDetails,
    };
    setDataBulkUpload(result);
  };

  const handleUploadCSV = async (value) => {
    let csvValue = new FormData();
    csvValue.append("file", value);
    const response = await dispatch(uploadActions.upload({ folder: "baskit-temps" }, csvValue));

    setIsBulkUploadLoading(true);
    if (response.isSuccess) {
      const values = { filename: response?.data?.fileName };
      const responseUpdateBulk = await dispatch(pricetierActions.updateBulk(id, values));
      if (responseUpdateBulk.isSuccess) {
        const { data } = responseUpdateBulk;
        checkUpdateBulkStatus(value, data);
        setIsBulkUploadLoading(false);
        setIsShowBulkUploadInfo(true);
      } else setIsBulkUploadLoading(true);
    } else setIsBulkUploadLoading(true);
  };

  const marginFormat = (value) => {
    if (!value) return "0%";

    if (typeof value === "number") {
      value = value.toFixed(2).replace(".", ",");
    }

    return `${value}%`;
  };

  const dataSource = data.map((data, index) => ({
    key: index,
    id: data?.id,
    image: data?.image,
    sku: data?.sku,
    name: data?.fullName,
    status: data,
    stock: data?.stock,
    basePrice: data?.basePrice,
    margin: data?.margin,
    sellingPrice: data?.sellingPrice,
    priceTier: data,
    action: (
      <div className="flex-center">
        <ButtonPrimaryActionTable
          text={t("Edit")}
          title={t("Edit")}
          className={isSelectMultiple ? "disabled-button" : ""}
          disabled={isSelectMultiple ? true : false}
          icon={<EditOutlined />}
          handleClick={() => {
            setSelectedSKU(data);
            setModalOpen(true);
          }}
        />
      </div>
    ),
  }));

  // Search sku by ID and then open edit modal when openEdit query params exist with value
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const skuId = queryParams.get('openEdit')

    if (skuId) {
      setSearchValue(skuId);
      const datum = data.find((datum) => datum.sku === skuId);
    
      if (datum) {
        setSelectedSKU(datum);
        setModalOpen(true);
      }
    }
  }, [search, setSearchValue, setSelectedSKU, setModalOpen, data])

  const ViewPriceTier = React.memo(({ data }) => (
    <Button
      className={"button-link-primary " + (data?.priceTier.length < 2 && "button-link-disabled")}
      disabled={isEmpty(data?.priceTier) ? true : false}
      type="link"
      icon={<FileTextOutlined />}
      onClick={() => {
        setSelectedSKU(data);
        setPriceTieringModalOpen(true);
      }}
    >
      {t("view")}
    </Button>
  ));

  const columns = useMemo(
    () => [
      {
        title: t("SKU ID"),
        dataIndex: "sku",
        key: "sku",
        ellipsis: {
          showTitle: false,
        },
        render: (text) => <TooltipCell text={text} />,
      },
      {
        title: t("SKU Name"),
        dataIndex: "name",
        key: "name",
        ellipsis: {
          showTitle: false,
        },
        render: (text) => <TooltipCell text={text} />,
      },
      {
        title: t("Status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (data) => <SkuSwitch productId={data?.id} isActive={data?.isActive} />,
      },
      {
        title: t("Stock"),
        dataIndex: "stock",
        key: "stock",
        width: 150,
      },
      {
        title: t("Current Base Price"),
        dataIndex: "basePrice",
        key: "basePrice",
        render: (value) => digitFormat(value),
      },
      {
        title: t("Current Margin"),
        dataIndex: "margin",
        key: "margin",
        render: (value) => marginFormat(value),
      },
      {
        title: t("Selling Price"),
        dataIndex: "sellingPrice",
        key: "sellingPrice",
        render: (value) => digitFormat(value),
      },
      {
        title: t("Price Tiering"),
        dataIndex: "priceTier",
        key: "priceTier",
        width: 120,
        fixed: "right",
        render: (data) => <ViewPriceTier data={data} />,
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        width: 120,
        fixed: "right",
      },
    ],
    [t],
  );

  const productSearchFilterProps = {
    placeholder: "Search product name",
    handleChange: (value) => setSearchValue(value),
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedSKUs(selectedRows);
    },
  };

  const tabelProps = {
    loading,
    dataSource,
    columns,
    rowSelection: isSelectMultiple
      ? {
          type: "checkbox",
          ...rowSelection,
        }
      : undefined,
    pagination: {
      pageSize: pageSize,
      current: page,
      total: meta?.totalData,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
    },
    scroll: { y: "60vh" },
  };

  const uploadCSVModalProps = {
    modalOpen: uploadCSVModalOpen,
    setModalOpen: setUploadCSVModalOpen,
    isBulkUploadLoading,
    isShowBulkUploadInfo,
    setIsShowBulkUploadInfo,
    data: dataBulkUpload,
    handleSubmit: handleUploadCSV,
  };

  const priceTieringModalProps = {
    modalOpen: priceTieringModalOpen,
    setModalOpen: setPriceTieringModalOpen,
    details: selectedSKU,
  };
  
  const editPriceTieringModalProps = {
    modalOpen,
    setModalOpen,
    isLoading: isUpdatePriceTierLoading,
    details: selectedSKU,
    handleSubmit: handleEditPriceTier,
  };

  const menuProps = {
    items: [
      {
        label: t("Upload CSV"),
        key: "upload-csv",
        icon: <CloudUploadOutlined />,
      },
      {
        label: t("Download CSV"),
        key: "download-csv",
        icon: <CloudDownloadOutlined />,
      },
    ],
    onClick: handleClickDropdown,
  };

  return (
    <div id="sku-management">
      <div id="sku-management-filter">
        <Row gutter={12}>
          <Col span={isSelectMultiple ? 10 : 14}>
            <ProductSearchFilter {...productSearchFilterProps} />
          </Col>
          <Col span={3}>
            <ButtonDefault
              text="Export"
              block={true}
              icon={<DownloadOutlined />}
              handleClick={handleExport}
            />
          </Col>
          <Col span={3}>
            <Dropdown menu={menuProps}>
              <Button className="button-default" type="default" block={true}>
                <Space className="full-width flex-center">
                  {t("Bulk Update")}
                  <DownOutlined style={{ marginLeft: 0 }} />
                </Space>
              </Button>
            </Dropdown>
          </Col>
          {isSelectMultiple ? (
            <>
              <Col span={4}>
                <ButtonCustom
                  className="button-sku-management-edit"
                  block={true}
                  color="#BDE3FF"
                  handleClick={() => {
                    setSelectedSKUs([]);
                    setIsSelectMultiple(false);
                  }}
                >
                  <span>{selectedSKUs.length + " " + t("Selected Product")}</span>
                  <CloseOutlined />
                </ButtonCustom>
              </Col>
              <Col span={4}>
                <ButtonPrimary
                  className={isEmpty(selectedSKUs) ? "disabled-button" : ""}
                  text="Edit"
                  block={true}
                  icon={<EditOutlined />}
                  disabled={isEmpty(selectedSKUs) ? true : false}
                  // handleClick={() => setModalOpen(true)}
                />
              </Col>
            </>
          ) : (
            <Col span={4}>
              <ButtonPrimary
                text="Select Multiple"
                block={true}
                handleClick={() => {
                  setSelectedSKUs([]);
                  setIsSelectMultiple(true);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
      <div id="sku-management-content">
        <Table async {...tabelProps} />
      </div>
      <div>
        <UploadCSVModal {...uploadCSVModalProps} />
        <PriceTieringModal {...priceTieringModalProps} />
        <EditPriceTieringModal {...editPriceTieringModalProps} />
      </div>
    </div>
  );
};

export default SKUManagement;

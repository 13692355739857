import {
  DASHBOARD_CHART_ERROR,
  DASHBOARD_CHART_FETCHED,
  DASHBOARD_CHART_FETCHING,
  DASHBOARD_PERIOD_CHANGING,
  DASHBOARD_RECORD_CHANGING,
  DASHBOARD_STATE_CHANGED,
  DASHBOARD_STATUS_CHANGING,
  DASHBOARD_TOTAL_FETCHING,
  DASHBOARD_TOTAL_FETCHED,
  DASHBOARD_TOTAL_ERROR,
  DASHBOARD_SKU_TRANSACTION_FETCHING,
  DASHBOARD_SKU_TRANSACTION_FETCHED,
  DASHBOARD_SKU_TRANSACTION_ERROR,
  DASHBOARD_TOP_FIVE_SKU_FETCHING,
  DASHBOARD_TOP_FIVE_SKU_FETCHED,
  DASHBOARD_TOP_FIVE_SKU_ERROR,
  DASHBOARD_SKU_ON_BOARD_FETCHING,
  DASHBOARD_SKU_ON_BOARD_FETCHED,
  DASHBOARD_SKU_ON_BOARD_ERROR,
  DASHBOARD_SKU_ON_BOARD_APPENDED,
  DASHBOARD_SKU_ON_BOARD_CLEAR,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { ErrorNotification } from "../utils/Notification";

/**
 * @typedef {'today'|'yesterday'|'lastweek'|'lastmonth'} PeriodType
 * @typedef {'transaction'|'income'} RecordType
 * @typedef {{
 *  selectAll?: boolean,
 *  selectOffline?: boolean,
 *  selectOnline?: boolean,
 * }} StatusType
 * @typedef {import("../pages/home/mainchart").DataPayload} ChartDataPayload
 * @typedef {import("../reducers/dashboard.reducer").TotalDataPayload} TotalDataPayload
 */

/**
 * @function setPeriodChanging
 * @description The action creator for setting the period.
 * @param {PeriodType} period - The new period.
 * @returns {Object} The Redux action.
 */
const setPeriodChanging = (period) => ({
  type: DASHBOARD_PERIOD_CHANGING,
  period,
});

/**
 * set data type changing state
 * @function setDataTypeChanging
 * @param   {RecordType}  recordType  [type description]
 * @return  {Object}        [return description]
 */
const setRecordTypeChanging = (recordType) => ({
  type: DASHBOARD_RECORD_CHANGING,
  recordType,
});

/**
 * set chart fetching state
 * @function setChartFetching
 * @return  {Object}        [return description]
 */
const setChartFetching = () => ({
  type: DASHBOARD_CHART_FETCHING,
});

/**
 * set chart fetched state
 * @function setChartFetched
 * @param {ChartDataPayload[]} chartData
 * @return  {Object}        [return description]
 */
const setChartFetched = (chartData) => ({
  type: DASHBOARD_CHART_FETCHED,
  chartData,
});

/**
 * set chart fetch error state
 * @function setChartError
 * @return  {Object}        [return description]
 */
const setChartError = () => ({
  type: DASHBOARD_CHART_ERROR,
});

/**
 * set total fetching state
 * @function setTotalFetching
 * @return  {Object}        [return description]
 */
const setTotalFetching = () => ({
  type: DASHBOARD_TOTAL_FETCHING,
});

/**
 * set total fetched state
 * @function setTotalFetched
 * @param {TotalDataPayload} totalData
 * @return  {Object}        [return description]
 */
const setTotalFetched = (totalData) => ({
  type: DASHBOARD_TOTAL_FETCHED,
  totalData,
});

/**
 * set total fetch error state
 * @function setTotalError
 * @return  {Object}        [return description]
 */
const setTotalError = () => ({
  type: DASHBOARD_TOTAL_ERROR,
});

/**
 * set record status changing state
 * @function setRecordStatusChanging
 * @param   {StatusType}  status  [type description]
 * @return  {Object}        [return description]
 */
const setRecordStatusChanging = (status) => ({
  type: DASHBOARD_STATUS_CHANGING,
  ...status,
});

/**
 * set period done changing state
 * @function setPeriodChanged
 * @param   {PeriodType}  period  new period
 * @returns {Object} The Redux action.
 */
const setStateChanged = () => ({
  type: DASHBOARD_STATE_CHANGED,
});

/**
 * @function setDashboardPeriod
 * @description The action creator for setting the period.
 * @param {PeriodType} period - The new period.
 * @returns {(dispatch:Function) => Promise<void>}
 */
export const setDashboardPeriod = (period) => {
  return async (dispatch) => {
    dispatch(setPeriodChanging(period));
    dispatch(setStateChanged());
  };
};

/**
 * @function setDashboardRecordType
 * @description The action creator for setting the record type.
 * @param {RecordType} recordType - The new record type.
 * @returns {(dispatch:Function) => Promise<void>}
 */
export const setDashboardRecordType = (recordType) => {
  return async (dispatch) => {
    dispatch(setRecordTypeChanging(recordType));
    dispatch(setStateChanged());
  };
};

/**
 * @function setStatusSelect
 * @description The action creator for setting the period.
 * @param {StatusType} status - The new status.
 * @returns {(dispatch:Function) => Promise<void>}
 */
export const setStatusSelect = (status) => {
  return async (dispatch) => {
    dispatch(setRecordStatusChanging(status));
    dispatch(setStateChanged());
  };
};

const setSKUTransactionFetching = () => ({
  type: DASHBOARD_SKU_TRANSACTION_FETCHING,
});

const setSKUTransactionFetched = (skuTransaction) => ({
  type: DASHBOARD_SKU_TRANSACTION_FETCHED,
  skuTransaction,
});

const setSKUTransactionChartError = () => ({
  type: DASHBOARD_SKU_TRANSACTION_ERROR,
});

const setTopFiveSKUFetching = () => ({
  type: DASHBOARD_TOP_FIVE_SKU_FETCHING,
});

const setTopFiveSKUFetched = (topFiveSku) => ({
  type: DASHBOARD_TOP_FIVE_SKU_FETCHED,
  topFiveSku,
});

const setTopFiveSKUError = () => ({
  type: DASHBOARD_TOP_FIVE_SKU_ERROR,
});

const setSKUOnBoardFetching = () => ({
  type: DASHBOARD_SKU_ON_BOARD_FETCHING,
});

const setSKUOnBoardFetched = (skuOnBoard) => ({
  type: DASHBOARD_SKU_ON_BOARD_FETCHED,
  skuOnBoard,
});

const setSKUOnBoardAppended = (skuOnBoard) => ({
  type: DASHBOARD_SKU_ON_BOARD_APPENDED,
  skuOnBoard,
});

const setSKUOnBoardClear = () => ({
  type: DASHBOARD_SKU_ON_BOARD_CLEAR,
});

const setSKUOnBoardError = () => ({
  type: DASHBOARD_SKU_ON_BOARD_ERROR,
});

/**
 * @function fetchChartData
 * @description The action creator for fetching chart data.
 * @param {PeriodType} period - Selector period.
 * @param {boolean} singleDay - Is period single day.
 * @returns {(dispatch:Function) => Promise<void>}
 */
export const fetchChartData = (period, singleDay) => {
  return async (dispatch) => {
    dispatch(setChartFetching());
    const requestParams = new URLSearchParams({ periode: period });
    const requestURL = new URL(
      `dashboard/mainchart?${requestParams}`,
      process.env.REACT_APP_API_URL_V2,
    );
    try {
      const response = await httpClient.get(requestURL.toString());
      /**
       * @typedef {{
       *  date: string,
       *  time: string,
       *  status: 'Online'|'Offline',
       *  income: number,
       *  transaction: number,
       * }} ChartDataResponse
       * @type {ChartDataResponse[]}
       */
      const responseData = response.data.data;

      const onlineTotal = responseData
        .filter(({ status }) => status === "Online")
        .reduce(
          (prev, curr) => ({
            transaction: prev.transaction + curr.transaction,
            income: prev.income + curr.income,
          }),
          { transaction: 0, income: 0 },
        );

      /** @type {ChartDataPayload[]} */
      const chartData = [];
      for (let index = 0; index < responseData.length; index += 2) {
        const [onlineData, offlineData] = responseData.slice(index, index + 2);
        chartData.push({
          time: onlineData.time,
          transaction: {
            all: onlineData.transaction + offlineData.transaction,
            online: singleDay ? onlineTotal.transaction : onlineData.transaction,
            offline: offlineData.transaction,
          },
          income: {
            all: onlineData.income + offlineData.income,
            online: singleDay ? onlineTotal.income : onlineData.income,
            offline: offlineData.income,
          },
          edge: index === 0 || index === responseData.length - 2,
        });
      }
      dispatch(setChartFetched(chartData));
    } catch (error) {
      dispatch(setChartError());
    }
  };
};

/**
 * @function fetchTotalTransactionData
 * @description The action creator for fetching total transaction data.
 * @param {PeriodType} period - Selector period.
 * @param {RecordType} recordType - Selector record type.
 * @returns {(dispatch:Function) => Promise<void>}
 */
export const fetchTotalTransactionData = (period, recordType) => {
  return async (dispatch) => {
    dispatch(setTotalFetching());
    const requestParams = new URLSearchParams({ periode: period, type: recordType });
    const requestURL = new URL(
      `dashboard/transaction-total?${requestParams}`,
      process.env.REACT_APP_API_URL_V2,
    );
    try {
      const response = await httpClient.get(requestURL);
      dispatch(setTotalFetched(response.data.data));
    } catch (error) {
      dispatch(setTotalError());
    }
  };
};

export const fetchSKUTransaction = (params) => {
  return async (dispatch) => {
    dispatch(setSKUTransactionFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_V2 + `dashboard/sku-transaction${paramsString}`,
      );
      dispatch(setSKUTransactionFetched(response.data.data));
      // SuccessNotification(response);
    } catch (error) {
      dispatch(setSKUTransactionChartError());
      ErrorNotification(error);
    }
  };
};

export const fetchTopFiveSKU = (params) => {
  return async (dispatch) => {
    dispatch(setTopFiveSKUFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_V2 + `dashboard/topfive-sku${paramsString}`,
      );
      dispatch(setTopFiveSKUFetched(response.data.data));
      // SuccessNotification(response);
    } catch (error) {
      dispatch(setTopFiveSKUError());
      ErrorNotification(error);
    }
  };
};

export const fetchSKUOnBoard = (params) => {
  return async (dispatch) => {
    dispatch(setSKUOnBoardClear());
    dispatch(setSKUOnBoardFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory${paramsString}`,
      );
      const { data, totalPage, totalData } = response.data;
      dispatch(setSKUOnBoardFetched({ data, meta: { totalPage, totalData} }));
      // SuccessNotification(response);
    } catch (error) {
      dispatch(setSKUOnBoardError());
      ErrorNotification(error);
    }
  };
};

export const loadMoreSKUONBoard = (params) => {
  return async (dispatch) => {
    dispatch(setSKUOnBoardFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory${paramsString}`,
      );
      const { data } = response.data;
      dispatch(setSKUOnBoardAppended(data));
    } catch (error) {
      dispatch(setSKUOnBoardError());
    }
  }
}

export const updateSKUOnBoard = ({ values, params, setModalOpen, setParams }) => {
  return async (dispatch) => {
    dispatch(setSKUOnBoardFetching());
    try {
      await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory/${values.id}`,
        values,
      );
      setModalOpen(false);
      const newParams = { ...params, $page: 1 };
      dispatch(fetchSKUOnBoard(newParams));
      setParams(newParams);
    } catch (error) {
      dispatch(setSKUOnBoardError());
      ErrorNotification(error);
    }
  };
};

import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./ButtonDefault.scss";

const ButtonDefault = ({
  children = undefined,
  text,
  block = false,
  disabled = false,
  className = "",
  htmlType = "button",
  danger = false,
  icon = undefined,
  loading = false,
  handleClick,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      className={"button-default " + className}
      type="default"
      disabled={disabled}
      htmlType={htmlType}
      block={block}
      danger={danger}
      onClick={handleClick}
      icon={icon}
      loading={loading}
    >
      {text === undefined ? children : t(text)}
    </Button>
  );
};

export default ButtonDefault;

import {
  SUBSCRIPTION_FETCHING,
  SUBSCRIPTION_FAILED,
  SUBSCRIPTION_SUCCESS,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIPTION_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case SUBSCRIPTION_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case SUBSCRIPTION_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    default:
      return state;
  }
};

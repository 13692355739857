/// <reference path="./actions.d.ts"/>
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import swal from "sweetalert";
import {
  FETCH_OPTION_SUCCESS,
  ORDER_CLEAR,
  ORDER_DETAIL_FAILED,
  ORDER_DETAIL_FETCHING,
  ORDER_DETAIL_SUCCESS,
  ORDER_FAILED,
  ORDER_FETCHING,
  ORDER_PAYMENT,
  ORDER_PRINT_PDF,
  ORDER_PRODUCT_CLEAR,
  ORDER_SET_TAX,
  ORDER_SUCCESS,
  ORDER_UPDATE,
  ORDER_UPDATE_STATUS,
  ORDER_UPDATE_STATUS_CANCEL,
  ORDER_UPDATE_STATUS_CLEAR,
  ORDER_UPDATE_STATUS_EXECUTE,
  ORDER_UPDATE_STATUS_SUCCESS,
  SHOP_UPDATE_ORDER,
  SHOP_UPDATE_PAYMENT,
  ORDER_LIST_FAILED,
  ORDER_LIST_FETCHING,
  ORDER_LIST_SUCCESS,
  UPDATE_ORDER_FETCHING,
  UPDATE_ORDER_FAILED,
  ORDER_DETAILS_FETCHING,
  ORDER_DETAILS_FAILED,
  ORDER_DETAILS_SUCCESS,
  DOWNLOAD_ORDER_INVOICE_FETCHING,
  DOWNLOAD_ORDER_INVOICE_FAILED,
  server,
  ORDER_REASONS_FAILED,
  ORDER_REASONS_FETCHING,
  ORDER_REASONS_SUCCESS,
  ORDER_UPDATE_STATUS_FAILED,
  ORDER_UPDATE_STATUS_FETCHING,
  ORDER_UPDATE_STATUS_BULK_FAILED,
  ORDER_UPDATE_STATUS_BULK_FETCHING,
  ORDER_CREATE_FAILED,
  ORDER_CREATE_FETCHING,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setOrderStateToFetching = () => ({
  type: ORDER_FETCHING,
});

export const setOrderStateToFailed = () => ({
  type: ORDER_FAILED,
});
export const setOrderStateToClear = () => ({
  type: ORDER_CLEAR,
});
export const setOrderStateToSuccess = (payload, meta = {}) => ({
  type: ORDER_SUCCESS,
  payload,
  orderMeta: meta,
});
export const setOrderData = (payload) => ({
  type: ORDER_UPDATE,
  payload,
});
export const setOrderPaymentStateToSuccess = (payload) => ({
  type: ORDER_PAYMENT,
  payload,
});
export const setFetchOptionStateToSuccess = (payload) => ({
  type: FETCH_OPTION_SUCCESS,
  payload,
});
export const setOrderProductStateToClear = () => ({
  type: ORDER_PRODUCT_CLEAR,
});

export const setUpdateOrderStatus = (payload) => ({
  type: ORDER_UPDATE_STATUS,
  payload,
});

export const setUpdateOrderStatusCancel = () => ({
  type: ORDER_UPDATE_STATUS_CANCEL,
});

export const setUpdateOrderStatusClear = () => ({
  type: ORDER_UPDATE_STATUS_CLEAR,
});

export const cancelUpdateOrderStatus = (params = {}) => ({
  type: ORDER_UPDATE_STATUS_CANCEL,
  updateStatusOpen: false,
});

export const executeUpdateOrderStatus = () => ({
  type: ORDER_UPDATE_STATUS_EXECUTE,
});

export const setUpdateOrderStatusSuccess = () => ({
  type: ORDER_UPDATE_STATUS_SUCCESS,
});

export const setOrderTax = (payload) => ({
  type: ORDER_SET_TAX,
  payload,
});

const setStateShoptoUpdateOrder = (payload) => ({
  type: SHOP_UPDATE_ORDER,
  payload: payload,
});

export const setOrderDetailStateToFetching = () => ({
  type: ORDER_DETAIL_FETCHING,
});

export const setOrderDetailStateToFailed = () => ({
  type: ORDER_DETAIL_FAILED,
});

export const setOrderDetailStateToSuccess = (payload) => ({
  type: ORDER_DETAIL_SUCCESS,
  payload,
});

export const setOrderListStateToFetching = () => ({
  type: ORDER_LIST_FETCHING,
});

export const setOrderListStateToFailed = () => ({
  type: ORDER_LIST_FAILED,
});

export const setOrderListStateToSuccess = (payload) => ({
  type: ORDER_LIST_SUCCESS,
  payload,
});

export const setUpdateOrderStateToFetching = () => ({
  type: UPDATE_ORDER_FETCHING,
});

export const setUpdateOrderStateToFailed = () => ({
  type: UPDATE_ORDER_FAILED,
});

export const setOrderDetailsStateToFetching = () => ({
  type: ORDER_DETAILS_FETCHING,
});

export const setOrderDetailsStateToFailed = () => ({
  type: ORDER_DETAILS_FAILED,
});

export const setOrderDetailsStateToSuccess = (payload) => ({
  type: ORDER_DETAILS_SUCCESS,
  payload,
});

export const setDownloadOrderInvoiceStateToFetching = () => ({
  type: DOWNLOAD_ORDER_INVOICE_FETCHING,
});

export const setDownloadOrderInvoiceStateToFailed = () => ({
  type: DOWNLOAD_ORDER_INVOICE_FAILED,
});

export const setOrderReasonsStateToFetching = () => ({
  type: ORDER_REASONS_FETCHING,
});

export const setOrderReasonsStateToFailed = () => ({
  type: ORDER_REASONS_FAILED,
});

export const setOrderReasonsStateToSuccess = (payload) => ({
  type: ORDER_REASONS_SUCCESS,
  payload,
});

export const setOrderUpdateStatusStateToFetching = () => ({
  type: ORDER_UPDATE_STATUS_FETCHING,
});

export const setOrderUpdateStatusStateToFailed = () => ({
  type: ORDER_UPDATE_STATUS_FAILED,
});

export const setOrderUpdateStatusBulkStateToFetching = () => ({
  type: ORDER_UPDATE_STATUS_BULK_FETCHING,
});

export const setOrderUpdateStatusBulkStateToFailed = () => ({
  type: ORDER_UPDATE_STATUS_BULK_FAILED,
});

export const setOrderCreateStateToFetching = () => ({
  type: ORDER_CREATE_FETCHING,
});

export const setOrderCreateStateToFailed = () => ({
  type: ORDER_CREATE_FAILED,
});

// export const addProduct = () => {
//   return (dispatch, getState) => {
//     let productLines = getState().orderReducer.products;
//     productLines.push({
//       product: null,
//       qty: ''
//     })

//     dispatch(setOrderProduct(productLines));
//   };
// };

// export const removeProduct = (index) => {
//   return (dispatch, getState) => {
//     let productLines = getState().orderReducer.products;
//     productLines.splice(index, 1)

//     dispatch(setOrderProduct(productLines));
//   };
// };

// export const setDetailProduct = (index, payload) => {
//   return (dispatch, getState) => {
//     let productLines = getState().orderReducer.products;
//     productLines[index] = payload

//     dispatch(setOrderProduct(productLines));
//   };
// };

export const setPrintData = (payload) => ({
  type: ORDER_PRINT_PDF,
  payload,
});

export const getPrintData = async (id) => {
  const response = await httpClient.get(process.env.REACT_APP_API_URL + "order/print/" + id);

  if (response.data.result === "success") {
    return response.data.data;
    // dispatch(setPrintData(response.data.data));
  } else if (response.data.result === "error") {
    // dispatch(setOrderStateToFailed());
    swal("Error!", response.data.message, "error");
  }
};

// export const getOrderDetail = (id) => {
//   return async (dispatch) => {
//     dispatch(setOrderStateToFetching());
//     const response = await httpClient.get(
//         process.env.REACT_APP_API_URL + "order/" + id
//     );

//     if (response.data.result === "success") {
//       const {order, orderDetail, customer} = response.data.data
//       let data = {
//         orderDetail,
//         order,
//         customer
//       }
//       data.id = id
//       dispatch(setOrderData(data));
//       dispatch(setOrderTax(order.tax));
//       dispatch(shopActions.setOrder(orderDetail))

//     } else if (response.data.result === "error") {
//       dispatch(setOrderStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const getOrderDetail = (
  id,
  /** @type {Object?} */
  params
) => {
  return async (dispatch) => {
    dispatch(setOrderDetailStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/${id}`, { params }
      );
      dispatch(setOrderDetailStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setOrderDetailStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getOrderPayment = (id) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `order/payment/${id}`);
      dispatch(setOrderPaymentStateToSuccess(response.data.data[0]));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getOrderInvoice = (id, params = null) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_V2 + `order/invoice/${id}${paramsString}`,
      );
      // window.open(process.env.REACT_APP_API_URL + response.data.url);
      SuccessNotification(response);
      return { isSuccess: true, url: response.data.url };
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false };
    }
  };
};

export const CreatePayment = (values, history) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "order/payment", values);
    if (response.data.result === "success") {
      dispatch(setOrderStateToSuccess(response.data));
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setOrderStateToClear());
        dispatch(Index());
        history.push("/order");
      });
    } else if (response.data.result === "error") {
      dispatch(setOrderStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const Update = (values, history) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    const response = await httpClient.put(process.env.REACT_APP_API_URL + "order", values);
    if (response.data.result === "success") {
      dispatch(setOrderStateToSuccess(response.data));
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setOrderStateToClear());
        dispatch(Index());
        history.push("/order");
      });
    } else if (response.data.result === "error") {
      dispatch(setOrderStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const Create = (values, history) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "order", values);
    if (response.data.result === "success") {
      dispatch(setOrderStateToSuccess(response.data));
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setOrderStateToClear());
        dispatch(Index());
        history.push("/order");
      });
    } else if (response.data.result === "error") {
      dispatch(setOrderStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const Cancel = (value, setLoading) => {
//   return async (dispatch) => {
//       dispatch(setOrderStateToFetching());
//       const response = await httpClient.get(
//           process.env.REACT_APP_API_URL + "order/cancel/" + value);
//       if (response.data.result === "success") {
//           swal("Success!", response.data.message, "success").then((value) => {
//               dispatch(setOrderStateToClear());
//               dispatch(Index());
//               setLoading(false);
//           });
//       } else if (response.data.result === "error") {
//           dispatch(setOrderStateToFailed());
//           swal("Error!", response.data.message, "error");
//       }
//   };
// };

/**
 * @param {unknown} value
 * @param {object?} history
 */
export const Cancel = (value, history = undefined) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + "order/cancel/" + value,
      );
      dispatch(setOrderStateToClear());
      SuccessNotification(response);
      if (history?.goBack) {
        history.goBack();
      }
      return true;
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const uploadPaymentProof = (value, orderID, id) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_V2 + `order/payment/upload-proof/${orderID}`,
        value,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      dispatch(setOrderStateToClear());
      SuccessNotification(response);
      dispatch(getOrderDetail(id, { withPrices: "base" }));
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getReceipt = (id, setModalOpen) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL_NEW_V2 + `order/receipt/${id}`, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
      setModalOpen(false);
      SuccessNotification(response);
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const SavePrint = async (values) => {
  const response = await httpClient.post(process.env.REACT_APP_API_URL + "order/save-pdf", values);
  if (response.data.result === "success") {
    return response.data.data;
    // dispatch(setOrderStateToSuccess(response.data));
    // swal("Success!", response.data.message, "success").then((value) => {
    //     dispatch(setOrderStateToClear());
    //     dispatch(Index());
    // });
  } else if (response.data.result === "error") {
    // dispatch(setOrderStateToFailed());
    swal("Error!", response.data.message, "error");
  }
};

/**
 * @typedef {{
 *  companyId?: string,
 *  search?: unknown,
 *  status?: unknown,
 *  limit?: number,
 *  page?: number,
 *  periode?: BaskitApp.PeriodeType,
 *  orderType?: 'offline'|'online',
 * }} GetOrderParams
 */

/**
 * Get order list actions
 *
 * @param   {GetOrderParams?}  params  [params description]
 *
 * @return  {(dispatch: any) => Promise<void>}          [return description]
 */
export const GetOrderV2 = (params = null) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    const paramsString = parseParamsString(params);
    const response = await httpClient.get(
      process.env.REACT_APP_API_URL_V2 + `order${paramsString}`,
    );
    if (response.data.result === "success") {
      dispatch(setOrderStateToSuccess(response.data.data, response.data.meta));
    } else if (response.data.result === "error") {
      dispatch(setOrderStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

/**
 * @typedef {{
 *  targetOrderOId: unknown,
 *  newStatus: BaskitApp.OrderStatus,
 *  totalValue: number,
 *  updateStatusOpen?: boolean,
 * }} UpdateOrderStatusParams
 */

export const UpdateOrderStatus = (/** @type {UpdateOrderStatusParams} */ params) => {
  return async (dispatch) => {
    dispatch(setUpdateOrderStatus(params));
  };
};

export const CancelUpdateOrderStatus = () => {
  return async (dispatch) => {
    dispatch(cancelUpdateOrderStatus());
  };
};

/**
 * @typedef {{
 *  newStatus: BaskitApp.OrderStatus,
 *  order_id: unknown,
 *  fail?: string|null,
 *  success: string,
 * }} ExecuteUpdateStatusParams
 */

export const ExecuteUpdateStatus = (
  /** @type {ExecuteUpdateStatusParams} */ { newStatus, order_id, fail, success },
) => {
  return async (dispatch) => {
    dispatch(executeUpdateOrderStatus());
    try {
      if (newStatus === "paid") {
        await httpClient.post(`${process.env.REACT_APP_API_URL}order/set-paid`, {
          order_id,
        });
      }
      if (newStatus === "canceled") {
        await httpClient.get(process.env.REACT_APP_API_URL + "order/cancel/" + order_id);
      }
      dispatch(setUpdateOrderStatusSuccess());
      return SuccessNotification(success, true, {
        duration: 5,
      });
    } catch (error) {
      dispatch(setUpdateOrderStatusSuccess());
      return ErrorNotification(error, {
        duration: 5,
      });
    }
  };
};

/**
 * Get order list actions
 *
 * @param   {GetOrderParams?}  params  [params description]
 *
 * @return  {(dispatch: any) => Promise<void>}          [return description]
 */
export const Index = (params = null) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    const paramsString = parseParamsString(params);
    const response = await httpClient.get(
      process.env.REACT_APP_API_URL_V2 + `order${paramsString}`,
    );
    if (response.data.result === "success") {
      dispatch(setOrderStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setOrderStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

const doUpdateOrder = (dispatch, orderLines) => {
  // debugger;
  let totalPrice = 0;
  let taxAmt = 0;
  for (let item of orderLines) {
    totalPrice += item.price * item.qty;
  }
  taxAmt = totalPrice * 0.07;

  dispatch(
    setStateShoptoUpdateOrder({
      orderLines,
      totalPrice,
      taxAmt,
    }),
  );
};

export const addOrder = (item) => {
  return (dispatch, getState) => {
    let orderLines = getState().shopReducer.mOrderLines;
    let index = orderLines.indexOf(item);
    if (index === -1) {
      item.qty = 1;
      orderLines.unshift(item);
    } else {
      orderLines[index].qty++;
    }

    doUpdateOrder(dispatch, orderLines);
  };
};

export const removeOrder = (product) => {
  return (dispatch, getState) => {
    let orderLines = getState().shopReducer.mOrderLines;
    var foundIndex = orderLines.indexOf(product);

    orderLines.map((item) => {
      if (item.product_id === product.product_id) {
        item.qty = 1;
      }
    });
    orderLines.splice(foundIndex, 1);

    doUpdateOrder(dispatch, orderLines);
  };
};

export const submitPayment = (data) => {
  return (dispatch, getState) => {
    httpClient.post(server.TRANSACTION_URL, data).then(() => {
      // Clear payment
      getState().shopReducer.mOrderLines = [];
      dispatch({
        type: SHOP_UPDATE_PAYMENT,
        payload: {
          isPaymentMade: false,
          given: 0,
        },
      });
    });
  };
};

export const togglePaymentState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SHOP_UPDATE_PAYMENT,
      payload: {
        isPaymentMade: !getState().shopReducer.mIsPaymentMade,
        given: !getState().shopReducer.mGiven,
      },
    });
  };
};

export const Remove = (id) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + "order/" + id);
    if (response.data.result === "success") {
      dispatch(setOrderStateToSuccess());
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setOrderStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const printPDF = async (id, isDownload = true, isSave = false) => {
  const { customer, order, orderDetail, payment } = await getPrintData(id);
  var pageWidth = 8.3,
    lineHeight = 1.5,
    margin = 0.6,
    marginTop = 0.48,
    maxLineWidth = pageWidth - margin,
    fontSize = 10,
    ptsPerInch = 72,
    oneLineHeight = (fontSize * lineHeight) / ptsPerInch;

  var doc = new JsPDF({
    unit: "in",
    // lineHeight: lineHeight,
    format: "a4",
  })
    .setProperties({ title: "Invoice" })
    .setFont("times", "normal");

  doc.setFontSize(15).text("Invoice", margin, marginTop + oneLineHeight);
  // doc.addImage(baskitLogo, "PNG", 5.7, -0.7, 2.5, 2.5);
  doc.addImage(
    `${window.location.origin}/images/baskit-logo-invoice.png`,
    "PNG",
    5.7,
    -0.7,
    2.5,
    2.5,
  );
  // doc.text(statusVal, 7.6, marginTop + oneLineHeight, {align: "right"})

  var tinggiGarisPembatas = marginTop + oneLineHeight + 0.05;
  doc.setLineWidth(0.01).line(margin, tinggiGarisPembatas, maxLineWidth - 0.1, tinggiGarisPembatas);

  doc
    .setFont("times", "bold")
    .setFontSize(15)
    .text("Baskit", 7.6, marginTop + 3 * oneLineHeight, { align: "right" });
  doc
    .setFontSize(10)
    .text("Baskit Address", 7.6, marginTop + 4 * oneLineHeight, { align: "right" });
  doc.text("Baskit Phone Number", 7.6, marginTop + 5 * oneLineHeight, { align: "right" });

  doc
    .setFontSize(15)
    .text("Sales Order: " + order.no_sales_order, margin, marginTop + 6 * oneLineHeight);
  doc
    .setFont("times", "normal")
    .setFontSize(10)
    .text(
      "Created Date: " + moment(order.timestamp).format("DD-MM-YYYY"),
      margin,
      marginTop + 7 * oneLineHeight,
    );
  doc.text(
    "Due Date: " + moment(order.timestamp).add(order.payment_due, "days").format("DD-MM-YYYY"),
    margin,
    marginTop + 8 * oneLineHeight,
  );

  doc
    .setFontSize(12)
    .setFont("times", "bold")
    .text("Invoiced to", margin, marginTop + 10 * oneLineHeight);
  doc
    .setFontSize(10)
    .setFont("times", "normal")
    .text(customer.name, margin, marginTop + 11 * oneLineHeight);
  doc.text(customer.email, margin, marginTop + 12 * oneLineHeight);
  doc.text(customer.address, margin, marginTop + 13 * oneLineHeight);
  doc.text(customer.noPhone, margin, marginTop + 14 * oneLineHeight);
  // doc.setFont('times', 'bold').setFontSize(12).text('Ditagihkan Ke', margin, marginTop + 6 * oneLineHeight)
  // doc.setFont('times', 'normal').setFontSize(10).text(namaVal, margin, marginTop + 7 * oneLineHeight)
  // doc.text(emailVal, margin, marginTop + 8 * oneLineHeight)

  // let dataHal1 = dataDetail.splice(0, 15);

  let opsi = {
    headStyles: { fillColor: [170, 170, 170] },
    columns: [
      { header: "Product", dataKey: "name" },
      { header: "Price", dataKey: "price" },
      { header: "Qty", dataKey: "qty" },
      { header: "Subtotal", dataKey: "subtotal" },
    ],
  };

  var subtotal = 0;
  var isAddPage = true;
  let finalY = 0;

  opsi.startY = marginTop + 15 * oneLineHeight;
  opsi.body = orderDetail;
  opsi.willDrawCell = function (data) {
    if (data.column.index === 3 && data.cell.section === "body") {
      // var img = data.cell.raw;
      const { price, qty } = data.row.raw;
      const subtotalRow = price * qty;
      subtotal += subtotalRow;
      data.cell.text = subtotalRow.toString();
    }

    if (data.cell.y + data.cell.height > 10.4) {
      data.cell.y = 0.6;

      if (isAddPage) {
        finalY = data.cell.y + data.cell.height;
        doc.autoTableAddPage();
        isAddPage = false;
      }
    }
  };
  doc.autoTable(opsi);

  if (payment.length > 0) {
    finalY = isAddPage ? doc.lastAutoTable.finalY + 2 * oneLineHeight : finalY;
    doc.setFontSize(12).setFont("times", "bold").text("Payments", margin, finalY);
    finalY += oneLineHeight;
    opsi = {
      headStyles: { fillColor: [170, 170, 170] },
      columns: [
        { header: "Date", dataKey: "date" },
        { header: "Amount", dataKey: "amount" },
      ],
    };

    doc.setFont("times", "normal");
    isAddPage = true;

    // opsi.columnStyles = {
    //     0: {font: 'times', fontStyle: 'bold', cellWidth: 1.3, cellPadding: {right: 0.1}},
    //     1: {font: 'times', cellWidth: 5.7}
    // }
    opsi.startY = finalY;
    opsi.body = payment;
    // opsi.willDrawCell = function (data) {
    //     if(data.cell.y + data.cell.height > 10.4){
    //         data.cell.y = 0.6

    //         if(isAddPage){
    //             finalY = data.cell.y + data.cell.height
    //             doc.autoTableAddPage();
    //             isAddPage = false
    //         }
    //     }
    // }
    doc.autoTable(opsi);
  }

  finalY = doc.lastAutoTable.finalY;

  if (finalY > 8.7) {
    doc.addPage("a4");
    finalY = marginTop;
  }

  doc.setFont("times", "bold").text(`Subtotal `, margin + 4.8, finalY + oneLineHeight);
  doc.text(`Tax `, margin + 4.8, finalY + 2 * oneLineHeight);
  doc.text(`Total `, margin + 4.8, finalY + 3 * oneLineHeight);
  doc.text(`Rest Of the Bill `, margin + 4.8, finalY + 4 * oneLineHeight);

  doc.setFont("times", "normal").text(subtotal.toString(), margin + 6.1, finalY + oneLineHeight);
  doc.text(order.tax + "%", margin + 6.1, finalY + 2 * oneLineHeight);
  doc.text(order.total.toString(), margin + 6.1, finalY + 3 * oneLineHeight);
  doc.text((order.total - order.paid).toString(), margin + 6.1, finalY + 4 * oneLineHeight);

  if (isDownload) doc.save(`${order.no_sales_order}.pdf`);

  if (isSave) {
    var blob = doc.output("blob");
    let formData = new FormData();
    formData.append("pdf", blob);
    formData.append("noSalesOrder", order.no_sales_order);
    return await SavePrint(formData);
    // doc.save(`${order.no_sales_order}.pdf`);
    // saveAs(blob, `${order.no_sales_order}.pdf`);
  }
};

export const downloadPDFInvoice = (orderId, params) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_V2 + `order/invoice-download/${orderId}${paramsString}`,
        { responseType: "blob" },
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      return window.open(blobUrl, "_blank");
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const downloadZipInvoice = (orderId) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_V2 + `order/zip-download/${orderId}`,
        { responseType: "blob" },
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      const blobUrl = URL.createObjectURL(blob);
      window.location.href = blobUrl;
      return URL.revokeObjectURL(blobUrl);
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const confirmOrder = (orderId, values, type) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/status/${orderId}?type=${type}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const updateOrder = (orderId, values) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_V2 + `order/detail/${orderId}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const orderList = (params = null) => {
  return async (dispatch) => {
    dispatch(setOrderListStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/list-order${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setOrderListStateToSuccess({ data: data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setOrderListStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const update = (id, values) => {
  return async (dispatch) => {
    dispatch(setorderstatetofetching());
    try {
      const response = await httpclient.patch(
        process.env.react_app_api_url_new_v2 + `order/${id}`,
        values,
      );
      successmessage(response);
      return true;
    } catch (error) {
      dispatch(setorderstatetofailed());
      errornotification(error);
      return false;
    }
  };
};

export const orderDetails = (id, params) => {
  return async (dispatch) => {
    dispatch(setOrderDetailsStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/${id}`
      );

      dispatch(setOrderDetailsStateToSuccess(response?.data.data));
      SuccessNotification(response);
      return { orderId, order };
    } catch (error) {
      dispatch(setOrderDetailsStateToFailed());
      return false;
    }
  };
};

export const downloadOrderInvoice = (id, params) => {
  return async (dispatch) => {
    dispatch(setDownloadOrderInvoiceStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/invoice/${id}${paramsString}`,
        { responseType: "blob" },
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setDownloadOrderInvoiceStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const reasons = (params = null) => {
  return async (dispatch) => {
    dispatch(setOrderReasonsStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/reason${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setOrderReasonsStateToSuccess({ data: data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setOrderReasonsStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const updateStatus = (id, values, params = null) => {
  return async (dispatch) => {
    dispatch(setOrderUpdateStatusStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/status/${id}${paramsString}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setOrderUpdateStatusStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const updateStatusBulk = (values) => {
  return async (dispatch) => {
    dispatch(setOrderUpdateStatusBulkStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `order/status/bulk`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setOrderUpdateStatusBulkStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const index = (params) => {
  return async (dispatch) => {
    dispatch(setOrderStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `order${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setOrderStateToSuccess({ data, totalData, totalPage }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setOrderStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const create = (values) => {
  return async (dispatch) => {
    dispatch(setOrderCreateStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + "order",
        values,
      );
      SuccessMessage(response);
      return { isSuccess: true, orderCode: response?.data?.data?.orderCode, orderId: response?.data?.data?.id, ...response?.data?.data };
    } catch (error) {
      dispatch(setOrderCreateStateToFailed());
      // ErrorNotification(error);
      return { isSuccess: false, orderId: null };
    }
  };
};

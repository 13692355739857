import { CalendarOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import moment from "moment";
import "moment/min/locales";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardPeriod } from "../../actions/dashboard.action";
import ButtonCoditional from "../../components/atoms/ButtonConditional/ButtonConditional";
import SingleRangePicker from "../../components/atoms/SingleRangePicker/SingleRangePicker";
import i18n from "../../languages/i18n";

/**
 * @typedef {import("../../reducers/dashboard.reducer").DashboardState} DashboardState
 * @typedef {import("../../actions/dashboard.action").PeriodType} PeriodType
 */

const dateRangeRegex = /\d\d\/\d\d\/\d\d\d\d-\d\d\/\d\d\/\d\d\d\d/i;

const PeriodSelector = () => {
  /** @type {DashboardState} */
  const { period } = useSelector(( state ) => state.dashboardReducer)
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /** @type {(date:[string, string]) => void} */
  const handleDateSelect = (date) => {
    changePeriod(date.join('-'));
  };

  /** @type {(period:PeriodType) => string} */
  const translatePeriod = (period) => {
    if (dateRangeRegex.test(period)) {
      moment.locale(i18n.language === "bh" ? "id" : i18n.language);
      const [ start, end ] = period.split('-');
      const dateStart = moment(start, 'DD/MM/YYYY').format('D MMM YYYY');
      const dateEnd = moment(end, 'DD/MM/YYYY').format('D MMM YYYY');
      return `${dateStart} - ${dateEnd}`
    }
    return t(period);
  }

  /** @type {(period:PeriodType) => void} */
  const changePeriod = (period) => {
    dispatch(setDashboardPeriod(period));
  };

  return (
    <div id="period-selector">
      <Row gutter={16}>
        <Col id="period-selector-label" span={8}>
          <span>
            <CalendarOutlined id="period-icon"/>
            {t("period")}: {translatePeriod(period)}
          </span>
        </Col>
        <Col id="period-selector-button" span={16}>
          <ButtonCoditional
            text={t("today")}
            active={period === "today"}
            handleClick={() => changePeriod("today")}
          />
          <ButtonCoditional
            text={t("yesterday")}
            active={period === "yesterday"}
            handleClick={() => changePeriod("yesterday")}
          />
          <ButtonCoditional
            text={t("lastweek")}
            active={period === "lastweek"}
            handleClick={() => changePeriod("lastweek")}
          />
          <ButtonCoditional
            text={t("lastmonth")}
            active={period === "lastmonth"}
            handleClick={() => changePeriod("lastmonth")}
          />
          <div id="selector-date-range"
            className={dateRangeRegex.test(period) ? "active" : undefined}
          >
            <SingleRangePicker
              format="DD/MM/YYYY"
              handleChange={handleDateSelect}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PeriodSelector;

import { server } from "../constants";
import { logout } from "../actions/login.action";
import axios from "axios";
import join from "url-join";
import Cookies from "js-cookie";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(process.env.REACT_APP_API_URL, config.url);
  }

  const accessToken = localStorage.getItem(server.TOKEN_KEY);
  const refreshToken = localStorage.getItem(server.REFRESH_TOKEN_KEY);
  const userId = localStorage.getItem(server.USER_ID);

  if (config.url.includes('refresh-token') && refreshToken) {
    config.headers = { Authorization: refreshToken, "X-ID": userId };
  } else if (accessToken) {
    config.headers = { Authorization: accessToken, "X-ID": userId };
  }

  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const errorConfig = { ...error };

    if (error.response) {
      if (error.config.url.includes('refresh-token') && error.response.status === 401) {
        logout();
        const isInsideIframe = window.parent !== window;

        if (!isInsideIframe) {
          window.location.href = '/login';
        } else {
          console.error(error, 'ini error bro. kenapa?');
        }
      }

      if (error.response.status === 401) {
        const refreshToken = localStorage.getItem(server.REFRESH_TOKEN_KEY);
        const userId = localStorage.getItem(server.USER_ID);
        const values = { id: userId, refreshToken };

        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL_NEW_V2 + "refresh-token",
            values,
          );

          const { data } = response.data;

          localStorage.setItem(server.TOKEN_KEY, data?.accessToken);
          localStorage.setItem(server.REFRESH_TOKEN_KEY, data?.refreshToken);

          window.location.reload();
        } catch (error) {
          logout();
          const isInsideIframe = window.parent !== window;

          if (!isInsideIframe) {
            window.location.href = '/login';
          } else {
            console.error(error, 'ini error bro. kenapa?');
          }
        }
      } 
    } else if (errorConfig.request) {
      // No response received
      errorConfig.response = {
        status: 408,
        statusText: "Unable to connect to the server",
        data: {
          message: "Please ensure your internet connection is stable and try again",
        },
      };
    } else {
      // Other errors occurred
      errorConfig.response = {
        status: 600,
        statusText: "Unexpected error",
        data: {
          message: "The application has encountered an unknown error",
        },
      };
    }
    return Promise.reject(errorConfig);
  },
);

export const httpClient = axios;

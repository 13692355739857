import {
  PRICE_TIER_TEMPLATE_FETCHING,
  PRICE_TIER_TEMPLATE_FAILED,
  PRICE_TIER_FETCHING,
  PRICE_TIER_FAILED,
  PRICE_TIER_SUCCESS,
  PRICE_TIER_UPDATE_BULK_FETCHING,
  PRICE_TIER_UPDATE_BULK_FAILED,
  PRICE_TIER_UPDATE_FETCHING,
  PRICE_TIER_UPDATE_FAILED,
} from "../constants";
import { downloadCSV, parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setPriceTierTemplateStateToFetching = () => ({
  type: PRICE_TIER_TEMPLATE_FETCHING,
});

export const setPriceTierTemplateStateToFailed = () => ({
  type: PRICE_TIER_TEMPLATE_FAILED,
});

export const setPriceTierStateToFetching = () => ({
  type: PRICE_TIER_FETCHING,
});

export const setPriceTierStateToFailed = () => ({
  type: PRICE_TIER_FAILED,
});

export const setPriceTierStateToSuccess = (payload) => ({
  type: PRICE_TIER_SUCCESS,
  payload,
});

export const setPriceTierUpdateBulkStateToFetching = () => ({
  type: PRICE_TIER_UPDATE_BULK_FETCHING,
});

export const setPriceTierUpdateBulkStateToFailed = () => ({
  type: PRICE_TIER_UPDATE_BULK_FAILED,
});

export const setPriceTierUpdateStateToFetching = () => ({
  type: PRICE_TIER_UPDATE_FETCHING,
});

export const setPriceTierUpdateStateToFailed = () => ({
  type: PRICE_TIER_UPDATE_FAILED,
});

export const template = () => {
  return async (dispatch) => {
    dispatch(setPriceTierTemplateStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `price-tier/template`,
      );
      downloadCSV(response.data);
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setPriceTierTemplateStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const index = (params = null) => {
  return async (dispatch) => {
    dispatch(setPriceTierStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `price-tier/${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setPriceTierStateToSuccess({ data: data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setPriceTierStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const updateBulk = (companyId, values) => {
  return async (dispatch) => {
    dispatch(setPriceTierUpdateBulkStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + `price-tier/bulk/${companyId}`,
        values,
      );
      SuccessNotification(response);
      return { isSuccess: true, data: response?.data?.data };
    } catch (error) {
      dispatch(setPriceTierUpdateBulkStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false, data: null };
    }
  };
};

export const update = (id, values) => {
  return async (dispatch) => {
    dispatch(setPriceTierUpdateStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `price-tier/inventory/${id}`,
        values,
      );
      SuccessMessage(response);
      return { isSuccess: true, data: response?.data?.data };
    } catch (error) {
      dispatch(setPriceTierUpdateStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false, data: null };
    }
  };
};

import React from "react";
import { Breadcrumb, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toSentenceCase } from "../../../utils/Functions";
import "./Breadcrumb.scss";

const MainBreadcrumb = ({ items }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className='breadcrumb-wrapper'>
      <Breadcrumb>
        {items.length > 0 &&
          items.map((item, index) => {
            const onClick = () => {
              if (item.link === '/transaction-management') {
                window?.parent?.postMessage(
                  {
                    type: 'transactionManagement',
                    payload: item.link
                  },
                  process.env.REACT_APP_NEW_CMS
                )

              } else {
                window?.parent?.postMessage(
                  {
                    type: 'breadcrumbs',
                    payload: item.link
                  },
                  process.env.REACT_APP_NEW_CMS
                )
              }
            };

            if (index === items.length - 1) {
              return (
                <Breadcrumb.Item key={index} onClick={onClick}>
                  <Tag>{toSentenceCase(t(item.title))}</Tag>
                </Breadcrumb.Item>
              );
            } else {
              return (
                <Breadcrumb.Item key={index} onClick={onClick}>
                  <a onClick={() => { 
                    if(item.link === 'default') history.goBack();
                    else history.push(item.link);
                  }}>{toSentenceCase(t(item.title))}</a>
                </Breadcrumb.Item>
              );
            }
          })}
      </Breadcrumb>
    </div>
  );
};

export default MainBreadcrumb;

import React, { useEffect, useState } from "react";
import {
  Col,
  Divider,
  Form,
  Image,
  InputNumber,
  Row,
  Select,
  Typography,
  Input,
  Space,
  Card,
  Button,
  Radio,
  Checkbox,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { server } from "../../constants";
import LoginContent from "../../components/organisms/LoginContent/LoginContent";
import * as loginActions from "../../actions/login.action";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./_style.scss";

const { Title, Text } = Typography;

const Login = () => {
  const constant = {
    SUCCESS: "success",
    FAILED: "failed",
    RESEND: "resend",
    TIMEREMAINING: "time_remaining",
  };
  const countryPhones = [{ code: "+60" }, { code: "+62" }, { code: "+65" }, { code: "+92" }];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loginStatus, setLoginStatus] = useState({});
  const [, setIsButtonDisabled] = useState(true);
  const [validationStatus, setValidationStatus] = useState({});
  const [dataStepOne, setDataStepOne] = useState({});
  const [, setValue] = useState(1);
  const [form] = Form.useForm();
  const history = useHistory();

  const [setPasswordVisible] = React.useState(false);

  useEffect(() => {
    form.setFieldValue("code_phone", "+62");
    setDataStepOne(JSON.parse(localStorage.getItem("registerItem")));
  }, []);

  useEffect(() => {
    if (loginStatus.status === 400) {
      setValidationStatus({
        status: constant.FAILED,
        message: "Your number is not registered yet, you can register first",
      });
    }
  }, [loginStatus]);

  const checkFirstThreePhoneNumber = (arr, stringValue) => {
    const firstThreeChars = stringValue.substring(0, 3);

    for (let i = 0; i < arr.length; i++) {
      const code = arr[i].code;
      if (code.startsWith(firstThreeChars)) return true;
    }
    return false;
  };

  const checkNumberOnly = (inputString) => {
    const numberRegex = /^[0-9]+$/;
    return numberRegex.test(inputString);
  };

  const handleSubmit = () => {
    const { code_phone, phone } = form.getFieldsValue();
    if (checkFirstThreePhoneNumber(countryPhones, `+${phone}`)) {
      setValidationStatus({
        status: constant.FAILED,
        message:
          "The phone number format you entered is incorrect, please start without using the phone number country code",
      });
    } else if (!checkNumberOnly(phone.toString())) {
      setValidationStatus({
        status: constant.FAILED,
        message:
          "The phone number format you entered is incorrect. Please only input numbers without any symbols or other characters",
      });
    } else {
      localStorage.removeItem(server.LOGIN_VALUES);
      localStorage.removeItem(constant.TIMEREMAINING);
      const dataToSend = {
        phone: code_phone + phone,
        type: "login",
      };
      dispatch(loginActions.login(dataToSend, history, setLoginStatus));
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    form.setFieldValue();
    form.setFieldValue("code_phone", "+62");
    if (e.target.value === 0) {
      form.setFieldValue("username", dataStepOne.username);
      form.setFieldValue("companyEmail", dataStepOne.companyEmail);
    }
  };

  const handleChange = (value) => {
    const phoneNumber = value ? value.toString() : "";
    const phoneNumberLength = (form.getFieldValue("code_phone") + phoneNumber).length;
    if (phoneNumberLength > 11) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
    setValidationStatus({});
  };

  return (
    <LoginContent>
      <div id="register-wrapper" className="flex-center-justify">
        <div className="content">
          <Row gutter={24} className="register-card ml-20 mr-20">
            <Col span={24}>
              <div className="button-title-back" onClick={() => history.goBack()}>
                <Space direction="horizontal" style={{ marginLeft: "1rem" }}>
                  <ArrowLeftOutlined />
                  <Title level={3} style={{ margin: 0 }}>
                    {t("Register")}
                  </Title>
                </Space>
              </div>
              <div>
                <Divider className="register-divider" />
              </div>
            </Col>
            <Col span={20}>
              <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                {t("Informasi Toko")}
              </Title>
            </Col>
            <Col span={4}>
              <Title level={5} style={{ margin: 0, textAlign: "right" }}>
                2/2
              </Title>
            </Col>
            <Col span={12}>
              <div className="register-card-content">
                <div className="mt-40 mb-10">
                  <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                    Apakah nama pemilik sama dengan data sebelumnya?
                  </Text>
                </div>
                <Form form={form} onFinish={handleSubmit}>
                  <Radio.Group onChange={onChange} defaultValue={1}>
                    <Radio value={0}>Ya</Radio>
                    <Radio value={1}>Tidak</Radio>
                  </Radio.Group>

                  <div className="mt-40 mb-10">
                    <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                      {t("Name")}
                    </Text>
                  </div>
                  <Form.Item name="username">
                    <Input placeholder="Masukan nama" className="register-number" />
                  </Form.Item>

                  <div className="mt-40 mb-10">
                    <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                      {t("Alamat Email")}
                    </Text>
                  </div>
                  <Form.Item name="companyEmail">
                    <Input placeholder="Masukan alamat email" className="register-input-number" />
                  </Form.Item>
                  <div className="mt-40 mb-10">
                    <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                      {t("Masukan nomor ponsel")}
                    </Text>
                  </div>
                  <FormItemPhoneNumber
                    name="phone"
                    size="large"
                    label="Enter your phone name here"
                    countryPhones={countryPhones}
                    validateStatus={validationStatus.status === constant.FAILED && "error"}
                    help={
                      !isEmpty(validationStatus) ? (
                        <span>
                          <ExclamationCircleOutlined /> {" " + t(validationStatus.message)}
                        </span>
                      ) : (
                        ""
                      )
                    }
                    handleChange={handleChange}
                  />
                </Form>
              </div>
            </Col>
            <Col span={12}>
              <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                {t("Foto Tokomu")}
              </Title>
              <Card style={{ margin: "5px", background: "#F4F4F4", border: "2px dotted #8A8A8A" }}>
                <Image src={dataStepOne ? dataStepOne.companyImage : ""}></Image>
              </Card>
              <Row gutter={24}>
                <Col span={24} style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Checkbox>
                    Dengan mendaftar, Anda menyetujui Syarat & Ketentuan serta Kebijakan privasi.
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Button size="large" block onClick={() => history.push("/register")}>
                    Kembali
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => setPasswordVisible((prevState) => !prevState)}
                  >
                    Daftar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </LoginContent>
  );
};

const FormItemPhoneNumber = ({
  label,
  name,
  rules,
  size = "middle",
  disabled = false,
  countryPhones,
  validateStatus,
  help,
  handleChange,
}) => {
  const { t } = useTranslation();
  const prefixSelector = (
    <Form.Item name={"code_" + name} noStyle>
      <Select>
        {countryPhones.map(({ code }) => (
          <Select.Option value={code} key={code}>
            {code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const parseNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <Form.Item name={name} rules={rules} validateStatus={validateStatus} help={help}>
      <InputNumber
        addonBefore={prefixSelector}
        className="register-input-number"
        type="number"
        parser={parseNumber}
        placeholder={t(label)}
        style={{ width: "100%" }}
        size={size}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default Login;

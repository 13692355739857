import React from "react";
import { Col, ConfigProvider, Divider, Row, Space, Typography, Timeline } from "antd";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import dayjs from "dayjs";
import "./style.scss";

const { Text } = Typography;

const DeliveryDetail = ({ data, orderHistory }) => {
  const { t } = useTranslation();

  return (
    <div id="delivery-detail">
      <div className="mb-20">
        <Text strong>{t("deliveryDetail")}</Text>
      </div>
      <ListItem label="deliveryName" value={data?.name ?? ""} />
      <ListItem label="Phone No." value={data?.phone ?? ""} />
      <ListItem label="vehicleNo" value={data?.vehicle ?? ""} />
      <ListItem label="vehicleType" value={data?.vehicleType ?? ""} />
      {!isEmpty(orderHistory) && (
        <>
          <Divider />
          <div id="order-history">
            <ConfigProvider theme={{ token: { colorPrimary: "#0a8080" } }}>
              <Timeline className="mt-10">
                {orderHistory.map(({ id, content, createdAt }) => (
                  <Timeline.Item key={id}>
                    <Space className="timeline-content w-1/2" direction="vertical">
                      <Text strong>{content?.message}</Text>
                      <Divider style={{ margin: "0px" }} />
                      <Text style={{ fontSize: "12px" }} type="secondary">
                        {t("dateCreated")}
                      </Text>
                      <Text>
                        {dayjs(createdAt).format("DD MMM YYYY")} |{" "}
                        {dayjs(createdAt).format("HH:mm:ss")}
                      </Text>
                    </Space>
                  </Timeline.Item>
                ))}
              </Timeline>
            </ConfigProvider>
          </div>
        </>
      )}
    </div>
  );
};

const ListItem = ({ label, value, children }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-10">
      <Col span={6}>
        <Text strong>{t(label)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={17}>{value ? <Text>{value}</Text> : children}</Col>
    </Row>
  );
};

export default DeliveryDetail;

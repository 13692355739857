import React, { useState, useEffect } from "react";
import { Col, Row, Typography, Card, Modal, Image } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getType } from "./helpers";
import { SellerStatus } from "../../constants";
import { useTranslation } from "react-i18next";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import TagCustom from "../../components/atoms/TagCustom/TagCustom";
import ButtonSecondary from "../../components/atoms/ButtonSecondary/ButtonSecondary";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import MapModal from "../../components/molecules/MapModal";
import * as companyActions from "../../actions/company.action";
import "./_style.scss";

const { Text } = Typography;

const Seller = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [status, setStatus] = useState(SellerStatus.INACTIVE.value);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalTextApprove, setModalTextApprove] = useState(
    "Are you sure you want to approve this seller?",
  );
  const [modalTextReject, setModalTextReject] = useState(
    "Are you sure you want to reject this seller?",
  );

  const { id } = useParams();
  const { companySellerDetails } = useSelector((state) => state.companyReducer);

  useEffect(() => {
    dispatch(companyActions.sellerDetails(id));
  }, []);

  useEffect(() => {
    if (companySellerDetails) {
      setStatus(companySellerDetails?.status);
      setData(companySellerDetails);
    }
  }, [companySellerDetails]);

  const headerProps = {
    buttonBack: { text: "Seller Details", status: true },
    description: "Manage your seller details",
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setOpenApprove(false);
    setOpenReject(false);
  };

  const showModalApprove = () => {
    setOpenApprove(true);
  };

  const showModalReject = () => {
    setOpenReject(true);
  };

  const setCompanyApproval = async (values) => {
    setModalTextApprove("Proccessing Request...");
    setConfirmLoading(true);

    const isSuccess = await dispatch(companyActions.approval(id, values));
    if (isSuccess) setStatus(values?.status);
    else setStatus(data?.status);
    setConfirmLoading(false);
    setOpenApprove(false);
    setOpenReject(false);
  };

  const handleApprove = async () => {
    const values = { status: SellerStatus.ACTIVE.value };
    setCompanyApproval(values);
  };

  const handleReject = () => {
    const values = { status: SellerStatus.INACTIVE.value };
    setCompanyApproval(values);
  };

  const handleOpenGoogleMaps = (lat, long) => {
    const mapUrl = `https://www.google.com/maps?q=${lat},${long}`;
    window.open(mapUrl, "_blank");
  };

  const mainBreadcrumbItems = [
    {
      link: "/saler",
      title: "Seller",
    },
    {
      title: "Seller Details",
    },
  ];

  return (
    <MainContent headerProps={headerProps}>
      <div id="seller-detail-wrapper">
        <div>
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div id="seller-detail-status" className="text-right">
          <TagCustom
            className="strong"
            text={status ? SellerStatus[status]?.label : "undefined"}
            type={getType(status)}
          />
        </div>

        <div id="seller-detail-content">
          <Row gutter={12}>
            <Col span={12}>
              <Card className="card-info" title="Seller Information" bordered={false}>
                <ListItem label="Name" value={data?.detail?.accountHolderName ?? ""} />
                <ListItem label="Email" value={data?.email ?? ""} />
                <ListItem label="Phone No." value={data?.phone ?? ""} />
              </Card>
            </Col>

            <Col span={12}>
              <Card className="card-info" title="Store Information" bordered={false}>
                <ListItem label="Store Name" value={data?.companyName ?? ""} />
                <ListItem label="Store Address" value={data?.detail?.address ?? ""} />
                <ListItem label="Location">
                  <ButtonSecondary
                    text="View On Map"
                    icon={<EnvironmentFilled />}
                    handleClick={() => showModal()}
                  />
                </ListItem>
              </Card>
            </Col>
          </Row>

          <Row className="mt-10">
            <Col span={24}>
              <Card title="Uploaded Document" bordered={false} className="no-border">
                <Image
                  width={200}
                  height={200}
                  src={
                    data?.detail?.avatar ?? `${window.location.origin}/images/baskit-product.jpg`
                  }
                  fallback={`${window.location.origin}/images/baskit-product.jpg`}
                />
              </Card>
            </Col>
          </Row>

          <div className="mt-10 mr-10">
            <Row gutter={12}>
              <Col offset={12} span={6}>
                <ButtonDefault
                  className={status === SellerStatus.INACTIVE.value ? "button-disabled" : ""}
                  disabled={status === SellerStatus.INACTIVE.value ? true : false}
                  text="Reject"
                  block
                  handleClick={() => showModalReject()}
                />
              </Col>
              <Col span={6}>
                <ButtonPrimary
                  className={status === SellerStatus.ACTIVE.value ? "button-disabled" : ""}
                  disabled={status === SellerStatus.ACTIVE.value ? true : false}
                  text="Approve"
                  block
                  handleClick={() => showModalApprove()}
                />
              </Col>
            </Row>
          </div>

          <MapModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            latitude={data?.detail?.latitude}
            longitude={data?.detail?.longitude}
            address={data?.detail?.address}
          />

          <Modal
            title="Accept Request"
            open={openApprove}
            onOk={handleApprove}
            centered
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>{modalTextApprove}</p>
          </Modal>

          <Modal
            title="Accept Request"
            open={openReject}
            onOk={handleReject}
            centered
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>{modalTextReject}</p>
          </Modal>
        </div>
      </div>
    </MainContent>
  );
};

const ListItem = ({ label, value, children }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-10">
      <Col span={6}>
        <Text strong>{t(label)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={17}>{value ? <Text>{value}</Text> : children}</Col>
    </Row>
  );
};

export default Seller;

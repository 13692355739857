import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Dashboard from "./components/templates/Dashboard/Dashboard";
import LoadingPage from "./components/templates/LoadingPage/LoadingPage";
import { server } from "./constants";
import AmplitudeContextProvider from "./contexts/AmplitudeContext";
import { routes } from "./helper/routeList";
import HomeIndex from "./pages/home";
import LoginIndex from "./pages/login";
import NotFound from "./pages/notFound";

const AuthRoute = ({ authenticated, component: Component, ...rest }) => {
  const renderComponent = (props) => {
    if (!authenticated) return <LoginIndex {...props} />;
    return <Component {...props} />;
  };

  return <Route {...rest} render={renderComponent} />;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const isAuthenticated = localStorage.getItem(server.TOKEN_KEY) ? true : false;

      setTimeout(() => {
        setAuthenticated(isAuthenticated);
        setIsLoading(false);
      }, 1000);
    };

    checkAuth();
  }, []);

  if (isLoading) return <LoadingPage />;

  return (
    <Router>
      <AmplitudeContextProvider>
        <Dashboard>
          <Switch>
            {routes.map(({ path, exact, component, permission }) => {
              if (isEmpty(permission))
                return <Route key={path} path={path} exact={exact} component={component} />;
              return (
                <AuthRoute
                  key={path}
                  path={path}
                  exact={exact}
                  authenticated={authenticated}
                  component={component}
                />
              );
            })}

            <AuthRoute path="/" exact={true} authenticated={authenticated} component={HomeIndex} />
            <Route render={() => <NotFound />} />
          </Switch>
        </Dashboard>
      </AmplitudeContextProvider>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { FormOthers, FormShipping, FormStoreInfo, FormStoreOperational } from "./forms";
import { filterFields } from "./helpers";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import MainContent from "../../components/organisms/Content/Content";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import MapModal from "../../components/molecules/MapModal";
import * as companyActions from "../../actions/company.action";

const BuyerManagementDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [values, setValues] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const { buyer } = useSelector(({ companyReducer }) => companyReducer);
  const { routerPrompt, onDirty, onPristine } = useUnsavedChangesWarning();

  useEffect(() => {
    dispatch(companyActions.buyer(id));
  }, []);

  useEffect(() => {
    if (buyer) {
      const {
        companyName = "",
        detail: {
          image = "",
          address = "",
          detailAddress = "",
          landmark = "",
          roadAccess = "",
          latitude = 0,
          longitude = 0,
          openingTime = 0,
          closingTime = 0,
          notes = "",
          storePhoto = "",
        } = {},
        companyType = {},
        companyCategory = {},
        internalRegion = {},
        province = {},
        regency = {},
        district = {},
        user = {},
        registeredBy = "",
        registeredAt = "",
      } = buyer;
      const { profile = {}, phone = "", shopLoggedIn = false, assignedTo = {} } = user ?? {};

      const newValues = { companyName, phone };
      const newData = {
        companyName,
        phone,
        image,
        firstName: profile?.firstName ?? "",
        lastName: profile?.lastName ?? "",
        companyType: { id: companyType?.id ?? "", name: companyType?.name ?? "" },
        companyCategory: { id: companyCategory?.id ?? "", name: companyCategory?.name ?? "" },
        internalRegion: { id: internalRegion?.id ?? "", name: internalRegion?.name ?? "" },
        registeredBy,
        registeredAt,
        assignedTo: {
          id: assignedTo?.id ?? "",
          name: `${assignedTo?.firstName ?? ""} ${assignedTo?.lastName ?? ""}`,
          phone: assignedTo?.phone ?? "",
          internalRegion: assignedTo?.internalRegion ?? "-",
        },
        address,
        detailAddress,
        province: { id: province?.id ?? "", name: province?.name ?? "" },
        regency: { id: regency?.id ?? "", name: regency?.name ?? "" },
        district: { id: district?.id ?? "", name: district?.name ?? "" },
        landmark,
        roadAccess,
        latitude,
        longitude,
        storePhoto,
        openingTime,
        closingTime,
        shopLoggedIn,
        notes,
      };

      setData(newData);
      setValues(newValues);
    }
  }, [buyer]);

  const handleSubmit = async (fields) => {
    const newValues = filterFields({ ...values, ...fields });
    const isSuccess = await dispatch(companyActions.updateBuyer(id, newValues));
    if (isSuccess) {
      dispatch(companyActions.buyer(id));
      return true;
    } else return false;
  };

  const headerProps = {
    buttonBack: { text: "buyerManagementDetail", status: true },
    description: "buyerManagementDetailDescription",
  };

  const mainBreadcrumbItems = [
    {
      link: "/buyer-management",
      title: "buyerManagement",
    },
    {
      title: "buyerManagementDetail",
    },
  ];

  const formProps = {
    data: data,
    onDirty: onDirty,
    onPristine: onPristine,
    handleSubmit: handleSubmit,
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="buyer-management-detail-wrapper">
        <div className="mb-10">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div className="buyer-management-detail-form">
          <FormStoreInfo {...formProps} />
        </div>

        <div className="buyer-management-detail-form mt-10">
          <FormShipping {...formProps} setModalOpen={setModalOpen} />
        </div>

        <div className="buyer-management-detail-form mt-10">
          <FormStoreOperational {...formProps} />
        </div>

        <div className="buyer-management-detail-form mt-10 mb-40">
          <FormOthers {...formProps} />
        </div>

        <div>
          {routerPrompt}

          <MapModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            latitude={data?.latitude}
            longitude={data?.longitude}
            address={data?.address}
          />
        </div>
      </div>
    </MainContent>
  );
};
export default BuyerManagementDetail;

import React, { useEffect, useState } from "react";
import { Col, Row, Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash-es";
import { EditOutlined } from "@ant-design/icons";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import * as userActions from "../../actions/user.action";

const { Text } = Typography;

const Profile = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(userActions.getUserDetails(loggedInUser?.id));
  }, []);

  useEffect(() => {
    if (!isEmpty(user.result)) {
      setData(user.result);
      setLoading(false);
    }
  }, [user]);

  return (
    <div id="my-account-profile-section">
      {loading ? (
        <SpinLoaderContent />
      ) : (
        <Row gutter={12}>
          <Col span={24} className="mb-10">
            <Text strong>{t("My Profile")}</Text>
          </Col>
          <Col span={12}>
            <Space direction="vertical" className="full-width">
              <ListItem label="First Name" value={data?.first_name} />
              <ListItem label="Last Name" value={data?.last_name} />
              <ListItem label="Username" value={data?.username} />
              <ListItem label="Email" value={data?.email} />
              <ListItem label="Position" value={data?.position} />
              <ListItem label="Phone No." value={data?.phone} />
              <ListItem label="Address" value={data?.address} />
              <ListItem label="State" value={data?.state} />
              <ListItem label="Country" value={data?.country} />
              <ListItem label="ZIP Code" value={data?.zip_code} />
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical" className="full-width">
              <ListItem label="Language" value={data?.language} />
              <ListItem label="Time Zone" value={data?.timezone} />
              <ListItem label="Currency" value={data?.currency} />
            </Space>
          </Col>
          <Col span={24}>
            <ButtonPrimary
              className="float-right"
              text="Edit Profile"
              icon={<EditOutlined />}
              handleClick={() => history.push("/my-account/profile/update/" + loggedInUser?.id)}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

const ListItem = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={6}>
        <Text>{t(label)} :</Text>
      </Col>
      <Col span={18}>
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

export default Profile;

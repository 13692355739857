import {
  FETCH_OPTION_SUCCESS,
  ORDER_CLEAR,
  ORDER_FAILED,
  ORDER_FETCHING,
  ORDER_PAYMENT,
  ORDER_PRINT_PDF,
  ORDER_SET_TAX,
  ORDER_SUCCESS,
  ORDER_UPDATE,
  ORDER_UPDATE_STATUS,
  ORDER_UPDATE_STATUS_CANCEL,
  ORDER_UPDATE_STATUS_CLEAR,
  ORDER_DETAIL_FETCHING,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAILED,
  ORDER_LIST_FETCHING,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAILED,
  UPDATE_ORDER_FETCHING,
  UPDATE_ORDER_FAILED,
  ORDER_DETAILS_FETCHING,
  ORDER_DETAILS_FAILED,
  ORDER_DETAILS_SUCCESS,
  DOWNLOAD_ORDER_INVOICE_FETCHING,
  DOWNLOAD_ORDER_INVOICE_FAILED,
  ORDER_REASONS_FETCHING,
  ORDER_REASONS_SUCCESS,
  ORDER_REASONS_FAILED,
  ORDER_UPDATE_STATUS_FAILED,
  ORDER_UPDATE_STATUS_FETCHING,
  ORDER_UPDATE_STATUS_BULK_FAILED,
  ORDER_UPDATE_STATUS_BULK_FETCHING,
  ORDER_CREATE_FAILED,
  ORDER_CREATE_FETCHING,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  data: null,
  printData: null,
  tax: 0,
  paymentData: null,
  orderMeta: null,
  // tagetOrderOId: null,
  // totalValue: 0,
  selectedOrders: null,
  newStatus: "",
  isUpdateStatusMultiple: false,
  updateStatusOpen: false,
  updateStatusCancel: false,
  // updatingStatus: false,
  isDetailFetching: false,
  isDetailError: false,
  detail: null,
  isOrderListFetching: false,
  isOrderListError: false,
  orderList: null,
  isUpdateOrdertFetching: false,
  isUpdateOrdertError: false,
  isOrderListDetailsFetching: false,
  isOrderListDetailsError: false,
  orderDetials: null,
  isDownloadOrdertInvoiceFetching: false,
  isDownloadOrdertInvoiceError: false,
  reasons: null,
  isReasonsFetching: false,
  isReasonsError: false,
  isUpdateStatusFetching: false,
  isUpdateStatusError: false,
  isUpdateStatusBulkFetching: false,
  isUpdateStatusBulkError: false,
  isCreateFetching: false,
  isCreateError: false,
};

export default (state = initialState, { type, payload, orderMeta }) => {
  switch (type) {
    case ORDER_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case ORDER_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case ORDER_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload, orderMeta };
    case ORDER_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case FETCH_OPTION_SUCCESS:
      return { ...state, isFetching: false, isError: false, options: payload };
    case ORDER_UPDATE:
      return { ...state, isFetching: false, isError: false, data: payload };
    case ORDER_UPDATE_STATUS:
      return {
        ...state,
        selectedOrders: payload.selectedOrders,
        newStatus: payload.newStatus,
        updateStatusOpen: payload.updateStatusOpen,
        updateStatusCancel: false,
      };
    case ORDER_UPDATE_STATUS_CANCEL:
      return {
        ...state,
        selectedOrders: null,
        newStatus: "",
        updateStatusOpen: false,
        updateStatusCancel: true,
      };
    case ORDER_UPDATE_STATUS_CLEAR:
      return {
        ...state,
        selectedOrders: null,
        newStatus: "",
        updateStatusOpen: false,
        updateStatusCancel: false,
      };
    case ORDER_SET_TAX:
      return { ...state, tax: payload };
    case ORDER_PRINT_PDF:
      return { ...state, printData: payload };
    case ORDER_PAYMENT:
      return { ...state, isFetching: false, isError: false, paymentData: payload };
    case ORDER_DETAIL_FETCHING:
      return { ...state, isDetailFetching: true, isDetailError: false, detail: null };
    case ORDER_DETAIL_FAILED:
      return { ...state, isDetailFetching: false, isDetailError: true, detail: null };
    case ORDER_DETAIL_SUCCESS:
      return { ...state, isDetailFetching: false, isDetailError: false, detail: payload };
    case ORDER_LIST_FETCHING:
      return { ...state, isOrderListFetching: true, isOrderListError: false, orderList: null };
    case ORDER_LIST_FAILED:
      return { ...state, isOrderListFetching: false, isOrderListError: true, orderList: null };
    case ORDER_LIST_SUCCESS:
      return { ...state, isOrderListFetching: false, isOrderListError: false, orderList: payload };
    case UPDATE_ORDER_FETCHING:
      return { ...state, isUpdateOrderFetching: true, isUpdateOrderError: false };
    case UPDATE_ORDER_FAILED:
      return { ...state, isUpdateOrderFetching: false, isUpdateOrderError: true };
    case ORDER_DETAILS_FETCHING:
      return {
        ...state,
        isOrderDetailsFetching: true,
        isOrderDetailsError: false,
        orderDetails: null,
      };
    case ORDER_DETAILS_FAILED:
      return {
        ...state,
        isOrderDetailsFetching: false,
        isOrderDetailsError: true,
        orderDetails: null,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isOrderDetailsFetching: false,
        isOrderDetailsError: false,
        orderDetails: payload,
      };
    case DOWNLOAD_ORDER_INVOICE_FETCHING:
      return { ...state, isDownloadOrderInvoiceFetching: true, isDownloadOrderInvoiceError: false };
    case DOWNLOAD_ORDER_INVOICE_FAILED:
      return { ...state, isDownloadOrderInvoiceFetching: false, isDownloadOrderInvoiceError: true };
    case ORDER_REASONS_FETCHING:
      return { ...state, isReasonsFetching: true, isReasonsError: false, reasons: null };
    case ORDER_REASONS_FAILED:
      return { ...state, isReasonsFetching: false, isReasonsError: true, reasons: null };
    case ORDER_REASONS_SUCCESS:
      return {
        ...state,
        isReasonsFetching: false,
        isReasonsError: false,
        reasons: payload,
      };
    case ORDER_UPDATE_STATUS_FETCHING:
      return { ...state, isUpdateStatusFetching: true, isUpdateStatusError: false };
    case ORDER_UPDATE_STATUS_FAILED:
      return { ...state, isUpdateStatusFetching: false, isUpdateStatusError: true };
    case ORDER_UPDATE_STATUS_BULK_FETCHING:
      return { ...state, isUpdateStatusBulkFetching: true, isUpdateStatusBulkError: false };
    case ORDER_UPDATE_STATUS_BULK_FAILED:
      return { ...state, isUpdateStatusBulkFetching: false, isUpdateStatusBulkError: true };
    case ORDER_CREATE_FETCHING:
      return { ...state, isCreateFetching: true, isCreateError: false };
    case ORDER_CREATE_FAILED:
      return { ...state, isCreateFetching: false, isCreateError: true };
    default:
      return state;
  }
};

import React from "react";
import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import "./FormItemNumber.scss";

const FormItemNumber = ({ label, name, rules, disabled = false, handleChange = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Form.Item label={t(label)} name={name} className="form-item-number" rules={rules}>
      <InputNumber
        placeholder={t(label)}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default FormItemNumber;

export const columns = [
  {
    title: "merchantName",
    dataIndex: "merchantName",
    key: "merchantName",
    fixed: "left",
    width: 200,
  },
  {
    title: "ownerName",
    dataIndex: "ownerName",
    key: "ownerName",
    width: 200,
  },
  {
    title: "phone",
    dataIndex: "phoneNo",
    key: "phoneNo",
    width: 180,
  },
  {
    title: "partnershipType",
    dataIndex: "partnershipType",
    key: "partnershipType",
    width: 150,
  },
  {
    title: "merchantID",
    dataIndex: "merchantID",
    key: "merchantID",
    width: 150,
  },
  {
    title: "region",
    dataIndex: "region",
    key: "region",
  },
  {
    title: "district",
    dataIndex: "district",
    key: "district",
    width: 150,
  },
  {
    title: "subdistrict",
    dataIndex: "subdistrict",
    key: "subdistrict",
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
    width: 100,
  },
];

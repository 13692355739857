import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Divider, List, Row, Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ClockCircleOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import { formatRupiah, toCheckLoginToken, toSentenceCase } from "../../utils/Functions";
import { Redirect, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash-es";
import { OrderStatus, server } from "../../constants";
import dayjs from "dayjs";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import TagCustom from "../../components/atoms/TagCustom/TagCustom";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import CartEmpty from "../../components/atoms/CartEmpty/CartEmpty";
import PaymentDetailListItem from "../../components/molecules/PaymentDetailListItem/PaymentDetailListItem";
import PaymentSuccessModal from "../../components/molecules/PaymentSuccessModal/PaymentSuccessModal";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import ProductCartConfirmationModal from "../../components/molecules/ProductCartConfirmationModal/ProductCartConfirmationModal";
import PaymentDetailModal from "../../components/molecules/PaymentDetailModal/PaymentDetailModal";
import * as orderActions from "../../actions/order.action";
import * as receiptActions from "../../actions/receipt.action";
import "./_detail.scss";

const { Text } = Typography;

const OrderDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedProductDelete, setSelectedProductDelete] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [addOrderModalOpen, setAddOrderModalOpen] = useState(false);
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState(false);
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
  const [modalOpenPayment, setModalOpenPayment] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPrintReceiptLoading, setIsPrintReceiptLoading] = useState(false);
  const [isDeleteProductCartLoading, setIsDeleteProductCartLoading] = useState(false);
  const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  const [orderDetail, setOrderDetail] = useState({});
  const [productList, setProductList] = useState([]);
  const [payment, setPayment] = useState({});
  const [customer, setCustomer] = useState({});
  const [receiptDetail, setReceiptDetail] = useState({});

  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);

  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderReducer);
  const receipt = useSelector((state) => state.receiptReducer);
  const modeTablet = tokenParams ? true : false;
  const params = { withPrices: "base" };

  useEffect(() => {
    dispatch(orderActions.getOrderDetail(id, params));
    dispatch(receiptActions.getUserReceiptSettings());
  }, []);

  useEffect(() => {
    if (order?.detail) {
      const { detail } = order;
      setOrderDetail(detail?.order);
      setProductList(detail?.orderDetail);
      setPayment(detail?.payment);
      setCustomer(detail?.customer);
      setLoading(false);
      setPaymentStatus(detail?.order?.payment_status.toLowerCase());

      if (isEmpty(detail?.customer)) {
        setCustomer({
          name: `Pelanggan - ${order?.detail?.companyName}`,
          address: "-",
          noPhone: "-",
        });
      }
    }
  }, [order]);

  useEffect(() => {
    setReceiptDetail(receipt.data);
  }, [receipt]);

  const setLabelPaymentStatus = (status = "waiting") => {
    const key = status.toLocaleLowerCase();
    let tagConfig;

    switch (key) {
      case "paid":
        tagConfig = { text: "paymentSuccess", type: "success" };
        break;
      case "canceled":
      case "failed":
        tagConfig = { text: "orderCanceled", type: "danger" };
        break;
      default:
        tagConfig = { text: "waitingPayment", type: "warning" };
    }
    return <TagCustom className="strong" text={tagConfig.text} type={tagConfig.type} />;
  };

  const handlePrintStruck = async () => {
    setIsPrintReceiptLoading(true);
    const response = await dispatch(orderActions.getOrderInvoice(orderDetail?.order_id, params));
    if (response.isSuccess) {
      window.open(process.env.REACT_APP_API_URL + response.url);
      setIsPrintReceiptLoading(false);
    } else setIsPrintReceiptLoading(false);
  };

  const handleDeleteProductCart = async () => {
    const result = productList
      .filter((product) => product?._id !== selectedProductDelete?.product_id)
      .map((product) => ({ productId: product?._id, qty: product?.qty }));

    const dataToSend = {
      details: JSON.stringify(result),
    };
    setIsDeleteProductCartLoading(true);

    const isSuccess = await dispatch(orderActions.updateOrder(orderDetail?.order_id, dataToSend));
    if (isSuccess) {
      setIsDeleteProductCartLoading(false);
      setDeleteOrderModalOpen(false);
      dispatch(orderActions.getOrderDetail(id, params));
    } else setIsDeleteProductCartLoading(false);
  };

  const handleCancelOrder = async () => {
    setIsCancelOrderLoading(true);
    const isSuccess = await dispatch(orderActions.Cancel(orderDetail?.order_id));
    if (isSuccess) {
      setCancelOrderModalOpen(false);
      setIsCancelOrderLoading(false);
      dispatch(orderActions.getOrderDetail(id, params));
    } else setIsCancelOrderLoading(false);
  };

  const handlePayment = async (values) => {
    setIsPaymentLoading(true);
    const isSuccess = await dispatch(orderActions.confirmOrder(orderDetail?.order_id, values));
    if (isSuccess) {
      setIsPaymentLoading(false);
      setModalOpenPayment(false);
      dispatch(orderActions.getOrderDetail(id, params));
    } else setIsPaymentLoading(false);
  };

  const paymentDetailModalProps = {
    modalOpenPayment,
    setModalOpenPayment,
    cartData: productList,
    totalPrice: orderDetail?.total,
    discount,
    isPaymentLoading,
    handlePayment,
  };

  const paymentSuccessModalProps = {
    modalOpen,
    setModalOpen,
    data: order?.detail,
    customer,
    orderDetail,
    productList,
    receiptDetail,
    paymentStatus,
    setLabelPaymentStatus,
    isPrintReceiptLoading,
    handlePrintStruck,
  };

  const headerProps = {
    buttonBack: { text: "Order Detail", status: true },
    description: "Order List",
  };

  const mainBreadcrumbItems = [
    {
      link: "/order-list",
      title: "Order List",
    },
    {
      title: "Order Detail",
    },
  ];

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="order-detail-wrapper">
        {!modeTablet && (
          <div id="order-detail-breadcrumb">
            <MainBreadcrumb items={mainBreadcrumbItems} />
          </div>
        )}

        <div id="order-detail-content">
          <Row gutter={12}>
            <Col span={14}>
              <div id="order-detail-user-information">
                {loading ? (
                  <SpinLoaderContent />
                ) : (
                  <>
                    <ListItem label="Order ID" value={orderDetail?.order_id} />
                    <ListItem
                      label="Date"
                      value={dayjs(orderDetail?.timestamp).format("DD MMM YYYY")}
                    />
                    <ListItem label="Merchant Name" value={order?.detail?.companyName} />
                    <ListItem
                      label="paymentMethod"
                      value={toSentenceCase(orderDetail?.payment_type || "")}
                    />
                    <ListItem label="Payment Status">
                      {setLabelPaymentStatus(orderDetail?.payment_status.toLowerCase())}
                      <ClockCircleOutlined className="ml-10" />
                      <span className="ml-10">
                        {dayjs(payment?.timestamp).format("DD MMMM YYYY") +
                          " | " +
                          dayjs(payment?.timestamp).format("HH:mm")}
                      </span>
                    </ListItem>
                  </>
                )}
              </div>
              <div id="order-detail-order" className="mt-10">
                <Row>
                  <Col span={24}>
                    <Space direction="horizontal">
                      <Text strong>{t("Order")}</Text>
                      <div className="number-order">
                        <Text strong>{productList.length}</Text>
                      </div>
                    </Space>
                    {paymentStatus === OrderStatus.WAITING && (
                      <Button
                        icon={<PlusOutlined />}
                        className={"add-order-link float-right"}
                        type="link"
                        onClick={() => setAddOrderModalOpen(true)}
                      >
                        {t("setOrder")}
                      </Button>
                    )}
                  </Col>
                  <Col span={24}>
                    <div id="order-detail-order-content">
                      {loading ? (
                        <SpinLoaderContent />
                      ) : !isEmpty(productList) ? (
                        <List
                          id="shopping-cart-order-list"
                          itemLayout="horizontal"
                          dataSource={productList}
                          renderItem={(item) => {
                            return (
                              <PaymentDetailListItem
                                key={item._id}
                                data={item}
                                setSelectedProductDelete={setSelectedProductDelete}
                                setModalOpen={setDeleteOrderModalOpen}
                                isDeleteDisabled={paymentStatus !== OrderStatus.WAITING}
                              />
                            );
                          }}
                        />
                      ) : (
                        <CartEmpty />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={10}>
              <div id="order-detail-due-date">
                <ListItem label="Customer Name" value={customer?.name} />
                <ListItem label="Address" value={customer?.address} />
                <ListItem label="Phone No." value={customer?.noPhone} />
              </div>

              <div id="order-detail-summary" className="mt-20">
                <Text strong>{t("Ringkasan")}</Text>
                <Row gutter={12}>
                  <Col span={12}>
                    <Text>{t("totalProductPrice", { amount: productList.length })}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(orderDetail?.total)}</Text>
                  </Col>
                  <Col span={12}>
                    <Text>{t("Total discounts")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(orderDetail?.discount)}</Text>
                  </Col>
                  <Col span={24}>
                    <Divider className="order-detail-divider" />
                  </Col>
                  <Col span={12} className="flex-center-align">
                    <Text strong>{t("Total Payment")}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong className="order-detail-title float-right">
                      {formatRupiah(orderDetail?.total)}
                    </Text>
                  </Col>
                </Row>
              </div>

              <Row gutter={12}>
                <Col span={24} className="mt-20 mb-10">
                  <ButtonPrimary
                    className={paymentStatus !== OrderStatus.WAITING && "button-disabled"}
                    disabled={paymentStatus !== OrderStatus.WAITING}
                    block={true}
                    text="pay"
                    handleClick={() => setModalOpenPayment(true)}
                  />
                </Col>
                <Col span={20}>
                  <ButtonDefault
                    block={true}
                    text="viewReceipt"
                    handleClick={() => setModalOpen(true)}
                  />
                </Col>
                <Col span={4}>
                  <ButtonPrimary
                    block={true}
                    icon={<PrinterOutlined />}
                    handleClick={handlePrintStruck}
                    loading={isPrintReceiptLoading}
                  />
                </Col>
                <Col span={24} className="mt-10">
                  <ButtonDefault
                    className={paymentStatus !== OrderStatus.WAITING && "button-disabled"}
                    disabled={paymentStatus !== OrderStatus.WAITING}
                    block={true}
                    text="Cancel Order"
                    handleClick={() => setCancelOrderModalOpen(true)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <PaymentDetailModal {...paymentDetailModalProps} />
          <PaymentSuccessModal {...paymentSuccessModalProps} />
          <ProductCartConfirmationModal
            title="setOrderAsk"
            message="serOrderMessage"
            modalOpen={addOrderModalOpen}
            handleYes={() => history.push(`/edit-order/${id}`)}
            handleNo={() => setAddOrderModalOpen(false)}
          />
          <ProductCartConfirmationModal
            title="deleteProduct"
            message="deleteProductCartMessage"
            modalOpen={deleteOrderModalOpen}
            isLoadingYes={isDeleteProductCartLoading}
            handleYes={handleDeleteProductCart}
            handleNo={() => setDeleteOrderModalOpen(false)}
          />
          <ProductCartConfirmationModal
            title="cancelOrder"
            message="cancelOrderMessage"
            modalOpen={cancelOrderModalOpen}
            isLoadingYes={isCancelOrderLoading}
            handleYes={handleCancelOrder}
            handleNo={() => setCancelOrderModalOpen(false)}
          />
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

const ListItem = ({ label, value, children }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-10">
      <Col span={7}>
        <Text strong>{t(label)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={16}>{value ? <Text>{value}</Text> : children}</Col>
    </Row>
  );
};

export default OrderDetail;

import {
  INVENTORY_FETCHING,
  INVENTORY_SUCCESS,
  INVENTORY_FAILED,
  INVENTORY_CLEAR,
  INVENTORY_APPENDED,
  INVENTORY_BULK_UPDATE_FETCHING,
  INVENTORY_BULK_UPDATE_FAILED,
  CREATE_INVENTORY_FETCHING,
  CREATE_INVENTORY_FAILED,
  UPDATE_INVENTORY_FETCHING,
  UPDATE_INVENTORY_FAILED,
  CREATE_BULK_INVENTORY_FETCHING,
  CREATE_BULK_INVENTORY_FAILED,
  EXPORT_INVENTORY_TO_CSV_FETCHING,
  EXPORT_INVENTORY_TO_CSV_FAILED,
  ExportSkuCsvHeader,
} from "../constants";
import { convertArrayToCSV, downloadCSV, parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { ErrorMessage, SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { isEmpty } from "lodash-es";

export const setInventoryStateToFetching = () => ({
  type: INVENTORY_FETCHING,
});

export const setInventoryStateToFailed = () => ({
  type: INVENTORY_FAILED,
});

export const setInventoryStateToClear = () => ({
  type: INVENTORY_CLEAR,
});

export const setInventoryStateToSuccess = (payload) => ({
  type: INVENTORY_SUCCESS,
  payload,
});

export const setInventoryBulkUpdateStateToFetching = () => ({
  type: INVENTORY_BULK_UPDATE_FETCHING,
});

export const setInventoryBulkUpdateStateToFailed = () => ({
  type: INVENTORY_BULK_UPDATE_FAILED,
});

export const setCreateInventoryStateToFetching = () => ({
  type: CREATE_INVENTORY_FETCHING,
});

export const setCreateInventoryStateToFailed = () => ({
  type: CREATE_INVENTORY_FAILED,
});

export const setUpdateInventoryStateToFetching = () => ({
  type: UPDATE_INVENTORY_FETCHING,
});

export const setUpdateInventoryStateToFailed = () => ({
  type: UPDATE_INVENTORY_FAILED,
});

export const setCreateBulkInventoryStateToFetching = () => ({
  type: CREATE_BULK_INVENTORY_FETCHING,
});

export const setCreateBulkInventoryStateToFailed = () => ({
  type: CREATE_BULK_INVENTORY_FAILED,
});

export const setExportInventoryToCsvStateToFetching = () => ({
  type: EXPORT_INVENTORY_TO_CSV_FETCHING,
});

export const setExportInventoryToCsvStateToFailed = () => ({
  type: EXPORT_INVENTORY_TO_CSV_FAILED,
});

export const appendInventory = (payload) => ({
  type: INVENTORY_APPENDED,
  payload,
});

export const index = (params = null) => {
  return async (dispatch) => {
    dispatch(setInventoryStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setInventoryStateToSuccess({ data: data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setInventoryStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const loadMore = (params = null) => {
  return async (dispatch) => {
    dispatch(setInventoryStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory${paramsString}`,
      );

      const { data } = response.data;
      dispatch(appendInventory(data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setInventoryStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const updateBulk = (values) => {
  return async (dispatch) => {
    dispatch(setInventoryBulkUpdateStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory/bulk`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setInventoryBulkUpdateStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const create = (values) => {
  return async (dispatch) => {
    dispatch(setCreateInventoryStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCreateInventoryStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const createBulk = (values) => {
  return async (dispatch) => {
    dispatch(setCreateBulkInventoryStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory/bulk`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCreateBulkInventoryStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const update = (id, values) => {
  return async (dispatch) => {
    dispatch(setUpdateInventoryStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory/${id}`,
        values,
      );
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setUpdateInventoryStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const changeStatus = (id) => {
  return async (dispatch) => {
    dispatch(setUpdateInventoryStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory/change-status/${id}`,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setUpdateInventoryStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const exportInventoryToCsv = (params = null) => {
  return async (dispatch) => {
    dispatch(setExportInventoryToCsvStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `inventory${paramsString}`,
      );
      const { data } = response?.data;
      const csvFormat = [...ExportSkuCsvHeader];

      if (!isEmpty(data)) {
        data.forEach(({ sku, stock, basePrice, priceTier }) => {
          priceTier.sort((a, b) => a.tierNo - b.tierNo);
          const rowPriceTier = [];
          if (!isEmpty(priceTier)) {
            priceTier.forEach(({ min, max, margin }) => {
              rowPriceTier.push(min, max, margin);
            });
          }
          const rowSku = [sku, stock ?? 0, basePrice ?? 0, ...rowPriceTier];
          csvFormat.push(rowSku);
        });
      }

      const csvContent = convertArrayToCSV(csvFormat);
      downloadCSV(csvContent, "export-sku-data.csv");
      SuccessMessage(response);
    } catch (error) {
      dispatch(setExportInventoryToCsvStateToFailed());
      ErrorMessage(error);
    }
  };
};

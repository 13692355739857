import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./FormItemNumberPayment.scss";

/**
 * @param {{
 *  label?: string,
 *  rules?: import("antd/es/form").Rule[],
 *  name?: string,
 *  handleChange?(value:number|string|null): void,
 *  disable?: boolean,
 *  className?: string,
 * }} props
 */
const FormItemNumberPayment = ({
  label, name, rules, value,
  disabled = false,
  handleChange = () => {}, className = ''
}) => {
  const { t } = useTranslation();

  const formatter = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    const number = typeof value === "string" ? parseFloat(value.replace(/\D/g, "")) : value;
    if (isNaN(number)) {
      return "";
    }
    return number.toLocaleString("id-ID");
  };

  const parser = (value) => {
    return value.replace(/\D/g, "");
  };

  return (
    <Form.Item label={t(label)} name={name} className='form-item-number-payment' rules={rules}>
      <InputNumber
        prefix='Rp'
        placeholder={t(label)}
        style={{ width: "100%" }}
        formatter={formatter}
        parser={parser}
        disabled={disabled}
        onChange={handleChange}
        className={className}
      />
    </Form.Item>
  );
};

export default FormItemNumberPayment;

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { ConfigProvider } from "antd";
import reducers from "./reducers";
import logger from "redux-logger";
import thunk from "redux-thunk";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/reset.css";

var middlewares = null;

if (process.env.REACT_APP_IS_PRODUCTION === "1") {
  middlewares = applyMiddleware(thunk);
} else {
  middlewares = applyMiddleware(thunk, logger);
}

const store = createStore(reducers, composeWithDevTools(middlewares));
const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ConfigProvider theme={{ token: { colorPrimary: "#E89B4C" } }}>
      <App />
    </ConfigProvider>
  </Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

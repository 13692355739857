import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Select, Tabs, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { handleValidationDocument, toCheckLoginToken } from "../../utils/Functions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { UploadOutlined } from "@ant-design/icons";
import { server } from "../../constants";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import FormItemPhoneNumber from "../../components/atoms/FormItemPhoneNumber";
import FormItemAddress from "../../components/atoms/FormItemAddress";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import * as customerActions from "../../actions/customer.action";
import "./_style.scss";

const CreateCustomer = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const openTabs = searchParams.get("openTabs");
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);
  const modeTablet = tokenParams ? true : false;
  const [activeTabKey, setActiveTabKey] = useState(openTabs ? openTabs : "add-customer");
  const history = useHistory();

  const headerProps = {
    buttonBack: { text: "Create Customer", status: true },
    description: "Manage your customer",
  };

  const mainBreadcrumbItems = [
    {
      link: "/customer",
      title: "Customer",
    },
    {
      title: "Create Customer",
    },
  ];

  const [items] = useState([
    {
      key: "add-customer",
      label: t("Add Customer"),
      children: <AddCustomer modeTablet={modeTablet} />,
    },
    {
      key: "import-customer",
      label: t("Import Customer"),
      children: <ImportCustomer />,
    },
  ]);

  const handleChangeTabs = (value) => {
    searchParams.set("openTabs", value);
    history.replace({ search: searchParams.toString() });
    setActiveTabKey(value);
  };

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="create-customer-wrapper">
        <div id="create-customer-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id="create-customer-content">
          <Tabs
            defaultActiveKey={activeTabKey}
            activeKey={activeTabKey}
            items={items}
            onChange={handleChangeTabs}
          />
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

export const AddCustomer = () => {
  const { t } = useTranslation();
  const [customerTypeOptions, setCustomerTypeOptions] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const { customerOfflineType } = useSelector((state) => state.customerReducer);

  useEffect(() => {
    dispatch(customerActions.customerOfflineType());
    form.setFieldValue("code_phone", "+62");
  }, []);

  useEffect(() => {
    if (customerOfflineType) {
      const result = customerOfflineType.map((customer) => ({
        value: customer.id,
        label: customer.name,
      }));
      setCustomerTypeOptions(result);
    }
  }, [customerOfflineType]);

  const handleSubmit = async () => {
    const { name, email, code_phone, phone, customer_type, address } = form.getFieldsValue();

    const values = {
      firstName: name,
      phone: code_phone + phone,
      customerTypeId: customer_type ?? "",
      email: email ?? "",
      address: address ?? "",
    };

    setIsSubmitLoading(true);
    const response = await dispatch(customerActions.createCustomerOffline(values));
    if (response.isSuccess) {
      setIsSubmitLoading(false);
      history.push("/customer");
    } else setIsSubmitLoading(false);
  };

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      layout="horizontal"
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        label={t("Customer Name")}
        name="name"
        rules={[{ required: true, message: t("Please input your customer name!") }]}
      >
        <Input size="large" />
      </Form.Item>

      <FormItemPhoneNumber
        name="phone"
        label="Customer Phone"
        size="large"
        rules={[{ required: true, message: t("Please input your customer phone!") }]}
      />

      <Form.Item
        label={t("Customer Email")}
        name="email"
        // rules={[{ required: true, message: t("Please input your customer email!") }]}
      >
        <Input size="large" />
      </Form.Item>

      <Form.Item
        label={t("Customer Type")}
        name="customer_type"
        // rules={[{ required: true, message: t("Please select your customer type!") }]}
      >
        <Select options={customerTypeOptions} size="large" />
      </Form.Item>

      <FormItemAddress
        name="address"
        label="Customer Address"
        size="large"
        // handleSelect={setAddressDetails}
        // rules={[{ required: true, message: t("Please input your customer address!") }]}
      />

      <Form.Item wrapperCol={{ span: 24 }}>
        <ButtonPrimary
          text="Add"
          className="float-right"
          htmlType="submit"
          loading={isSubmitLoading}
        />
      </Form.Item>
    </Form>
  );
};

export const ImportCustomer = () => {
  const { t } = useTranslation();
  const [customerFile, setCustomerFile] = useState(0);

  const handleImportFile = () => {
    let formdata = new FormData();
    formdata.append("fileImport", customerFile);
    console.log("file customer: ", customerFile);
  };

  return (
    <Form onFinish={handleImportFile}>
      <Form.Item
        name="import-customer"
        extra="Import Customer (Excel/ .xlsx, Max. 2 MB)"
        rules={[{ required: true, message: t("Please select your customer file!") }]}
      >
        <Upload
          name="fileCustomer"
          listType="picture"
          maxCount={1}
          beforeUpload={(file) => {
            const validate = handleValidationDocument(file, 2, "xlsx");
            if (validate) {
              setCustomerFile(file);
              return false;
            } else {
              return Upload.LIST_IGNORE;
            }
          }}
        >
          <Button icon={<UploadOutlined />}>{t("Click to upload")}</Button>
        </Upload>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 6 }}>
        <ButtonPrimary text="Add" htmlType="submit" />
      </Form.Item>
    </Form>
  );
};

export default CreateCustomer;

import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Typography, Divider, List, Space, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import { formatRupiah, toCheckLoginToken } from "../../utils/Functions";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { server } from "../../constants";
import Cookies from "js-cookie";
import MainContent from "../../components/organisms/Content/Content";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import FormItemSelectCustomer from "../../components/atoms/FormItemSelectCustomer/FormItemSelectCustomer";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import ShoppingCartListItem from "../../components/molecules/ShoppingCartListItem/ShoppingCartListItem";
import CartEmpty from "../../components/atoms/CartEmpty/CartEmpty";
import DeleteProductCartModal from "../../components/molecules/DeleteProductCartModal/DeleteProductCartModal";
import PaymentDetailModal from "../../components/molecules/PaymentDetailModal/PaymentDetailModal";
import ProductCartConfirmationModal from "../../components/molecules/ProductCartConfirmationModal/ProductCartConfirmationModal";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import * as customerActions from "../../actions/customer.action";
import * as shopActions from "../../actions/shop.action";
import "./_style.scss";

const { Text } = Typography;

const ShoppingCart = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [data, setData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [form] = Form.useForm();
  const [modalOpenPayment, setModalOpenPayment] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [selectedProductDelete, setSelectedProductDelete] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [loadingCart, setLoadingCart] = useState(true);
  const [unavailableProducts, setUnavailableProducts] = useState({});
  const [isMakeOrderButtonDisabled, setIsMakeOrderButtonDisabled] = useState(true);
  const [isCancelDeleteProduct, setIsCancelDeleteProduct] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const customerID = localStorage.getItem(server.CUSTOMER_ID);
  const source = loggedInUser?.id;

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);
  const modeTablet = tokenParams ? true : false;

  const customer = useSelector((state) => state.customerReducer);
  const shop = useSelector((state) => state.shopReducer);

  useEffect(() => {
    dispatch(customerActions.Index());
    dispatch(shopActions.getCart(source));
  }, []);

  useEffect(() => {
    if (shop.data) {
      const { data } = shop;
      let _totalPrice = 0;
      let _totalProduts = 0;
      data.map((product) => {
        _totalPrice += product.price * product.qty;
        _totalProduts += product.qty;
      });
      setTotalPrice(_totalPrice);
      setTotalProducts(_totalProduts);
      setCartData(data);
      setLoadingCart(false);
    }
  }, [shop]);

  useEffect(() => {
    if (customer.result) {
      const { result } = customer;
      const _customerOptions = result.map((customer) => ({
        value: customer._id,
        label: customer.name,
        noPhone: customer.noPhone,
      }));
      setCustomerOptions(_customerOptions);
      setData(result);
    }
  }, [customer]);

  useEffect(() => {
    if (data.length) {
      form.setFieldValue("customer", customerID);
      handleChooseCustomer(customerID);
    }
  }, [customerID, data]);

  useEffect(() => {
    if (isEmpty(cartData) || !isEmpty(unavailableProducts)) setIsMakeOrderButtonDisabled(true);
    else setIsMakeOrderButtonDisabled(false);
  }, [cartData, unavailableProducts]);

  const handleDeleteProductCart = () => {
    setModalOpen(false);
    setNotificationModalOpen(false);

    const { staff_id, product_id } = selectedProductDelete;
    let dataToSend = {
      staff_id: staff_id,
      source: source,
      product_id: product_id,
    };
    dispatch(shopActions.deleteFromCart(dataToSend, source));
  };

  const handleCancelDeleteProduct = () => {
    setIsCancelDeleteProduct(true);
    setNotificationModalOpen(false);
  };

  const handleChooseCustomer = (value) => {
    if (value) {
      let _selectedCustomer = data.filter((customer) => customer._id === value);
      _selectedCustomer = !isEmpty(_selectedCustomer) ? _selectedCustomer[0] : {};
      setSelectedCustomer(_selectedCustomer);
    } else {
      setSelectedCustomer({});
    }
  };

  const handlePayment = (value) => {
    const { paid } = value;

    let dataToSend = {
      customerId: selectedCustomer._id,
      companyId: loggedInUser?.companyId,
      paidAmount: paid,
    };
    setModalOpenPayment(false);
    const path = modeTablet ? `/shopping-cart?token=${tokenParams}` : "/shopping-cart";
    history.push(path);

    dispatch(
      shopActions.createOrderPOS(dataToSend, loggedInUser?.id, history, modeTablet, tokenParams),
    );
  };

  const paymentDetailModalProps = {
    modalOpenPayment,
    setModalOpenPayment,
    cartData,
    totalPrice,
    discount,
    handlePayment,
  };

  const headerProps = {
    buttonBack: { text: "Shopping Cart", status: true },
    description: "POS",
  };

  const mainBreadcrumbItems = [
    {
      link: "/pos",
      title: "Create Order",
    },
    {
      link: "/choose-product",
      title: "Choose Product",
    },
    {
      title: "Shopping Cart",
    },
  ];

  const handleOpenModal = () => {
    setModalOpenPayment(true);
  };

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="shopping-cart-wrapper">
        {!modeTablet && (
          <div className="mb-10">
            <MainBreadcrumb items={mainBreadcrumbItems} />
          </div>
        )}

        <div id="shopping-cart-content">
          <Row gutter={12}>
            <Col span={16}>
              <div id="shopping-cart-select-customer">
                <Row>
                  <Col span={24}>
                    <Text strong>{t("Customer")}</Text>
                  </Col>
                  <Col span={24}>
                    <Form form={form}>
                      <Row gutter={24}>
                        <Col span={24}>
                          <div>
                            <FormItemSelectCustomer
                              name="customer"
                              placeholder={t("Choose a customer name")}
                              rules={[
                                { required: true, message: t("Please choose your customer name!") },
                              ]}
                              handleChange={(value) => {
                                Cookies.set(server.CUSTOMER_ID, value);
                                handleChooseCustomer(value);
                              }}
                              options={customerOptions}
                            />
                          </div>
                          <div>
                            {Object.keys(selectedCustomer).length > 0 && (
                              <>
                                <Text strong>{t("Details")}</Text>
                                <div className="selected-customer-detail">
                                  <Row>
                                    <Col span={3}>
                                      <Text>{t("Phone No.")}</Text>
                                    </Col>
                                    <Col span={21}>
                                      <span>:</span>{" "}
                                      <Text className="ml-1">{selectedCustomer.noPhone}</Text>
                                    </Col>
                                    <Col span={3}>
                                      <Text>{t("Address")} </Text>
                                    </Col>
                                    <Col span={21}>
                                      <span>:</span>{" "}
                                      <Text className="ml-1">{selectedCustomer.address}</Text>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>

              <div id="shopping-cart-order" className="mt-10">
                <div>
                  <Text strong>{t("Order")}</Text>
                  <Button
                    icon={<PlusOutlined />}
                    className="add-order-link float-right"
                    type="link"
                    onClick={() => setOrderModalOpen(true)}
                  >
                    {t("Add Order")}
                  </Button>
                </div>

                <div id="shopping-cart-order-content" className="mt-10">
                  {loadingCart ? (
                    <SpinLoaderContent />
                  ) : !isEmpty(cartData) ? (
                    <List
                      id="shopping-cart-order-list"
                      itemLayout="horizontal"
                      dataSource={cartData}
                      renderItem={(item) => {
                        return (
                          <ShoppingCartListItem
                            key={item._id}
                            data={item}
                            source={source}
                            setModalOpen={setModalOpen}
                            setNotificationModalOpen={setNotificationModalOpen}
                            selectedProductDelete={selectedProductDelete}
                            setSelectedProductDelete={setSelectedProductDelete}
                            unavailableProducts={unavailableProducts}
                            setUnavailableProducts={setUnavailableProducts}
                            isCancelDeleteProduct={isCancelDeleteProduct}
                            setIsCancelDeleteProduct={setIsCancelDeleteProduct}
                          />
                        );
                      }}
                    />
                  ) : (
                    <CartEmpty />
                  )}

                  <DeleteProductCartModal
                    title="Delete product"
                    message="Are you sure you want to remove this product from the shopping list?"
                    modalOpen={modalOpen}
                    handleDeleteProductCart={handleDeleteProductCart}
                    handleCancel={() => setModalOpen(false)}
                  />
                  <DeleteProductCartModal
                    title="Are you sure?"
                    message="The product will be automatically removed from your shopping cart if the total item count reaches 0."
                    modalOpen={notificationModalOpen}
                    handleDeleteProductCart={handleDeleteProductCart}
                    handleCancel={handleCancelDeleteProduct}
                  />
                  <ProductCartConfirmationModal
                    title="Atur Pesanan?"
                    message="Apakah Anda yakin ingin menambah pesanan?"
                    modalOpen={orderModalOpen}
                    handleYes={() => history.push("/choose-product")}
                    handleNo={() => setOrderModalOpen(false)}
                  />
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div id="shopping-cart-summary">
                <Text strong>{t("Shopping Summary")}</Text>
                <Row className="mt-20">
                  <Col span={16}>
                    <Space direction="vertical" size="small">
                      <Text>{t("Total selected products")}</Text>
                      <Text>{t("Total discounts")}</Text>
                    </Space>
                  </Col>
                  <Col span={8} className="text-right">
                    <Space direction="vertical" size="small">
                      <Text strong>{totalProducts}</Text>
                      <Text strong>{formatRupiah(discount)}</Text>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col span={16}>
                    <Text strong>{t("Total Price")}</Text>
                  </Col>
                  <Col span={8} className="text-right">
                    <Text strong>{formatRupiah(totalPrice)}</Text>
                  </Col>
                  <Col span={24} className="mt-20">
                    <Form.Item>
                      <ButtonPrimary
                        className={isMakeOrderButtonDisabled && "button-disabled"}
                        disabled={isMakeOrderButtonDisabled}
                        block={true}
                        htmlType="submit"
                        handleClick={handleOpenModal}
                        text={t("Make an Order")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <PaymentDetailModal {...paymentDetailModalProps} />
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

export default ShoppingCart;

import { Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { t } from "../../../utils/Functions";
import TooltipCell from "../../atoms/TooltipCell";
import ButtonPrimaryActionTable from "../../atoms/ButtonPrimaryActionTable";
import ImageCustom from "../../atoms/Image";

const { Text } = Typography;

export const columns = [
  {
    title: "productName",
    dataIndex: "name",
    key: "name",
    width: 250,
    render: (text, record) => {
      return (
        <Space className="full-width">
          <ImageCustom
            className="sku-master__list-image"
            width={65}
            height={40}
            src={record?.image}
          />
          <Text
            ellipsis={{ tooltip: { title: text, placement: "topLeft" } }}
            style={{ maxWidth: 160 }}
          >
            {text}
          </Text>
        </Space>
      );
    },
  },
  {
    title: "principal",
    dataIndex: "principal",
    key: "principal",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => <TooltipCell text={text} />,
  },
  {
    title: "brand",
    dataIndex: "brand",
    key: "brand",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => <TooltipCell text={text} />,
  },
  {
    title: "unit",
    dataIndex: "unit",
    key: "unit",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => <TooltipCell text={text} />,
  },
  {
    title: "uom",
    dataIndex: "uom",
    key: "uom",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => <TooltipCell text={text} />,
  },
  {
    title: "qtyOrContent",
    dataIndex: "qty",
    key: "qty",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => <TooltipCell text={text} />,
  },
  {
    title: "action",
    dataIndex: "action",
    key: "action",
    width: 100,
    render: (_, record) => {
      if (record?.isAdded)
        return <div className="sku-master__list-row-product-added">{t("productAdded")}</div>;
      else
        return (
          <div className="flex-center">
            <ButtonPrimaryActionTable
              text="add"
              title="addProduct"
              icon={<PlusOutlined />}
              handleClick={record?.handleSelectSku}
            />
          </div>
        );
    },
  },
];

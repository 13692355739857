import React, { useEffect, useState } from "react";
import { Form, Modal, Typography, Divider, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { formatRupiah } from "../../../utils/Functions";
import FormItemNumberPayment from "../../atoms/FormItemNumberPayment/FormItemNumberPayment";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import "./PaymentDetailModal.scss";
import useAmplitudeContext from "../../../hooks/useAmplitudeContext";

const { Text, Title } = Typography;

const PaymentDetailModal = ({
  modalOpenPayment,
  setModalOpenPayment,
  cartData,
  totalPrice,
  orderId,
  discount,
  isPaymentLoading,
  handlePayment,
}) => {
  const { t } = useTranslation();
  const [change, setChange] = useState(0);
  const [paid, setPaid] = useState(0);
  const [isInvoiceAmountValid, setIsInvoiceAmountValid] = useState(false);
  const [form] = Form.useForm();
  const { trackAmplitudeEvent } = useAmplitudeContext();

  useEffect(() => {
    // form.setFieldValue("invoiceAmount", paid);
    let _change = paid - (totalPrice - discount);
    _change = _change > 0 ? _change : 0;
    setChange(_change);
  }, [paid, totalPrice, discount]);

  const handleSubmit = () => {
    trackAmplitudeEvent("POS_PAYMENT_COMPLETE_CLICK", {
      order_id: orderId,
    });
    handlePayment({ paidAmount: paid, deliveryType: 'BASKIT_DELIVERY', orderStatus: 'ORDER_FINISHED' });
  };

  return (
    <Modal
      className='payment-detail-modal'
      centered
      open={modalOpenPayment}
      onCancel={() => setModalOpenPayment(false)}
      width={980}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row gutter={24}>
        <Col span={24} className='mb-20 text-center'>
          <Title level={4}>{t("Payment Details")}</Title>
        </Col>
        <Col span={12}>
          <div id="payment-detail-table">
            <Row gutter={12} className="pl-10 pr-10 pt-10">
              <Col span={14}>
                <Text strong>{t("Item")}</Text>
              </Col>
              <Col span={3} className='text-center'>
                <Text strong>{t("Qty")}</Text>
              </Col>
              <Col span={7} className='text-center'>
                <Text strong>{t("Price")}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row id='list-item-payments' className='pl-10 pr-10 pb-10'>
              <Col span={24}>
                {cartData.map((item, index) => (
                  <PaymentDetailItem key={index} title={item?.name} qty={item?.qty} total={item?.qty * item?.sellingPrice} />
                ))}
              </Col>
            </Row>
          </div>
          <Row className="mt-10 pl-10 pr-10">
            <Col span={24}>
              <Text>{t("Total Ongkos Kirim")}</Text>
              <Text className='float-right'>
                {/* {formatRupiah(totalPrice)} */}
                Rp 0
              </Text>
            </Col>
            <Col span={24} className='mt-10'>
              <Text>{t("Discount")}</Text>
              <Text className='float-right'>{formatRupiah(discount)}</Text>
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <Text strong className='text-total'>{t("Total")}</Text>
              <Text strong className='text-total float-right'>
                {formatRupiah(totalPrice)}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={24} className='mb-15'>
              <Text strong>{t("Cash")}</Text>
            </Col>
            <Col span={24}>
              <Form form={form} onFinish={handleSubmit}>
                <FormItemNumberPayment
                  handleChange={(value) => {
                    if (value >= totalPrice) setIsInvoiceAmountValid(true);
                    else setIsInvoiceAmountValid(false);
                    setPaid(value);
                  }}
                  placeholder="0"
                  name='invoiceAmount'
                  rules={[
                    {
                      required: true,
                      message: t("Please input your payment amount"),
                    },
                    {
                      type: "number",
                      min: totalPrice - discount,
                      message: t("Payment amount is insufficient"),
                    },
                  ]}
                />
                <Form.Item>
                  <Row gutter={12}>
                    <Col span={24}>
                      <Divider />
                    </Col>
                    <Col span={24} id="payment-detail-change" className='mb-70'>
                      <Text strong>{t("Change")}</Text>
                      <Text strong className='float-right'>
                        {formatRupiah(change)}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <ButtonDefault text='Cancel' block={true} handleClick={() => setModalOpenPayment(false)} />
                    </Col>
                    <Col span={12}>
                      <ButtonPrimary
                        text='Pay'
                        className={!isInvoiceAmountValid && "disabled-button"}
                        disabled={!isInvoiceAmountValid}
                        block={true}
                        htmlType='submit'
                        loading={isPaymentLoading}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

const PaymentDetailItem = ({ title, qty, total }) => {
  return (
    <Row>
      <Col span={14}>
        <Text>{title}</Text>
      </Col>
      <Col span={3} className='text-center'>
        <Text>{qty}</Text>
      </Col>
      <Col span={7} className='text-right'>
        <Text>{formatRupiah(total)}</Text>
      </Col>
    </Row>
  );
};

export default PaymentDetailModal;

import React, { useEffect, useState } from "react";
import { Collapse, Image, List } from "antd";
import { formatRupiah } from "../../../utils/Functions";
import { isNumber } from "lodash-es";
import "./AdjustedDetailListItem.scss";
import deepDiff from "deep-diff";

const { Panel } = Collapse;

const tagMapper = {
  CHANGE_PRICE: 'Perubahan Harga',
  CHANGE_QTY: 'Perubahan Jumlah Produk',
  CHANGE_QTY_PRICE: 'Perubahan Harga & Jumlah Produk',
  FINISHED_CHANGE_PRICE: "Selesai Dengan Perubahan Harga",
  FINISHED_CHANGE_QTY: "Selesai Dengan Perubahan Jumlah Produk",
  FINISHED_CHANGE_QTY_PRICE: "Selesai Dengan Perubahan Harga & Jumlah Produk",
  FINISHED: "Selesai",
  CANCELLED: "Dibatalkan"
};

const tagColorMapper = {
  CHANGE_PRICE: {
    bg: '#FFDF69',
    text: '#B77D02',
  },
  CHANGE_QTY: {
    bg: '#FFDF69',
    text: '#B77D02',
  },
  CHANGE_QTY_PRICE: {
    bg: '#FFDF69',
    text: '#B77D02',
  },
  FINISHED_CHANGE_PRICE: {
    bg: '#00DFB7',
    text: '#000000'
  },
  FINISHED_CHANGE_QTY: {
    bg: '#00DFB7',
    text: '#000000'
  },
  FINISHED_CHANGE_QTY_PRICE: {
    bg: '#00DFB7',
    text: '#000000'
  },
  FINISHED: {
    bg: '#00DFB7',
    text: '#000000'
  },
  CANCELLED: {
    bg: '#FF9F83',
    text: '#B7192B'
  }
}

const AdjustedDetailListItem = ({
  data,
}) => {
  const { sellingPrice, qty, image } = data;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (isNumber(qty) && isNumber(sellingPrice)) setTotal(qty * sellingPrice);
  }, [qty, sellingPrice]);

  if (!data) {
    return  null;
  }

  const basePrice = data?.sellingPrice - data?.marginValue;
  const marginValueBefore = data?.initial?.initialPrice - basePrice;

  const marginValueAfter = data?.marginValue;
  const diff = deepDiff.diff(data?.initial, { initialPrice: data.sellingPrice, orderDetailId: data.id, initialQty: data.qty })

  return (
    <List.Item className="orderDetailListItem">
      <div className="wrapper">
        <div className="flex header">
          <Image
            className="payment-detail-list-item-image"
            width={126}
            height={126}
            preview={false}
            src={image ?? `${window.location.origin}/images/baskit-product.jpg`}
            fallback={`${window.location.origin}/images/baskit-product.jpg`}
          />
          <div className="flex detail">
            <div className="tag" style={{ background: tagColorMapper[data?.status]?.bg, color: tagColorMapper[data?.status]?.text }}>
              {tagMapper[data?.status]}
            </div>
            <div className="title">{data.fullName}</div>
            <div className="margin flex">
              Nilai Margin:
              <span className="originalMargin">{formatRupiah(marginValueBefore || 0)}</span>
              {marginValueBefore !== marginValueAfter && data?.status !== 'CANCELLED' ? (
                <>
                  <img src={`${process.env.PUBLIC_URL}/images/long-arrow-left.svg`} alt="change" />
                  <span className="changedMargin">{formatRupiah(marginValueAfter)}</span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex description">
          <div className="flex original">
            <div className="title" style={{ visibility: !diff || data?.status === 'CANCELLED' ? 'hidden' : 'visible' }}>Original</div>
            <div className="flex qty">
              <span className="key">Qty:</span>
              <span className="value">{data?.initial?.initialQty}</span>
            </div>
            <div className="flex price">
              <span className="key">Harga:</span>
              <span className="value">{formatRupiah(data?.initial?.initialPrice)}</span>
            </div>
            <div className="flex total">
              <span className="key">Total:</span>
              <span className="value">{formatRupiah(data?.initial?.initialPrice * data?.initial?.initialQty)}</span>
            </div>
          </div>
          {diff && data.status !== 'CANCELLED' ? (
            <div className="flex changes">
              <div className="title">Perubahan</div>
              <div className="flex qty">
                <span className="key">Qty:</span>
                <span className="value">{data.qty}</span>
              </div>
              <div className="flex price">
                <span className="key">Harga:</span>
                <span className="value">{formatRupiah(data.sellingPrice)}</span>
              </div>
              <div className="flex total">
                <span className="key">Total:</span>
                <span className="value">{formatRupiah(total)}</span>
              </div>
            </div>
          ) : null}
          {diff ? (
            <Collapse
              style={{marginTop: 10}}
              accordion={true}
              bordered={false}
              expandIconPosition="end"
            >
              <Panel
                className="panel"
                header={(
                  <span className="flex panel">
                    <div className="flex panel-item">
                      <span className="key">Alasan</span>
                      <span className="value">{data?.reason?.name}</span>
                    </div>
                    <span className="more">Lihat catatan</span>
                  </span>
                )}
              >
                {data?.notes}
              </Panel>
            </Collapse>
          ) : null}
        </div>
      </div>
    </List.Item>
  );
};

export default AdjustedDetailListItem;

import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { httpClient } from "../../utils/HttpClient";
import { ErrorNotification } from "../../utils/Notification";
import { parsePhoneNumber } from "../../utils/Functions";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import FormItemPhoneNumber from "../../components/atoms/FormItemPhoneNumber";
import FormItemAddress from "../../components/atoms/FormItemAddress";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import * as companyActions from "../../actions/company.action";
import "./_style.scss";

const UpdateCompany = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isFetchingSuccess, setIsFetchingSuccess] = useState(false);
  const [loading, setLoading] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector((state) => state.companyReducer);

  useEffect(() => {
    dispatch(companyActions.getCompanyDetails());
    getCountry();
  }, []);

  useEffect(() => {
    if (company.isFetching) setIsFetchingSuccess(true);
    if (!isEmpty(company.result) && isFetchingSuccess) {
      const { companyname, email, phone, zipCode, state, country, description, address } = company.result;
      const phoneFormat = parsePhoneNumber(phone);
      form.setFieldsValue({
        companyname,
        email,
        code_phone: phoneFormat.code,
        phone: phoneFormat.number,
        zipCode,
        state,
        country,
        description,
        address,
      });
      setLoading(false);
    }
  }, [company, isFetchingSuccess]);

  const getCountry = async () => {
    await httpClient
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const options = response.data.map((item) => ({ value: item?.cca2, label: item?.name.common }));
        setCountryOptions(options);
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };

  const handleSubmit = async () => {
    const { companyname, email, code_phone, phone, zipCode, state, country, address, description } =
      form.getFieldsValue();
    const dataToSend = {
      id,
      companyname,
      email,
      phone: code_phone + phone,
      zipCode,
      state,
      country,
      address,
      description,
    };
    dispatch(companyActions.update(dataToSend, history));
  };

  const headerProps = {
    buttonBack: { text: "Update Company", status: true },
    description: "Manage your update compay",
  };

  const mainBreadcrumbItems = [
    {
      link: "/my-account",
      title: "My Account",
    },
    {
      title: "Update Company",
    },
  ];
  return (
    <MainContent headerProps={headerProps}>
      <div id='update-profile-wrapper'>
        <div id='update-profile-breadcrumb'>
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id='update-profile-content'>
          {loading ? (
            <SpinLoaderContent />
          ) : (
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              layout='horizontal'
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item
                label={t("Company Name")}
                name='companyname'
                rules={[{ required: true, message: t("Please input your company name!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Email")}
                name='email'
                rules={[{ required: true, message: t("Please input your email!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <FormItemPhoneNumber
                name='phone'
                label={t("Phone Number")}
                size='large'
                rules={[{ required: true, message: t("Please input phone number!") }]}
              />

              <Form.Item
                label={t("Zip Code")}
                name='zipCode'
                rules={[{ required: true, message: t("Please input your zip code!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("State")}
                name='state'
                rules={[{ required: true, message: t("Please input state!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Country")}
                name='country'
                rules={[{ required: true, message: t("Please select your country!") }]}
              >
                <Select options={countryOptions} />
              </Form.Item>

              <FormItemAddress
                label='Address'
                name='address'
                rules={[
                  {
                    required: true,
                    message: "Please input your address!",
                  },
                ]}
              />

              <Form.Item
                label={t("Description")}
                name='description'
                rules={[{ required: true, message: t("Please input your description!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <ButtonPrimary text='Save Change' className='float-right' htmlType='submit' />
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </MainContent>
  );
};

export default UpdateCompany;

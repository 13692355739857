import {
  CATEGORY_FETCHING,
  CATEGORY_SUCCESS,
  CATEGORY_FAILED,
  CATEGORY_CLEAR,
  CATEGORY_OPTIONS_FETCHING,
  CATEGORY_OPTIONS_SUCCESS,
  CATEGORY_OPTIONS_FAILED,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setCategoryStateToFetching = () => ({
  type: CATEGORY_FETCHING,
});
export const setCategoryStateToFailed = () => ({
  type: CATEGORY_FAILED,
});
export const setCategoryStateToClear = () => ({
  type: CATEGORY_CLEAR,
});
export const setCategoryStateToSuccess = (payload) => ({
  type: CATEGORY_SUCCESS,
  payload,
});

export const setCategoryOptionsStateToFetching = () => ({
  type: CATEGORY_OPTIONS_FETCHING,
});

export const setCategoryOptionsStateToFailed = () => ({
  type: CATEGORY_OPTIONS_FAILED,
});

export const setCategoryOptionsStateToSuccess = (payload) => ({
  type: CATEGORY_OPTIONS_SUCCESS,
  payload,
});

// export const getCategories = async () => {
//   const response = await httpClient.get(process.env.REACT_APP_API_URL + "category?type=master");
//   if (response.data.result === "success") {
//     return response.data.data;
//   } else if (response.data.result === "error") {
//     swal("Error!", response.data.message, "error");
//   }
// };

// export const Index = () => {
//     return async (dispatch) => {
//         dispatch(setCategoryStateToFetching());
//         const response = await httpClient.get(
//             process.env.REACT_APP_API_URL + "category?type=master"
//         );
//         if (response.data.result === "success") {
//             dispatch(setCategoryStateToSuccess(response.data.data));
//         } else if (response.data.result === "error") {
//             dispatch(setCategoryStateToFailed());
//             swal("Error!", response.data.message, "error");
//         }
//     };
// };

export const Index = () => {
  return async (dispatch) => {
    dispatch(setCategoryStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "category?type=master");
      dispatch(setCategoryStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCategoryStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const index = (params) => {
  return async (dispatch) => {
    dispatch(setCategoryStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `category${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setCategoryStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCategoryStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const options = (params) => {
  return async (dispatch) => {
    dispatch(setCategoryOptionsStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `category${paramsString}`,
      );
      dispatch(setCategoryOptionsStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCategoryOptionsStateToFailed());
      ErrorNotification(error);
    }
  };
};

import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { server } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toCheckLoginToken } from "../../utils/Functions";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";
import Cookies from "js-cookie";
import MainContent from "../../components/organisms/Content/Content";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import FormItemSelectCustomer from "../../components/atoms/FormItemSelectCustomer/FormItemSelectCustomer";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import AddCustomerModal from "../../components/molecules/AddCustomerModal/AddCustomerModal";
import * as customerActions from "../../actions/customer.action";
import "./_style.scss";

const { Text } = Typography;

const POS = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customerCreated, setCustomerCreated] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [data, setData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerTypeOptions, setCustomerTypeOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);
  const modeTablet = tokenParams ? true : false;

  const history = useHistory();
  const dispatch = useDispatch();
  const { customerOffline, customerOfflineType, customerTypeResult } = useSelector(
    (state) => state.customerReducer,
  );

  useEffect(() => {
    dispatch(customerActions.customerOffline());
    dispatch(customerActions.customerOfflineType());
  }, []);

  useEffect(() => {
    if (customerOffline) {
      const _customerOptions = customerOffline.map((customer) => ({
        value: customer.id,
        label: customer.firstName,
        phone: customer.phone,
      }));
      setCustomerOptions(_customerOptions);
      setData(customerOffline);
    }
  }, [customerOffline]);

  useEffect(() => {
    if (customerOfflineType) {
      const result = customerOfflineType.map((customer) => ({
        value: customer.id,
        label: customer.name,
      }));
      setCustomerTypeOptions(result);
    }
  }, [customerOfflineType]);

  useEffect(() => {
    if (data && !isEmpty(customerCreated)) {
      const _selectedCustomer = data.find((customer) => customer.phone === customerCreated.phone);
      if (_selectedCustomer || !isEmpty(_selectedCustomer)) {
        form.setFieldValue("customer", _selectedCustomer.id);
        setSelectedCustomer(_selectedCustomer);
        setSelectedStatus(true);
      }
    }
  }, [data, customerCreated]);

  const handleChooseCustomer = (value) => {
    if (value) {
      const _selectedCustomer = data.find((customer) => customer.id === value);
      setSelectedCustomer(_selectedCustomer);
      setSelectedStatus(true);
    } else {
      setSelectedCustomer({});
      setSelectedStatus(false);
    }
  };

  const handleSubmit = () => {
    const customerId = form.getFieldValue("customer");
    Cookies.set(server.CUSTOMER_ID, customerId);
    const path = modeTablet ? `/choose-product?token=${tokenParams}` : "/choose-product";
    history.push(path);
  };

  const headerProps = {
    title: "Create Order",
    description: "POS",
  };

  const mainBreadcrumbItems = [
    {
      title: "Create Order",
    },
  ];

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="pos-wrapper">
        {!modeTablet && (
          <div>
            <MainBreadcrumb items={mainBreadcrumbItems} />
          </div>
        )}

        <div id="pos-content">
          <Row>
            <Col span={24}>
              <Row gutter={12}>
                <Col span={15}>
                  <div className="pos-customer">
                    <div>
                      <Text strong>{t("Customer")}</Text>
                    </div>
                    <div>
                      <Form form={form}>
                        <FormItemSelectCustomer
                          name="customer"
                          placeholder={t("Choose a customer name")}
                          // rules={[
                          //   { required: true, message: t("Please choose your customer name!") },
                          // ]}
                          handleChange={handleChooseCustomer}
                          options={customerOptions}
                          setModalOpen={setModalOpen}
                        />
                      </Form>
                    </div>
                    <div>
                      {Object.keys(selectedCustomer).length > 0 && (
                        <>
                          <Text strong>{t("Details")}</Text>
                          <div className="selected-customer-detail">
                            <Row>
                              <Col span={3}>
                                <Text>{t("Phone No.")}</Text>
                              </Col>
                              <Col span={21}>
                                <Text className="ml-10">: {selectedCustomer.phone}</Text>
                              </Col>
                              <Col span={3}>
                                <Text>{t("Address")} </Text>
                              </Col>
                              <Col span={21}>
                                <Text className="ml-10">: {selectedCustomer.address}</Text>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="text-center" span={9}>
                  <div className="pos-transaction">
                    <div>
                      <p>
                        <InfoCircleOutlined />
                        <span> {t("directTransactionMessage")}</span>
                      </p>
                    </div>
                    <ButtonPrimary
                      className={"create-customer-button " + (selectedStatus && "button-disabled")}
                      disabled={!selectedStatus ? false : true}
                      icon={<PlusOutlined />}
                      block={true}
                      text={"directTransaction"}
                      handleClick={() => {
                        Cookies.remove(server.CUSTOMER_ID);
                        history.push('/choose-product');
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <AddCustomerModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            setCustomerCreated={setCustomerCreated}
            customerTypeOptions={customerTypeOptions}
          />
          <div className="float-button">
            <ButtonPrimary
              className={!selectedStatus && "button-disabled"}
              disabled={selectedStatus ? false : true}
              block={true}
              htmlType="submit"
              text={"Choose Product"}
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

export default POS;

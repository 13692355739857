import React, { useEffect, useMemo, useState } from "react";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatRupiah, toCheckLoginToken } from "../../utils/Functions";
import { isEmpty } from "lodash-es";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { server } from "../../constants";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import * as customerActions from "../../actions/customer.action";
import "./_style.scss";

const { Text, Title } = Typography;

const CustomerFinancials = () => {
  // const { id } = useParams();
  const { search } = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isFetchingSuccess, setIsFetchingSuccess] = useState(false);
  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);

  const customer = useSelector((state) => state.customerReducer);

  useEffect(() => {
    dispatch(customerActions.getFinance());
  }, []);

  useEffect(() => {
    if (customer.isFetching) setIsFetchingSuccess(true);
    if (!isEmpty(customer.result) && isFetchingSuccess) {
      const { awaitingApproval, paymentDue, overdue } = customer.result;
      const _data = {
        awaitingApproval: { sum: awaitingApproval[0].sum, count: awaitingApproval[0].count },
        paymentDue: { sum: paymentDue[0].sum, count: paymentDue[0].count },
        overdue: { sum: overdue[0].sum, count: overdue[0].count },
      };
      setData(_data);
      setLoading(false);
    }
  }, [customer, isFetchingSuccess]);

  const headerProps = {
    buttonBack: { text: "Customer Financials", status: true },
    description: "Manage your customer financials",
  };

  const mainBreadcrumbItems = [
    {
      link: "/customer",
      title: "Customer",
    },
    {
      title: "Customer Financials",
    },
  ];

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id='customer-financials-wrapper'>
        <div id='customer-financials-breadcrumb'>
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id='customer-financials-content'>
          {loading ? (
            <SpinLoaderContent />
          ) : (
            <Row gutter={12}>
              <CustomCard
                className='custom-card-bg-secondary'
                title='Awaiting Approval'
                total={data?.awaitingApproval.sum}
                bills={data?.awaitingApproval.count}
              />
              <CustomCard
                className='custom-card-bg-primary'
                title='Payment Due'
                total={data?.paymentDue.sum}
                bills={data?.paymentDue.count}
              />
              <CustomCard
                className='custom-card-bg-secondary'
                title='Overdue'
                total={data?.overdue.sum}
                bills={data?.overdue.count}
              />
            </Row>
          )}
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to='/login' />;
};

const CustomCard = ({ title, total, bills, className }) => {
  const { t } = useTranslation();

  return (
    <Col span={8} className='text-center'>
      <Title level={5}>{t(title)}</Title>
      <Card className={"text-center " + className}>
        <Title level={3}>{formatRupiah(total)}</Title>
        <Text>{bills}</Text>
        <Text> {t("Bills")}</Text>
      </Card>
    </Col>
  );
};

export default CustomerFinancials;

import { React, useEffect, useMemo, useRef, useState } from "react";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Button, Divider, Layout, Menu, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { UserRoles, server } from "../../../constants";
import { motion, AnimatePresence } from "framer-motion";
import { items } from "./helpers";
import { routes } from "../../../helper/routeList";
import RoleSwitcher from "../../atoms/RoleSwitcher";
import useLoggedInUser from "../../../hooks/useLoggedInUser";
import "./Dashboard.scss";
import { getCurrentRole } from "../../../utils/Functions";

const pjson = require("../../../../package.json");
const posIcon = `${window.location.origin}/images/pos-icon.svg`;
const { Sider, Content } = Layout;
const { Text } = Typography;

const checkRoutePermission = (routePath, routes) => {
  const route = routes.find((route) => route.path.startsWith(routePath));
  return route?.permission ? true : false;
};

const CustomLabel = ({ path, label }) => (
  <a href={path} onClick={(e) => e.preventDefault()}>
    {label}
  </a>
);

const DashboardView = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const history = useHistory();
  const currentPath = pathname === "/" ? "/home" : pathname.match(/\/([^/]+)/)[0];
  const userRole = getCurrentRole();
  const { firstName, roleUser, companyName } = useLoggedInUser();
  const [isVisible, setIsVisible] = useState(false);

  const isAuthenticated = useMemo(() => {
    const isHasToken = localStorage.getItem(server.TOKEN_KEY) ? true : false;

    if (pathname === "/") return isHasToken;
    const parentPathName = pathname.split("/")[1];
    const isRouteHasPermission = checkRoutePermission(`/${parentPathName}`, routes);

    return isRouteHasPermission && isHasToken ? true : false;
  }, [pathname]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    window?.parent?.postMessage(
      {
        type: 'pathname',
        payload: pathname.replace('/auth', '')
      },
      process.env.REACT_APP_NEW_CMS
    );
  }, [pathname]);

  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label: children === undefined ? <CustomLabel path={key} label={label} /> : label,
    };
  };

  const handleSidebarMenuClick = (value) => {
    const { key } = value;
    if (key) history.push(key);
  };

  const renderItems = (items) => {
    return items.map((item) => {
      if (item.permission.includes(userRole)) {
        if (item.hasOwnProperty("subMenu")) {
          const subMenu = item.subMenu.map((subMenu) => getItem(t(subMenu.title), subMenu.path));
          return getItem(t(item.title), item.path, item.icon, subMenu);
        } else {
          return getItem(t(item.title), item.path, item.icon);
        }
      }
    });
  };

  const posButtonProps = {
    id: "button-shortcut-ops",
    type: "primary",
    icon: <img className="pos-icon" src={posIcon} />,
    onClick: () => history.push("/pos"),
  };

  const isInsideIframe = window.parent !== window;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {isAuthenticated ? (
            <Layout id="private-layout">
              {isInsideIframe ? null : (
                <Sider id="sider" trigger={null} collapsible collapsed={collapsed}>
                  <div className="sider-profile">
                    <button className="trigger-menu" onClick={() => setCollapsed(!collapsed)}>
                      {!collapsed && <span className="text">{t("Hide Menu")}</span>}
                      {!collapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
                    </button>
                    <div>
                      {!collapsed ? (
                        <>
                          <Text className="title">
                            {t("Welcome")}, {firstName}
                          </Text>
                          <br />
                          <Text className="description">
                            {roleUser} {companyName}
                          </Text>
                          <br />
                          <RoleSwitcher />
                        </>
                      ) : (
                        <Text className="title">{firstName}</Text>
                      )}
                      <Divider />
                    </div>
                  </div>

                  <Menu
                    className="custom-menu"
                    mode="inline"
                    selectedKeys={[currentPath]}
                    defaultSelectedKeys={[currentPath]}
                    onClick={handleSidebarMenuClick}
                    items={renderItems(items)}
                  />

                  {userRole === UserRoles.ADMIN && currentPath !== "/pos" && (
                    <Tooltip placement="right" title="POS">
                      {collapsed ? (
                        <Button className="button-shortcut-ops-collapsed" {...posButtonProps} />
                      ) : (
                        <Button {...posButtonProps} />
                      )}
                    </Tooltip>
                  )}

                  <div className="app-version text-center">
                    {collapsed ? (
                      <Text className="ml-20" type="secondary">
                        v{pjson.version}
                      </Text>
                    ) : (
                      <>
                        <Text type="secondary">
                          {t("Version").toLowerCase()} {pjson.version}
                        </Text>
                        <br />
                        <Text className="text-copy-right" type="secondary">
                          {t("Copyright 2023 @ PT Baskit Technology Aplikasi")}
                        </Text>
                      </>
                    )}
                  </div>
                </Sider>
              )}
              <Layout id="site-layout">
                <div
                  style={{
                    background: isInsideIframe ? 'transparent' : '#f5f5f5'
                  }}
                >
                  {children}
                </div>
              </Layout>
            </Layout>
          ) : (
            <Layout id="public-layout">
              <Content>{children}</Content>
            </Layout>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DashboardView;

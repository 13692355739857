import React from "react";
import { Card, Col, Form, Input, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { CreditCardOutlined } from "@ant-design/icons";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import "./_style.scss";

const { Text } = Typography;

const Payment = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const { name, date, cvv } = form.getFieldsValue();
    const dataToSend = { name, date, cvv };
    console.log("data to send: ", dataToSend);
    // no action to change password
  };

  return (
    <div id='my-account-payment-section'>
      <Row gutter={12}>
        <Col span={10}>
          <Text strong>{t("My Payment Method")}</Text>
          <Card className='card-payment-section mt-10'>
            <Row>
              <Col span={24} className='mb-70'>
                <Space direction='horizontal'>
                  <CreditCardOutlined />
                  <Text>{t("My Card")}</Text>
                </Space>
              </Col>
              <Col span={12}>
                <Text strong>{t("VISA")}</Text>
              </Col>
              <Col span={12} className='text-right'>
                <Text>486516*******5555</Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={14}>
          <Text strong>{t("Payment Information")}</Text>
          <br />
          <Text>{t("Enter your card information")}</Text>
          <Form className='mt-10' form={form} layout='vertical' onFinish={handleSubmit}>
            <Form.Item label={t("Customer Name")} name='name'>
              <Input size='large' />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label={t("Name On Card")} name='name'>
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t("Expiration Date")} name='date'>
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t("CVV")} name='cvv'>
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ span: 24 }}>
              <ButtonPrimary text='Save Changes' className='float-right' htmlType='submit' />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Payment;

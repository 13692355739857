import {
    SALES_DETAIL_FETCHING,
    SALES_DETAIL_SUCCESS,
    SALES_DETAIL_FAILED,
    SALES_DETAIL_CLEAR,
  } from "../constants";
  
  const initialState = {
    isFetching: false,
    isError: false,
    result: null,
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case SALES_DETAIL_FETCHING:
        return { ...state, isFetching: true, isError: false, result: null };
      case SALES_DETAIL_FAILED:
        return { ...state, isFetching: false, isError: true, result: null };
      case SALES_DETAIL_SUCCESS:
        return { ...state, isFetching: false, isError: false, result: payload };
      case SALES_DETAIL_CLEAR:
        return { ...state, result: null, isFetching: false, isError: false };
      default:
        return state;
    }
  };
  
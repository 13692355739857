import swal from "sweetalert";
import {
  FETCH_OPTION_SUCCESS,
  PRODUCT_CLEAR,
  PRODUCT_FAILED,
  PRODUCT_FETCHING,
  PRODUCT_SUCCESS,
  PRODUCT_UPDATE,
  CREATE_PRODUCT_MASTER_FETCHING,
  CREATE_PRODUCT_MASTER_FAILED,
  PRODUCT_MASTER_FETCHING,
  PRODUCT_MASTER_FAILED,
  PRODUCT_MASTER_SUCCESS,
  PRODUCT_MASTER_APPENDED,
  UPDATE_PRODUCT_MASTER,
  server,
  PRODUCT_MASTER_DROPDOWN_SUCCESS,
  PRODUCT_MASTER_DROPDOWN_FETCHING,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import Cookies from "js-cookie";

export const setProductStateToFetching = () => ({
  type: PRODUCT_FETCHING,
});

export const setProductStateToFailed = () => ({
  type: PRODUCT_FAILED,
});

export const setProductStateToClear = () => ({
  type: PRODUCT_CLEAR,
});

export const setProductStateToSuccess = (payload) => ({
  type: PRODUCT_SUCCESS,
  payload,
});

export const setFetchOptionStateToSuccess = (payload) => ({
  type: FETCH_OPTION_SUCCESS,
  payload,
});

export const setProductData = (payload) => ({
  type: PRODUCT_UPDATE,
  payload,
});

export const setCreateProductMasterStateToFetching = () => ({
  type: CREATE_PRODUCT_MASTER_FETCHING,
});

export const setCreateProductMasterStateToFailed = () => ({
  type: CREATE_PRODUCT_MASTER_FAILED,
});

export const setProductMasterStateToFetching = () => ({
  type: PRODUCT_MASTER_FETCHING,
});

export const setProductMasterStateToFailed = () => ({
  type: PRODUCT_MASTER_FAILED,
});

export const setProductMasterStateToSuccess = (payload) => ({
  type: PRODUCT_MASTER_SUCCESS,
  payload,
});

export const setProductMasterDropdownToFetching = (payload) => ({
  type: PRODUCT_MASTER_DROPDOWN_FETCHING,
});

export const setProductMasterDrowndownToSuccess = (payload) => ({
  type: PRODUCT_MASTER_DROPDOWN_SUCCESS,
  payload,
})

export const appendProductMaster = (payload) => ({
  type: PRODUCT_MASTER_APPENDED,
  payload,
});

export const updateProductMaster = (payload) => ({
  type: UPDATE_PRODUCT_MASTER,
  payload,
});

// export const getProducts = async () => {
//     const response = await httpClient.get(
//         process.env.REACT_APP_API_URL + "product"
//     );
//     if (response.data.result == "success") {
//         return response.data.data
//     } else if (response.data.result === "error") {
//         swal("Error!", response.data.message, "error");
//     }

//     // const response = await httpClient.get(
//     //     process.env.REACT_APP_API_URL + "category"
//     // );
//     // if (response.data.result == "success") {
//     //     return response.data.data
//     // } else if (response.data.result === "error") {
//     //     swal("Error!", response.data.message, "error");
//     // }
// };

// export const getProductMaster = () => {
//     return async (dispatch) => {
//         dispatch(setProductStateToFetching());
//         const response = await httpClient.get(
//             process.env.REACT_APP_API_URL + "product?type=master"
//         );
//         if (response.data.result === "success") {
//             dispatch(setProductStateToSuccess(response.data.data));
//         } else if (response.data.result === "error") {
//             dispatch(setProductStateToFailed());
//             swal("Error!", response.data.message, "error");
//         }
//     };
// };

export const getProductMaster = (params = null) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + `product-master${paramsString}`,
      );
      const { data, meta } = response.data;
      dispatch(setProductStateToSuccess({ data, meta }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const Detail = (id) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "product/" + id);
    if (response.data.result === "success") {
      dispatch(setProductStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getProductByCompanyCategory = (companyId, categoryId) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.get(
      process.env.REACT_APP_API_URL + `eshop/product/company/${companyId}/category/${categoryId}`,
    );
    if (response.data.result === "success") {
      dispatch(setProductStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getProductDetail = (id) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "product/update/" + id);

    if (response.data.result === "success") {
      dispatch(setProductData(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const importProduct = (values) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.post(
      process.env.REACT_APP_API_URL + "product/import",
      values,
    );
    if (response.data.result === "success") {
      dispatch(setProductStateToSuccess());
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const Index = () => {
//     return async (dispatch) => {
//         dispatch(setProductStateToFetching());
//         const response = await httpClient.get(
//             process.env.REACT_APP_API_URL + "product"
//         );
//         if (response.data.result === "success") {
//             dispatch(setProductStateToSuccess(response.data.data));
//         } else if (response.data.result === "error") {
//             dispatch(setProductStateToFailed());
//             swal("Error!", response.data.message, "error");
//         }
//     };
// };

export const Index = (params = null) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_V2 + `product${paramsString}`,
      );
      const { data, meta } = response.data;
      dispatch(setProductStateToSuccess({ data, meta }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getDropdownPOS = () => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "get_supplier");
    if (response.data.result === "success") {
      let result = response.data.data.flat().map((item) => {
        return {
          value: item._id,
          label: item.alias,
        };
      });

      dispatch(setFetchOptionStateToSuccess(result));
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const Create = (values, history) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "product", values);
    if (response.data.result === "success") {
      dispatch(setProductStateToSuccess(response.data));
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setProductStateToClear());
        history.goBack();
        dispatch(Index());
      });
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const Update = (values, history) => {
//     return async (dispatch) => {
//         dispatch(setProductStateToFetching());
//         const response = await httpClient.put(
//             process.env.REACT_APP_API_URL + "product2",
//             values
//         );
//         if (response.data.result === "success") {
//             swal("Success!", response.data.message, "success").then((value) => {
//                 dispatch(setProductStateToClear());
//                 dispatch(Index());
//             });
//         } else if (response.data.result === "error") {
//             dispatch(setProductStateToFailed());
//             swal("Error!", response.data.message, "error");
//         }
//     };
// };

export const Update = (values) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + "product2", values);
      dispatch(setProductStateToClear());
      dispatch(Index());
      SuccessNotification(response);
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const Remove = (id) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + "product/" + id);
    if (response.data.result === "success") {
      dispatch(setProductStateToSuccess());
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getSingleBranch = (id) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "product/" + id);

    if (response.data.result === "success") {
      dispatch(getDropdownPOS()).then(() => {
        dispatch(setProductStateToSuccess(response.data.data));
      });
    } else if (response.data.result === "error") {
      dispatch(setProductStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const getProductMasterByCategoryId = (categoryId) => {
//     return async (dispatch) => {
//         dispatch(setProductStateToFetching());
//         const response = await httpClient.get(
//             process.env.REACT_APP_API_URL + "/product/master/search?categoryId=" + categoryId
//         );

//         if (response.data.result === "success") {
//             dispatch(setProductStateToSuccess(response.data.data));
//         } else if (response.data.result === "error") {
//             dispatch(setProductStateToFailed());
//             swal("Error!", response.data.message, "error");
//         }
//     };
// };

export const getProductMasterByCategoryId = (categoryId, params = null) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      const paramsString = parseParamsString({ categoryId, ...params });
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + `product/master/search${paramsString}`,
      );
      const { data, meta } = response.data;
      dispatch(setProductStateToSuccess({ data, meta }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

// export const createFromMaster = (values) => {
//     return async (dispatch) => {
//         dispatch(setProductStateToFetching());
//         try{
//             const response = await httpClient.post(
//                 process.env.REACT_APP_API_URL + "product/from-master",
//                 values
//             );
//             if (response.data.result === "success") {
//                 dispatch(setProductStateToClear());
//                 swal("Success!", response.data.message, "success");
//             } else if (response.data.result === "error") {
//                 dispatch(setProductStateToFailed());
//                 swal("Error!", response.data.message, "error");
//             }
//         }catch(error){
//             dispatch(setProductStateToFailed());
//             const { status, statusText, data } = error.response;
//             if(status === 404) swal("Error!", statusText, "error");
//             else swal("Error!", data.message, "error");
//         }
//     };
// };

export const createFromMaster = (values, successMessage, history, tokenParams) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL + "product/from-master",
        values,
      );
      dispatch(setProductStateToClear());
      const path = `/product-list${parseParamsString(tokenParams)}`;
      Cookies.set(server.ALERT_MESSAGE, successMessage, { expires: 1 });
      history.push(path);
      SuccessNotification(response);
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

// export const setActivateDeactivateProduct = (productId, isActive) => {
//     return async (dispatch) => {
//             dispatch(setProductStateToFetching());
//             try{
//                 if (isActive) {
//                     const response = await httpClient.patch(
//                         process.env.REACT_APP_API_URL + `product/activate/${productId}`);
//                         if (response.data.result === "success") {
//                             dispatch(setProductStateToClear());
//                             swal("Success!", response.data.message, "success");
//                         } else if (response.data.result === "error") {
//                             dispatch(setProductStateToFailed());
//                             swal("Error!", response.data.message, "error");
//                         }
//                 } else {
//                     const response = await httpClient.patch(
//                         process.env.REACT_APP_API_URL + `product/deactivate/${productId}`);
//                         if (response.data.result === "success") {
//                             dispatch(setProductStateToClear());
//                             swal("Success!", response.data.message, "success");
//                         } else if (response.data.result === "error") {
//                             dispatch(setProductStateToFailed());
//                             swal("Error!", response.data.message, "error");
//                         }
//                 }

//             }catch(error){
//                 dispatch(setProductStateToFailed());
//                 const { status, statusText, data } = error.response;
//                 if(status === 404) swal("Error!", statusText, "error");
//                 else swal("Error!", data.message, "error");
//             }
//         };
// }

export const setActivateDeactivateProduct = (productId, isActive) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      if (isActive) {
        const response = await httpClient.patch(
          process.env.REACT_APP_API_URL + `product/activate/${productId}`,
        );
        dispatch(setProductStateToClear());
        SuccessNotification(response);
      } else {
        const response = await httpClient.patch(
          process.env.REACT_APP_API_URL + `product/deactivate/${productId}`,
        );
        dispatch(setProductStateToClear());
        SuccessNotification(response);
      }
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

// export const createProductMaster = (values, params, setModalOpen) => {
//   return async (dispatch) => {
//     dispatch(setProductStateToFetching());
//     try {
//       const response = await httpClient.post(
//         process.env.REACT_APP_API_URL_V2 + "product/master",
//         values,
//       );
//       dispatch(setProductStateToClear());
//       dispatch(getProductMaster(params));
//       setModalOpen(false);
//       return SuccessMessage(response);
//     } catch (error) {
//       dispatch(setProductStateToFailed());
//       ErrorNotification(error);
//     }
//   };
// };

export const createProductMasterPrivate = (values, params, setModalOpen) => {
  return async (dispatch) => {
    dispatch(setProductStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_V2 + "product/master?type=private",
        values,
      );
      dispatch(setProductStateToClear());
      dispatch(getProductMaster(params));
      setModalOpen(false);
      return SuccessMessage(response);
    } catch (error) {
      dispatch(setProductStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const createProductMaster = (values, params = null) => {
  return async (dispatch) => {
    dispatch(setCreateProductMasterStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + `product/master${paramsString}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCreateProductMasterStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const productMaster = (params = null) => {
  return async (dispatch) => {
    dispatch(setProductMasterStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `product/master${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;

      dispatch(setProductMasterStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setProductMasterStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const productMasterDropdown = (params = null) => {
  return async (dispatch) => {
    dispatch(setProductMasterDropdownToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `product/master${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;

      dispatch(setProductMasterDrowndownToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      ErrorNotification(error);
      return false;
    }
  };
}

export const loadMoreProductMaster  = (params = null) =>  {
  return async (dispatch) => {
    dispatch(setProductMasterStateToFetching());

    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `product/master${paramsString}`,
      );
      const { data } = response.data;

      dispatch(appendProductMaster(data))
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setProductMasterStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const clearState = () => {
  return (dispatch) => {
    dispatch(setProductStateToClear());
  };
};

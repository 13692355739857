import React, { useState } from "react";
import { ConfigProvider, Col, Form, InputNumber, Modal, Row, Switch, Typography, Space } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { generalPriceRoundFormula, getCurrentRole } from "../../../utils/Functions";
import { UserRoles } from "../../../constants";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ImageCustom from "../../atoms/Image";
import "./AddSkuMasterModal.scss";

const { Text, Title } = Typography;

const ListItem = ({ title = "-", value = "-" }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={6}>
        <Text>{t(title)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={17}>
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

const CustomFormInputNumber = ({ name, label, rules, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Form.Item name={name} label={<Text strong>{t(label)}</Text>} rules={rules}>
      <InputNumber className="full-width" size="large" min={0} placeholder={0} {...rest} />
    </Form.Item>
  );
};

export const formatter = (value) => {
  if (value === null || value === undefined) {
    return "";
  }
  const number = typeof value === "string" ? parseFloat(value.replace(/\D/g, "")) : value;
  if (isNaN(number)) {
    return "";
  }
  return number.toLocaleString("id-ID");
};

export const parser = (value) => {
  return value.replace(/\D/g, "");
};

export const parserPercentage = (value) => {
  return value ? Number(value.replace(/[^0-9.,-]/g, "").replace(",", ".")) : "";
};

const AddSkuMasterModal = ({
  modalOpen,
  setModalOpen,
  isLoading,
  data: {
    id,
    image,
    name,
    category,
    subCategory,
    principal,
    brand,
    packType,
    size,
    variant,
    uom,
    uomLabel,
  },
  handleSubmit = () => {},
}) => {
  const userRole = getCurrentRole();
  const { t } = useTranslation();
  const newPrincipal = principal ?? "-";
  const newCategory = subCategory ? `${category} > ${subCategory}` : `${category}`;
  const newBrand = brand ?? "-";
  const product = name ? `${name} ${variant}` : "-";
  const newSize = packType ?? "-";
  const qty = uomLabel ?? "_";
  const weight = size ? `${size} ${uom}` : "-";
  const newVariant = variant ?? "-";

  const [form] = Form.useForm();
  const [basePrice, setBasePrice] = useState(0);
  const [isGenerateTier, setIsGenerateTier] = useState(false);

  const handleChangeBasePrice = (value) => {
    const { margin } = form.getFieldsValue();
    const newSellingPrice = generalPriceRoundFormula(value + (value * margin) / 100);
    form.setFieldsValue({ sellingPrice: newSellingPrice });
    setBasePrice(value);
  };

  const handleChangeMargin = (value) => {
    const { basePrice } = form.getFieldsValue();
    const newSellingPrice = generalPriceRoundFormula(basePrice + (basePrice * value) / 100);
    form.setFieldsValue({ sellingPrice: newSellingPrice });
  };

  const handleChangeSellingPrice = (value) => {
    const { basePrice } = form.getFieldsValue();
    let newMargin = Number((((value - basePrice) * 100) / basePrice).toFixed(2));
    if (newMargin < 0) newMargin = 0;
    form.setFieldsValue({ margin: newMargin });
  };

  const handleFinish = (values) => {
    let newValues = { ...values, productId: id};
    if (userRole !== UserRoles.BASKIT_ADMIN) {
      newValues.sellingPrice = 0;
      newValues.margin = 0;
    }
    handleSubmit(newValues);
  };

  const handleAfterClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      className="sku-master-modal"
      title={<Title level={5}>{t("addProduct")}</Title>}
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      afterClose={handleAfterClose}
      width={700}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Row gutter={12} className="pl-10 pr-10">
        <Col span={8}>
          <div className="sku-master-modal__image">
            <ImageCustom width={200} height={200} src={image} />
          </div>
        </Col>
        <Col span={16}>
          <Row>
            <Col span={24}>
              <Space className="full-width" direction="vertical" size={0}>
                <div className="sku-master-modal__principal">
                  <Text strong>{newPrincipal}</Text>
                </div>
                <Text className="sku-master-modal__category" type="secondary">
                  {newCategory}
                </Text>
                <Text strong>{newBrand}</Text>
                <div className="sku-master-modal__product">
                  <Text className="ant-typography-ellipsis-multiple-line">{product}</Text>
                </div>
              </Space>
            </Col>
            <Col span={24} className="mt-10">
              <Space className="full-width sku-master-modal__detail" direction="vertical" size={0}>
                <Text strong>Detail</Text>
                <ListItem title="size" value={newSize} />
                <ListItem title="qtyOrContent" value={qty} />
                <ListItem title="weight" value={weight} />
                <ListItem title="variant" value={newVariant} />
              </Space>
            </Col>

            <Col span={24} className="mt-10">
              <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Row gutter={12}>
                  <Col span={12}>
                    <CustomFormInputNumber
                      name="basePrice"
                      label={userRole === UserRoles.BASKIT_ADMIN ? "basePrice" : "price"}
                      prefix="Rp."
                      rules={[
                        {
                          required: true,
                          message: `Please enter your ${
                            userRole === UserRoles.BASKIT_ADMIN ? "base price" : "price"
                          }`,
                        },
                      ]}
                      formatter={formatter}
                      parser={parser}
                      onChange={handleChangeBasePrice}
                    />

                    {userRole === UserRoles.BASKIT_ADMIN && (
                      <CustomFormInputNumber
                        name="sellingPrice"
                        label="sellingPrice"
                        prefix="Rp."
                        rules={[
                          { required: true, message: "Please enter your selling price" },
                          {
                            type: "number",
                            min: basePrice,
                            message: t(
                              "The selling price (tier 1) must not be lower than the base price.",
                            ),
                          },
                        ]}
                        formatter={formatter}
                        parser={parser}
                        onChange={handleChangeSellingPrice}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    <CustomFormInputNumber
                      name="stock"
                      label="stock"
                      rules={[{ required: true, message: "Please enter your stock" }]}
                      formatter={formatter}
                      parser={parser}
                    />

                    {userRole === UserRoles.BASKIT_ADMIN && (
                      <CustomFormInputNumber
                        name="margin"
                        label="margin"
                        addonAfter="%"
                        parser={parserPercentage}
                        rules={[{ required: true, message: "Please enter your margin" }]}
                        onChange={handleChangeMargin}
                      />
                    )}
                  </Col>
                  
                  {userRole === UserRoles.BASKIT_ADMIN && (
                    <Col span={24}>
                      <Form.Item>
                        <Space>
                          <ConfigProvider theme={{ token: { colorPrimary: "#03ac00" } }}>
                            <Form.Item name="generateTier" noStyle>
                                <Switch onChange={setIsGenerateTier} />
                            </Form.Item>
                          </ConfigProvider>

                          <Text className={isGenerateTier ? "sku-master-modal__switch-wholesale-price-label": ""}>
                            Add Wholesale Pricing
                          </Text>
                        </Space>
                      </Form.Item>
                      
                      {isGenerateTier && (
                        <Form.Item className="sku-master-modal__alert-message">
                          <ExclamationCircleFilled className="sku-master-modal__alert-message-warning"/>
                          <Text className="ml-10">
                            Prices will automatically be divided into four tiers with a 0.05% margin difference.
                          </Text>
                        </Form.Item>
                      )}

                      <Form.Item 
                        name="moq" 
                        initialValue={1}
                        rules={[
                          { required: true, message: "Please enter your minimum order" },
                          { type: 'number', 
                            min: 1, max: isGenerateTier ? 5 : 998, 
                            message: isGenerateTier ? 'Minimum 1 and maximum 5 orders':'Minimum 1 and maximum 998 orders'
                          },
                        ]}
                      >
                        <InputNumber className="w-full" size="large" addonBefore="Min. Order Quantity" />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24}>
                    <ButtonPrimary text="Add Product" loading={isLoading} block htmlType="submit" />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddSkuMasterModal;

import { decode } from "base-64";
import { server } from "../constants";
import { isEmpty } from "lodash-es";
import { Message } from "./Message";
// import jwt from "jsonwebtoken";
import i18next from "i18next";

const periodDateRegex = /\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}/;

/**
 * @function isSingleDay
 * @description Check if period is single day.
 * @param {PeriodType} period - period to be checked.
 * @returns {boolean}
 */
export const isSingleDay = (period) => {
  if ((period === "today") | (period === "yesterday")) return true;
  if (periodDateRegex.test(period)) {
    const [dateStart, dateEnd] = period.split("-");
    return dateStart === dateEnd;
  }
  return false;
};

export const getSerialNumber = (currentPage, index) => {
  const _index = index + 1;
  const number = currentPage === 1 ? 0 : parseInt(currentPage - 1 + "0");
  return number + _index;
};

export const getUserDataFromToken = () => {
  let accessToken = localStorage.getItem(server.TOKEN_KEY);
  if (accessToken === undefined || accessToken === "undefined") return {};
  if (accessToken) {
    var base64Url = accessToken.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      decode(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  }
  return {};
};

export const kFormatter = (number) => {
  if (!Number.isInteger(number)) return 0;

  var SI_SYMBOL = ["", "K", "M", "B", "T", "P", "E"];
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if zero, we don't need a suffix
  if (tier === 0) return number;
  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);
  // scale the number
  var scaled = number / scale;
  // format number and add suffix
  return scaled.toFixed(1) + suffix;
  // return Math.abs(num) > 999
  //   ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
  //   : Math.sign(num) * Math.abs(num);
};

export const handleValidationDocument = (file, maxSize, typeUpload) => {
  const isLt2M = file.size / 1024 / 1024 <= maxSize;
  const imageTypes = ["image/png", "image/jpg", "image/jpeg"];
  const pdfFile = ["application/pdf"];
  const xlsxFile = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  const csvFile = ["text/csv"];

  if (typeUpload === "image") {
    if (!imageTypes.includes(file.type)) {
      Message("error", "Type of document uploaded must be .jpg or .png format!");
      return false;
    }
  }

  if (typeUpload === "pdf") {
    if (!pdfFile.includes(file.type)) {
      Message("error", "Type of document uploaded must be .pdf format!");
      return false;
    }
  }

  if (typeUpload === "xlsx") {
    if (!xlsxFile.includes(file.type)) {
      Message("error", "Type of document uploaded must be .xlsx format!");
      return false;
    }
  }

  if (typeUpload === "csv") {
    if (!csvFile.includes(file.type)) {
      Message("error", "Type of document uploaded must be .csv format!");
      return false;
    }
  }

  if (!isLt2M) {
    Message("error", "Size of document entered must smaller than 2MB!");
    return false;
  }

  return true;
};

export const formatRupiah = (nominal) => {
  if (nominal === 0 || nominal === undefined || nominal === null) return "Rp 0";
  return nominal.toLocaleString("id-ID", { style: "currency", currency: "IDR" });
};

const rupiahSuffix = ["", "rb", "jt", "M", "T"];

/** @type {(nominal:number|string,prefix:string,significant:number) => string} */
export const scientificRupiahFormat = (nominal, prefix = "", minLength = 2) => {
  if (isNaN(nominal)) {
    throw new Error("nominal input is not numeric");
  }
  const nominalString = nominal.toString();
  let suffixIndex = 0;
  let trimmed = nominalString;
  while (trimmed.length > 3) {
    suffixIndex++;
    trimmed = trimmed.slice(0, -3);
  }
  const suffix = rupiahSuffix[suffixIndex];
  const padding =
    trimmed.length < nominalString.length && trimmed.length < minLength
      ? `,${nominalString.slice(trimmed.length, minLength)}`
      : "";
  console.log({ prefix, trimmed, padding, suffix });
  return `${prefix}${trimmed}${padding} ${suffix}`;
};

// Old Code
// export const formatRupiah = (angka = 0) => {
//   if (angka) {
//     var number_string = angka
//         .toString()
//         .replace(/[^,\d]/g, "")
//         .toString(),
//       split = number_string.split(","),
//       sisa = split[0].length % 3,
//       rupiah = split[0].substring(0, sisa),
//       ribuan = split[0].substring(sisa).match(/\d{3}/gi);

//     if (ribuan) {
//       let separator = sisa ? "." : "";
//       rupiah += separator + ribuan.join(".");
//     }

//     return (rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah);
//   }
//   return angka;
// };

export const checkInputIsNumber = (value) => {
  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(value) || value === "" || value === "-") return true;
  return false;
};

export const toSentenceCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeFirstLetter = (str) => {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
};

export const replaceSymbolsWithSpace = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, " ");
};

export const isArrayNotEmpty = (data) => {
  if (Array.isArray(data) || typeof data === "string") {
    return data.length !== 0;
  } else {
    return undefined;
  }
};

export const checkPercentage = (value) => {
  if (typeof value !== "string" || isNaN(parseFloat(value))) {
    return "0%";
  }

  const parsedValue = parseFloat(value);
  if (parsedValue >= 0 && parsedValue <= 100) {
    return parsedValue + "%";
  } else {
    return "0%";
  }
};

export const parsePhoneNumber = (input) => {
  input = input.trim();
  let countryCodeRegex = /^\+(\d{2})/;
  let matches = input.match(countryCodeRegex);

  if (matches && matches.length > 1) {
    let countryCode = matches[1];
    let number = input.slice(matches[0].length);
    let result = {
      code: "+" + countryCode,
      number: number,
    };
    return result;
  } else {
    return null;
  }
};

export const parseCountdownTimer = (expireTimestamp, setCountdownTimer) => {
  if (expireTimestamp === "") return { status: "paid", time: "00:00:00" };

  const currentTime = new Date();
  const targetTime = new Date(expireTimestamp);

  if (targetTime > currentTime) {
    let totalSeconds = Math.floor((targetTime - currentTime) / 1000);

    let interval = setInterval(function () {
      let hours = Math.floor(totalSeconds / 3600); // Menghitung jam
      let minutes = Math.floor((totalSeconds % 3600) / 60); // Menghitung menit
      let seconds = totalSeconds % 60; // Menghitung detik

      // Format waktu dalam format hh:mm:ss
      let time =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds;

      // Tampilkan waktu pada konsol atau lakukan sesuatu dengan nilai waktu
      setCountdownTimer(`${time}`);

      if (totalSeconds <= 0) {
        clearInterval(interval); // Hentikan perhitungan mundur jika waktu sudah habis
      }

      totalSeconds--; // Kurangi total waktu sebesar 1 detik
    }, 1000); // Perbarui setiap 1 detik (1000 milidetik)
  } else setCountdownTimer("00:00:00");
};

export const isLoggedIn = () => {
  let isTokenExists = localStorage.getItem(server.TOKEN_KEY) ? true : false;
  return isTokenExists;
};

export const isTokenExpired = (token) => {
  // if (token) {
  //   let decodedToken = jwt.decode(token, { complete: true });
  //   let dateNow = new Date();

  //   if (decodedToken === null) return false;
  //   if (decodedToken.payload.exp < dateNow.getTime() / 1000) return false;
  //   else {
  //     Cookies.set(server.TOKEN_KEY, token);
  //     return true;
  //   }
  // } else return false;
  return true;
};

export const toCheckLoginToken = (tokenParams) => {
  let tokenLocalStorage = localStorage.getItem(server.TOKEN_KEY);
  let token = tokenParams ? tokenParams : tokenLocalStorage;
  return isTokenExpired(token);
};

export function parseParamsString(obj) {
  if (isEmpty(obj)) return "";

  let params = Object.entries(obj)
    .flatMap(([key, value]) =>
      Array.isArray(value) ? value.map((newValue) => [key, newValue]) : [[key, value]],
    )
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `?${params}`;
}

export const formatRupiahNotRounded = (amount) => {
  const amountString = String(amount);
  const [wholePart, decimalPart] = amountString.split(".");
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const formattedAmount = decimalPart
    ? `Rp. ${formattedWholePart},${decimalPart}`
    : `Rp. ${formattedWholePart}`;

  return formattedAmount;
};

export const generalPriceRoundFormula = (input) => {
  // round to integer
  const value = Math.round(input);
  // Extract the last digit of the value
  const lastDigit = value % 10;

  // Check if the last digit is in the range 1-4
  // if (lastDigit >= 1 && lastDigit <= 4) {
  //   // If yes, subtract the last digit
  //   return value - lastDigit;
  // } 
  if (lastDigit >= 5 && lastDigit <= 9) {
    // If not, add the difference between 10 and the last digit
    return value + (10 - lastDigit);
  } else {
    // If the last digit is not between 1-9 (e.g., 0), return the original input
    return value;
  }
};

export const downloadCSV = (csvData, csvName = "data.csv") => {
  const blob = new Blob([csvData], { type: "text/csv" });

  // Create URL for the blob
  const blobUrl = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", csvName); // Set the file name for download

  // Add the link to the document and trigger a click event
  document.body.appendChild(link);
  link.click();

  // Remove the link after the download process completes
  document.body.removeChild(link);
};

export const parseDayToDate = (period) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const lastWeekStart = new Date(today);
  lastWeekStart.setDate(today.getDate() - ((today.getDay() + 6) % 7) - 7);
  const lastWeekEnd = new Date(today);
  lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  switch (period) {
    case "today":
      return {
        start: `${formatDate(today)}T00:00:00+0700`,
        end: `${formatDate(today)}T23:59:59+0700`,
      };
    case "yesterday":
      return {
        start: `${formatDate(yesterday)}T00:00:00+0700`,
        end: `${formatDate(yesterday)}T23:59:59+0700`,
      };
    case "lastweek":
      return { start: formatDate(lastWeekStart), end: formatDate(lastWeekEnd) };
    case "lastmonth":
      return { start: formatDate(lastMonthStart), end: formatDate(lastMonthEnd) };
    default:
      return null;
  }
};

export const t = (key) => {
  return i18next.t(key);
};

export const filterParams = (params) => {
  if (!params) return null;
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => {
      if (Array.isArray(value)) return !isEmpty(value);
      else return value !== undefined && value !== null && value !== "";
    }),
  );
};

export const convertArrayToCSV = (arr) => {
  const csv = arr.map((row) => row.join(",")).join("\n");
  return csv;
};

export const mapLegacyReciptPayload = (newPayload) => {
  const PERIOD = {
    YEARLY: "yearly",
    MONTHLY: "monthly",
  };

  const payload = {
    storeName: newPayload.storeName,
    storeAddress: newPayload.storeAddress,
    storePhone: newPayload.storePhone,
    storeInitial: newPayload.storeInitial,
    receiptNumber: newPayload.isShowReceipt,
    resetPeriod: PERIOD[newPayload.resetPeriod],
    size: `${newPayload.size}mm`,
    _id: newPayload.id,
  };

  return payload;
};

export const mapNewReciptPayload = (legacyPayload) => {
  const PERIOD = {
    yearly: "YEARLY",
    monthly: "MONTHLY",
  };

  const payload = {
    storeName: legacyPayload.storeName,
    storeAddress: legacyPayload.storeAddress,
    storePhone: legacyPayload.storePhone,
    storeInitial: legacyPayload.storeInitial,
    isShowReceipt: legacyPayload.receiptNumber,
    resetPeriod: PERIOD[legacyPayload.resetPeriod],
    size: parseInt(legacyPayload.size, 10),
  };

  return payload;
};

export const formatPhoneNumberWithSpaces = (phoneNumber) => {
  const numericOnly = phoneNumber.replace(/\D/g, "");

  if (numericOnly.length === 0) {
    return phoneNumber;
  }

  let formattedNumber = `+${numericOnly.substring(0, 3)} `;
  let i = 3;

  while (i < numericOnly.length) {
    const remainingDigits = numericOnly.length - i;
    const groupLength = remainingDigits > 4 ? 4 : remainingDigits;
    formattedNumber += `${numericOnly.substring(i, i + groupLength)} `;
    i += groupLength;
  }

  return formattedNumber.trim();
};

export const splitCountryCodeAndPhoneNumber = (phoneNumber) => {
  const regex = /(\+\d{2})(\d+)/;
  const match = regex.exec(phoneNumber);

  if (match) {
    const result = {
      countryCode: match[1],
      phone: match[2],
    };

    return result;
  } else return null;
};

export const getCurrentRole = () => {
  return localStorage.getItem(server.USER_ROLE);
}

export const getUserId = () => {
  return localStorage.getItem(server.USER_ID);
}

export const digitFormat = (amount) => {
  if (!amount) return "0";

  const amountString = String(amount);
  const [wholePart, decimalPart] = amountString.split(".");
  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const formattedAmount = decimalPart
    ? `${formattedWholePart},${decimalPart}`
    : formattedWholePart;

  return formattedAmount;
};

export const roundToTwoDecimals = (number)  => {
  if (!number) return 0;
  return Math.round(number * 100) / 100;
}
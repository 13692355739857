import React, { useEffect, useState } from "react";
import { debounce } from "lodash-es";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

const FormItemSelect = ({
  label,
  name,
  size,
  placeholder,
  rules,
  disabled = false,
  showSearch = true,
  filterOption = true,
  options = [],
  handleSearch = () => {},
  handleChange = () => {},
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [isOnSearch, setIsOnSearch] = useState(false);

  const handleFilterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (!isOnSearch) return;
    const debouncedAction = debounce(() => {
      handleSearch(searchValue);
    }, [500]);

    debouncedAction();
    return () => debouncedAction.cancel();
  }, [searchValue, isOnSearch]);

  return (
    <Form.Item label={t(label)} name={name} rules={rules}>
      <Select
        placeholder={t(placeholder)}
        size={size}
        disabled={disabled}
        options={options}
        showSearch={showSearch}
        allowClear
        filterOption={filterOption ? handleFilterOption : false}
        onSearch={(value) => {
          setSearchValue(value);
          setIsOnSearch(true);
        }}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default FormItemSelect;

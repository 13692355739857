import {
  CATEGORY_FETCHING,
  CATEGORY_SUCCESS,
  CATEGORY_FAILED,
  CATEGORY_CLEAR,
  CATEGORY_OPTIONS_FETCHING,
  CATEGORY_OPTIONS_SUCCESS,
  CATEGORY_OPTIONS_FAILED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  isOptionsFetching: false,
  isOptionsError: false,
  options: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CATEGORY_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case CATEGORY_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case CATEGORY_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case CATEGORY_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case CATEGORY_OPTIONS_FETCHING:
      return { ...state, isOptionsFetching: true, isOptionsError: false, options: null };
    case CATEGORY_OPTIONS_FAILED:
      return { ...state, isOptionsFetching: false, isOptionsError: true, options: null };
    case CATEGORY_OPTIONS_SUCCESS:
      return { ...state, isOptionsFetching: false, isOptionsError: false, options: payload };
    default:
      return state;
  }
};

import React from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";

const { Text } = Typography;

const Password = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const password = form.getFieldValue("password");
    const dataToSend = { password };
    console.log("data to send: ", dataToSend);
    // no action to change password
  };

  return (
    <div id='my-account-password-section'>
      <Row gutter={12}>
        <Col span={24}>
          <Text strong>{t("Change Password")}</Text>
        </Col>
        <Col span={24} className='mt-10'>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            layout='horizontal'
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item
              name='password'
              label={t("Password")}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  max: 250,
                  message: "The password input is limited to a maximum of 250 characters!",
                },
              ]}
              hasFeedback
            >
              <Input.Password size='large' />
            </Form.Item>

            <Form.Item
              name='confirm'
              label={t("Confirm Password")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  max: 250,
                  message: "The password input is limited to a maximum of 250 characters!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The two passwords that you entered do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <ButtonPrimary text='Save Changes' className='float-right' htmlType='submit' />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Password;

import { Image } from "antd";
import React from "react";

const ImageCustom = ({
  className = "",
  height = "",
  width = "",
  src,
  alt,
  preview = false,
  handleClick,
}) => {
  const defaultImage = `${window.location.origin}/images/baskit-product.jpg`;
  const image = src ? src : defaultImage;

  return (
    <Image
      className={className}
      height={height}
      width={width}
      preview={preview}
      alt={alt}
      src={image}
      fallback={defaultImage}
      onClick={handleClick}
    />
  );
};

export default ImageCustom;

import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Select, Row, Col } from "antd";
import { debounce } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import * as salesActions from "../../../actions/sales.action";
import * as salesUnassignedActions from "../../../actions/salesunassigned.action";
import "./AssignCompany.scss";

const { Option } = Select;

const AssignCompany = ({ id }) => {
  const dispatch = useDispatch();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const salesUnassigned = useSelector((state) => state.salesUnassignedReducer);

  useEffect(() => {
    dispatch(salesUnassignedActions.index(`type=UNASSIGNED&$limit=10&search=${searchString}`, id));
  }, [searchString]);

  useEffect(() => {
    if (salesUnassigned?.result != null) {
      setLoading(false);
    }

    if (salesUnassigned.result?.data) {
      const options = salesUnassigned.result?.data?.map((item) => ({
        value: item.id,
        label: item.companyName,
      }));
      setCompanyOptions(options);
    }
  }, [salesUnassigned]);

  const handleSubmit = async () => {
    setSearchString("");
    setCompanyId();
    setIsLoading(true);

    const isSuccess = await dispatch(
      salesActions.assignUserToCompany(
        {
          userId: id,
          companyId,
          role: "Salesman",
        },
        id,
      ),
    );
    if (isSuccess) setIsLoading(false);
    else setIsLoading(false);
  };

  const debounceSearch = useRef(
    debounce(async (criteria) => {
      setSearchString(criteria ?? "");
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  const handleSearch = (value) => {
    debounceSearch(value);
  };

  const renderOption = (option) => (
    <Option
      key={option.value}
      value={option.value}
      label={option.label}
      onMouseDown={(e) => e.preventDefault()}
    >
      <span className="label">{option.label}</span>
    </Option>
  );

  return (
    <div className="assign-sales">
      <Row gutter={12}>
        <Col span={16}>
          <Select
            showSearch
            className="select"
            style={{ width: "100%" }}
            placeholder="Search store name"
            filterOption={false}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            value={companyId}
            onChange={setCompanyId}
            onSearch={(value) => handleSearch(value)}
          >
            {companyOptions.map((option) => renderOption(option))}
          </Select>
        </Col>
        <Col span={8}>
          <ButtonPrimary
            className={!companyId && "disabled-button"}
            disabled={!companyId ? true : false}
            text="Add"
            loading={isLoading}
            block={true}
            icon={<PlusOutlined />}
            handleClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AssignCompany;

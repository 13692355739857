import React from "react";
import { Card, Col, Image, Row, Typography } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import './InvoiceCard.scss';

const { Title } = Typography;

const InvoiceCard = ({ title, isLoading, handleClick }) => {
  return (
    <Card
      hoverable
      id="invoice-card"
    >
      <Row>
        <Col span={24} className="text-center">
          <Title level={5}>{title}</Title>
        </Col>
        <Col span={24} className="mt-10">
          <div className="image-wrapper">
            <Image
              width="100%"
              height={200}
              preview={false}
              src={`${window.location.origin}/images/pdf-icon.svg`}
            />
          </div>
        </Col>
        <Col span={24} className="mt-20">
          <ButtonPrimary text="Download" block={true} loading={isLoading} icon={<CloudDownloadOutlined />} handleClick={handleClick} />
        </Col>
      </Row>
    </Card>
  )
}

export default InvoiceCard;
import React from "react";
import { Button, Card, Col, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { formatRupiah, toSentenceCase } from "../../../utils/Functions";
import "./EditOrderProductPOSCard.scss";

const { Text } = Typography;

const EditOrderProductPOSCard = ({ data, handleClick }) => {
  const { t } = useTranslation(); 
  const handleSubmit = () => {
    let dataToSend = {
      _id: data?._id,
      name: data?.name,
      stock: data?.stock,
      price: data?.price,
      sellingPrice: data?.sellingPrice,
      created: data?.created,
      qty: 1,
      tier: {},
      uomLabel: data?.uoms?.label,
    };
    handleClick(dataToSend) 
  };

  return (
    <>
      <Card className={`product-pos-card ${data?.isAlreadyCart && 'cart-item'}`} key={data?._id} hoverable>
        <Row>
          <Col span={24}>
            <div className='card-image-wrapper'>
              <Image
                width='100%'
                height={200}
                preview={false}
                src={process.env.REACT_APP_API_URL + "images/product/" + (data.image ? data.image : "default.jpg")}
                fallback={process.env.REACT_APP_API_URL + "images/product/default.jpg"}
              />
            </div>
          </Col>

          <Col span={24}>
            <div className='card-title'>
              <Text className='ant-typography-ellipsis-multiple-line'>{data?.name}</Text>
            </div>
          </Col>

          <Col span={12}>
            <div className='ml-10'>
              <div>
                <Text strong>{formatRupiah(data.price ? data.price : 0)}</Text>
              </div>
              {/* <div>
                <Text className='font-size-12' type='secondary' delete>
                  {formatRupiah(data.price ? data.price : 0)}
                </Text>
              </div> */}
            </div>
          </Col>
          <Col span={12}>
            <div className='card-right-content-wrapper'>
              <div className='text-right mr-10'>
                <div>
                  <Text strong className='font-size-12'>
                    {t("Stock")} : {data.stock ? data.stock : 0}
                  </Text>
                </div>
                {/* <div>
                  <Text className='font-size-12'>
                    {t("Packaging")} : {data.uoms ? toSentenceCase(data.uoms.label) : "-"}
                  </Text>
                </div> */}
              </div>
            </div>
          </Col>

          <Col span={24} className='text-right'>
            <Text className='font-size-12 mr-10'>
              {t("Packaging")} : {data.uoms ? toSentenceCase(data.uoms.label) : "-"}
            </Text>
          </Col>

          <Col span={24}>
            <div className='card-button-wrapper'>
              { data?.isAlreadyCart 
                ? <p className="cart-item-label">{t("alreadyAdded")}</p>
                : <Button
                    type='default'
                    htmlType='button'
                    block
                    className={(data?.stock <= 0 || data?.isActive === false) ? "button-disabled" : ''}
                    disabled={(data?.stock <= 0 || data?.isActive === false) ? true : false}
                    onClick={handleSubmit}
                  >
                    {t("Buy")}
                  </Button>
              }
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default EditOrderProductPOSCard;

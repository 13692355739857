import {
  USER_FETCHING,
  USER_SUCCESS,
  USER_LIST_SUCCESS,
  USER_FAILED,
  USER_CLEAR,
  USER_ME_FETCHING,
  USER_ME_SUCCESS,
  USER_ME_FAILED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  data: null,
  options: null,
  isUserMeFetching: false,
  isUserMeError: false,
  userMe: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case USER_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case USER_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case USER_LIST_SUCCESS:
      return { ...state, isFetching: false, isError: false, data: payload };
    case USER_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case USER_ME_FETCHING:
      return { ...state, isUserMeFetching: true, isUserMeError: false, userMe: null };
    case USER_ME_FAILED:
      return { ...state, isUserMeFetching: false, isUserMeError: true, userMe: null };
    case USER_ME_SUCCESS:
      return { ...state, isUserMeFetching: false, isUserMeError: false, userMe: payload };
    default:
      return state;
  }
};

import {
  SALES_UNASSIGNED_CLEAR,
  SALES_UNASSIGNED_FAILED,
  SALES_UNASSIGNED_FETCHING,
  SALES_UNASSIGNED_SUCCESS,
} from "../constants";
import { httpClient } from "../utils/HttpClient";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setSalesStateToFetching = () => ({
  type: SALES_UNASSIGNED_FETCHING,
});
export const setSalesStateToFailed = () => ({
  type: SALES_UNASSIGNED_FAILED,
});
export const setSalesStateToClear = () => ({
  type: SALES_UNASSIGNED_CLEAR,
});
export const setSalesStateToSuccess = (payload) => ({
  type: SALES_UNASSIGNED_SUCCESS,
  payload,
});

export const index = (values, id) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/user/companies/${id}?${values}`
      );
      dispatch(setSalesStateToSuccess(response.data));
      // dispatch(Index());
      SuccessNotification(response);
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorNotification(error);
    }
  };
};

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterParams } from "../utils/Functions";
import * as companyActions from "../actions/company.action";

const useBuyerData = (params) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { buyers } = useSelector(({ companyReducer }) => companyReducer);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const isSuccess = await dispatch(companyActions.buyers(filterParams(params)));
    if (isSuccess) setIsLoading(false);
    else setIsLoading(false);
  }, [dispatch, params]);

  useEffect(() => {
    fetchData();
  }, [dispatch, params]);

  useEffect(() => {
    if (buyers) {
      const { data, totalData, totalPage } = buyers;
      setData(data);
      setTotalData(totalData);
      setTotalPage(totalPage);
    }
  }, [buyers]);

  return { data, totalData, totalPage, isLoading };
};

export default useBuyerData;

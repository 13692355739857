// @ts-check
import { Button, Form, Input, Radio, Segmented, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ReceiptActions from '../../actions/receipt.action';
import MainContent from '../../components/organisms/Content/Content';
import ReceiptPreview from './ReceiptPreview';
import './ReceiptSetting.scss';
import useLoggedInUser from '../../hooks/useLoggedInUser';

const ReceiptSetting = () => {
  /** @type {[ import('antd').FormInstance<BaskitApp.ReceiptSettings> ]} */
  const [ form ] = Form.useForm();
  const [ selectedSize, setSelectedSize ] = useState(/** @type {BaskitApp.Size} */ ('80mm'));
  const [ receiptNumber, setReceiptNumber ] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useLoggedInUser();

  const { data } = useSelector((
    /** @type {AppReducer.Reducer} */
    { receiptReducer }
  ) => receiptReducer);

  useEffect(() => {
    form.setFieldsValue(data ?? {});
    setSelectedSize(data?.size ?? selectedSize);
    setReceiptNumber(data?.receiptNumber ?? true);
  }, [ data ]);

  useEffect(() => {
    dispatch(ReceiptActions.getUserReceiptSettings(companyId));
  }, [companyId])

  const onSave = useCallback(() => {
    dispatch(ReceiptActions.saveUserReceiptSettings(form.getFieldsValue(), companyId));
  }, [companyId]);

  return (
    <MainContent
      headerProps={{
        title: t('Receipt Settings'),
        description: t('Manage receipt view') ?? '',
      }}
    >
      <Form className='flex' form={form}>
        <div id="receipt-settings-container">
          <h3>{t('Receipt View')}</h3>
          <label className="required">{t('Store Name')}</label>
          <Form.Item name="storeName">
            <Input showCount minLength={1} maxLength={20} />
          </Form.Item>
          <label className="required">{t('Address')}</label>
          <Form.Item name="storeAddress">
            <Input showCount minLength={1} maxLength={80} />
          </Form.Item>
          <label>{t('Store Phone Number')}</label>
          <Form.Item name="storePhone">
            <Input showCount minLength={1} maxLength={12} />
          </Form.Item>
          <h3>{t('Receipt Number Format')}</h3>
          <div className="flex-between">
            <label>{t('Show_1')}</label>
            <Form.Item name="receiptNumber" valuePropName="checked">
              <Switch defaultChecked onChange={setReceiptNumber} />
            </Form.Item>
          </div>
          <Form.Item name="resetPeriod">
            <Radio.Group className="flex-between" disabled={!receiptNumber}>
              <Radio value="yearly">{t('Yearly Reset')}</Radio>
              <Radio value="monthly">{t('Monthly Reset')}</Radio>
            </Radio.Group>
          </Form.Item>
          <div className="grid grid-col-fr-1-2 grid-col-gap-1 grid-row-gap-1">
            <label>{t('Store Initial')}</label>
            <label>{t('Receipt Number Format')}</label>
            <Form.Item name="storeInitial">
              <Input maxLength={3} disabled={!receiptNumber} />
            </Form.Item>
            <Input disabled value={`00001-(${t('Tanggal transaksi')})`}/>
          </div>
          <ol>
            <li>{t('Inisial toko untuk nomor struk maksimal 3 huruf.')}</li>
            <li>{t('Format struk akan menyesuaikan tanggal yang sedang berjalan.')}</li>
            <li>{t('Nomor struk akan menyesuaikan dengan jumlah transaksi yang dibuat.')}</li>
            <li>{t('Nomor struk akan di-reset kembali sesuai pilihan per Tahun/Bulan.')}</li>
          </ol>
          <div className="grid grid-col-fr-1-1 grid-col-gap-1">
            <Button ghost type="primary">{t('Batal')}</Button>
            <Button type="primary" onClick={onSave}>{t('Simpan')}</Button>
          </div>
        </div>
        <div id="receipt-preview-container" className="flex-grow">
          <h3>{t('Preview')}</h3>
          { data?.size &&
            <Segmented
              defaultValue={data.size}
              options={[
                {
                  label: '58mm',
                  value: '58mm',
                },
                {
                  label: '80mm',
                  value: '80mm',
                },
                {
                  label: '210mm',
                  value: '210mm',
                },
              ]}
              onChange={(value) => setSelectedSize(/** @type {BaskitApp.Size} */ (value))}
              onResize={undefined}
              onResizeCapture={undefined}
            />
          }
          <div id="preview-container">
            <ReceiptPreview {...(data ?? {})} size={selectedSize} />
          </div>
          <Form.Item name="size">
            <Radio.Group className="flex-center">
              <Radio value="58mm"
                className={`${ selectedSize === '58mm' ? '' : 'hide'}`}
              >
                {t('Atur sebagai ukuran utama')}
              </Radio>
              <Radio value="80mm"
                className={`${ selectedSize === '80mm' ? '' : 'hide'}`}
              >
                {t('Atur sebagai ukuran utama')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
    </MainContent>
  );
}

export default ReceiptSetting;
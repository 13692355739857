import { Input, Select, Image, Form } from "antd";
import { formatRupiah } from "../../../utils/Functions";

const { TextArea } = Input;

function Card({
  onChangeQty,
  onChangePrice,
  onChangeReason,
  onChangeNotes,
  onCancel,
  changedItem,
  field,
  item,
  errorField,
  reason,
  diff,
  isReset,
}) {
  const enableReason = diff && Array.isArray(diff) && diff.length > 0;

  if (!field) {
    return null;
  }

  return (
    <div className="editOrderCard flex-between">
      <div className="editOrderCard__left">
        <div className="product flex">
          <Image
            className="product"
            width={90}
            height={90}
            preview={false}
            src={item.image ?? `${window.location.origin}/images/baskit-product.jpg`}
            fallback={`${window.location.origin}/images/baskit-product.jpg`}
          />
          <div>
            <p className="title">{item.name}</p>
            <p className="description flex">
              <span className="key">Nilai Margin:</span>
              <div className="value flex">
                <span className="margin">
                  {formatRupiah(
                    item.originalMarginValue
                  )}
                </span>
                {changedItem?.marginValue !== item.originalMarginValue ? (
                  <>
                    <img src={`${process.env.PUBLIC_URL}/images/long-arrow-left.svg`} alt="change" />
                    <span className="changedMargin">{formatRupiah(changedItem?.marginValue)}</span>
                  </>
                ) : null}
              </div>
            </p>
          </div>
        </div>
        <div className="original flex">
          <p className="title flex flex-between">Original</p>
          <div className="quantity flex">
            <span className="key">{'Qty: '}</span> 
            <span className="value">{item?.initial?.initialQty}</span>
          </div>
          <div className="price flex flex-between">
            <span className="key">{'Harga: '}</span> 
            <span className="value">{formatRupiah(item?.initial?.initialPrice)}</span>
          </div>
          <div className="total flex">
            <span className="key">{'Total: '}</span> 
            <span className="value">{formatRupiah(item?.initial?.initialPrice * item?.initial?.initialQty)}</span>
          </div>
        </div>
        <div className="changes flex">
          <p className="title flex flex-between">Perubahan</p>
          <div className="quantity flex flex-between">
            <Input value={changedItem?.qty || 0} min={0} step={1} type="number" style={{ width: 80, padding: 8 }} onChange={onChangeQty} />
          </div>
          <div className="price flex flex-between">
            <Input value={changedItem?.sellingPrice || 0} min={0} step={1} type="number" prefix="Rp. " style={{ width: 140, padding: 8 }} onChange={onChangePrice} />
          </div>
          <div className="total flex">
            <span className="value">{formatRupiah(changedItem?.sellingPrice * changedItem?.qty)}</span>
          </div>
        </div>
        {errorField.length > 0 ? (
          <div className="flex errorField">
            <img width={20} height={20} src={`${process.env.PUBLIC_URL}/images/exclaimation-circle-red.svg`} alt="cancel" />
            Silahkan lengkapi alasan perubahan dan catatan
          </div>
        ) : null}
      </div>
      <div className="editOrderCard__right flex">
        <div className="reasons">
          <Form.Item
            style={{ width: 300, marginBottom: 20 }}
            name={[field.name, 'reasonId']}
            rules={[{ required: enableReason, message: " " }]}
            initialValue={changedItem?.reasonId}
          >
            <Select
              disabled={!enableReason}
              options={reason.map((reasonItem) => ({ value: reasonItem.id, label: reasonItem.name }))}
              placeholder="Pilih alasan perubahan"
              onChange={onChangeReason}
            />
          </Form.Item>
          <Form.Item
            style={{ width: 300, marginBottom: 20 }}
            name={[field.name, 'notes']}
            initialValue={changedItem?.notes}
          >
            <TextArea
              placeholder="Catatan"
              disabled={!enableReason}
              autoSize={{ minRows: 2, maxRows: 4 }}
              onChange={onChangeNotes}
            />
          </Form.Item>
        </div>
        <div className="action">
          {changedItem?.qty === 0 && changedItem?.sellingPrice === 0 ? (
            <img src={`${process.env.PUBLIC_URL}/images/trash-highlighted.svg`} alt="cancel" onClick={onCancel} />
          ): (
            <img src={`${process.env.PUBLIC_URL}/images/trash.svg`} alt="cancel" onClick={onCancel} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Card;
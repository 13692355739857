import {
  CUSTOMER_FETCHING,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILED,
  CUSTOMER_CLEAR,
  CUSTOMER_OPTIONS_SUCCESS,
  CUSTOMER_ORDER_HISTORY,
  CUSTOMER_TYPE_SUCCESS,
  CREATE_CUSTOMER_OFFLINE_FETCHING,
  CREATE_CUSTOMER_OFFLINE_FAILED,
  CUSTOMER_OFFLINE_FETCHING,
  CUSTOMER_OFFLINE_FAILED,
  CUSTOMER_OFFLINE_SUCCESS,
  CUSTOMER_OFFLINE_TYPE_FETCHING,
  CUSTOMER_OFFLINE_TYPE_FAILED,
  CUSTOMER_OFFLINE_TYPE_SUCCESS,
  CUSTOMER_OFFLINE_DETAILS_FETCHING,
  CUSTOMER_OFFLINE_DETAILS_FAILED,
  CUSTOMER_OFFLINE_DETAILS_SUCCESS,
} from "../constants";
import swal from "sweetalert";
import { httpClient } from "../utils/HttpClient";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { parseParamsString } from "../utils/Functions";
import { SuccessMessage } from "../utils/Message";

export const setCustomerStateToFetching = () => ({
  type: CUSTOMER_FETCHING,
});

export const setCustomerStateToFailed = () => ({
  type: CUSTOMER_FAILED,
});
export const setCustomerStateToClear = () => ({
  type: CUSTOMER_CLEAR,
});
export const setCustomerStateToSuccess = (payload) => ({
  type: CUSTOMER_SUCCESS,
  payload,
});
export const setCustomerOrderHistory = (payload) => ({
  type: CUSTOMER_ORDER_HISTORY,
  payload,
});
export const setCustomerOptionStateToSuccess = (payload) => ({
  type: CUSTOMER_OPTIONS_SUCCESS,
  payload,
});

export const setCustomerTypeStateToSuccess = (payload) => ({
  type: CUSTOMER_TYPE_SUCCESS,
  payload,
});

export const setCreateCustomerOfflineStateToFetching = () => ({
  type: CREATE_CUSTOMER_OFFLINE_FETCHING,
});

export const setCreateCustomerOfflineStateToFailed = () => ({
  type: CREATE_CUSTOMER_OFFLINE_FAILED,
});

export const setCustomerOfflineStateToFetching = () => ({
  type: CUSTOMER_OFFLINE_FETCHING,
});

export const setCustomerOfflineStateToFailed = () => ({
  type: CUSTOMER_OFFLINE_FAILED,
});

export const setCustomerOfflineStateToSuccess = (payload) => ({
  type: CUSTOMER_OFFLINE_SUCCESS,
  payload,
});

export const setCustomerOfflineTypeStateToFetching = () => ({
  type: CUSTOMER_OFFLINE_TYPE_FETCHING,
});

export const setCustomerOfflineTypeStateToFailed = () => ({
  type: CUSTOMER_OFFLINE_TYPE_FAILED,
});

export const setCustomerOfflineTypeStateToSuccess = (payload) => ({
  type: CUSTOMER_OFFLINE_TYPE_SUCCESS,
  payload,
});

export const setCustomerOfflineDetailsStateToFetching = () => ({
  type: CUSTOMER_OFFLINE_DETAILS_FETCHING,
});

export const setCustomerOfflineDetailsStateToFailed = () => ({
  type: CUSTOMER_OFFLINE_DETAILS_FAILED,
});

export const setCustomerOfflineDetailsStateToSuccess = (payload) => ({
  type: CUSTOMER_OFFLINE_DETAILS_SUCCESS,
  payload,
});

//

// export const getFinance = async (id) => {
//   const response = await httpClient.get(process.env.REACT_APP_API_URL + "finance/customer");
//   if (response.data.result == "success") {
//     return response.data.data;
//     // dispatch(setCustomerStateToSuccess(response.data.data));
//   } else if (response.data.result === "error") {
//     // dispatch(setCustomerStateToFailed());
//     swal("Error!", response.data.message, "error");
//   }
// };

export const getFinance = () => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "finance/customer");
      dispatch(setCustomerStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getSalesPeople = () => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/salespeople");
      dispatch(setCustomerStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getCustomerType = () => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/type");
      dispatch(setCustomerTypeStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const GetCustomerOptions = () => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer");
    if (response.data.result === "success") {
      let data = [{ value: null, label: "-- Create New --" }];
      for (let i = 0; i < response.data.data.length; i++) {
        const { _id, name } = response.data.data[i];
        data.push({
          value: _id,
          label: name,
        });
      }
      dispatch(setCustomerOptionStateToSuccess(data));
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getSingleCustomer2 = async (id) => {
  // dispatch(setCustomerStateToFetching());
  const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/" + id);
  if (response.data.result === "success") {
    return response.data.data;
    // dispatch(setCustomerStateToSuccess(response.data.data));
  } else if (response.data.result === "error") {
    // dispatch(setCustomerStateToFailed());
    swal("Error!", response.data.message, "error");
  }
};

export const OrderHistory = (customerId) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.get(
      process.env.REACT_APP_API_URL + "customer/order-history/" + customerId,
    );
    if (response.data.result === "success") {
      dispatch(setCustomerOrderHistory(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const Index = () => {
//   return async (dispatch) => {
//     dispatch(setCustomerStateToFetching());
//     const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer");
//     if (response.data.result === "success") {
//       dispatch(setCustomerStateToSuccess(response.data.data));
//     } else if (response.data.result === "error") {
//       dispatch(setCustomerStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const Index = (params = null) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + `customer${paramsString}`,
      );
      dispatch(setCustomerStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const Index2 = () => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/group");
    if (response.data.result === "success") {
      dispatch(setCustomerStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const Create = (values, history) => {
//   return async (dispatch) => {
//     dispatch(setCustomerStateToFetching());
//     const response = await httpClient.post(process.env.REACT_APP_API_URL + "customer", values);
//     if (response.data.result === "success") {
//       dispatch(setCustomerStateToSuccess(response.data));
//       swal("Success!", response.data.message, "success").then((value) => {
//         dispatch(setCustomerStateToClear());
//         history.goBack();
//         dispatch(Index());
//       });
//     } else if (response.data.result === "error") {
//       dispatch(setCustomerStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const Create = (values, history) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "customer", values);
      dispatch(setCustomerStateToClear());
      SuccessNotification(response);
      history.goBack();
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const createFromPOS = (values) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "customer", values);
      dispatch(setCustomerStateToClear());
      SuccessNotification(response);
      dispatch(Index());
      SuccessMessage(response);
      return {
        isSuccess: true,
        data: {
          name: values.name,
          noPhone: values.noPhone,
        },
      };
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false };
    }
  };
};

export const Invite = (values) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.post(
      process.env.REACT_APP_API_URL + "customer/send-email-invite",
      values,
    );
    if (response.data.result === "success") {
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setCustomerStateToClear());
        dispatch(Index());
      });
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const getSingleCustomer = (id) => {
//   return async (dispatch) => {
//     dispatch(setCustomerStateToFetching());
//     const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/" + id);
//     if (response.data.result === "success") {
//       dispatch(setCustomerStateToSuccess(response.data.data));
//     } else if (response.data.result === "error") {
//       dispatch(setCustomerStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const getSingleCustomer = (id) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/" + id);
      dispatch(setCustomerStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCustomerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const Update = (values, history) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.put(process.env.REACT_APP_API_URL + "customer", values);
    if (response.data.result === "success") {
      dispatch(setCustomerStateToClear());
      history.goBack();
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const Remove = (id) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + "customer/" + id);
    if (response.data.result === "success") {
      dispatch(setCustomerStateToSuccess());
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getCustomers = async () => {
  const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer");
  if (response.data.result === "success") {
    return response.data.data;
  } else if (response.data.result === "error") {
    swal("Error!", response.data.message, "error");
  }
};

export const customerActivation = (token) => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.get(
      process.env.REACT_APP_API_URL + "customer/activation/" + token,
    );
    if (response.data.result === "success") {
      dispatch(setCustomerStateToClear());
      dispatch(setCustomerStateToSuccess({ success: true }));
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
    }
  };
};

export const getActiveCustomer = () => {
  return async (dispatch) => {
    dispatch(setCustomerStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "customer/active");
    if (response.data.result === "success") {
      dispatch(setCustomerStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setCustomerStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const createCustomerOffline = (values) => {
  return async (dispatch) => {
    dispatch(setCreateCustomerOfflineStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + "user-offline",
        values,
      );
      const { firstName, phone } = response.data.data;
      SuccessMessage(response);
      return {
        isSuccess: true,
        data: { firstName, phone },
      };
    } catch (error) {
      dispatch(setCreateCustomerOfflineStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false, data: {} };
    }
  };
};

export const customerOffline = (values) => {
  return async (dispatch) => {
    dispatch(setCustomerOfflineStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + "user-offline",
        values,
      );
      dispatch(setCustomerOfflineStateToSuccess(response.data.data));
      return true;
    } catch (error) {
      dispatch(setCustomerOfflineStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const customerOfflineType = () => {
  return async (dispatch) => {
    dispatch(setCustomerOfflineTypeStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + "company/type?type=CATEGORY",
      );
      dispatch(setCustomerOfflineTypeStateToSuccess(response.data.data));
      return true;
    } catch (error) {
      dispatch(setCustomerOfflineTypeStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const customerOfflineDetails = (id) => {
  return async (dispatch) => {
    dispatch(setCustomerOfflineDetailsStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `user-offline/${id}`,
      );
      dispatch(setCustomerOfflineDetailsStateToSuccess(response.data.data));
      return true;
    } catch (error) {
      dispatch(setCustomerOfflineDetailsStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tag, Typography, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import ImageCustom from "../../atoms/Image";
import "./SkuMasterCard.scss";

const { Text, Paragraph } = Typography;

const CustomTag = ({ text, color }) => {
  return (
    <Tag className="sku-master-card__tag" color={color}>
      <Text ellipsis style={{ maxWidth: 80 }}>
        {text}
      </Text>
    </Tag>
  );
};

const CustomText = ({ text, maxWidth = 200, ...rest }) => {
  return (
    <Text {...rest} ellipsis style={{ maxWidth }}>
      {text}
    </Text>
  );
};

const SkuMasterCard = ({
  data: {
    id,
    image,
    name,
    fullName,
    category,
    subCategory,
    isAdded,
    principal,
    brand,
    packType,
    size,
    variant,
    uom,
    uomLabel,
  },
  selectedProductIDs,
  handleCheckboxProduct,
  selectShow,
  handleClick,
}) => {
  const { t } = useTranslation();

  const newPackType = packType ?? "-";
  const newPrincipal = principal ?? "-";
  const newCategory = subCategory ? `${category} > ${subCategory}` : category ? `${category}` : "-";
  const newBrand = brand ?? "-";
  const product = name ? `${name} ${variant}` : "-";
  const newSize = size ? `${size}${uom}` : "-";
  const pieces = uomLabel ?? "-";
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(selectedProductIDs.includes(id) ? true : false);
  }, [selectedProductIDs, id]);

  const handleChecked = () => {
    const newIsChecked = !isChecked;
    const newData = { id, image, fullName, packType };
    setIsChecked(newIsChecked);
    handleCheckboxProduct(newIsChecked, id, newData);
  };

  return (
    <Card
      key={id}
      hoverable={!isAdded}
      className={`sku-master-card ${isAdded && "sku-master-card--disabled"} ${
        !isAdded && isChecked && "sku-master-card--selected"
      }`}
      onClick={
        !isAdded && !selectShow ? handleClick : !isAdded && selectShow ? handleChecked : () => {}
      }
    >
      <Row>
        <Col span={24}>
          {selectShow && !isAdded && (
            <div className="sku-master-card__checkbox">
              <Checkbox checked={isChecked} defaultChecked={isChecked}>
                {t("Select")}
              </Checkbox>
            </div>
          )}

          <div className="sku-master-card__pack-type">
            <CustomText strong text={newPackType} maxWidth={100} />
          </div>
          {isAdded && (
            <div className="sku-master-card__block">
              <div className="sku-master-card__label">
                <Text>{t("productAdded")}</Text>
              </div>
            </div>
          )}

          <div className="sku-master-card__image-wrapper">
            <div className="sku-master-card__principal">
              <CustomText strong text={newPrincipal} />
            </div>
            <ImageCustom className="sku-master-card__image" width="100%" height={200} src={image} />
          </div>
        </Col>

        <Col span={24}>
          <div className="sku-master-card__content">
            <Row>
              <Col span={24}>
                <CustomText
                  className="sku-master-card__category"
                  type="secondary"
                  text={newCategory}
                />
              </Col>
              <Col span={24}>
                <CustomText className="sku-master-card__brand" strong text={newBrand} />
              </Col>
              <Col span={24}>
                <div className="sku-master-card__product">
                  <Paragraph ellipsis={{ rows: 2 }}>{product}</Paragraph>
                </div>
              </Col>
              <Col span={24}>
                <CustomTag text={newSize} color={"#FFDFB9"} />
                <CustomTag text={pieces} color={"#94F6F3"} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default SkuMasterCard;

import { ClockCircleOutlined } from "@ant-design/icons";
import { Col, Divider, List, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EDITABLE_ORDER_STATUS, GOOGLE_MAP_SEARCH_URL, OrderStatus, OrderType, UserRoles, WA_SHARE_URL, server } from "../../../constants";
import { formatRupiah, getCurrentRole, toSentenceCase } from "../../../utils/Functions";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import CartEmpty from "../../atoms/CartEmpty/CartEmpty";
import SpinLoaderContent from "../../atoms/SpinLoaderContent/SpinLoaderContent";
import { UpdateStatusCell } from "../../organisms/UpdateStatusCell";
// import TagCustom from "../../atoms/TagCustom/TagCustom";
import { WhatsAppOutlined } from "@ant-design/icons";
import * as orderActions from "../../../actions/order.action";
import useHistoryData from "../../../hooks/useHistoryData";
import ButtonCustom from "../../atoms/ButtonCustom/ButtonCustom";
import MainBreadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import AdjustedDetailListItem from "../../molecules/PaymentDetailListItem/AdjustedDetailListItem";
import PaymentDetailListItem from "../../molecules/PaymentDetailListItem/PaymentDetailListItem";
import UpdateOrdersStatusModal from "../../molecules/UpdateOrdersStatusModal/UpdateOrdersStatusModal";
import MainContent from "../../organisms/Content/Content";
import DeliveryDetail from "../../organisms/DeliveryDetail/DeliveryDetail";
import EditOrder from "../../organisms/EditOrder";
import "./_detail.scss";

const defaultHeaderProps = {
  title: "Order Detail",
  buttonBack: { text: "Order Detail", status: true },
  description: "Order Detail",
};

const { Text } = Typography;

const OrderDetail = ({ headerProps = defaultHeaderProps }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(OrderStatus.ORDER_CREATED.value);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [enableEdit, setEnableEdit] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const currentRole = getCurrentRole();
  const isBaskitAdmin = currentRole === UserRoles.BASKIT_ADMIN;

  const order = useSelector(
    ({ orderReducer }) => orderReducer,
  );

  const { newStatus, selectedOrders, detail: orderDetails } = order;

  const params = useMemo(() => ({ $order: "createdAt", $sort: "ASC", refId: id }), [id]);
  const orderHistory = useHistoryData(params);

  useEffect(() => {
    dispatch(orderActions.getOrderDetail(id));
  }, []);

  const refetch = useCallback(async () => {
    dispatch(orderActions.getOrderDetail(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (orderDetails) {
      setData(orderDetails);
      setPaymentStatus(orderDetails?.orderStatus ?? "");
    }
    setLoading(false);
  }, [orderDetails]);

  useEffect(() => {
    if (selectedOrders) {
      const { orderId, status } = selectedOrders[0];
      setSelectedOrder({ orderId, status });
    }
  }, [selectedOrders]);

  const setOrderDetails = (isNew = true) => {
    const values = { ...data, orderStatus: newStatus };
    if (isNew) dispatch(orderActions.setOrderDetailsStateToSuccess(values));
    else dispatch(orderActions.setOrderDetailsStateToSuccess(data));
  };

  const handleUpdateStatus = async (values) => {
    setOrderDetails();
    setIsLoading(true);

    let isSuccess = false;
    if (currentRole === 'ADMIN') {
      isSuccess = await handleUpdateStatusWithDefaultDeliveryType(values);
    } else {
      isSuccess = await dispatch(orderActions.updateStatus(selectedOrder?.orderId, values))
    }

    if (!isSuccess) setOrderList(false);
    setIsLoading(false);
    dispatch(orderActions.setUpdateOrderStatusClear());
    dispatch(orderActions.getOrderDetail(id));
  };

  const handleUpdateStatusWithDefaultDeliveryType = useCallback(async (values) => {
    const newStatus = values.orderStatus;
    if (newStatus === 'ORDER_PICKED_UP') {
      const defaultValues = {
        orderStatus: newStatus,
        deliveryType: 'SELLER_DELIVERY',
        estimatePickupDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS Z")
      };

      return dispatch(orderActions.updateStatus(selectedOrder?.orderId, defaultValues))
    }

    return dispatch(orderActions.updateStatus(selectedOrder?.orderId, values))
  }, [currentRole, selectedOrder]);



  const handleUpdateStatusToWaiting = async (fields) => {
    const { estimatePickupDate, deliveryType } = fields;
    const values = {
      orderStatus: newStatus,
      estimatePickupDate,
      deliveryType,
    };

    handleUpdateStatus(values);
  };

  const handleUpdateStatusToCancel = async (fields) => {
    const { reasonId, reasonDescription } = fields;
    let values = { orderStatus: newStatus, reasonId };
    if (reasonDescription) values.reasonDescription = reasonDescription;

    handleUpdateStatus(values);
  };

  const parentLink = isBaskitAdmin ? "/transaction-management" : "/order-list/"

  const mainBreadcrumbItems = [
    {
      link: parentLink,
      title: "Order List",
    },
    {
      title: "Order Detail",
    },
  ];

  return (
    <MainContent headerProps={headerProps}>
      <div id="order-detail-template-wrapper">
        <div className="mb-10">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div id="order-detail-content">
          <Row gutter={12}>
            <Col span={14}>
              <div id="order-detail-user-information">
                {loading ? (
                  <SpinLoaderContent />
                ) : (
                  <>
                    <ListItem label="Order ID" value={data?.orderCode ?? ""} children={undefined} />
                    <ListItem
                      label="Date"
                      value={dayjs(data?.createdAt).format("DD MMM YYYY")}
                      children={undefined}
                    />
                    <ListItem
                      label="Merchant Name"
                      value={data?.companyName ?? ""}
                      children={undefined}
                    />
                    <ListItem
                      label="paymentMethod"
                      value={toSentenceCase(data?.paymentType?.name ?? "")}
                      children={undefined}
                    />
                    <ListItem label="orderStatus" value={undefined}>
                      <UpdateStatusCell
                        className="w-1/2 inline-block"
                        initialStatus={paymentStatus}
                        orderId={id}
                        deliveryType={data?.deliveryType}
                      />
                      <ClockCircleOutlined className="ml-10" />
                      <span className="ml-10">
                        {dayjs(data?.updatedAt).format("DD MMMM YYYY") +
                          " | " +
                          dayjs(data?.updatedAt).format("HH:mm:ss")}
                      </span>
                    </ListItem>
                  </>
                )}
              </div>

              {isBaskitAdmin && (
                <CustomerDetail isBaskitAdmin={isBaskitAdmin} data={data?.customer} />
              )}

              <div id="order-detail-orders" className="mt-10">
                <Row>
                  <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <Space direction="horizontal">
                      <Text strong>{t("Order")}</Text>
                      <div className="number-order">
                        <Text strong>{data?.orderDetail?.length ?? 0}</Text>
                      </div>
                      <div className="canceled-order">
                        {data?.orderDetail && data?.orderDetail?.filter((item => item.qty === 0)).length > 0 ? (
                          <Text>{`${data?.orderDetail.filter((item => item.qty === 0)).length} produk dibatalkan` }</Text>
                        ): null}
                      </div>
                    </Space>
                    {EDITABLE_ORDER_STATUS.includes(paymentStatus) && isBaskitAdmin ? (
                      <Space direction="horizontal" style={{cursor: 'pointer'}} onClick={() => {
                        window?.parent?.postMessage(
                          {
                            type: 'editOrder',
                            payload: {
                              id,
                            }, 
                          },
                          process.env.REACT_APP_NEW_CMS
                        )
                      }}>
                        <img width={20} height={20} style={{ marginTop: -4 }} src={`${process.env.PUBLIC_URL}/images/pencil-square.svg`} />
                        <Text className="edit-Order">{t('EditOrder')}</Text>
                      </Space>
                    ) : null}
                  </Col>
                  <Col span={24}>
                    <div id="order-detail-list">
                      {loading ? (
                        <SpinLoaderContent />
                      ) : !isEmpty(data?.orderDetail) ? (
                        <List
                          id="shopping-cart-order-list"
                          itemLayout="horizontal"
                          dataSource={data?.orderDetail}
                          renderItem={(item) => {
                            return currentRole === 'BASKIT_ADMIN' ? (
                              <AdjustedDetailListItem
                                key={item.id}
                                data={item}
                              />
                            ) : (
                              <PaymentDetailListItem
                                key={item.id}
                                data={item}
                                isDeleteDisabled={true}
                              />
                            );
                          }}
                        />
                      ) : (
                        <CartEmpty />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={10}>
              {isBaskitAdmin ? (
                <DeliveryDetail orderHistory={orderHistory?.data ?? []} />
              ) : (
                <CustomerDetail isBaskitAdmin={isBaskitAdmin} data={data?.customer} />
              )}

              <div id="order-detail-summary">
                <Text strong>{t("Shopping Summary")}</Text>
                <Row gutter={12} className="mt-10">
                  <Col span={12}>
                    <Text>
                      {t("totalProductPrice", { amount: data?.orderDetail?.length ?? 0 })}
                    </Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(data?.subTotal ?? 0)}</Text>
                  </Col>
                  <Col span={12}>
                    <Text>{t("Total discounts")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(data?.discount ?? 0)}</Text>
                  </Col>
                  <Col span={24}>
                    <Divider className="order-detail-divider" />
                  </Col>
                  <Col span={12}>
                    <Text>{t("Ongkos Kirim")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    {data?.orderType === OrderType.SHOP.value ? (
                      <Text delete={data?.subTotal >= 1000000 ? true : false}>
                        {formatRupiah(data?.shippingCost ?? 0)}
                      </Text>
                    ) : (
                      <Text>{formatRupiah(0)}</Text>
                    )}
                  </Col>
                  <Col span={24}>
                    <Divider className="order-detail-divider" />
                  </Col>
                  <Col span={12} className="flex-center-align">
                    <Text strong>{t("totalPayment")}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong className="order-detail-title float-right">
                      {formatRupiah(data?.grandTotal ?? 0)}
                    </Text>
                  </Col>
                </Row>
              </div>

              <Row gutter={12}>
                <Col span={24} className="mt-20 mb-10">
                  <ButtonPrimary
                    className={
                      ((paymentStatus === OrderStatus.ORDER_CANCELLED.value ||
                        paymentStatus === OrderStatus.ORDER_PENDING.value) &&
                        "button-disabled") ||
                      ""
                    }
                    disabled={
                      paymentStatus === OrderStatus.ORDER_CANCELLED.value ||
                      paymentStatus === OrderStatus.ORDER_PENDING.value
                        ? true
                        : false
                    }
                    block={true}
                    text="viewInvoice"
                    handleClick={() => history.push(`/transaction-management/invoice/${id}`)}
                  />
                </Col>
                <Col span={24} className="mt-10">
                  <ButtonDefault block={true} text="Back" handleClick={() => {
                    history.push(parentLink);
                  }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <UpdateOrdersStatusModal
        isLoading={isLoading}
        refetch={refetch}
        currentRole={currentRole}
        handleUpdateStatusToWaiting={handleUpdateStatusToWaiting}
        handleUpdateStatusToCancel={handleUpdateStatusToCancel}
        handleUpdateStatus={() => handleUpdateStatus({ orderStatus: newStatus })}
      />
      <EditOrder orderId={id} open={enableEdit} data={data} onCancel={() => setEnableEdit(false)} />
    </MainContent>
  );
};

const CustomerDetail = ({ isBaskitAdmin, data }) => {
  const { t } = useTranslation();
  const currentRole = getCurrentRole();
  const googleMapAddress = `${GOOGLE_MAP_SEARCH_URL}?api=1&query=${encodeURIComponent(data?.address || 'Indonesia')}`;

  return (
    <div id="order-detail-customer">
      <div className="mb-20">
        <Text strong>{t("Order Details")}</Text>
      </div>
      <ListItem label="Customer Name" value={data?.name ?? ""} />
      {isBaskitAdmin && (
        <ListItem label={t("Store Name")} value={data?.storeName ?? ""} children={undefined} />
      )}
      <ListItem label="Address" value={data?.address ?? ""} />
      <ListItem label="Phone No." value={data?.noPhone ?? ""} />
      {currentRole === 'ADMIN' ? (
        <>
          <ButtonPrimary
            text="View Map"
            className="mr-10"
            handleClick={() => {
              window.open(googleMapAddress, '_blank');
            }}
          />
          <ButtonCustom
            icon={<WhatsAppOutlined />}
            color="#29A71A"
            text="Bagikan lokasi via Whatsapp"
            handleClick={() => {
              window.open(`${WA_SHARE_URL}?text=${encodeURIComponent(googleMapAddress)}`, '_blank');
            }}
          />
        </>
      ) : null}
    </div>
  );
};

const ListItem = ({ label, value, children }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-10">
      <Col span={6}>
        <Text strong>{t(label)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={17}>{value ? <Text>{value}</Text> : children}</Col>
    </Row>
  );
};

export default OrderDetail;

import React from "react";
import { UserRoles } from "../../constants";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import Home from "./home";
import BoaHome from "./boaHome";
import "./_style.scss";

const Index = () => {
  const { userRole } = useLoggedInUser();
  if (userRole === UserRoles.BASKIT_ADMIN) return <BoaHome />;
  if (userRole === UserRoles.ADMIN || userRole === UserRoles.BUYER) return <Home />;
};

export default Index;

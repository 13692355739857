import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { index } from "../actions/order.action";
import { filterParams } from "../utils/Functions";

const useOrderData = (params) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { result } = useSelector(({ orderReducer }) => orderReducer);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    dispatch(index(filterParams(params)));
  }, [dispatch, params]);

  const refetchData = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, params]);

  useEffect(() => {
    if (result) {
      const { data, totalData = 0, totalPage = 0 } = result;
      setData(data);
      setTotalData(totalData);
      setTotalPage(totalPage);
    }
    setIsLoading(false);
  }, [result, setData, setTotalData, setTotalPage, setIsLoading]);

  return { data, totalData, totalPage, isLoading, refetchData };
};

export default useOrderData;

import React, { useEffect, useMemo, useRef, useState, useContext  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, ConfigProvider, Form, InputNumber, Modal, Row, Space, Switch, Table, Typography } from "antd";
import { ExclamationCircleFilled, WarningFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { digitFormat, formatRupiahNotRounded, generalPriceRoundFormula, roundToTwoDecimals } from "../../../utils/Functions";
import { formatter, parser, parserPercentage } from "../AddSkuMasterModal/AddSkuMasterModal";
import _ from 'lodash';
import ImageCustom from "../../atoms/Image";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import * as pricetierActions from "../../../actions/pricetier.action";
import "./EditPriceTieringModal.scss";

const EditableContext = React.createContext(null);
const TableContext = React.createContext(null);
const { Title, Text } = Typography;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const setDataSourceByDataIndex = (dataIndex, record, values, setWarningMessage=undefined, handleGetDataSource=undefined) => {
  
  if(dataIndex === 'basePrice') {
    const basePrice = values[dataIndex];
    const sellingPrice = generalPriceRoundFormula(basePrice + (basePrice * record?.margin) / 100);
    values = {...values, sellingPrice}
  } 

  else if(dataIndex === 'margin') {
    const margin = values[dataIndex];
    const sellingPrice = generalPriceRoundFormula(record?.basePrice + (record?.basePrice * margin) / 100);
    values = {...values, sellingPrice}
  } 
  
  else if(dataIndex === 'sellingPrice') {
    const sellingPrice = values[dataIndex];
    let margin = roundToTwoDecimals(((sellingPrice - record?.basePrice) * 100) / record?.basePrice);
    values = {...values, margin}
  } 
  
  else if(dataIndex === 'status') {
    const { status } = values;
    if(status) {
      const { status: prevStatus, max: prevMax } = handleGetDataSource(record?.tierNo - 1);
      if(!prevStatus) {
        values = { status: false };
        setWarningMessage({
          isShow: true,
          message: 'Please activate wholesale prices from the lowest tier!'
        });
      }else {
        values = {...values, min: prevMax + 1};
        setWarningMessage({isShow: false, message: ''});
      } 
    }else {
      const nextRecord = handleGetDataSource(record?.tierNo + 1);
      const nextStatus = record?.tierNo === 4 ? false : nextRecord?.status;
      if(nextStatus) {
        values = { status: true };
        setWarningMessage({
          isShow: true,
          message: 'Please deactivate wholesale prices from the highest tier!'
        });
      }else setWarningMessage({isShow: false, message: ''});
    }
      
  }

  return values;
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const { setIsFieldsEditing } = useContext(TableContext);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      setIsFieldsEditing(true);
    }else setIsFieldsEditing(false);
  }, [editing]);

  const toggleEdit = () => {
    setEditing(true);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      let values = await form.validateFields();
      handleSave({ 
        ...record, 
        ...setDataSourceByDataIndex(dataIndex, record, values)});
      setEditing(false);
    } catch (errInfo) {
      console.log(`Update ${dataIndex} failed: `, errInfo);
    }
  };

  let childNode = children;
  let rules = [{ required: true, message: `Please Input your ${title}!`}];

  let inputNumberProps = {
    className: "w-full",
    parser,
    ref: inputRef,
    onPressEnter: save,
    onBlur: save
  }
  
  if(dataIndex === 'basePrice' || dataIndex === 'sellingPrice') {
    inputNumberProps = {...inputNumberProps, addonBefore: "Rp"}
  }

  if(dataIndex === 'sellingPrice' && record?.basePrice) {
    const min = record?.basePrice + (record?.basePrice * 0.01) / 100;
    rules.push({
      type: 'number',
      min,
      message: `Selling price must be greater than ${record?.basePrice}!`
    })
  }

  if(dataIndex == 'margin') {
    rules.push({
      type: 'number',
      min: 0.01,
      message: `Margin must be greater than 0!`
    })
    inputNumberProps = {...inputNumberProps, addonAfter: '%', parser: parserPercentage};
  }else inputNumberProps = {...inputNumberProps, formatter};

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={rules}
      >
        <InputNumber {... inputNumberProps}/>
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  } 
  return <td {...restProps}>{childNode}</td>;
};

const PriceTierEditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleGetDataSource,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { setWarningMessage, setIsFieldsEditing } = useContext(TableContext);
  const prevRecord = record === undefined ? {} : handleGetDataSource(record?.tierNo - 1); 
  
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      setIsFieldsEditing(true);
    }else setIsFieldsEditing(false);
  }, [editing]);
  
  useEffect(() => {
    if(record) {
      form.setFieldsValue({ 
        status: record['status'],
      });
    }

    if(dataIndex === 'margin' && record && prevRecord) {
      if((prevRecord?.margin <= record?.margin) && record?.status) {
        toggleEdit();
        handleSave({...record, isError: true});
      } 
    }
    
    if(dataIndex === 'max' && record) {
      if((record?.max <= record?.min) && record?.status) {
        toggleEdit();
        handleSave({...record, isError: true});
      } 
    }
  }, [record, prevRecord, dataIndex, form]);

  const toggleEdit = async () => {
    const errors =  form.getFieldsError();
    if(errors.length > 1) return;

    setEditing(true);
    let value = prevRecord !== undefined && dataIndex === 'min' 
      ? ( prevRecord?.max + 1 ) : record[dataIndex];
    form.setFieldsValue({
      [dataIndex]: value,
    });
  };

  const save = async () => {
    const errors =  form.getFieldsError();
    if(errors.length > 1 && dataIndex === 'status'){
      form.setFieldsValue({ status: true });
    }

    try {
      let values = await form.validateFields();
      handleSave({ 
        ...record, 
        ...setDataSourceByDataIndex(dataIndex, record, values, setWarningMessage, handleGetDataSource),
        isError: false,
      });
        
      if(dataIndex === 'max' && record?.tierNo < 4) {
        let nextRecord = handleGetDataSource(record?.tierNo + 1);
        const nextMin = values?.max + 1;
        if(nextRecord?.status) {
          handleSave({...nextRecord, min: nextMin});
        }
      }

      if(dataIndex === 'min' && record?.tierNo > 1) {
        const prevMax = values?.min > 0 ? values?.min - 1 : 0;
        handleSave({...prevRecord, max: prevMax});
      }

      setEditing(false);
    } catch (errInfo) {
      handleSave({...record, isError: true});
      console.log(`Update ${dataIndex} failed: `, errInfo);
    }
  };

  let childNode = children;
  let rules = [{ required: true, message: `Please Input your ${title}!`}];

  let inputNumberProps = {
    className: "w-full",
    parser,
    ref: inputRef,
    onPressEnter: save,
    onBlur: save
  }

  if(dataIndex === 'sellingPrice' && record?.basePrice) {
    const min = record?.basePrice + (record?.basePrice * 0.01) / 100;
    const max = prevRecord?.sellingPrice - (prevRecord?.sellingPrice * 0.01) / 100;
    rules.push({
      type: 'number',
      min,
      max,
      message: `Selling price must be greater than ${record?.basePrice} and less than ${prevRecord?.sellingPrice}!`
    });
    inputNumberProps = {...inputNumberProps, addonBefore: "Rp"};
  }

  if(dataIndex == 'margin') {
    rules.push({
      type: 'number',
      min: 0.01,
      max: prevRecord?.margin - 0.01,
      message: `Margin must be greater than 0 and less than ${prevRecord?.margin}!`
    })
    inputNumberProps = {...inputNumberProps, addonAfter: '%', parser: parserPercentage};
  }else inputNumberProps = {...inputNumberProps, formatter};
  
  if(dataIndex == 'max') {
    rules.push({
      type: 'number',
      min: record?.min + 1,
      message: `Max amount must be greater than min amount ${record?.min}!`
    })
  }

  const childrenOnlyList = ['margin', 'sellingPrice', 'status'];
  const isDisabledEditable =
    record?.key === '0' && childrenOnlyList.includes(dataIndex)|| 
    record?.key !== '0' && record?.status === false && dataIndex !== 'status';

  if (editable && !isDisabledEditable) {
    const formItemWithoutCellValueWrap = dataIndex === 'status';
    const formItemNode = ( 
      <Form.Item
        style={{ margin: 0}}
        name={dataIndex}
        rules={rules}
        validateTrigger={["onFocus", "onChange", "onBlur"]}
      >
        <InputNumber {... inputNumberProps}/>
      </Form.Item>
    );

    childNode = formItemWithoutCellValueWrap ? (
      <ConfigProvider theme={{ token: { colorPrimary: "#03ac00" } }}>
        <Form.Item name='status' valuePropName="checked" noStyle>
          <Switch ref={inputRef} onChange={save}/>
        </Form.Item>
      </ConfigProvider>
    ) : editing ? (
     formItemNode 
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  } 
  return <td {...restProps}>{childNode}</td>;
};

const originData = [];

for (let i = 0; i < 4; i++) {
  originData.push({
    key: i.toString(),
    tierNo: (i + 1),
    min: 0,
    max: 0,
    margin: 0,
    sellingPrice: 0,
    status: false,
  });
}

const WarningMessage = ({icon, message }) => {
  const iconNode = icon === 'warning' 
    ? <ExclamationCircleFilled className="price-tiering-modal__alert-message-warning"/>
    : <WarningFilled className="price-tiering-modal__alert-message-danger"/>;

 return (
    <div className="price-tiering-modal__alert-message">
      {iconNode}
      <Text className="ml-10">{message}</Text>
    </div>
 ) 
}

const EditPriceTieringModal = ({ modalOpen, setModalOpen, isLoading, details, handleSubmit }) => {
  const { id }  = details;
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState(null);
  const [priceTiersDataSource, setPriceTiersDataSource] = useState(null);
  const [basePrice, setBasePrice] = useState(0);
  const [margin, setMargin] = useState(0);
  const [warningMessage, setWarningMessage] = useState({ isShow: false, message: '' });
  const [isFieldsEditing, setIsFieldsEditing] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dataSourceRef = useRef(null);
  const priceTiersDataSourceRef = useRef(null);
  const dispatch = useDispatch();

  const isDirty = useMemo(() => {
    return _.isEqual(dataSource, dataSourceRef?.current) ? false : true;
  }, [dataSource, dataSourceRef?.current]);
  
  const isDirtyPriceTiers = useMemo(() => {
    return _.isEqual(priceTiersDataSource, priceTiersDataSourceRef?.current) ? false : true;
  }, [priceTiersDataSource, priceTiersDataSourceRef?.current]);

  const priceTiers = useSelector((state) => state.pricetierReducer);

  const isErrorPriceTiers = useMemo(() => {
    if(priceTiersDataSource !== null && priceTiersDataSourceRef !== null) {
      return _.isEmpty(priceTiersDataSource.find(item => item.isError === true)) ? false : true;
    }
    return false;
  }, [priceTiersDataSource, priceTiersDataSourceRef]);

  useEffect(() => {
    if((isDirty || isDirtyPriceTiers) && !isFieldsEditing && !isErrorPriceTiers) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [isDirty, isDirtyPriceTiers, isFieldsEditing, isErrorPriceTiers]);

  useEffect(() => {
    if(details){
      const { stock, basePrice, sellingPrice, margin } = details;
      const newDataSource = [{ key: '0', stock, basePrice, sellingPrice, margin }];
      setBasePrice(basePrice);
      setMargin(margin);
      setDataSource(newDataSource);
      dataSourceRef.current = newDataSource;
    }
  }, [details]);
  
  useEffect(() => {
    if(dataSource){
      const { basePrice, margin } = dataSource[0];
      setBasePrice(basePrice);
      setMargin(margin);
    }
  }, [dataSource]);

  useEffect(() => {
    if(id && modalOpen) dispatch(pricetierActions.index({ inventoryId: id }));
  }, [id, modalOpen]);

  const setDataSourceValues = (originDataSource, priceTiers, basePrice, margin) => {
    return originDataSource.map((priceTier)=> {
      const initialValues = priceTiers.find(data => data.tierNo === priceTier.tierNo);
      if(initialValues){
        const { min, max, margin: initialMargin } = initialValues;
        const newMargin =  roundToTwoDecimals(priceTier?.tierNo === 1 ? margin : initialMargin);
        const newSellingPrice = generalPriceRoundFormula(basePrice + (basePrice * newMargin) / 100);
        priceTier = { ...priceTier, min, max, margin: newMargin, sellingPrice: newSellingPrice, status: true, isError: false };
      }
      return {...priceTier, basePrice};
    });
  }

  useEffect(() => {
    if(!priceTiers?.result) return;
    priceTiersDataSourceRef.current = setDataSourceValues(originData, priceTiers?.result?.data, details?.basePrice, details?.margin);
  }, [originData, priceTiers, details])

  useEffect(() => {
    if(isLoading || !priceTiers?.result) return;
    const newDataSource = setDataSourceValues(originData, priceTiers?.result?.data, basePrice, margin);
    setPriceTiersDataSource(newDataSource);
  }, [originData, priceTiers, basePrice, margin, isLoading]);
  
  const defaultColumns = [
    {
      title: t('stock'),
      dataIndex: 'stock',
      editable: true,
      width: '20%',
      render: (text) => digitFormat(text)
    },
    {
      title: t('basePrice'),
      dataIndex: 'basePrice',
      editable: true,
      width: '30%',
      render: (text) => formatRupiahNotRounded(text)
    },
    {
      title: t('margin'),
      dataIndex: 'margin',
      editable: true,
      width: '20%',
      render: (text) => `${text}%`
    },
    {
      title: t('sellingPrice'),
      dataIndex: 'sellingPrice',
      editable: true,
      width: '30%',
      render: (text) => formatRupiahNotRounded(text)
    }
  ]

  const priceTiersDefaultColumns = [
    {
      title: 'No',
      dataIndex: 'tierNo',
      width: '5%',
    },
    {
      title: t("minAmount"),
      dataIndex: "min",
      width: '15%',
      editable: true,
      render: (text) => digitFormat(text)
    },
    {
      title: t("maxAmount"),
      dataIndex: "max",
      width: '15%',
      editable: true,
      render: (text) => digitFormat(text)
    },
    {
      title: t("margin"),
      dataIndex: "margin",
      width: '20%',
      editable: true,
      render: (text) => `${text}%`
    },
    {
      title: t("sellingPrice"),
      dataIndex: "sellingPrice",
      editable: true,
      render: (text) => formatRupiahNotRounded(text),
    },
    {
      title: 'Action',
      dataIndex: 'status',
      width: '10%',
      editable: true,
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const priceTiersComponents = {
    body: {
      row: EditableRow,
      cell: PriceTierEditableCell,
    },
  };

  const getDataSourceByTierNo = (tierNo, dataSource) => {
    let record = undefined;
    if(dataSource && tierNo) {
      record = dataSource.find((priceTier) => priceTier?.tierNo === tierNo);
    } 
    return record;
  }

  const setColumns = (columns, handleSave, handleGetDataSource=undefined) => {
    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          handleGetDataSource,
        }),
      };
    }); 
  }
  
  const setDataSourceByRow = (row, setDataSource) => {
    setDataSource(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
       ...item,
       ...row,
      });
      return newData;
    });
  }
  
  const handleSave = (row) => {
    setDataSourceByRow(row, setDataSource);
  }

  const handleSavePriceTiers = (row)  => {
    setDataSourceByRow(row, setPriceTiersDataSource);
  }
  
  const handleGetDataSource = (tierNo) => {
    return getDataSourceByTierNo(tierNo, priceTiersDataSource);
  }
  
  const columns = setColumns(defaultColumns, handleSave);
  const priceTiersColumns = setColumns(priceTiersDefaultColumns, handleSavePriceTiers, handleGetDataSource);

  const handleFinish = async () => {
    const { stock, basePrice, margin, sellingPrice } = dataSource[0];
    const basePriceTierValue = [{id, stock, basePrice, margin, sellingPrice}];
    const priceTiersValue = [...priceTiersDataSource]
      .filter(({status}) => status)
      .map(({ tierNo, min, max, margin, sellingPrice }) => ({ tierNo, min, max, margin, sellingPrice }));
    
    const isSuccess = await handleSubmit(id, isDirty, basePriceTierValue, priceTiersValue);
    if(isSuccess) {
      dataSourceRef.current = dataSource;
      priceTiersDataSourceRef.current = priceTiersDataSource;
      dispatch(pricetierActions.index({ inventoryId: id }));
      setModalOpen(false);
    }
  }

  const handleAfterClose = () => {
    setDataSource(dataSourceRef.current);
    setPriceTiersDataSource(priceTiersDataSourceRef.current);
    setWarningMessage({ isShow: false, message: '' });
    setIsFieldsEditing(false);
  }

  return (
    <Modal
      className="price-tiering-modal"
      title={<Title level={5}>{t("Price Tiering")}</Title>}
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={900}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      afterClose={handleAfterClose}
      destroyOnClose
    >
      <Row className="pl-10 pr-10" gutter={12}>
        <Col className="text-center" span={6}>
          <ImageCustom className="sku-image" width={180} height={180} alt="sku inventory image" />
        </Col>
        <Col span={17}>
          <div className="description">
            <ListItem label="SKU ID" value={details?.sku ?? "-"} />
            <ListItem label="SKU Name" value={details?.fullName ?? "-"} />
          </div>
        </Col>

        <Col span={24} className="mt-10">
          <TableContext.Provider value={{warningMessage, setWarningMessage, setIsFieldsEditing, modalOpen}}> 
            <Space direction="vertical" size="large" className="w-full">
              <Table 
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource} 
                columns={columns} 
                pagination={false} 
              />

              <WarningMessage icon="warning" message="Minimum order quantity for this SKU is specified in Minimum Qty. level 1." />

              <Table 
                components={priceTiersComponents}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={priceTiersDataSource} 
                columns={priceTiersColumns} 
                pagination={false} 
              />
              { warningMessage.isShow && (<WarningMessage icon="danger" message={warningMessage.message} />) }
            </Space>
          </TableContext.Provider>
        </Col>

        <Col className="mt-20" span={12} offset={12}>
          <ButtonPrimary 
            className={isButtonDisabled ? "disabled-button" : ""} 
            text="Save Change" 
            block 
            htmlType="button" 
            disabled={isButtonDisabled}   
            loading={isLoading} 
            handleClick={handleFinish}
          />
        </Col>
      </Row>
    </Modal>
  );
};

const ListItem = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-10">
      <Col span={7}>
        <Text strong>{t(label)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={16}>
        <Text>{value}</Text>
      </Col>
    </Row>
  );
};

export default EditPriceTieringModal;

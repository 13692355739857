import {
  SALES_DETAIL_CLEAR,
  SALES_DETAIL_FAILED,
  SALES_DETAIL_FETCHING,
  SALES_DETAIL_SUCCESS,
} from "../constants";
import { httpClient } from "../utils/HttpClient";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

  export const setSalesStateToFetching = () => ({
    type: SALES_DETAIL_FETCHING,
  });
  export const setSalesStateToFailed = () => ({
    type: SALES_DETAIL_FAILED,
  });
  export const setSalesStateToClear = () => ({
    type: SALES_DETAIL_CLEAR,
  });
  export const setSalesStateToSuccess = (payload) => ({
    type: SALES_DETAIL_SUCCESS,
    payload,
  });

  export const index = (id) => {
    return async (dispatch) => {
      dispatch(setSalesStateToFetching());
      try {
        const response = await httpClient.get(process.env.REACT_APP_API_URL_NEW_V2 + `users/${id}`);
        dispatch(setSalesStateToSuccess(response.data.data));
        SuccessNotification(response);
      } catch (error) {
        dispatch(setSalesStateToFailed());
        ErrorNotification(error);
      }
    };
  };

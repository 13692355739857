import {
  SUBCATEGORY_FETCHING,
  SUBCATEGORY_SUCCESS,
  SUBCATEGORY_FAILED,
  SUBCATEGORY_CLEAR,
  SUBCATEGORY_BY_CATEGORY_ID_FETCHING,
  SUBCATEGORY_BY_CATEGORY_ID_SUCCESS,
  SUBCATEGORY_BY_CATEGORY_ID_FAILED,
  SUBCATEGORY_OPTIONS_FETCHING,
  SUBCATEGORY_OPTIONS_SUCCESS,
  SUBCATEGORY_OPTIONS_FAILED,
} from "../constants";
import swal from "sweetalert";
import { httpClient } from "../utils/HttpClient";
import { parseParamsString } from "../utils/Functions";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setSubcategoryStateToFetching = () => ({
  type: SUBCATEGORY_FETCHING,
});
export const setSubcategoryStateToFailed = () => ({
  type: SUBCATEGORY_FAILED,
});
export const setSubcategoryStateToClear = () => ({
  type: SUBCATEGORY_CLEAR,
});
export const setSubcategoryStateToSuccess = (payload) => ({
  type: SUBCATEGORY_SUCCESS,
  payload,
});

export const setSubcategoryByCategoryIdStateToFetching = () => ({
  type: SUBCATEGORY_BY_CATEGORY_ID_FETCHING,
});

export const setSubcategoryByCategoryIdStateToFailed = () => ({
  type: SUBCATEGORY_BY_CATEGORY_ID_FAILED,
});

export const setSubcategoryByCategoryIdStateToSuccess = (subcategoryByCategoryId) => ({
  type: SUBCATEGORY_BY_CATEGORY_ID_SUCCESS,
  subcategoryByCategoryId,
});

export const setSubcategoryOptionsStateToFetching = () => ({
  type: SUBCATEGORY_OPTIONS_FETCHING,
});
export const setSubcategoryOptionsStateToFailed = () => ({
  type: SUBCATEGORY_OPTIONS_FAILED,
});
export const setSubcategoryOptionsStateToSuccess = (payload) => ({
  type: SUBCATEGORY_OPTIONS_SUCCESS,
  payload,
});

export const Index = () => {
  return async (dispatch) => {
    dispatch(setSubcategoryStateToFetching());
    const response = await httpClient.get(
      process.env.REACT_APP_API_URL + "/category/sub?type=master",
    );
    if (response.data.result === "success") {
      dispatch(setSubcategoryStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setSubcategoryStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const subcategoryByCategoryId = (params) => {
  return async (dispatch) => {
    dispatch(setSubcategoryByCategoryIdStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + `category/sub/by-category${paramsString}`,
      );
      dispatch(setSubcategoryByCategoryIdStateToSuccess(response.data.data));
    } catch (error) {
      dispatch(setSubcategoryByCategoryIdStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const index = (params = null) => {
  return async (dispatch) => {
    dispatch(setSubcategoryStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `category/sub${paramsString}`,
      );
      dispatch(setSubcategoryStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setSubcategoryStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const options = (params = null) => {
  return async (dispatch) => {
    dispatch(setSubcategoryOptionsStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `category/sub${paramsString}`,
      );
      dispatch(setSubcategoryOptionsStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setSubcategoryOptionsStateToFailed());
      ErrorNotification(error);
    }
  };
};

import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { server } from "../../constants";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { delay } from "lodash-es";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";

function Auth() {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const setLocalStorageFromParent = () => {
      localStorage.setItem(server.TOKEN_KEY, queryParams.get(server.TOKEN_KEY));
      localStorage.setItem(server.REFRESH_TOKEN_KEY, queryParams.get(server.REFRESH_TOKEN_KEY));
      localStorage.setItem(server.ABOUT_USER, queryParams.get(server.ABOUT_USER));
      localStorage.setItem(server.USER_ID, queryParams.get(server.USER_ID));
      localStorage.setItem(server.USER_ROLE, queryParams.get(server.USER_ROLE));

      delay(() => {
        window.location.reload();
      }, 400);
    };

    // Cookies hasn't been set
    if (!localStorage.getItem(server.TOKEN_KEY)) {
      setLocalStorageFromParent();
    }

    if (localStorage.getItem(server.TOKEN_KEY) === undefined || localStorage.getItem(server.TOKEN_KEY) === 'undefined') {
      setLocalStorageFromParent();
    }

    // The existing cookies are different from the injected ones
    if (localStorage.getItem(server.TOKEN_KEY) !== queryParams.get(server.TOKEN_KEY)) {
      setLocalStorageFromParent();
    }
  }, [search, pathname])

  if (localStorage.getItem(server.TOKEN_KEY) === queryParams.get(server.TOKEN_KEY)) {
    const legacyQueryParams = queryParams.get('params') ? new URLSearchParams(JSON.parse(queryParams.get('params'))) : '';
    return <Redirect to={pathname.replace('/auth', '') + `?${legacyQueryParams.toString()}`} />
  }

  return <SpinLoaderContent />
}

export default Auth;
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import InvoiceComponent from "../../components/templates/Invoice";
import Cookies from "js-cookie";
import { server } from "../../constants";
import { getCurrentRole } from "../../utils/Functions";

const Invoice = () => {
  const { id } = useParams();
  const currentRole = getCurrentRole();

  const headerProps = {
    title: "Invoice",
    buttonBack: { text: "Invoice", status: true },
    description: "Invoice Detail",
  };

  const parentLink = currentRole === 'BASKIT_ADMIN' ? '/transaction-management' : '/order-list';
  const parentTitle = currentRole === 'BASKIT_ADMIN' ? 'Transaction Management' : 'Order List';

  const mainBreadcrumbItems = [
    {
      link: parentLink,
      title: parentTitle,
    },
    {
      title: "Invoice",
    },
  ];

  return (
    <InvoiceComponent
      orderId={id}
      headerProps={headerProps}
      mainBreadcrumbItems={mainBreadcrumbItems}
    />
  );
};

export default Invoice;

import { MapContainer, TileLayer } from "react-leaflet";
import './index.scss';
import LeafletControlGeocoder from "./LeafletGeocoder";

function NominatimLeafletContainer({ address, setLocation }) {
  return (
    <MapContainer zoom={18} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LeafletControlGeocoder positionInfos={[address]} setLocation={setLocation} />
    </MapContainer>
  )
}

export default NominatimLeafletContainer;


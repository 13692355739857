import { UPLOAD_FETCHING, UPLOAD_FAILED } from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case UPLOAD_FETCHING:
      return { ...state, isFetching: true, isError: false };
    case UPLOAD_FAILED:
      return { ...state, isFetching: false, isError: true };
    default:
      return state;
  }
};

import React from "react";
import { Form, Image, InputNumber, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

const FormItemPhoneNumber = ({
  label,
  name,
  rules,
  size = "middle",
  disabled = false,
  placeholder,
}) => {
  const { t } = useTranslation();

  const countryPhones = [
    { code: "+60", image: "my.svg" },
    { code: "+62", image: "id.svg" },
    { code: "+65", image: "sg.svg" },
    { code: "+92", image: "pk.svg" },
  ];

  const prefixSelector = (
    <Form.Item name={"code_" + name} noStyle>
      <Select size={size} disabled={disabled}>
        {countryPhones.map(({ code, image }) => (
          <Select.Option value={code} key={code}>
            <Space direction="horizontal" size="small">
              <Image
                width={20}
                preview={false}
                src={`${window.location.origin}/images/flags/${image}`}
              />
              <span>{code}</span>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const parseNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <Form.Item label={t(label)} name={name} rules={rules}>
      <InputNumber
        addonBefore={prefixSelector}
        type="number"
        parser={parseNumber}
        placeholder={t(placeholder)}
        style={{ width: "100%" }}
        size={size}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default FormItemPhoneNumber;

import { useEffect } from "react";
import { delay } from "lodash-es";

function Logout() {
  useEffect(() => {
    try {
      localStorage.clear();
    } catch {
      console.error('failed to remove localStorage');
    }

    delay(() => {
      window.location.href = '/login';
    }, 400);
  }, []);

  return null;
}

export default Logout;

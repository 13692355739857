import {
  UOM_FETCHING,
  UOM_SUCCESS,
  UOM_FAILED,
  UOM_CLEAR,
  UOM_UNIT_FETCHING,
  UOM_UNIT_SUCCESS,
  UOM_UNIT_FAILED,
  UOM_PACK_TYPE_FETCHING,
  UOM_PACK_TYPE_SUCCESS,
  UOM_PACK_TYPE_FAILED,
} from "../constants";
import swal from "sweetalert";
import { httpClient } from "./../utils/HttpClient";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setUOMStateToFetching = () => ({
  type: UOM_FETCHING,
});
export const setUOMStateToFailed = () => ({
  type: UOM_FAILED,
});
export const setUOMStateToClear = () => ({
  type: UOM_CLEAR,
});
export const setUOMStateToSuccess = (payload) => ({
  type: UOM_SUCCESS,
  payload,
});

export const setUomUnitStateToFetching = () => ({
  type: UOM_UNIT_FETCHING,
});

export const setUomUnitStateToSuccess = (payload) => ({
  type: UOM_UNIT_SUCCESS,
  payload,
});

export const setUomUnitStateToFailed = () => ({
  type: UOM_UNIT_FAILED,
});

export const setUomPackTypeStateToFetching = () => ({
  type: UOM_PACK_TYPE_FETCHING,
});

export const setUomPackTypeStateToSuccess = (payload) => ({
  type: UOM_PACK_TYPE_SUCCESS,
  payload,
});

export const setUomPackTypeStateToFailed = () => ({
  type: UOM_PACK_TYPE_FAILED,
});

export const clearState = () => {
  return (dispatch) => {
    dispatch(setUOMStateToClear());
  };
};

// export const index = () => {
//   return async (dispatch) => {
//     dispatch(setUOMStateToFetching);
//     const response = await httpClient.get(process.env.REACT_APP_API_URL + "uom");
//     if (response.data.result === "success") {
//       // console.log(response.data);
//       dispatch(setUOMStateToSuccess(response.data.data));
//     } else if (response.data.result === "error") {
//       dispatch(setUOMStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const Index = () => {
  return async (dispatch) => {
    dispatch(setUOMStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL_NEW_V2 + "uom");
      SuccessNotification(response);
      dispatch(setUOMStateToSuccess(response.data.data));
      return true;
    } catch (error) {
      dispatch(setUOMStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const getUomMaster = () => {
  return async (dispatch) => {
    dispatch(setUOMStateToFetching);
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "uom?type=master");
    if (response.data.result === "success") {
      dispatch(setUOMStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setUOMStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getUomUnit = () => {
  return async (dispatch) => {
    dispatch(setUomUnitStateToFetching);
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + "uom?type=master&category=unit",
      );
      dispatch(setUomUnitStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setUomUnitStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getUomPackType = () => {
  return async (dispatch) => {
    dispatch(setUomPackTypeStateToFetching);
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + "uom?type=master&category=packtype",
      );
      dispatch(setUomPackTypeStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setUomPackTypeStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getUOMMachineById = (id) => {
  return async (dispatch) => {
    dispatch(setUOMStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "uom/" + id);
    if (response.data.result === "success") {
      dispatch(setUOMStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setUOMStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const create = (values, history) => {
  return async (dispatch) => {
    dispatch(setUOMStateToFetching());
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "uom", values);
    if (response.data.result === "success") {
      dispatch(setUOMStateToSuccess(response.data));
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setUOMStateToClear());
        history.goBack();
        dispatch(Index());
      });
    } else if (response.data.result === "error") {
      dispatch(setUOMStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};
export const update = (values, history) => {
  return async (dispatch) => {
    dispatch(setUOMStateToFetching());
    const response = await httpClient.put(process.env.REACT_APP_API_URL + "uom", values);
    if (response.data.result === "success") {
      dispatch(setUOMStateToClear());
      history.goBack();
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setUOMStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};
export const inline_update = (values, history) => {
  return async (dispatch) => {
    // dispatch(setPOSMachineStateToFetching());
    const response = await httpClient.put(process.env.REACT_APP_API_URL + "uom", values);
    if (response.data.result === "success") {
      // dispatch(setPOSMachineStateToClear());
    } else if (response.data.result === "error") {
      // dispatch(setPOSMachineStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};
export const remove = (id) => {
  console.log(id);
  return async (dispatch) => {
    console.log("remove");
    dispatch(setUOMStateToFetching());
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + "uom/" + id);
    if (response.data.result === "success") {
      dispatch(setUOMStateToSuccess());
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setUOMStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};
export const bulk_delete = (id) => {
  console.log(id);
  return async (dispatch) => {
    console.log("remove");
    dispatch(setUOMStateToFetching());
    const response = await httpClient.delete(
      process.env.REACT_APP_API_URL + "uom/bulk_delete/" + id,
    );
    if (response.data.result === "success") {
      dispatch(setUOMStateToSuccess());
      dispatch(Index());
    } else if (response.data.result === "error") {
      dispatch(setUOMStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

import { Col, Divider, Row, Typography } from "antd";
import { isEmpty } from "lodash-es";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as categoryActions from "../../actions/category.action";
import * as inventoryActions from "../../actions/inventory.action";
import ProductEmpty from "../../components/atoms/ProductEmpty/ProductEmpty";
import ProductNotFound from "../../components/atoms/ProductNotFound/ProductNotFound";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import SortProduct from "../../components/atoms/SortProduct/SortProduct";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import MultipleSelectCategory from "../../components/molecules/MultipleSelectCategory/MultipleSelectCategory";
import ProductListCard from "../../components/molecules/ProductListCard/ProductListCard";
import SingleProductModal from "../../components/molecules/SingleProductModal/SingleProductModal";
import MainContent from "../../components/organisms/Content/Content";
import { server } from "../../constants";
import useAmplitudeContext from "../../hooks/useAmplitudeContext";
import { toCheckLoginToken } from "../../utils/Functions";
import "./_style.scss";

const { Text } = Typography;

const ProductList = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [sortProduct, setSortProduct] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [onFiltering, setOnFiltering] = useState(false);
  const [productMetaData, setProductMetaData] = useState({});
  const [loadingInfiniteScroll, setLoadingInfiniteScroll] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    $limit: 20,
    $page: 1,
  });
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);

  const category = useSelector((state) => state.categoryReducer);
  const inventory = useSelector((state) => state.inventoryReducer);

  useEffect(() => {
    dispatch(categoryActions.index());
    dispatch(inventoryActions.index(params));
  }, []);

  useEffect(() => {
    if (inventory?.result) {
      const { data, meta } = inventory?.result;
      setOriginalData(data);
      setData(data);
      setProductMetaData(meta);
      setLoading(false);
      setLoadingInfiniteScroll(false);
    }
  }, [inventory]);

  useEffect(() => {
    if (category?.result) {
      const _categoryOptions = category.result.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setCategoryOptions(_categoryOptions);
    }
  }, [category]);

  useEffect(() => {
    if (sortProduct) {
      const _data = [...data];

      if (sortProduct === "highest") {
        _data.sort((a, b) => b.basePrice - a.basePrice);
      } else if (sortProduct === "lowest") {
        _data.sort((a, b) => a.basePrice - b.basePrice);
      }
      if (sortProduct === "active") {
        _data.sort((a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1));
      } else if (sortProduct === "pasive") {
        _data.sort((a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1));
      }
      setData(_data);
    } else setData(originalData);
  }, [sortProduct]);

  const loadMoreData = () => {
    if (loadingInfiniteScroll) return;

    setLoadingInfiniteScroll(true);
    const _params = { ...params, $page: params.$page + 1 };
    setParams(_params);
    dispatch(inventoryActions.loadMore(_params));
  };

  const handleProductSearch = (value) => {
    const _params = { ...params };
    if (value) {
      _params.search = value;
      setOnFiltering(true);
    } else {
      delete _params.search;
      setOnFiltering(false);
    }
    dispatch(inventoryActions.index(_params));
  };

  const handleProductSearchByCategoryId = (values) => {
    if (!isEmpty(values)) {
      dispatch(inventoryActions.index({ categoryId: values.join(",") }));
    } else dispatch(inventoryActions.index());
  };

  const handleUpdateProduct = async (fields) => {
    const { price, stock } = fields;
    const { id, sellingPrice, margin } = selectedProduct;
    const values = {
      id,
      stock,
      basePrice: price,
      sellingPrice,
      margin,
    };

    setIsLoading(true);
    const isSuccess = await dispatch(inventoryActions.update(values?.id, values));
    if (isSuccess) {
      trackAmplitudeEvent("PRODUCT_UPDATE_INVENTORIES_CLICK", {
        product_id: values.id,
      });
      setIsLoading(false);
      setModalOpen(false);
      dispatch(inventoryActions.index());
    } else setIsLoading(false);
  };

  const headerProps = {
    title: "Product List",
    description: "Manage your product",
  };

  const multipleSelectCategoryProps = {
    options: categoryOptions,
    handleChange: handleProductSearchByCategoryId,
  };

  const productSearchFilterProps = {
    placeholder: "Search by product name",
    options: [],
    handleChange: handleProductSearch,
  };

  const sortProductProps = {
    placeholder: "Sort",
    handleChange: (value) => setSortProduct(value),
    options: [
      { value: "highest", label: "Highest Price" },
      { value: "lowest", label: "Lowest Price" },
      { value: "active", label: "Active Products" },
      { value: "pasive", label: "Inactive Products" },
    ],
  };

  const singleProductModalProps = {
    modalOpen,
    setModalOpen,
    isLoading,
    data: selectedProduct,
    buttonText: "Update data product",
    handleSubmit: handleUpdateProduct,
  };

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="product-wrapper">
        <div id="product-filter">
          <Row gutter={12}>
            <Col span={6}>
              <Text strong>{t("Total Product")}</Text>
              <br />
              <Text>
                {productMetaData.totalData} {t("Product")}
              </Text>
            </Col>
            <Col span={4}>
              <MultipleSelectCategory {...multipleSelectCategoryProps} />
            </Col>
            <Col span={4}>
              <SortProduct {...sortProductProps} />
            </Col>
            <Col span={10}>
              <ProductSearchFilter {...productSearchFilterProps} />
            </Col>
          </Row>
        </div>

        <div id="product-content">
          {loading ? (
            <SpinLoaderContent />
          ) : isEmpty(data) && onFiltering === false ? (
            <ProductEmpty withCTA={true} />
          ) : isEmpty(data) && onFiltering === true ? (
            <ProductNotFound />
          ) : (
            <InfiniteScroll
              dataLength={data.length}
              next={loadMoreData}
              hasMore={data.length < productMetaData.totalData}
              endMessage={
                <div className="full-width pl-10 pr-10">
                  <Divider plain>{t("It is all, nothing more")} !</Divider>
                </div>
              }
              scrollableTarget="product-content"
              loader={<SpinLoaderContent />}
            >
              <div className="card-wrapper">
                {data.map((product) => (
                  <ProductListCard
                    key={product.id}
                    data={product}
                    handleClick={() => {
                      setSelectedProduct(product);
                      trackAmplitudeEvent("PRODUCT_PRICES_STOCK_CLICK", {
                        product_id: product.id,
                      });
                      setModalOpen(true);
                    }}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}

          <SingleProductModal {...singleProductModalProps} />
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

export default ProductList;

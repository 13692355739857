import { Select, Typography } from "antd";
import useRoleSwitcher from "../../hooks/useRoleSwitcher";

const { Text } = Typography;

function RoleSwitcher() {
  const { options, handleChange, defaultValue, hasMultipleRoles } = useRoleSwitcher();

  if (hasMultipleRoles) {
    return (
      <Select
        style={{ width: 150 }}
        defaultValue={defaultValue}
        onChange={handleChange}
        options={options}
      />
    );
  }

  return (
    <Text>{options[0]?.label}</Text>
  );
}

export default RoleSwitcher;

import {
  COMPANY_FETCHING,
  COMPANY_SUCCESS,
  COMPANY_FAILED,
  COMPANY_CLEAR,
  COMPANY_OPTIONS_SUCCESS,
  COMPANY_UPDATE,
  COMPANY_SELLER_FETCHING,
  COMPANY_SELLER_FAILED,
  COMPANY_SELLER_SUCCESS,
  COMPANY_SELLER_DETAILS_FETCHING,
  COMPANY_SELLER_DETAILS_FAILED,
  COMPANY_SELLER_DETAILS_SUCCESS,
  COMPANY_RELATED_SALES_FETCHING,
  COMPANY_RELATED_SALES_FAILED,
  COMPANY_RELATED_SALES_SUCCESS,
  COMPANY_APPROVAL_FETCHING,
  COMPANY_APPROVAL_FAILED,
  COMPANY_BUYERS_FETCHING,
  COMPANY_BUYERS_FAILED,
  COMPANY_BUYERS_SUCCESS,
  COMPANY_BUYER_FETCHING,
  COMPANY_BUYER_FAILED,
  COMPANY_BUYER_SUCCESS,
  COMPANY_TYPES_FETCHING,
  COMPANY_TYPES_FAILED,
  COMPANY_TYPES_SUCCESS,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  options: null,
  data: null,
  isCompanySellerFetching: false,
  isCompanySellerError: false,
  companySeller: null,
  isCompanySellerDetailsFetching: false,
  isCompanySellerDetailsError: false,
  companySellerDetails: null,
  isRelatedSalesFetching: false,
  isRelatedSalesError: false,
  relatedSales: null,
  isApprovalFetching: false,
  isApprovalError: false,
  isBuyersFetching: false,
  isBuyersError: false,
  buyers: null,
  isTypesFetching: false,
  isTypesError: false,
  types: null,
  isTypeFetching: false,
  isTypeError: false,
  type: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case COMPANY_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case COMPANY_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case COMPANY_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case COMPANY_OPTIONS_SUCCESS:
      return { ...state, isFetching: false, isError: false, options: payload };
    case COMPANY_UPDATE:
      return { ...state, isFetching: false, isError: false, data: payload };
    case COMPANY_SELLER_FETCHING:
      return {
        ...state,
        isCompanySellerFetching: true,
        isCompanySellerError: false,
        companySeller: null,
      };
    case COMPANY_SELLER_FAILED:
      return {
        ...state,
        isCompanySellerFetching: false,
        isCompanySellerError: true,
        companySeller: null,
      };
    case COMPANY_SELLER_SUCCESS:
      return {
        ...state,
        isCompanySellerFetching: false,
        isCompanySellerError: false,
        companySeller: payload,
      };
    case COMPANY_SELLER_DETAILS_FETCHING:
      return {
        ...state,
        isCompanySellerDetailsFetching: true,
        isCompanySellerDetailsError: false,
        companySellerDetails: null,
      };
    case COMPANY_SELLER_DETAILS_FAILED:
      return {
        ...state,
        isCompanySellerDetailsFetching: false,
        isCompanySellerDetailsError: true,
        companySellerDetails: null,
      };
    case COMPANY_SELLER_DETAILS_SUCCESS:
      return {
        ...state,
        isCompanySellerDetailsFetching: false,
        isCompanySellerDetailsError: false,
        companySellerDetails: payload,
      };
    case COMPANY_RELATED_SALES_FETCHING:
      return {
        ...state,
        isRelatedSalesFetching: true,
        isRelatedSalesError: false,
        relatedSales: null,
      };
    case COMPANY_RELATED_SALES_FAILED:
      return {
        ...state,
        isRelatedSalesFetching: false,
        isRelatedSalesError: true,
        relatedSales: null,
      };
    case COMPANY_RELATED_SALES_SUCCESS:
      return {
        ...state,
        isRelatedSalesFetching: false,
        isRelatedSalesError: false,
        relatedSales: payload,
      };
    case COMPANY_APPROVAL_FETCHING:
      return {
        ...state,
        isApprovalFetching: true,
        isApprovalError: false,
      };
    case COMPANY_APPROVAL_FAILED:
      return {
        ...state,
        isApprovalFetching: false,
        isApprovalError: true,
      };
    case COMPANY_BUYERS_FETCHING:
      return {
        ...state,
        isBuyersFetching: true,
        isBuyersError: false,
        buyers: null,
      };
    case COMPANY_BUYERS_FAILED:
      return {
        ...state,
        isBuyersFetching: false,
        isBuyersError: true,
        buyers: null,
      };
    case COMPANY_BUYERS_SUCCESS:
      return {
        ...state,
        isBuyersFetching: false,
        isBuyersError: false,
        buyers: payload,
      };
    case COMPANY_BUYER_FETCHING:
      return {
        ...state,
        isBuyerFetching: true,
        isBuyerError: false,
        buyer: null,
      };
    case COMPANY_BUYER_FAILED:
      return {
        ...state,
        isBuyerFetching: false,
        isBuyerError: true,
        buyer: null,
      };
    case COMPANY_BUYER_SUCCESS:
      return {
        ...state,
        isBuyerFetching: false,
        isBuyerError: false,
        buyer: payload,
      };
    case COMPANY_TYPES_FETCHING:
      return {
        ...state,
        isTypesFetching: true,
        isTypesError: false,
        types: null,
      };
    case COMPANY_TYPES_FAILED:
      return {
        ...state,
        isTypesFetching: false,
        isTypesError: true,
        types: null,
      };
    case COMPANY_TYPES_SUCCESS:
      return {
        ...state,
        isTypesFetching: false,
        isTypesError: false,
        types: payload,
      };
    default:
      return state;
  }
};

import { httpClient } from "../utils/HttpClient";
import { HISTORY_FETCHING, HISTORY_SUCCESS, HISTORY_FAILED, HISTORY_CLEAR } from "../constants";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { parseParamsString } from "../utils/Functions";

export const setHistoryStateToFetching = () => ({
  type: HISTORY_FETCHING,
});

export const setHistoryStateToFailed = () => ({
  type: HISTORY_FAILED,
});

export const setHistoryStateToClear = () => ({
  type: HISTORY_CLEAR,
});

export const setHistoryStateToSuccess = (payload) => ({
  type: HISTORY_SUCCESS,
  payload,
});

export const index = (params = null) => {
  return async (dispatch) => {
    dispatch(setHistoryStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `history${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setHistoryStateToSuccess({ data, totalData, totalPage }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setHistoryStateToFailed());
      ErrorNotification(error);
    }
  };
};

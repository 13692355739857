import React, { useEffect, useState } from "react";
import { Button, Col, Image, InputNumber, List, Row, Typography } from "antd";
import { formatRupiah, toSentenceCase } from "../../../utils/Functions";
import { DeleteOutlined, ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { debounce } from "lodash-es";
import * as shopActions from "../../../actions/shop.action";
import "./ShoppingCartListItem.scss";

const { Text } = Typography;

const CartListItem = ({
  data,
  source,
  setModalOpen,
  setNotificationModalOpen,
  selectedProductDelete,
  setSelectedProductDelete,
  unavailableProducts,
  setUnavailableProducts,
  isCancelDeleteProduct,
  setIsCancelDeleteProduct,
}) => {
  const { t } = useTranslation();
  const { product_id, staff_id, price, qty, uoms, products } = data;
  const [updatedQty, setUpdatedQty] = useState(qty);
  const [isOnClick, setIsOnClick] = useState(false);
  const dispatch = useDispatch();
  const stock = products.stock ? products.stock : 0;

  useEffect(() => {
    if (!isOnClick) return;

    const debouncedAction = debounce(() => {
      if (updatedQty > stock) return;
      if (updatedQty <= 0) {
        setSelectedProductDelete({ staff_id, product_id });
        setNotificationModalOpen(true);
      } else {
        const dataToSend = {
          source: source,
          product_id: product_id,
          qty: updatedQty,
          subTotal: price * updatedQty,
        };
        dispatch(shopActions.updateCart(dataToSend, source));
      }
      setIsOnClick(false);
    }, 1500);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [updatedQty, isOnClick]);

  useEffect(() => {
    const _unavailableProducts = { ...unavailableProducts };
    if (updatedQty > stock) {
      _unavailableProducts[product_id] = updatedQty;
    } else {
      delete _unavailableProducts[product_id];
    }
    setUnavailableProducts(_unavailableProducts);
  }, [updatedQty, stock]);

  useEffect(() => {
    if (isCancelDeleteProduct && selectedProductDelete.product_id === product_id) {
      setUpdatedQty(1);
      setIsOnClick(true);
      setIsCancelDeleteProduct(false);
    }
  }, [isCancelDeleteProduct, selectedProductDelete]);

  return (
    <List.Item className='shopping-cart-list-item'>
      <Row className='full-width mr-10'>
        <Col span={4}>
          <Image
            className='shopping-cart-list-item-image'
            width={90}
            height={90}
            preview={false}
            src={process.env.REACT_APP_API_URL + "images/product/" + (products.image ? products.image : "default.jpg")}
            fallback={process.env.REACT_APP_API_URL + "images/product/default.jpg"}
          />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={12}>
              <div className='shopping-cart-list-item-title'>
                <Text className='ant-typography-ellipsis-multiple-line'>{products?.name}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div className='float-right'>
                <Text strong className='font-size-12'>
                  {t("Stock")} : {stock}
                </Text>
                <br />
                <Text className='font-size-12'>
                  {t("Packaging")} : {uoms ? toSentenceCase(uoms?.label) : "-"}
                </Text>
              </div>
            </Col>
            <Col span={12} className='flex-center-align mt-10'>
              <Text strong>{formatRupiah(price ? price : 0)}</Text>
              {/* <br />
              <Text className='font-size-12' type='secondary' delete>
                {formatRupiah(price ? price : 0)}
              </Text> */}
            </Col>
            <Col span={12} className='flex-center-align mt-10'>
              <Row className='full-width'>
                <Col span={4} className='flex-center '>
                  <Button
                    className='shopping-cart-list-item-button'
                    size='small'
                    type='default'
                    shape='circle'
                    icon={<MinusOutlined />}
                    onClick={() => {
                      if (updatedQty) {
                        setUpdatedQty((prevQty) => prevQty - 1);
                        setIsOnClick(true);
                      }
                    }}
                  />
                </Col>
                <Col span={12} className='flex-center'>
                  <InputNumber
                    className='shopping-cart-list-item-input-number'
                    size='small'
                    status={updatedQty > stock && "error"}
                    defaultValue={updatedQty}
                    value={updatedQty}
                    onChange={(value) => {
                      setUpdatedQty(value);
                      setIsOnClick(true);
                    }}
                  />
                </Col>
                <Col span={4} className='flex-center'>
                  <Button
                    className='shopping-cart-list-item-button'
                    size='small'
                    type='primary'
                    shape='circle'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setUpdatedQty((prevQty) => prevQty + 1);
                      setIsOnClick(true);
                    }}
                  />
                </Col>
                <Col span={4} className='flex-center'>
                  <Button
                    className='text-gray'
                    size='small'
                    type='default'
                    htmlType='button'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setSelectedProductDelete({ staff_id, product_id });
                      setModalOpen(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {updatedQty > stock && (
          <Col span={24}>
            <div className='shopping-cart-list-item-alert flex-center-align'>
              <ExclamationCircleOutlined className='alert-icon' />
              <Text className='alert-title'>
                {t(
                  "Maaf, total item produk ini melebihi stok yang Anda miliki. Silahkan merubah total item untuk melanjutkan pesanan"
                )}
              </Text>
            </div>
          </Col>
        )}
      </Row>
    </List.Item>
  );
};

export default CartListItem;

import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import InvoiceComponent from "../../components/templates/Invoice";

const Invoice = () => {
  const { companyId, orderId } = useParams();

  const headerProps = {
    buttonBack: { text: "Invoice", status: true },
    description: "Invoice Detail",
  };

  const mainBreadcrumbItems = [
    {
      link: "/merchant-list",
      title: "Merchant List",
    },
    {
      link: `/merchant-list/detail/${companyId}?openTabs=transactions`,
      title: "Merchant Detail",
    },
    {
      title: "Invoice Detail",
    },
  ];

  return (
    <InvoiceComponent
      orderId={orderId}
      headerProps={headerProps}
      mainBreadcrumbItems={mainBreadcrumbItems}
    />
  );
};

export default Invoice;

// @ts-check
import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderDetail from '../../components/templates/OrderDetail/OrderDetail';

const TransactionDetail = () => {
  const { t } = useTranslation();

  return (
    <OrderDetail/>
  );
};

export default TransactionDetail;
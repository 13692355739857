import React from "react";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const ButtonDefaultActionTable = ({ title = "", text = "", icon, danger = false, handleClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(title)}>
      <Button type='default' size='small' danger={danger} htmlType='button' icon={icon} onClick={handleClick}>
        {t(text)}
      </Button>
    </Tooltip>
  );
};

export default ButtonDefaultActionTable;

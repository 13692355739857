import React from "react";
import { Button, Card, Col, Row, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatRupiah } from "../../../utils/Functions";
import dayjs from "dayjs";
import "./CustomerListCard.scss";

const { Text } = Typography;

const CustomerListCard = ({ data, handleInvite, modeTablet, tokenParams }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const customerFinancialsPath = modeTablet
    ? `/customer/financials/${data?.id}?token=${tokenParams}`
    : `/customer/financials/${data?.id}`;
  const customerDetailPath = modeTablet
    ? `customer/detail/${data?.id}?token=${tokenParams}`
    : `customer/detail/${data?.id}`;

  return (
    <Card className="customer-list-card" key={data?.id} hoverable>
      <Row gutter={12}>
        <Col span={24} className="text-center mb-20">
          <Tag className="initial-name-tag">{data?.firstName.substring(0, 1)}</Tag>
          <Text strong>{data?.firstName}</Text>
        </Col>
        <Col span={24} className="mb-20">
          <Space direction="vertical" className="full-width">
            <ListItem label="phone" value={formatRupiah(data?.phone)} />
            {/* <ListItem label="Order Value" value={formatRupiah(data?.totalOrder)} />
            <ListItem label="Satisfaction" value="-" />
            <ListItem label="Recent" value={data?.newestSo} />
            <ListItem
              label="Last Interaction"
              value={
                data?.lastInteraction ? dayjs(data?.lastInteraction).format("D MMM, YYYY") : "-"
              }
            />
            <Row gutter={12}>
              <Col span={8}>
                <Button
                  className="disabled-button"
                  disabled
                  type="primary"
                  block
                  onClick={() => handleInvite(data?.id)}
                >
                  {t("Invite")}
                </Button>
              </Col>
              <Col span={8}>
                <Button type="primary" block onClick={() => history.push(customerFinancialsPath)}>
                  {t("Financials")}
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  type="primary"
                  block
                  onClick={() => window.open(`https://wa.me/${data?.phone}`)}
                >
                  {t("Chat")}
                </Button>
              </Col>{" "}
            </Row> */}
          </Space>
        </Col>
        {/* <Col span={8}>
          <Button type="link" size="small" onClick={() => history.push(customerDetailPath)}>
            {t("View Detail")}
          </Button>
        </Col>
        <Col span={16} className="text-right">
          <Text>
            {t("Managed by")}: {data?.first_name || data?.first_name || "-"}
          </Text>
        </Col> */}
      </Row>
    </Card>
  );
};

const ListItem = ({ value, label }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={12}>
        <Text>{t(label)}</Text>
      </Col>
      <Col span={12} className="text-right">
        <Text>{value}</Text>
      </Col>
    </Row>
  );
};

export default CustomerListCard;

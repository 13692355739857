import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from "antd";
import { EditOutlined, EnvironmentFilled, FileImageOutlined } from "@ant-design/icons";
import { formatPhoneNumberWithSpaces, splitCountryCodeAndPhoneNumber } from "../../utils/Functions";
import { parseToDayjs, parseToDuration, roadAccessOptions } from "./helpers";
import { debounce, isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";
import ImageCustom from "../../components/atoms/Image";
import useCompanyTypeData from "../../hooks/useCompanyTypeData";
import useSalesData from "../../hooks/useSalesData";
import FormItemPhoneNumber from "../../components/atoms/FormItemPhoneNumber";
import useInternalRegionData from "../../hooks/useInternalRegionData";
import useProvinceData from "../../hooks/useProvinceData";
import useCityData from "../../hooks/useCityData";
import useDistrictData from "../../hooks/useDistrictData";
import useOptions from "../../hooks/useOptions";
import dayjs from "dayjs";

const { Text, Title } = Typography;

export const CustomSelect = ({
  size = "large",
  showSearch = true,
  handleSearch = () => {},
  handleSelect = () => {},
  ...rest
}) => {
  const debounceSearch = useRef(
    debounce(async (value) => {
      handleSearch(value);
    }, 500),
  ).current;

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  return (
    <Select
      {...rest}
      size={size}
      showSearch={showSearch}
      onSearch={debounceSearch}
      onSelect={handleSelect}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
      }
    />
  );
};

const FormItem = ({ label, name, value = "-", children, isOnEdit = false, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Form.Item className={isOnEdit ? "" : "disabled-edit"} label={t(label)} name={name} {...rest}>
      {isOnEdit ? children : <Text strong>{value ? value : "-"}</Text>}
    </Form.Item>
  );
};

const CustomForm = ({
  title,
  form,
  handleFinish,
  isOnEdit,
  isLoading = false,
  setIsOnEdit,
  labelCol = { span: 7 },
  wrapperCol = { span: 17 },
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      className="pb-20"
      form={form}
      colon={false}
      labelAlign="left"
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      onFinish={handleFinish}
    >
      <Row gutter={12}>
        <Col span={20} className="flex-center-align">
          <Title level={5}>{t(title)}</Title>
        </Col>
        <Col span={4}>
          {isOnEdit ? (
            <Space className="float-right">
              <Button type="default" onClick={() => setIsOnEdit(false)}>
                {t("cancel")}
              </Button>

              <Button htmlType="submit" type="primary" loading={isLoading}>
                {t("save")}
              </Button>
            </Space>
          ) : (
            <Button
              className="float-right"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsOnEdit(true)}
            >
              {t("Edit")}
            </Button>
          )}
        </Col>
        <Col span={24} className="mt-10 mb-10">
          <Divider style={{ margin: "0px" }} />
        </Col>

        {children}
      </Row>
    </Form>
  );
};

export const FormStoreInfo = ({ data, onDirty, onPristine, handleSubmit }) => {
  const { t } = useTranslation();
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [internalRegionOptions, setInternalRegionOptions] = useState([]);
  const [salesOptions, setSalesOptions] = useState([]);
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [companyCategoryOptions, setCompanyCategoryOptions] = useState([]);
  const [companyTypeId, setCompanyTypeId] = useState("");
  const [selectedCompanyType, setSelectedCompanyType] = useState("");
  const [salesSearchValue, setSalesSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const salesParams = useMemo(() => {
    return { role: "SALESMAN", search: salesSearchValue };
  }, [salesSearchValue]);

  const companyTypeParams = useMemo(() => {
    return {
      type: "BUYER",
    };
  }, []);

  const companyCategoryParams = useMemo(() => {
    const parentId = companyTypeId ? companyTypeId : data?.companyType?.id;
    return {
      parentId,
    };
  }, [data?.companyType, companyTypeId]);

  const companyType = useCompanyTypeData(companyTypeParams);
  const internalRegion = useInternalRegionData();
  const sales = useSalesData(salesParams);
  const companyCategory = useOptions(companyCategoryParams, "company_type");

  useEffect(() => {
    const newPhone = splitCountryCodeAndPhoneNumber(data?.phone);
    const code_phone = newPhone?.countryCode ?? "+62";
    const phone = newPhone?.phone ? parseInt(newPhone?.phone) : "";

    form.setFieldsValue({
      companyName: data?.companyName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone,
      code_phone,
      companyType: data?.companyType?.id,
      companyCategory: data?.companyCategory?.id,
      internalRegion: data?.internalRegion?.id,
      assignedTo: data?.assignedTo?.id,
    });
  }, [data]);

  useEffect(() => {
    if (companyType?.data) {
      const newOptions = companyType?.data.map(({ id, name }) => ({ value: id, label: name }));
      setCompanyTypeOptions(newOptions);
    }
  }, [companyType?.data]);

  useEffect(() => {
    if (companyCategory?.data) {
      let newOptions = companyCategory?.data.map(({ id, name }) => ({ value: id, label: name }));
      setCompanyCategoryOptions(newOptions);
    }
  }, [companyCategory?.data, companyTypeId]);

  useEffect(() => {
    if (internalRegion?.data) {
      const newOptions = internalRegion?.data.map(({ id, name }) => ({ value: id, label: name }));
      setInternalRegionOptions(newOptions);
    }
  }, [internalRegion?.data]);

  useEffect(() => {
    if (sales?.data) {
      let newOptions = sales?.data.map(({ id, profile }) => ({
        value: id,
        label: `${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`,
      }));

      if (!isEmpty(data?.assignedTo)) {
        newOptions.push({ label: data?.assignedTo?.name, value: data?.assignedTo?.id });
      }

      setSalesOptions(newOptions);
    }
  }, [sales?.data, data?.assignedTo]);

  useEffect(() => {
    if (isOnEdit) onDirty();
    else onPristine();
  }, [isOnEdit]);

  const handleFinish = async (values) => {
    setIsLoading(true);
    const { companyName, firstName, lastName, internalRegion, assignedTo } = values;
    const isSuccess = await handleSubmit({
      companyName,
      firstName,
      lastName,
      companyType: selectedCompanyType,
      internalRegion,
      assignedTo,
    });
    if (isSuccess) setIsOnEdit(false);
    setIsLoading(false);
  };

  const handleSelectCompanyType = (value) => {
    setSelectedCompanyType(value);
    setCompanyTypeId(value);
    form.setFieldValue("companyCategory", undefined);
  };

  return (
    <CustomForm
      title="storeInformation"
      form={form}
      isOnEdit={isOnEdit}
      isLoading={isLoading}
      setIsOnEdit={setIsOnEdit}
      handleFinish={handleFinish}
    >
      <Col span={12}>
        <FormItem
          className="disabled-edit"
          label="storeName"
          value={data?.companyName}
          isOnEdit={isOnEdit}
        >
          <Form.Item
            name="companyName"
            rules={[{ required: true, message: t("Please input your storeName!") }]}
          >
            <Input size="large" placeholder="Enter your storeName" />
          </Form.Item>
        </FormItem>

        <FormItem
          label="ownerName"
          className="disabled-edit"
          value={`${data?.firstName ?? "-"} ${data?.lastName ?? ""}`}
          isOnEdit={isOnEdit}
        >
          <Form.Item name="firstName" style={{ display: "inline-block", width: "calc(50% - 8px)" }}>
            <Input size="large" placeholder="Enter your firstname" />
          </Form.Item>
          <Form.Item
            name="lastName"
            style={{ display: "inline-block", width: "calc(50% - 8px)", margin: "0 8px" }}
          >
            <Input size="large" placeholder="Enter your lastName" />
          </Form.Item>
        </FormItem>

        <FormItem
          className="disabled-edit"
          label="Phone No."
          value={formatPhoneNumberWithSpaces(data?.phone ?? "")}
          isOnEdit={isOnEdit}
        >
          <FormItemPhoneNumber
            name="phone"
            size="large"
            disabled={true}
            placeholder="Enter your phone"
            rules={[{ required: true, message: t("Please input your phone!") }]}
          />
        </FormItem>

        <FormItem
          label="storeType"
          className="disabled-edit"
          value={data?.companyCategory ? data?.companyCategory?.name : data?.companyType?.name}
          isOnEdit={isOnEdit}
        >
          <Form.Item name="companyType">
            <CustomSelect
              placeholder="Choose your store type"
              options={companyTypeOptions}
              handleSelect={handleSelectCompanyType}
            />
          </Form.Item>

          {!isEmpty(companyCategoryOptions) && (
            <Form.Item
              name="companyCategory"
              rules={[{ required: true, message: t("Please choose store category!") }]}
            >
              <CustomSelect
                placeholder="Choose your store category"
                options={companyCategoryOptions}
                handleSelect={setSelectedCompanyType}
              />
            </Form.Item>
          )}
        </FormItem>
        <FormItem
          label="internalRegion"
          name="internalRegion"
          value={data?.internalRegion?.name}
          isOnEdit={isOnEdit}
        >
          <CustomSelect placeholder="Choose your internal region" options={internalRegionOptions} />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="registeredBy" value={data?.registeredBy} />
        <FormItem
          label="registeredAt"
          value={data?.registeredAt ? dayjs(data?.registeredAt).format("DD MMMM YYYY") : undefined}
        />
        <FormItem
          label="assignedTo"
          name="assignedTo"
          value={data?.assignedTo?.name}
          isOnEdit={isOnEdit}
        >
          <CustomSelect
            placeholder="Choose your sales"
            options={salesOptions}
            handleSearch={setSalesSearchValue}
          />
        </FormItem>
        <Form.Item label=" ">
          <Space size={4} direction="vertical">
            <Text>{data?.assignedTo?.internalRegion}</Text>
            <Text>{data?.assignedTo?.phone}</Text>
          </Space>
        </Form.Item>
      </Col>
    </CustomForm>
  );
};

export const FormShipping = ({ data, onDirty, onPristine, setModalOpen, handleSubmit }) => {
  const { t } = useTranslation();
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [regencyId, setRegencyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const params = useMemo(() => {
    const newProvinceId = provinceId ? provinceId : data?.province?.id;
    return {
      provinceId: newProvinceId,
    };
  }, [data?.province, provinceId]);

  const districtParams = useMemo(() => {
    const newRegencyId = regencyId ? regencyId : data?.regency?.id;
    return {
      regencyId: newRegencyId,
    };
  }, [data?.regency, regencyId]);

  const provinces = useProvinceData();
  const cities = useCityData(params);
  const districts = useDistrictData(districtParams);

  useEffect(() => {
    form.setFieldsValue({
      detailAddress: data?.detailAddress,
      province: data?.province?.id,
      regency: data?.regency?.id,
      district: data?.district?.id,
      landmark: data?.landmark,
      roadAccess: (data?.roadAccess ?? "").toLowerCase(),
    });
  }, [data]);

  useEffect(() => {
    if (provinces?.data) {
      let newOptions = provinces?.data.map(({ id, name }) => ({ value: id, label: name }));
      setProvinceOptions(newOptions);
    }
  }, [provinces?.data]);

  useEffect(() => {
    if (cities?.data) {
      let newOptions = cities?.data.map(({ id, name }) => ({ value: id, label: name }));
      setCityOptions(newOptions);
    }
  }, [cities?.data]);

  useEffect(() => {
    if (districts?.data) {
      let newOptions = districts?.data.map(({ id, name }) => ({ value: id, label: name }));
      setDistrictOptions(newOptions);
    }
  }, [districts?.data]);

  useEffect(() => {
    if (isOnEdit) onDirty();
    else onPristine();
  }, [isOnEdit]);

  const handleFinish = async (values) => {
    setIsLoading(true);
    const { detailAddress, district, landmark, roadAccess } = values;
    const isSuccess = await handleSubmit({
      detailAddress,
      districtId: district,
      landmark,
      roadAccess: roadAccess,
    });
    if (isSuccess) setIsOnEdit(false);
    setIsLoading(false);
  };

  return (
    <CustomForm
      title="shipping"
      form={form}
      isOnEdit={isOnEdit}
      isLoading={isLoading}
      setIsOnEdit={setIsOnEdit}
      handleFinish={handleFinish}
    >
      <Col span={12}>
        <FormItem
          label="address"
          name="detailAddress"
          value={data?.detailAddress}
          isOnEdit={isOnEdit}
        >
          <Input.TextArea value={data?.detailAddress} showCount />
        </FormItem>

        <FormItem label="province" name="province" value={data?.province?.name} isOnEdit={isOnEdit}>
          <CustomSelect
            placeholder="Choose your province"
            options={provinceOptions}
            handleSelect={setProvinceId}
          />
        </FormItem>

        <FormItem label="city" name="regency" value={data?.regency?.name} isOnEdit={isOnEdit}>
          <CustomSelect
            placeholder="Choose your city"
            options={cityOptions}
            handleSelect={setRegencyId}
          />
        </FormItem>

        <FormItem label="district" name="district" value={data?.district?.name} isOnEdit={isOnEdit}>
          <CustomSelect placeholder="Choose your district" options={districtOptions} />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="landmark" name="landmark" value={data?.landmark} isOnEdit={isOnEdit}>
          <Input size="large" />
        </FormItem>

        <FormItem label="roadAccess" name="roadAccess" value={data?.roadAccess} isOnEdit={isOnEdit}>
          <CustomSelect placeholder="Choose your road access" options={roadAccessOptions} />
        </FormItem>

        <Form.Item label={t("map")}>
          <Button type="primary" icon={<EnvironmentFilled />} onClick={() => setModalOpen(true)}>
            {t("viewMap")}
          </Button>
        </Form.Item>
      </Col>
    </CustomForm>
  );
};

export const FormStoreOperational = ({ data, onDirty, onPristine, handleSubmit }) => {
  const { t } = useTranslation();
  const format = "HH:mm";
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        openingTime: parseToDayjs(data?.openingTime),
        closingTime: parseToDayjs(data?.closingTime),
      });
    }
  }, [data]);

  useEffect(() => {
    if (isOnEdit) onDirty();
    else onPristine();
  }, [isOnEdit]);

  const handleFinish = async (values) => {
    setIsLoading(true);
    const { openingTime, closingTime } = values;
    const isSuccess = await handleSubmit({
      openingTime: parseToDuration(openingTime),
      closingTime: parseToDuration(closingTime),
    });
    if (isSuccess) setIsOnEdit(false);
    setIsLoading(false);
  };

  return (
    <CustomForm
      title="storeOperational"
      form={form}
      isOnEdit={isOnEdit}
      isLoading={isLoading}
      setIsOnEdit={setIsOnEdit}
      handleFinish={handleFinish}
    >
      <Col span={12}>
        <FormItem
          label="openingHours"
          name="openingTime"
          value={parseToDayjs(data?.openingTime).format(format)}
          isOnEdit={isOnEdit}
        >
          <TimePicker size="large" showSecond={false} format={format} />
        </FormItem>

        <FormItem
          label="closingHours"
          name="closingTime"
          value={parseToDayjs(data?.closingTime).format(format)}
          isOnEdit={isOnEdit}
        >
          <TimePicker size="large" showSecond={false} format={format} />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="loginBaskitShop" value={t(data?.shopLoggedIn ? "yes" : "no")} />

        <Form.Item label="viewStoreFoto">
          <ImageCustom
            className="image-none"
            src={data?.storePhoto}
            preview={{
              visible,
              scaleStep: 0.5,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
          <Button type="primary" icon={<FileImageOutlined />} onClick={() => setVisible(true)}>
            {t("viewStoreFoto")}
          </Button>
        </Form.Item>
      </Col>
    </CustomForm>
  );
};

export const FormOthers = ({ data, onDirty, onPristine, handleSubmit }) => {
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      notes: data?.notes,
    });
  }, [data]);

  useEffect(() => {
    if (isOnEdit) onDirty();
    else onPristine();
  }, [isOnEdit]);

  const handleFinish = async (values) => {
    setIsLoading(true);
    const isSuccess = await handleSubmit(values);
    if (isSuccess) setIsOnEdit(false);
    setIsLoading(false);
  };

  return (
    <CustomForm
      title="others"
      form={form}
      isOnEdit={isOnEdit}
      isLoading={isLoading}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 20 }}
      setIsOnEdit={setIsOnEdit}
      handleFinish={handleFinish}
    >
      <Col span={24}>
        <FormItem label="internalNotes" name="notes" value={data?.notes} isOnEdit={isOnEdit}>
          <Input.TextArea value={data?.detailAddress} showCount />
        </FormItem>
      </Col>
    </CustomForm>
  );
};

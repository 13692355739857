import React, { useEffect } from "react";
import { Select, Checkbox, Divider, Button, Space, ConfigProvider } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../utils/Functions";
import { debounce, isEmpty } from "lodash-es";
import "./MultipleSelectCategory.scss";

const { Option } = Select;

const MultipleSelectCategory = ({ options, handleChange, getPopupContainer = false }) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (isFirstRendering) {
      setIsFirstRendering(false);
      return;
    }

    const debouncedAction = debounce(() => {
      handleChange([]);
    }, 100);

    if (isEmpty(selectedOptions)) debouncedAction();
  }, [selectedOptions]);

  const handleOptionChecked = (value) => {
    const index = selectedOptions.indexOf(value);
    if (index > -1) {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((option) => option !== value),
      );
    } else {
      if (!selectedOptions.includes(value)) {
        const result = [...selectedOptions, value];
        setSelectedOptions(result);
      }
    }
  };

  const handleOptionSelect = (values) => {
    const result = [...selectedOptions];
    values.map((value) => {
      if (!result.includes(value)) result.push(value);
    });
    setSelectedOptions(result);
  };

  const renderOption = (option) => (
    <Option
      className="multiple-select-category-option"
      key={option?.value}
      value={option?.value}
      label={option?.label}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Checkbox
        checked={selectedOptions.includes(option?.value)}
        onChange={() => handleOptionChecked(option?.value)}
      />
      <span className="label">{capitalizeFirstLetter(option?.label || '')}</span>
    </Option>
  );

  const handleSelectOpen = () => {
    if (!dropdownOpen) setDropdownOpen(true);
  };

  return (
    <div id="multiple-select-category-wrapper">
      <Select
        className="multiple-select-category"
        placeholder={t("All Categories")}
        mode="multiple"
        maxTagCount="responsive"
        value={selectedOptions}
        defaultOpen={dropdownOpen}
        open={dropdownOpen}
        getPopupContainer={getPopupContainer}
        onChange={(value) => handleOptionSelect(value)}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLocaleLowerCase())
        }
        onFocus={handleSelectOpen}
        onClick={handleSelectOpen}
        onBlur={() => setDropdownOpen(false)}
        dropdownRender={(menu) => (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {menu}
            <Divider style={{ margin: "8px 0px" }} />
            <Space
              className="buttons-wrapper"
              direction="vertical"
              size="small"
              style={{ width: "100%", marginBottom: "4px" }}
            >
              <ConfigProvider theme={{ token: { colorPrimary: "#247cff" } }}>
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    handleChange([...selectedOptions]);
                    setDropdownOpen(false);
                  }}
                >
                  {t("Apply")}
                </Button>
              </ConfigProvider>

              <Button
                block
                type="link"
                onClick={() => {
                  setSelectedOptions([]);
                  setDropdownOpen(false);
                }}
                icon={isEmpty(selectedOptions) ? <CloseOutlined /> : <UndoOutlined />}
              >
                {t(isEmpty(selectedOptions) ? "Close" : "Reset")}
              </Button>
            </Space>
          </div>
        )}
      >
        {options?.map((option) => renderOption(option))}
      </Select>
    </div>
  );
};

export default MultipleSelectCategory;

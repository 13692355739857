import React from "react";
import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import "./InputNumberCustom.scss";

const InputNumberCustom = ({ placeholder, defaultValue = 0, disabled = false, handleChange = () => {} }) => {
  const { t } = useTranslation();

  return (
    <InputNumber
      className='input-number'
      placeholder={t(placeholder)}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default InputNumberCustom;

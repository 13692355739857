import Cookies from "js-cookie";
import swal from "sweetalert";
import { httpClient } from "../utils/HttpClient";
import {
  USER_FETCHING,
  USER_SUCCESS,
  USER_LIST_SUCCESS,
  USER_FAILED,
  USER_CLEAR,
  USER_ME_FETCHING,
  USER_ME_SUCCESS,
  USER_ME_FAILED,
  server,
} from "../constants";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";

export const setUserStateToFetching = () => ({
  type: USER_FETCHING,
});
export const setUserStateToFailed = () => ({
  type: USER_FAILED,
});
export const setUserStateToClear = () => ({
  type: USER_CLEAR,
});
export const setUserStateToSuccess = (payload) => ({
  type: USER_SUCCESS,
  payload,
});
export const setUserListStateToSuccess = (payload) => ({
  type: USER_LIST_SUCCESS,
  payload,
});

export const setUserMeStateToFetching = () => ({
  type: USER_ME_FETCHING,
});
export const setUserMeStateToFailed = () => ({
  type: USER_ME_FAILED,
});
export const setUserMeStateToSuccess = (payload) => ({
  type: USER_ME_SUCCESS,
  payload,
});

export const ListUser = () => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "user/user-role");
    if (response.data.result === "success") {
      // console.log(response.data);
      dispatch(setUserStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setUserStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const createMember = (values, history) => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "user/member", values);
    if (response.data.result === "success") {
      dispatch(setUserStateToSuccess(response.data));
      history.push("/my-account/member");
    } else if (response.data.result === "error") {
      dispatch(setUserStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getUserListMember = () => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "user/list-member");
    if (response.data.result === "success") {
      dispatch(setUserStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setUserStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const assignCompany = (values) => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    const response = await httpClient.put(
      process.env.REACT_APP_API_URL + "user/assign-company",
      values,
    );
    if (response.data.result === "success") {
      swal("Success!", response.data.message, "success").then(() => {
        dispatch(setUserStateToClear());
        dispatch(getUserListMember());
      });
    } else if (response.data.result === "error") {
      dispatch(setUserStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getUserList = () => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "user/list");
    if (response.data.result === "success") {
      dispatch(setUserListStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setUserStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const getSalesman = () => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "user/sales");
      dispatch(setUserStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setUserStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const setTarget = (values, history) => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    try {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + "sales-target", values);
      dispatch(setUserStateToClear());
      SuccessNotification(response);
      history.goBack();
    } catch (error) {
      dispatch(setUserStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const getUserDetails = (userId) => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "profile/id/" + userId);
      dispatch(setUserStateToSuccess(response.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setUserStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const updateUserProfile = (values, history) => {
  return async (dispatch) => {
    dispatch(setUserStateToFetching());
    try {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + "profile", values);
      dispatch(setUserStateToClear());
      SuccessNotification(response);
      history.goBack();
    } catch (error) {
      dispatch(setUserStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const itsMe = () => {
  return async (dispatch) => {
    dispatch(setUserMeStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL_NEW_V2 + "users/me");
      const { id, firstName, lastName, businessName, companies, roles } = response.data.data;
      const values = {
        id,
        firstName: firstName ?? "User",
        lastName: lastName ?? "",
        businessName: businessName ?? "",
        businessName: businessName ?? "",
        companyId: companies ? companies[0]?.id : null,
        companyName: companies ? companies[0]?.companyName : "",
        userRole: (roles ? roles[0]?.roleName : "").toUpperCase(),
      };
      localStorage.setItem(server.ABOUT_USER, JSON.stringify(values));
      localStorage.setItem(server.USER_ROLE, values.userRole);
      localStorage.setItem(server.ROLES, JSON.stringify(roles.map(role => role.roleName)));
      return true;
    } catch (error) {
      dispatch(setUserMeStateToFailed());
      return false;
    }
  };
};

import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InputOTP } from "antd-input-otp";
import { Message } from "../../utils/Message";
import { server } from "../../constants";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import LoginContent from "../../components/organisms/LoginContent/LoginContent";
import * as loginActions from "../../actions/login.action";
import * as userActions from "../../actions/user.action";
import "./_style.scss";

const { Title, Text } = Typography;

const OTP = () => {
  const constants = {
    SUCCESS: "success",
    FAILED: "failed",
    RESEND: "resend",
    TIMEREMAINING: "time_remaining",
  };
  const initialTime = 300;
  const getPhone = localStorage.getItem(server.LOGIN_VALUES);
  const getTimeRemaining = localStorage.getItem(constants.TIMEREMAINING);
  const initialTimeRemaining = getTimeRemaining ? parseInt(getTimeRemaining) : initialTime;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [otpStatus, setOtpStatus] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(initialTimeRemaining);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    loginActions.logout();
  }, []);

  useEffect(() => {
    if (getPhone !== null) setPhone(getPhone);
  }, [getPhone]);

  useEffect(() => {
    let intervalId;

    if (timeRemaining <= 0) {
      clearInterval(intervalId);
      setOtpStatus(constants.RESEND);
      return Message("error", t("Your OTP code has expired"));
    } else {
      intervalId = setInterval(() => {
        let currentTime = timeRemaining - 1;
        Cookies.set(constants.TIMEREMAINING, currentTime.toString());
        setTimeRemaining(currentTime);
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [timeRemaining]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const checkValidOTP = (array) => {
    if (!Array.isArray(array) || array.length !== 6) return false;
    const containsEmptyString = array.some((item) => item === "");
    if (containsEmptyString) return false;
    return true;
  };

  const formatPhoneNumber = (phoneNumber) => {
    const numericOnly = phoneNumber.replace(/\D/g, "");
    if (numericOnly.length === 0) {
      return phoneNumber;
    }
    let formattedNumber = "+" + numericOnly.substring(0, 2) + "-";
    let i = 2;
    while (i < numericOnly.length) {
      const remainingDigits = numericOnly.length - i;
      const groupLength = remainingDigits > 4 ? 4 : remainingDigits;
      formattedNumber += numericOnly.substring(i, i + groupLength) + "-";
      i += groupLength;
    }

    formattedNumber = formattedNumber.slice(0, -1);
    return formattedNumber;
  };

  const handleResendOTPCode = async () => {
    const value = { username: phone };
    const isSuccess = await dispatch(loginActions.sendOtp(value));
    if (isSuccess) {
      Cookies.set(server.LOGIN_VALUES, phone);
      setTimeRemaining(300);
      setOtpStatus("success");
    }
  };

  const handleSubmit = async () => {
    const pincode = form.getFieldValue("otpNumber").join("");
    const values = { username: phone, password: pincode };

    setIsLoading(true);
    const isSuccess = await dispatch(loginActions.auth(values));
    if (isSuccess) {
      const isItsMeSuccess = await dispatch(userActions.itsMe());
      if (isItsMeSuccess) {
        Cookies.remove(server.LOGIN_VALUES);
        Cookies.remove(constants.TIMEREMAINING);
        setIsLoading(false);
        return (window.location.href = "/home");
      }else setIsLoading(false);
    }else setIsLoading(false);
  };

  const render = () => (
    <LoginContent>
      <div id="login-otp-wrapper" className="flex-center">
        <div className="login-otp-card">
          <div className="login-otp-card-header">
            <div className="button-title-back" onClick={() => history.goBack()}>
              <Space direction="horizontal">
                <ArrowLeftOutlined />
                <Title level={3} style={{ margin: 0 }}>
                  {t("Login")}
                </Title>
              </Space>
            </div>
          </div>
          <div>
            <Divider className="login-divider" />
          </div>
          <div className="login-otp-card-content">
            <Row gutter={12}>
              <Col span={24}></Col>
              <Col span={15}>
                <Text>{t("Enter the OTP code received through WhatsApp sent to your number")}</Text>
              </Col>
              <Col span={9} className="flex-center">
                <Text className="phone-number-label" strong>
                  {phone ? formatPhoneNumber(phone) : "-"}
                </Text>
              </Col>
              <Col span={24} className="text-center mt-20">
                <Text className="warning-notification">
                  {t("Do not share the OTP code with anyone")}
                </Text>
              </Col>
              <Col span={24} className="mt-40">
                <Form form={form} onFinish={handleSubmit}>
                  <Form.Item name="otpNumber">
                    <InputOTP
                      autoFocus
                      inputType="numeric"
                      length={6}
                      status={otpStatus === constants.FAILED && "error"}
                      onChange={(value) => {
                        setIsButtonDisabled(!checkValidOTP(value));
                        if (!checkValidOTP(value) && otpStatus !== constants.RESEND)
                          setOtpStatus("");
                      }}
                    />
                  </Form.Item>
                  <Form.Item className="text-center">
                    <Space direction="vertical" className="login-otp-countdown-timer">
                      <div>
                        {otpStatus !== constants.RESEND ? (
                          <>
                            <Text>{t("OTP code is valid for")} </Text>
                            <Text strong>{formatTime(timeRemaining)}</Text>
                          </>
                        ) : (
                          <Text>
                            {t(
                              "Your OTP code has expired. You can resend the code using the link below",
                            )}
                          </Text>
                        )}
                      </div>
                      <Button
                        disabled={otpStatus !== constants.RESEND ? true : false}
                        className="button-resend-otp"
                        type="link"
                        htmlType="button"
                        onClick={handleResendOTPCode}
                      >
                        {t("Resend code")}
                      </Button>
                    </Space>
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <ButtonPrimary
                      loading={isLoading}
                      className={
                        (isButtonDisabled || otpStatus === constants.RESEND) && "button-disabled"
                      }
                      disabled={isButtonDisabled || otpStatus === constants.RESEND}
                      text="Verify"
                      block={true}
                      htmlType="submit"
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="login-otp-card-footer">
            <Row gutter={12} className="mt-20">
              <Col span={12}>
                <Text>{t("Or are you a new user")}?</Text>
              </Col>
              <Col span={12} className="text-right">
                <a className="register-link" href="/register">
                  {t("Register here")}
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </LoginContent>
  );

  return getPhone === null ? <Redirect to="/login" /> : render();
};

export default OTP;

import React from "react";
import { Button, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import "./ButtonSecondary.scss";

const ButtonSecondary = ({
  text,
  block = false,
  disabled,
  className = "",
  htmlType = "button",
  danger = false,
  icon,
  handleClick,
}) => {
  const { t } = useTranslation();
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#0a8080" } }}>
      <Button
        className={"button-secondary " + className}
        type="primary"
        disabled={disabled}
        htmlType={htmlType}
        block={block}
        danger={danger}
        onClick={handleClick}
        icon={icon}
      >
        {t(text)}
      </Button>
    </ConfigProvider>
  );
};

export default ButtonSecondary;

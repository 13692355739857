import { Spin } from "antd";
import React from "react";
import "./SpinLoaderContent.scss";

const SpinLoaderContent = () => {
  return (
    <div id='spin-loader-content'>
      <Spin tip='Loading' size='large' />
    </div>
  );
};

export default SpinLoaderContent;

import React, { useEffect, useState } from "react";
import { Button, Col, Image, InputNumber, List, Row, Space, Typography } from "antd";
import { formatRupiah } from "../../../utils/Functions";
import { DeleteOutlined, ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";

const { Text } = Typography;

const EditOrderCartListItem = ({
  data,
  setModalOpen,
  setNotificationModalOpen,
  selectedProductDelete,
  setSelectedProductDelete,
  unavailableProducts,
  setUnavailableProducts,
  isCancelDeleteProduct,
  setIsCancelDeleteProduct,
  handleUpdateCart
}) => {
  const { t } = useTranslation();
  const { _id, name, price, qty, stock, uomLabel, image } = data;
  const [updatedQty, setUpdatedQty] = useState(qty);
  const [isOnClick, setIsOnClick] = useState(false);

  useEffect(() => {
    if (!isOnClick) return;

    const debouncedAction = debounce(() => {
      if (updatedQty > stock) return;
      if (updatedQty <= 0) {
        setSelectedProductDelete({ product_id : _id });
        setNotificationModalOpen(true);
      } else {
        handleUpdateCart(_id, updatedQty)
      }
      setIsOnClick(false);
    }, 1500);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [updatedQty, isOnClick]);

  useEffect(() => {
    setUpdatedQty(qty);
  }, [qty]);

  useEffect(() => {
    const _unavailableProducts = { ...unavailableProducts };
    if (updatedQty > stock) {
      _unavailableProducts[_id] = updatedQty;
    } else {
      delete _unavailableProducts[_id];
    }
    setUnavailableProducts(_unavailableProducts);
  }, [updatedQty, stock]);

  useEffect(() => {
    if (isCancelDeleteProduct && selectedProductDelete.product_id === _id) {
      setUpdatedQty(1);
      setIsOnClick(true);
      setIsCancelDeleteProduct(false);
    }
  }, [isCancelDeleteProduct, selectedProductDelete]);

  return (
    <List.Item>
      <Row className='full-width'>
        <Col span={8}>
          <Image
            className='choose-product-cart-list-image'
            width={"100%"}
            height={90}
            preview={false}
            src={process.env.REACT_APP_API_URL + "images/product/" + (image ? image : 'default.jpg')}
            fallback={process.env.REACT_APP_API_URL + "images/product/default.jpg"}
          />
        </Col>
        <Col span={14}>
          <div className='choose-product-cart-list-title'>
            <Text className='ant-typography-ellipsis-multiple-line'>{name}</Text>
          </div>
          <div>
            <Space direction='horizontal'>
              <Text strong>{formatRupiah(price ? price : 0)}</Text>
              {/* <Text className='font-size-12' type='secondary' delete>
                {formatRupiah(price ? price : 0)}
              </Text> */}
            </Space>
          </div>
          <div>
            <Space direction='horizonatal'>
              <Text strong className='font-size-12'>
                {t("Stock")} : {stock}
              </Text>
              <span>|</span>
              <Text className='font-size-12'>
                {t("Packaging")} : {uomLabel || "-"}
              </Text>
            </Space>
          </div>
        </Col>
        <Col span={2}>
          <Button
            className='text-gray'
            size='small'
            type='default'
            htmlType='button'
            icon={<DeleteOutlined />}
            onClick={() => {
              setSelectedProductDelete({ product_id: _id });
              setModalOpen(true);
            }}
          />
        </Col>
        <Col span={24}>
          <Row className='full-width mt-10'>
            <Col span={8} className='flex-center-align'>
              <Text strong>{t("Total Item")}</Text>
            </Col>
            <Col span={2}>
              <Button
                className='choose-product-cart-list-button'
                size='small'
                type='default'
                shape='circle'
                icon={<MinusOutlined />}
                onClick={() => {
                  if (updatedQty > 0) {
                    setUpdatedQty((prevQty) => prevQty - 1);
                    setIsOnClick(true);
                  }
                }}
              />
            </Col>
            <Col span={12} className='pl-10 pr-10'>
              <InputNumber
                status={updatedQty > stock && "error"}
                className='choose-product-cart-list-input-number'
                value={updatedQty}
                onChange={(value) => {
                  setUpdatedQty(value);
                  setIsOnClick(true);
                }}
              />
            </Col>
            <Col span={2}>
              <Button
                className='choose-product-cart-list-button'
                size='small'
                type='primary'
                shape='circle'
                icon={<PlusOutlined />}
                onClick={() => {
                  setUpdatedQty((prevQty) => prevQty + 1);
                  setIsOnClick(true);
                }}
              />
            </Col>
            {updatedQty > stock && (
              <Col span={24} className='mt-10'>
                <div className='choose-product-alert flex-center-align'>
                  <ExclamationCircleOutlined className='choose-product-alert-icon' />
                  <Text className='choose-product-alert-title'>
                    {t("Maaf, total item melebihi stok yang Anda miliki")}
                  </Text>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </List.Item>
  );
};

export default EditOrderCartListItem;

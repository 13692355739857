import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { columns as defaultColumns } from "./helpers";
import { filterParams } from "../../utils/Functions";
import { useTranslation } from "react-i18next";
import MainContent from "../../components/organisms/Content/Content";
import CustomLink from "../../components/atoms/CustomLink/CustomLink";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import * as companyActions from "../../actions/company.action";
import "./_style.scss";

const MerchantList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");

  const columns = useMemo(() => {
    return defaultColumns.map((column) => ({ ...column, title: t(column?.title) }));
  }, [t]);

  const { companySeller } = useSelector((state) => state.companyReducer);

  const params = useMemo(() => {
    const paramsConstruct = {
      $limit: pageSize,
      $page: page,
      search: searchValue,
    };
    return filterParams(paramsConstruct);
  }, [page, pageSize, searchValue]);

  useEffect(() => {
    dispatch(companyActions.seller(params));
  }, [params]);

  useEffect(() => {
    if (companySeller) {
      const { data, meta } = companySeller;
      setData(data);
      setMeta(meta);
      setLoading(false);
    }
  }, [companySeller]);

  const dataSource = data.map((data, index) => ({
    key: index,
    merchantName: (
      <div
        onClick={() => {
          window?.parent?.postMessage(
            {
              type: 'merchantList',
              payload: data?.id
            },
            process.env.REACT_APP_NEW_CMS
          )
        }}
      >
        <CustomLink key={index} text={data?.companyName} path={`/merchant-list/detail/${data?.id}`} />
      </div>
    ),
    ownerName: data?.ownerName,
    phoneNo: data?.phone,
    partnershipType: data?.type,
    merchantID: data?.merchantId,
    region: data?.region,
    district: data?.districtNo,
    subdistrict: data?.subdistrict,
    status: data?.status,
  }));

  const headerProps = {
    title: "Merchant List",
    description: "Manage your merchant list",
  };

  const productSearchFilterProps = {
    placeholder: "Search merchant by name",
    handleChange: (value) => setSearchValue(value),
  };

  const tableProps = {
    loading: loading,
    dataSource: dataSource,
    columns,
    pagination: {
      pageSize: pageSize,
      current: page,
      total: meta?.totalData,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
    },
    scroll: { x: 1500, y: "60vh" },
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="merchant-list-wrapper">
        <div id="merchant-list-filter">
          <Row gutter={12}>
            <Col span={24}>
              <ProductSearchFilter {...productSearchFilterProps} />
            </Col>
          </Row>
        </div>
        <div id="merchant-list-content">
          <Table async {...tableProps} />
        </div>
      </div>
    </MainContent>
  );
};

export default MerchantList;

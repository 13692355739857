import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterParams } from "../utils/Functions";
import * as productActions from "../actions/product.action";

const useSkuMasterData = (params) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { productMaster } = useSelector(({ productReducer }) => productReducer);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    dispatch(productActions.productMaster(filterParams(params)));
  }, [dispatch, params]);

  const refetchData = () => {
    fetchData();
  };

  const fetchMoreData = (params) => {
    dispatch(productActions.loadMoreProductMaster(filterParams(params)));
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, params]);

  useEffect(() => {
    if (productMaster) {
      const { data, meta: { totalData = 0, totalPage = 0 } = {} } = productMaster;
      setData(data);
      setTotalData(totalData);
      setTotalPage(totalPage);
    }
    setIsLoading(false);
  }, [productMaster, setData, setTotalData, setTotalPage, setIsLoading]);

  return { data, totalData, totalPage, isLoading, refetchData, fetchMoreData };
};

export default useSkuMasterData;

import React from "react";
import { Col, Divider, Modal, Row, Space, Typography } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";
import MapsComponent from "../../atoms/Maps/GoogleMaps";
import "./ViewMapModal.scss";

const { Text } = Typography;

const ViewMapModal = ({
  modalOpen,
  setModalOpen,
  address,
  coordinate = { lat: -6.175392, lng: 106.827153 },
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="view-map-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={900}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row className="mt-30" gutter={12}>
        <Col span={8}>
          <div className="address-wrapper">
            <Space direction="horizontal" className="full-width pl-10 pr-10 pt-10">
              <EnvironmentFilled className="address-icon" />
              <Text>{t("Merchant Address")}</Text>
            </Space>
            <Divider />
            <div className="pl-10 pr-10 pb-10">
              <Text>{address}</Text>
            </div>
          </div>
        </Col>
        <Col span={16}>
          <MapsComponent
            isMarkerShown
            location={{
              lat: coordinate?.lat,
              lng: coordinate?.lng,
            }}
            zoom={15}
          />
          <ButtonSecondary
            className="mt-20"
            text={t("View Map")}
            block={true}
            icon={<EnvironmentFilled />}
            handleClick={() =>
              window.open(`https://www.google.com/maps?q=${coordinate?.lat},${coordinate?.lng}`)
            }
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewMapModal;

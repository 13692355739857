import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, ConfigProvider, Row, Switch, Table, Tag } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import MainContent from "../../organisms/Content/Content";
import ProductSearchFilter from "../../atoms/ProductSearchFilter/ProductSearchFilter";
import AddSalesmanModal from "../../molecules/AddSalesmanModal/AddSalesmanModal";
import SortProduct from "../../atoms/SortProduct/SortProduct";
import MainBreadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import CustomLink from "../../atoms/CustomLink/CustomLink";
import * as districtActions from "../../../actions/district.action";
import "./_style.scss";

const Sales = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [params, setParams] = useState({});

  const district = useSelector((state) => state.districtReducer);

  useEffect(() => {
    dispatch(districtActions.districtSearch(params));
  }, []);

  useEffect(() => {
    if (district.districtSearch) {
      const { data, meta } = district.districtSearch;
      setData(data);
      setMeta(meta);
      setLoading(false);
    }
  }, [district]);

  useEffect(() => {
    const _params = { ...params, $page: page, $limit: pageSize };
    dispatch(districtActions.districtSearch(_params));
    setParams(_params);
  }, [page, pageSize]);

  const handleUpdateStatus = async (districtId, value) => {
    const isSuccess = await dispatch(districtActions.updateStatus(districtId, value));
    if (isSuccess) {
      dispatch(districtActions.districtSearch(params));
    }
  };

  const column = [
    {
      title: t("city"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("district"),
      dataIndex: "district",
      key: "district",
      render: (data) => (
        <CustomLink text={data?.name} path={`/coverage-area/${data?.id}`} />
      ),
    },
    {
      title: t("sellerAssignment"),
      dataIndex: "sellerAssignment",
      key: "sellerAssignment",
      render: (assignment) => <AssignmentTag assignment={assignment} />,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (data) => <UpdateStatusSwitch data={data} handleChange={handleUpdateStatus} />,
    },
  ];

  const dataSource = data?.map((data, index) => ({
    key: index,
    city: data?.regencyName,
    district: data,
    sellerAssignment: data?.totalAssigned,
    status: data,
  }));

  const handleCoverageAreaSearch = (value) => {
    const _params = { ...params };
    if (value) _params.search = value;
    else delete _params.search;
    dispatch(districtActions.districtSearch(_params));
    setParams(_params);
  };

  const handleCoverageAreaSort = (value) => {
    const _params = { ...params };
    if (value === null || value === undefined) delete _params.active;
    else _params.active = value;
    setLoading(true);
    dispatch(districtActions.districtSearch(_params));
    setParams(_params);
  };

  const headerProps = {
    title: "Account Manager",
    description: "Manage your account",
  };

  const mainBreadcrumbItems = [
    {
      title: "coverageArea",
    },
  ];

  const salesSearchFilterProps = {
    placeholder: "searchCoverageArea",
    options: [],
    handleChange: handleCoverageAreaSearch,
  };

  const addSalesmanModalProps = {
    modalOpen,
    setModalOpen,
    buttonText: "Submit",
  };

  const sortCoverageAreaProps = {
    placeholder: "Status",
    handleChange: handleCoverageAreaSort,
    options: [
      { value: null, label: "All" },
      { value: true, label: "Active" },
      { value: false, label: "Inactive" },
    ],
  };

  const tableProps = {
    loading: loading,
    dataSource: dataSource,
    columns: column,
    pagination: {
      pageSize: pageSize,
      current: page,
      total: meta?.totalData,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
    },
    scroll: { y: "60vh" },
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="sales-wrapper">
        <div id="sales-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div id="sales-filter">
          <Row gutter={24}>
            <Col span={20}>
              <ProductSearchFilter {...salesSearchFilterProps} />
            </Col>
            <Col span={4}>
              <SortProduct {...sortCoverageAreaProps} />
            </Col>
          </Row>
        </div>

        <div id="sales-content">
          <Table async {...tableProps} />
        </div>
      </div>

      <AddSalesmanModal {...addSalesmanModalProps} />
    </MainContent>
  );
};

const AssignmentTag = ({ assignment }) => {
  const { t } = useTranslation();
  const store = assignment > 1 ? "sellers" : "seller";
  assignment = assignment ? assignment : 0;

  return (
    <div className="antd-content-center">
      <Tag icon={<ShopOutlined />} color="orange">
        {assignment} {t(store)}
      </Tag>
    </div>
  );
};

const UpdateStatusSwitch = ({ data, handleChange }) => {
  const { t } = useTranslation();

  return (
    <div className="antd-content-center">
      <ConfigProvider theme={{ token: { colorPrimary: "#03AC00" } }}>
        <Switch checked={data?.active} onChange={(value) => handleChange(data?.id, value)} />
      </ConfigProvider>
      <span className="ml-10">{t(data?.active == true ? "Active" : "Inactive")}</span>
    </div>
  );
};

export default Sales;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileZipOutlined } from "@ant-design/icons";
import { Col, Empty, Row } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash-es";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import InvoiceCard from "../../components/molecules/InvoiceCard/InvoiceCard";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import * as orderActions from "../../actions/order.action";
import "./invoice.scss";

const Invoice = () => {
  const { companyId, invoiceId } = useParams();
  const params = { withPrices: "base" };
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [orderId, setOrderId] = useState('')

  const order = useSelector((state) => state.orderReducer);

  useEffect(() => {
    dispatch(orderActions.getOrderDetail(invoiceId, params));
  }, []);

  useEffect(() => {
    if (!isEmpty(order?.detail)) {
      const result = order?.detail;
      const values = [
        {
          key: "invoice",
          name: "Invoice",
          invFile: result?.invoice?.invFile,
        },
        {
          key: "delivery-note",
          name: "Delivery Note",
          invFile: result?.invoice?.invDeliveryFile,
        },
      ];
      setOrderId(result?.invoice?.order_id);
      setData(values);
    }
  }, [order]);

  const handleDownloadAllInvoices = () => {
    dispatch(orderActions.downloadZipInvoice(orderId));
  };

  const handleDownloadInvoice = (type) => {
    dispatch(orderActions.downloadPDFInvoice(orderId, {type}));
  };

  const headerProps = {
    buttonBack: { text: "Invoice", status: true },
    description: "Invoice Detail",
  };

  const mainBreadcrumbItems = [
    {
      link: `/merchant-list/detail/${companyId}?openTabs=transactions`,
      title: "Merchant Detail",
    },
    {
      title: "Invoice Detail",
    },
  ];

  return (
    <MainContent headerProps={headerProps}>
      <div id="transaction-invoice-wrapper">
        <div id="transaction-invoice-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id="transaction-invoice-action">
          <Row>
            <Col offset={20} span={4}>
              <ButtonPrimary
                text="Download All Invoices"
                icon={<FileZipOutlined />}
                block={true}
                handleClick={handleDownloadAllInvoices}
              />
            </Col>
          </Row>
        </div>
        {isEmpty(data) ? (
          <div className="mt-50">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : (
          <div id="transaction-invoice-card">
            {data.map((invoice) => (
              <InvoiceCard
                key={invoice.name}
                title={invoice.name}
                handleClick={() => handleDownloadInvoice(invoice?.key)}
              />
            ))}
          </div>
        )}
      </div>
    </MainContent>
  );
};

export default Invoice;

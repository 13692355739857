import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Segmented, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import MainContent from "../../components/organisms/Content/Content";
import Information from "./information";
import SKUManagement from "./skuManagement";
import Transaction from "./transaction";
import SkuMaster from "../../components/templates/SkuMaster/SkuMaster";
import * as companyActions from "../../actions/company.action";
import "./_detail.scss";

const { Text, Title } = Typography;

const MerchantManagement = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const openTabs = searchParams.get("openTabs");
  const [activeTabKey, setActiveTabKey] = useState(openTabs ? openTabs : "information");

  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const { companySellerDetails } = useSelector((state) => state.companyReducer);

  useEffect(() => {
    dispatch(companyActions.sellerDetails(id));
  }, []);

  useEffect(() => {
    if (companySellerDetails) {
      setData(companySellerDetails);
    }
  }, [companySellerDetails]);

  const handleChangeTabs = (value) => {
    searchParams.set("openTabs", value);
    history.replace({ search: searchParams.toString() });
    setActiveTabKey(value);
  };

  const headerProps = {
    buttonBack: { text: "Merchant Detail", status: true },
    description: "Manage your merchant details",
  };

  const mainBreadcrumbItems = [
    {
      link: "/merchant-list",
      title: "Merchant List",
    },
    {
      title: "Merchant Detail",
    },
  ];

  const segmentedOptions = [
    { label: t("Information"), value: "information" },
    { label: t("SKU Management"), value: "sku-management" },
    { label: t("Add Product"), value: "add-product" },
    { label: t("Transactions"), value: "transactions" },
  ];

  const isInsideIframe = window.parent !== window;

  return (
    <MainContent headerProps={headerProps}>
      <div id="merchant-detail-wrapper" className="mb-10">
        <div id="merchant-detail-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div id="merchant-detail-subheader">
          <Row gutter={12}>
            <Col span={12}>
              <Title level={5}>{data?.companyName}</Title>
            </Col>
            <Col span={12} className="text-right">
              <Space direction="horizontal" size="large">
                <div>
                  <Text>{t("Joining date")}: </Text>
                  <Text strong>{dayjs(data?.joinDate).format("DD MMM YYYY") || "-"}</Text>
                </div>
                <span>|</span>
                <div>
                  <Text>{t("Status")}: </Text>
                  <Text strong>{data?.status || "-"}</Text>
                </div>
              </Space>
            </Col>
          </Row>
        </div>

        {isInsideIframe ? (
          null
        ) : (
          <div id="merchant-detail-segmented">
            <Row>
              <Col span={14}>
                <Segmented
                  className="segmented-custom"
                  block
                  size="large"
                  options={segmentedOptions}
                  defaultValue={activeTabKey}
                  value={activeTabKey}
                  onChange={handleChangeTabs}
                />
              </Col>
            </Row>
          </div>
        )}

        <div id="merchant-detail-content">
          {activeTabKey === "information" && <Information id={id} data={data} />}
          {activeTabKey === "sku-management" && <SKUManagement merchantDetail={data} />}
          {activeTabKey === "add-product" && <SkuMaster />}
          {activeTabKey === "transactions" && (
            <Transaction
              companyId={id}
              columns={{
                serialNo: true,
                date: true,
                updatedAt: true,
                orderID: true,
                customer: true,
                total: true,
                marginValue: true,
                deliveryType: true,
                sales: true,
                status: true,
                transactionDetails: true,
              }}
            />
          )}
        </div>
      </div>
    </MainContent>
  );
};

export default MerchantManagement;

import {
    SALES_ASSIGNED_FETCHING,
    SALES_ASSIGNED_SUCCESS,
    SALES_ASSIGNED_FAILED,
    SALES_ASSIGNED_CLEAR,
  } from "../constants";
  
  const initialState = {
    isFetching: false,
    isError: false,
    result: null,
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case SALES_ASSIGNED_FETCHING:
        return { ...state, isFetching: true, isError: false, result: null };
      case SALES_ASSIGNED_FAILED:
        return { ...state, isFetching: false, isError: true, result: null };
      case SALES_ASSIGNED_SUCCESS:
        return { ...state, isFetching: false, isError: false, result: payload };
      case SALES_ASSIGNED_CLEAR:
        return { ...state, result: null, isFetching: false, isError: false };
      default:
        return state;
    }
  };
  
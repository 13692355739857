import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { httpClient } from "../../utils/HttpClient";
import { ErrorNotification } from "../../utils/Notification";
import { parsePhoneNumber } from "../../utils/Functions";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import FormItemPhoneNumber from "../../components/atoms/FormItemPhoneNumber";
import FormItemAddress from "../../components/atoms/FormItemAddress";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import * as userActions from "../../actions/user.action";
import "./_style.scss";

const UpdateProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isFetchingSuccess, setIsFetchingSuccess] = useState(false);
  const [loading, setLoading] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const [form] = Form.useForm();
  const [currencyOptions] = useState([
    { value: "IDR", label: "Indonesian Rupiah" },
    { value: "USD", label: "United States Dollar" },
    { value: "SGD", label: "Singapore Dollar" },
    { value: "MYR", label: "Malaysian Ringgit" },
  ]);
  const [timeZoneOptions] = useState([
    { value: "UTC(+7)", label: "Western Indonesian Time" },
    { value: "UTC(+8)", label: "Central Indonesian Time" },
    { value: "UTC(+9)", label: "Eastern Indonesian Time" },
  ]);

  const [languageOptions] = useState([
    { value: "Bahasa", label: "Bahasa" },
    { value: "English", label: "English" },
    { value: "Malay", label: "Malay" },
  ]);

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(userActions.getUserDetails(id));
    getCountry();
  }, []);

  useEffect(() => {
    if (user.isFetching) setIsFetchingSuccess(true);
    if (!isEmpty(user.result) && isFetchingSuccess) {
      const {
        first_name,
        last_name,
        username,
        email,
        position,
        phone,
        state,
        country,
        language,
        currency,
        zip_code,
        timezone,
        address,
      } = user.result;

      const phoneFormat = parsePhoneNumber(phone);

      form.setFieldsValue({
        first_name,
        last_name,
        username,
        email,
        position,
        code_phone: phoneFormat.code,
        phone: phoneFormat.number,
        state,
        country,
        language,
        currency,
        zip_code,
        timezone,
        address,
      });
      setLoading(false);
    }
  }, [user, isFetchingSuccess]);

  const getCountry = async () => {
    await httpClient
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const options = response.data.map((item) => ({ value: item?.cca2, label: item?.name.common }));
        setCountryOptions(options);
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };

  const handleSubmit = () => {
    const {
      first_name,
      last_name,
      username,
      email,
      position,
      code_phone,
      phone,
      state,
      country,
      language,
      currency,
      zip_code,
      timezone,
      address,
    } = form.getFieldsValue();
    const dataToSend = {
      id,
      first_name,
      last_name,
      username,
      email,
      position,
      phone: code_phone + phone,
      state,
      country,
      language,
      currency,
      zip_code,
      timezone,
      address,
    };
    dispatch(userActions.updateUserProfile(dataToSend, history));
  };

  const headerProps = {
    buttonBack: { text: "Update Profile", status: true },
    description: "Manage your update profile",
  };

  const mainBreadcrumbItems = [
    {
      link: "/my-account",
      title: "My Account",
    },
    {
      title: "Update Profile",
    },
  ];
  return (
    <MainContent headerProps={headerProps}>
      <div id='update-profile-wrapper'>
        <div id='update-profile-breadcrumb'>
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id='update-profile-content'>
          {loading ? (
            <SpinLoaderContent />
          ) : (
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              layout='horizontal'
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item
                label={t("FirstName")}
                name='first_name'
                rules={[{ required: true, message: t("Please input your first name!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("LastName")}
                name='last_name'
                rules={[{ required: true, message: t("Please input your last name!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Username")}
                name='username'
                rules={[{ required: true, message: t("Please input your username!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Email")}
                name='email'
                rules={[{ required: true, message: t("Please input your email!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Position")}
                name='position'
                rules={[{ required: true, message: t("Please input position!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <FormItemPhoneNumber
                name='phone'
                label={t("Phone Number")}
                size='large'
                rules={[{ required: true, message: t("Please input phone number!") }]}
              />

              <Form.Item
                label={t("State")}
                name='state'
                rules={[{ required: true, message: t("Please input state!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Zip Code")}
                name='zip_code'
                rules={[{ required: true, message: t("Please input zip code!") }]}
              >
                <Input size='large' />
              </Form.Item>

              <Form.Item
                label={t("Language")}
                name='language'
                rules={[{ required: true, message: t("Please select your language") }]}
              >
                <Select size='large' options={languageOptions} />
              </Form.Item>

              <Form.Item
                label={t("Country")}
                name='country'
                rules={[{ required: true, message: t("Please select your country!") }]}
              >
                <Select size='large' options={countryOptions} />
              </Form.Item>

              <Form.Item
                label={t("Currency")}
                name='currency'
                rules={[{ required: true, message: t("Please select your currency!") }]}
              >
                <Select size='large' options={currencyOptions} />
              </Form.Item>

              <Form.Item
                label={t("Time Zone")}
                name='timezone'
                rules={[{ required: true, message: t("Please select your time zone!") }]}
              >
                <Select size='large' options={timeZoneOptions} />
              </Form.Item>

              <FormItemAddress
                name='address'
                label='Address'
                size='large'
                rules={[
                  {
                    required: true,
                    message: "Please input your address!",
                  },
                ]}
              />

              <Form.Item wrapperCol={{ span: 24 }}>
                <ButtonPrimary text='Save Change' className='float-right' htmlType='submit' />
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </MainContent>
  );
};

export default UpdateProfile;

import React, { useEffect, useMemo, useState } from "react";
import { Col, Pagination, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Redirect, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import { toCheckLoginToken } from "../../utils/Functions";
import { server } from "../../constants";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import CustomerListCard from "../../components/molecules/CustomerListCard/CustomerListCard";
import MainContent from "../../components/organisms/Content/Content";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import ProductEmpty from "../../components/atoms/ProductEmpty/ProductEmpty";
import ProductNotFound from "../../components/atoms/ProductNotFound/ProductNotFound";
import * as customerActions from "../../actions/customer.action";
import "./_style.scss";

const Customer = () => {
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [onFiltering, setOnFiltering] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);
  const modeTablet = tokenParams ? true : false;
  const createCustomerPath = modeTablet
    ? `/customer/create?token=${tokenParams}`
    : "/customer/create";

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data.slice(indexOfFirstData, indexOfLastData);

  const { customerOffline } = useSelector((state) => state.customerReducer);

  useEffect(() => {
    dispatch(customerActions.customerOffline());
  }, []);

  useEffect(() => {
    if (customerOffline) {
      setData(customerOffline);
      setLoading(false);
    }
  }, [customerOffline]);

  const handleInvite = (customerId) => {
    const dataToSend = { customerId: customerId };
    dispatch(customerActions.Invite(dataToSend));
  };

  const handleCustomerSearch = (value) => {
    const params = {};
    if (value) {
      params.search = value;
      setOnFiltering(true);
    } else {
      delete params.search;
      setOnFiltering(false);
    }
    dispatch(customerActions.Index(params));
  };

  const paginationProps = {
    defaultCurrent: 1,
    current: currentPage,
    total: data.length,
    pageSize: 10,
    onChange: (value) => setCurrentPage(value),
  };

  const headerProps = {
    title: "Customer",
    description: "Manage your customer",
  };

  const productSearchFilterProps = {
    placeholder: "Search by customer name",
    options: [],
    handleChange: handleCustomerSearch,
  };

  const productNotFoundProps = {
    title: "Customer not found",
    description: "Please try another keyword to find the customer you are looking for",
  };

  const productEmptyProps = {
    title: "Your customer list is still empty",
    description: "You don't have a customer list yet. Let's register your store customers first",
    button: {
      text: "Add New Customer",
      handleClick: () => history.push(createCustomerPath),
    },
  };

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id="customer-wrapper">
        <div id="customer-filter">
          <Row gutter={12}>
            <Col span={8}>
              <ProductSearchFilter {...productSearchFilterProps} />
            </Col>
            <Col span={16}>
              <ButtonPrimary
                className="float-right"
                text="Add New Customer"
                icon={<PlusOutlined />}
                handleClick={() => history.push(createCustomerPath)}
              />
            </Col>
          </Row>
        </div>
        <div id="customer-content">
          {loading ? (
            <SpinLoaderContent />
          ) : isEmpty(data) && onFiltering === false ? (
            <ProductEmpty {...productEmptyProps} />
          ) : isEmpty(data) && onFiltering === true ? (
            <ProductNotFound {...productNotFoundProps} />
          ) : (
            <Row>
              <Col span={24}>
                <div className="customer-card-wrapper">
                  {currentData.map((customer, index) => (
                    <CustomerListCard
                      key={index}
                      modeTablet={modeTablet}
                      tokenParams={tokenParams}
                      data={customer}
                      handleInvite={handleInvite}
                    />
                  ))}
                </div>
              </Col>
              <Col span={24}>
                <div className="float-right mb-20">
                  <Pagination {...paginationProps} />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to="/login" />;
};

export default Customer;

import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, List, Row, Space, Typography, Form } from "antd";
import { formatRupiah, toCheckLoginToken } from "../../utils/Functions";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { server } from "../../constants";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductEmpty from "../../components/atoms/ProductEmpty/ProductEmpty";
import MainContent from "../../components/organisms/Content/Content";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import ProductNotFound from "../../components/atoms/ProductNotFound/ProductNotFound";
import EditOrderProductPOSCard from "../../components/molecules/EditOrderProductPOSCard/EditOrderProductPOSCard";
import CartEmpty from "../../components/atoms/CartEmpty/CartEmpty";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import DeleteProductCartModal from "../../components/molecules/DeleteProductCartModal/DeleteProductCartModal";
import EditOrderCartListItem from "../../components/molecules/EditOrderCartListItem/EditOrderCartListItem";
import MultipleSelectCategory from "../../components/molecules/MultipleSelectCategory/MultipleSelectCategory";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import * as productActions from "../../actions/product.action";
import * as categoryActions from "../../actions/category.action";
import * as orderActions from "../../actions/order.action";
import "./_style.scss";

const { Text } = Typography;

const ChooseProduct = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProductDelete, setSelectedProductDelete] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [unavailableProducts, setUnavailableProducts] = useState({});
  const [isSaveUpdateButtonDisabled, setIsSaveUpdatesDisabled] = useState(true);
  const [isCancelDeleteProduct, setIsCancelDeleteProduct] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCart, setLoadingCart] = useState(true);
  const [onFiltering, setOnFiltering] = useState(false);
  const [productMetaData, setProductMetaData] = useState({});
  const [loadingInfiniteScroll, setLoadingInfiniteScroll] = useState(false);
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();

  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const isLoggedIn = toCheckLoginToken(tokenParams);
  const modeTablet = tokenParams ? true : false;

  const product = useSelector((state) => state.productReducer);
  const category = useSelector((state) => state.categoryReducer);
  const order = useSelector((state) => state.orderReducer);
  
  const checkObjectInArray = (array, searchValue, propertyName) => {
    return array.some(obj => obj[propertyName] === searchValue);
  }

  const findIndexInArray = (array, searchValue, propertyName) => {
    return array.findIndex((obj) => obj[propertyName] === searchValue);
  }

  const removeObjectByIndex = (array, index) => {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1); 
    }
  }

  useEffect(() => {
    dispatch(orderActions.getOrderDetail(id, {withPrices: "base"}));
    dispatch(productActions.Index(params));
    dispatch(categoryActions.Index());
  }, []);
  
  useEffect(() => {
    if (order?.detail) {
      const { detail } = order;
      setOrderDetail(detail?.order)
      setCartData(detail?.orderDetail);
      setLoadingCart(false);
      setLoading(false);
    }
  }, [order]);

  useEffect(() => {
    if (product.result) {
      const { data, meta } = product.result;
      const result = data.map((product)=> {
        let isAlreadyCart = false;
        if(checkObjectInArray(cartData, product._id, '_id' )) isAlreadyCart = true
        return {...product, isAlreadyCart}
      })
      setOriginalData(result);
      setData(result);
      setProductMetaData(meta);
      setLoading(false);
      setLoadingInfiniteScroll(false);
    }
  }, [product, cartData]);

  useEffect(() => {
    let _totalPrice = 0;
    let _totalProduts = 0;
    cartData.map((product) => {
      _totalPrice += product.price * product.qty
      _totalProduts += product.qty
    } );
    setTotalPrice(_totalPrice);
    setTotalProducts(_totalProduts);
  }, [cartData])

  useEffect(() => {
    if (category.result) {
      const _categoryOptions = category.result.map((category) => ({ value: category._id, label: category.label }));
      setCategoryOptions(_categoryOptions);
    }
  }, [category]);

  useEffect(() => {
    if (!isEmpty(selectedCategories)) {
      const selectResult = originalData.filter((product) => selectedCategories.includes(product.category_id));
      setData(selectResult);
      setOnFiltering(true);
    } else {
      setData(originalData);
      setOnFiltering(false);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (isEmpty(cartData) || !isEmpty(unavailableProducts)) setIsSaveUpdatesDisabled(true);
    else setIsSaveUpdatesDisabled(false);
  }, [cartData, unavailableProducts]);

  const handleDeleteProductCart = async () => {
    const cartTemp = [...cartData];
    const productIndex = findIndexInArray(cartData, selectedProductDelete.product_id, '_id');
    
    if (productIndex !== -1) {
      removeObjectByIndex(cartTemp, productIndex);
      setCartData(cartTemp);
      setModalOpen(false);
      setNotificationModalOpen(false);
    }
  };

  const handleUpdateCart = (product_id, qty) => {
    const cartTemp = [...cartData];
    const productIndex = findIndexInArray(cartTemp, product_id, "_id");

    if (productIndex !== -1) {
      const product = { ...cartTemp[productIndex] };
      product.qty = qty;
      cartTemp[productIndex] = product;
      setCartData(cartTemp);
    }
  };

  const handleCancelDeleteProduct = () => {
    setIsCancelDeleteProduct(true);
    setNotificationModalOpen(false);
  };

  const loadMoreData = () => {
    if (loadingInfiniteScroll) return;

    setLoadingInfiniteScroll(true);
    const _params = { ...params, limit: params.limit + 20 };
    setParams(_params);
    dispatch(productActions.Index(_params));
  };

  const handleProductSearch = (value) => {
    const _params = { ...params };
    if (value) {
      _params.search = value;
      setOnFiltering(true);
    } else {
      delete _params.search;
      setOnFiltering(false);
    }
    dispatch(productActions.Index(_params));
  };

  const headerProps = {
    buttonBack: { text: "orderDetails", status: true },
    description: t("Edit Order") + `: ${orderDetail?.order_id}`,
  };

  const productSearchFilterProps = {
    placeholder: "Search product name",
    options: [],
    handleChange: handleProductSearch,
  };

  const mainBreadcrumbItems = [
    {
      link: 'default',
      title: "Detail",
    },
    {
      title: "Edit Order",
    },
  ];

  const multipleSelectCategoryProps = {
    options: categoryOptions,
    handleChange: (value) => setSelectedCategories([...value]),
  };

  const handleSubmit = async () => {
    const result = cartData.map((product) => ({ productId: product._id, qty: product.qty }));
    const dataToSend = {
      details: JSON.stringify(result) 
    };
    setIsSaveLoading(true);

    const isSuccess = await dispatch(orderActions.updateOrder(orderDetail?.order_id, dataToSend));
    if(isSuccess){
      history.push(`/payment/detail/${id}`);
      setIsSaveLoading(true);
    }else setIsSaveLoading(false);
  };

  const handleAddToCart = async (values) => {
    setCartData(prevState => [...prevState, values]);
  }

  const render = () => (
    <MainContent headerProps={headerProps}>
      <div id='choose-product-wrapper'>
        {!modeTablet && (
          <div id='choose-product-breadcrumb'>
            <MainBreadcrumb items={mainBreadcrumbItems} />
          </div>
        )}

        <div id='choose-product-content'>
          <Row gutter={12}>
            <Col span={16}>
              <div id='choose-product-filter'>
                <Row gutter={24}>
                  <Col span={8}>
                    <MultipleSelectCategory {...multipleSelectCategoryProps} />
                  </Col>
                  <Col span={16}>
                    <ProductSearchFilter {...productSearchFilterProps} />
                  </Col>
                </Row>
              </div>

              <div id='choose-product-cards'>
                {loading ? (
                  <SpinLoaderContent />
                ) : isEmpty(data) && onFiltering === false ? (
                  <ProductEmpty />
                ) : isEmpty(data) && onFiltering === true ? (
                  <ProductNotFound />
                ) : (
                  <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length < productMetaData.total}
                    endMessage={
                      <div className='full-width pl-10 pr-10'>
                        <Divider plain>{t("It is all, nothing more")} !</Divider>
                      </div>
                    }
                    scrollableTarget='choose-product-cards'
                    loader={<SpinLoaderContent />}
                  >
                    <div className='card-wrapper'>
                      {data.map((product) => (
                        <EditOrderProductPOSCard 
                          key={product._id} 
                          data={product} 
                          handleClick={handleAddToCart} 
                        />
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </div>
            </Col>
            <Col span={8}>
              <Form form={form} onFinish={handleSubmit}>
                <div id='choose-product-sidebar-summary'>
                  <div id='choose-product-cart'>
                    <Space direction='horizontal'>
                      <Text strong>{t("Order")}</Text>
                      {!isEmpty(cartData) && (
                        <div id='choose-product-number-order'>
                          <Text strong>{totalProducts}</Text>
                        </div>
                      )}
                    </Space>

                    {loadingCart ? (
                      <SpinLoaderContent />
                    ) : !isEmpty(cartData) ? (
                      <List
                        id='choose-product-cart-list'
                        itemLayout='horizontal'
                        dataSource={cartData}
                        renderItem={(item) => {
                          return (
                            <EditOrderCartListItem
                              key={item._id}
                              data={item}
                              setModalOpen={setModalOpen}
                              setNotificationModalOpen={setNotificationModalOpen}
                              selectedProductDelete={selectedProductDelete}
                              setSelectedProductDelete={setSelectedProductDelete}
                              unavailableProducts={unavailableProducts}
                              setUnavailableProducts={setUnavailableProducts}
                              isCancelDeleteProduct={isCancelDeleteProduct}
                              setIsCancelDeleteProduct={setIsCancelDeleteProduct}
                              handleUpdateCart={handleUpdateCart}
                            />
                          );
                        }}
                      />
                    ) : (
                      <CartEmpty />
                    )}
                    <DeleteProductCartModal
                      title='Delete product'
                      message='Are you sure you want to remove this product from the shopping list?'
                      modalOpen={modalOpen}
                      handleDeleteProductCart={handleDeleteProductCart}
                      handleCancel={() => setModalOpen(false)}
                    />
                    <DeleteProductCartModal
                      title='Are you sure?'
                      message='The product will be automatically removed from your shopping cart if the total item count reaches 0.'
                      modalOpen={notificationModalOpen}
                      handleDeleteProductCart={handleDeleteProductCart}
                      handleCancel={handleCancelDeleteProduct}
                    />
                  </div>

                  <div id='choose-product-summary'>
                    <Text strong>{t("Shopping Summary")}</Text>
                    <Row className='mt-10'>
                      <Col span={16}>
                        <Space direction='vertical' size='small'>
                          <Text>{t("Total products")}</Text>
                          <Text>{t("Total discounts")}</Text>
                        </Space>
                      </Col>
                      <Col span={8} className='text-right'>
                        <Space direction='vertical' size='small'>
                          <Text strong>{totalProducts}</Text>
                          <Text strong>{formatRupiah(0)}</Text>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Divider style={{margin: '10px 0px'}} />
                      </Col>
                      <Col span={16}>
                        <Text strong>{t("Total Price")}</Text>
                      </Col>
                      <Col span={8} className='text-right'>
                        <Text strong>{formatRupiah(totalPrice)}</Text>
                      </Col>
                      <Col span={24} className='mt-20'>
                        <ButtonPrimary
                          className={isSaveUpdateButtonDisabled ? 'button-disabled' : ''}
                          disabled={isSaveUpdateButtonDisabled ? true : false}
                          block={true}
                          htmlType='submit'
                          text='saveChanges'
                          loading={isSaveLoading}
                        />
                        <ButtonDefault
                          className='mt-10 mb-20'
                          block={true}
                          htmlType='submit'
                          text='Cancel'
                          handleClick={() => history.goBack()}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </MainContent>
  );

  return isLoggedIn ? render() : <Redirect to='/login' />;
};

export default ChooseProduct;

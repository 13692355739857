import React, { useEffect, useState } from "react";
import { Card, Col, Form, Input, List, Modal, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import dayjs from "dayjs";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import * as branchActions from "../../actions/branch.action";
import * as posmachineActions from "../../actions/posmachine.action";
import * as uomActions from "../../actions/uom.action";
import * as categoryActions from "../../actions/category.action";
import "./_style.scss";

const { Text, Title } = Typography;

const Master = () => {
  const { t } = useTranslation();
  const [branchData, setBranchData] = useState([]);
  const [posmachineData, setPosmachineData] = useState([]);
  const [uomData, setUomData] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();

  const branch = useSelector((state) => state.branchReducer);
  const posmachine = useSelector((state) => state.posmachineReducer);
  const uom = useSelector((state) => state.uomReducer);
  const category = useSelector((state) => state.categoryReducer);

  useEffect(() => {
    dispatch(branchActions.Index());
    dispatch(posmachineActions.index());
    dispatch(uomActions.getUomMaster());
    dispatch(categoryActions.Index());
  }, []);

  useEffect(() => {
    if (!isEmpty(branch.result)) {
      const { result } = branch;
      const options = result.map((branch) => ({ value: branch._id._id, label: branch._id.name }));
      setBranchData(result);
      setBranchOptions(options);
    }
  }, [branch]);

  useEffect(() => {
    if (!isEmpty(posmachine.result)) {
      setPosmachineData(posmachine.result);
    }
  }, [posmachine]);

  useEffect(() => {
    if (!isEmpty(uom.result)) {
      setUomData(uom.result);
    }
  }, [uom]);

  useEffect(() => {
    if (!isEmpty(category.result)) {
      const options = category.result.map((category) => ({
        value: category._id,
        label: category.label,
      }));
      setCategoryOptions(options);
    }
  }, [category]);

  const handleSubmitBranch = (values) => {
    console.log("branch data: ", values);
    // dispatch(branchActions.Create(values, history));
  };

  const handleSubmitPOS = (values) => {
    console.log("pos data: ", values);
    // dispatch(branchActions.Create(values, history));
  };

  const handleSubmitSubCategory = (values) => {
    console.log("subcategory data: ", values);
    // dispatch(branchActions.Create(values, history));
  };

  const handleSubmitCategory = (values) => {
    console.log("category data: ", values);
    // dispatch(branchActions.Create(values, history));
  };

  const handleSubmitUom = (values) => {
    console.log("uom data: ", values);
    // dispatch(branchActions.Create(values, history));
  };

  return (
    <div id="my-account-master-section">
      <Row gutter={12}>
        <Col span={24} className="mb-10 text-center">
          <Text strong>{t("Master Data")}</Text>
        </Col>
        <Col span={8}>
          <AddBranch
            data={branchData}
            companyID={loggedInUser?.companyId}
            handleSubmitBranch={handleSubmitBranch}
          />
        </Col>

        <Col span={8}>
          <AddPOS
            data={posmachineData}
            branchOptions={branchOptions}
            handleSubmitPOS={handleSubmitPOS}
          />
        </Col>

        <Col span={8}>
          <AddSubCategory
            categoryOptions={categoryOptions}
            handleSubmitSubCategory={handleSubmitSubCategory}
          />
        </Col>
      </Row>
      <Row gutter={12} className="mt-10">
        <Col span={8}>
          <AddCategory handleSubmitCategory={handleSubmitCategory} />
        </Col>

        <Col span={8}>
          <AddUOM data={uomData} handleSubmitUom={handleSubmitUom} />
        </Col>
      </Row>
    </div>
  );
};

const AddBranch = ({ data, companyID, handleSubmitBranch }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const { name, tel, address } = form.getFieldsValue();
    const dataToSend = { name, tel, address, companyId: companyID };
    handleSubmitBranch(dataToSend);
  };

  return (
    <>
      <Card title={t("Add Branch")}>
        <Form className="mt-10" form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="name">
            <Input size="large" placeholder={t("Customer Name")} />
          </Form.Item>

          <Form.Item name="tel">
            <Input size="large" placeholder={t("Phone No.")} />
          </Form.Item>

          <Form.Item name="address">
            <Input size="large" placeholder={t("Address")} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Row gutter={12}>
              <Col span={12}>
                <ButtonDefault block={true} text="Show" handleClick={() => setModalOpen(true)} />
              </Col>
              <Col span={12}>
                <ButtonPrimary block={true} text="Add" className="float-right" htmlType="submit" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>

      <MasterModal modalOpen={modalOpen} setModalOpen={setModalOpen} title="Branch List">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <Row gutter={12} className="full-width">
                <Col span={12}>
                  <Text type="secondary" className="mr-10">
                    {t("Name")} :
                  </Text>
                  <Text>{item?._id.name}</Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary" className="mr-10">
                    {t("Telp No.")} :
                  </Text>
                  <Text>{item?._id.tel}</Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary" className="mr-10">
                    {t("Address")} :
                  </Text>
                  <Text>{item?._id.address}</Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </MasterModal>
    </>
  );
};

const AddPOS = ({ data, branchOptions, handleSubmitPOS }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const { id, serialno, alias } = form.getFieldsValue();
    const dataToSend = { id, serialno, alias };
    handleSubmitPOS(dataToSend);
  };

  return (
    <>
      <Card title={t("Add POS")}>
        <Form className="mt-10" form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="id">
            <Select options={branchOptions} size="large" placeholder={t("Select Branch")} />
          </Form.Item>

          <Form.Item name="alias">
            <Input size="large" placeholder={t("Alias")} />
          </Form.Item>

          <Form.Item name="serialno">
            <Input size="large" placeholder={t("Serial No")} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Row gutter={12}>
              <Col span={12}>
                <ButtonDefault block={true} text="Show" handleClick={() => setModalOpen(true)} />
              </Col>
              <Col span={12}>
                <ButtonPrimary block={true} text="Add" className="float-right" htmlType="submit" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>

      <MasterModal modalOpen={modalOpen} setModalOpen={setModalOpen} title="Branch List">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <Row gutter={12} className="full-width">
                <Col span={10}>
                  <Text type="secondary" className="mr-10">
                    {t("Name")} :
                  </Text>
                  <Text>{item?.alias}</Text>
                </Col>
                <Col span={14}>
                  <Text type="secondary" className="mr-10">
                    {t("Telp No.")} :
                  </Text>
                  <Text>{item?.branches[0].tel}</Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary" className="mr-10">
                    {t("Address")} :
                  </Text>
                  <Text>{item?.branches[0].address}</Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </MasterModal>
    </>
  );
};

const AddSubCategory = ({ categoryOptions, handleSubmitSubCategory }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const { category, enter } = form.getFieldsValue();
    const dataToSend = { category, enter };
    handleSubmitSubCategory(dataToSend);
  };

  return (
    <Card title={t("Add Sub Category")}>
      <Form className="mt-10" form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="category">
          <Select options={categoryOptions} size="large" placeholder={t("Select Category")} />
        </Form.Item>

        <Form.Item name="enter">
          <Input size="large" placeholder={t("Enter")} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <ButtonPrimary block={true} text="Add" className="float-right" htmlType="submit" />
        </Form.Item>
      </Form>
    </Card>
  );
};

const AddCategory = ({ handleSubmitCategory }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const { label } = form.getFieldsValue();
    const dataToSend = { label };
    handleSubmitCategory(dataToSend);
  };

  return (
    <Card title={t("Add Category")}>
      <Form className="mt-10" form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="label">
          <Input size="large" placeholder={t("Enter")} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <ButtonPrimary block={true} text="Add" className="float-right" htmlType="submit" />
        </Form.Item>
      </Form>
    </Card>
  );
};

const AddUOM = ({ data, handleSubmitUom }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    const { uom } = form.getFieldsValue();
    const dataToSend = { uom };
    handleSubmitUom(dataToSend);
  };

  return (
    <>
      <Card title={t("Add New UOM")}>
        <Form className="mt-10" form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item name="uom">
            <Input size="large" placeholder={t("Enter")} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Row gutter={12}>
              <Col span={12}>
                <ButtonDefault block={true} text="Show" handleClick={() => setModalOpen(true)} />
              </Col>
              <Col span={12}>
                <ButtonPrimary block={true} text="Add" className="float-right" htmlType="submit" />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
      <MasterModal modalOpen={modalOpen} setModalOpen={setModalOpen} title="Branch List">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <Row gutter={12} className="full-width">
                <Col span={10}>
                  <Text type="secondary" className="mr-10">
                    {t("Label")} :
                  </Text>
                  <Text>{item?.label}</Text>
                </Col>
                <Col span={14}>
                  <Text type="secondary" className="mr-10">
                    {t("Created")} :{" "}
                  </Text>
                  <Text>{dayjs(item?.timestamp).format("DDD, DD MMM YYYY HH:mm:ss")}</Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </MasterModal>
    </>
  );
};

const MasterModal = ({ modalOpen, setModalOpen, title, children }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="my-account-master-modal"
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={800}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row gutter={24}>
        <Col span={24} className="text-center mb-20">
          <Title level={4}>{t(title)}</Title>
        </Col>
        <Col span={24}>
          <div className="my-account-master-modal-content">{children}</div>
        </Col>
      </Row>
    </Modal>
  );
};

export default Master;

import {
  CUSTOMER_FETCHING,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILED,
  CUSTOMER_CLEAR,
  CUSTOMER_OPTIONS_SUCCESS,
  CUSTOMER_ORDER_HISTORY,
  CUSTOMER_TYPE_SUCCESS,
  CREATE_CUSTOMER_OFFLINE_FETCHING,
  CREATE_CUSTOMER_OFFLINE_FAILED,
  CUSTOMER_OFFLINE_FETCHING,
  CUSTOMER_OFFLINE_FAILED,
  CUSTOMER_OFFLINE_SUCCESS,
  CUSTOMER_OFFLINE_TYPE_FETCHING,
  CUSTOMER_OFFLINE_TYPE_FAILED,
  CUSTOMER_OFFLINE_TYPE_SUCCESS,
  CUSTOMER_OFFLINE_DETAILS_FETCHING,
  CUSTOMER_OFFLINE_DETAILS_FAILED,
  CUSTOMER_OFFLINE_DETAILS_SUCCESS,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  options: null,
  orderHistory: null,
  customerTypeResult: null,
  isCustomerOfflineFetching: false,
  isCustomerOfflineError: false,
  customerOffline: null,
  isCustomerOfflineTypeFetching: false,
  isCustomerOfflineTypeError: false,
  customerOfflineType: null,
  isCustomerOfflineDetailsFetching: false,
  isCustomerOfflineDetailsError: false,
  customerOfflineDetails: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CUSTOMER_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case CUSTOMER_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case CUSTOMER_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case CUSTOMER_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case CUSTOMER_OPTIONS_SUCCESS:
      return { ...state, isFetching: false, isError: false, options: payload };
    case CUSTOMER_ORDER_HISTORY:
      return { ...state, isFetching: false, isError: false, orderHistory: payload };
    case CUSTOMER_TYPE_SUCCESS:
      return { ...state, isFetching: false, isError: false, customerTypeResult: payload };
    case CREATE_CUSTOMER_OFFLINE_FETCHING:
      return {
        ...state,
        isCreateCustomerOfflineFetching: true,
        isCreateCustomerOfflineError: false,
      };
    case CREATE_CUSTOMER_OFFLINE_FAILED:
      return {
        ...state,
        isCreateCustomerOfflineFetching: false,
        isCreateCustomerOfflineError: true,
      };
    case CUSTOMER_OFFLINE_FETCHING:
      return {
        ...state,
        isCustomerOfflineFetching: true,
        isCustomerOfflineError: false,
        customerOffline: null,
      };
    case CUSTOMER_OFFLINE_FAILED:
      return {
        ...state,
        isCustomerOfflineFetching: false,
        isCustomerOfflineError: true,
        customerOffline: null,
      };
    case CUSTOMER_OFFLINE_SUCCESS:
      return {
        ...state,
        isCustomerOfflineFetching: false,
        isCustomerOfflineError: false,
        customerOffline: payload,
      };
    case CUSTOMER_OFFLINE_TYPE_FETCHING:
      return {
        ...state,
        isCustomerOfflineTypeFetching: true,
        isCustomerOfflineTypeError: false,
        customerOfflineType: null,
      };
    case CUSTOMER_OFFLINE_TYPE_FAILED:
      return {
        ...state,
        isCustomerOfflineTypeFetching: false,
        isCustomerOfflineTypeError: true,
        customerOfflineType: null,
      };
    case CUSTOMER_OFFLINE_TYPE_SUCCESS:
      return {
        ...state,
        isCustomerOfflineTypeFetching: false,
        isCustomerOfflineTypeError: false,
        customerOfflineType: payload,
      };
    case CUSTOMER_OFFLINE_DETAILS_FETCHING:
      return {
        ...state,
        isCustomerOfflineDetailsFetching: true,
        isCustomerOfflineDetailsError: false,
        customerOfflineDetails: null,
      };
    case CUSTOMER_OFFLINE_DETAILS_FAILED:
      return {
        ...state,
        isCustomerOfflineDetailsFetching: false,
        isCustomerOfflineDetailsError: true,
        customerOfflineDetails: null,
      };
    case CUSTOMER_OFFLINE_DETAILS_SUCCESS:
      return {
        ...state,
        isCustomerOfflineDetailsFetching: false,
        isCustomerOfflineDetailsError: false,
        customerOfflineDetails: payload,
      };
    default:
      return state;
  }
};

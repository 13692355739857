import React, { useEffect, useState } from "react";
import { Form, Input, Dropdown, Divider, Button, Space, theme } from "antd";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash-es";
import { CloseOutlined } from "@ant-design/icons";

const { useToken } = theme;

const FormItemInputSearchDropDown = ({
  label,
  name,
  size,
  placeholder,
  rules,
  disabled = false,
  setProductSearch,
  setModalOpen,
  options,
  handleChange = () => {},
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [onSearch, setOnSearch] = useState(false);

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  useEffect(() => {
    if (!onSearch) return;

    const debouncedAction = debounce(() => {
      handleChange(searchValue.toLowerCase());
    }, 500);

    debouncedAction();
    return () => {
      debouncedAction.cancel();
    };
  }, [searchValue, onSearch]);

  useEffect(() => {
    if (!isEmpty(options)) setDropdownOpen(true);
    else setDropdownOpen(false);
  }, [options]);

  const handleClick = ({ key }) => {
    setDropdownOpen(false);
    setProductSearch(key);
    setModalOpen(false);
  };

  return (
    <Dropdown
      menu={{
        items: options,
        onClick: handleClick,
      }}
      open={dropdownOpen}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          <div style={{ padding: 4 }}>
            <Button
              className="float-right"
              type="text"
              size="small"
              icon={<CloseOutlined />}
              onClick={() => setDropdownOpen(false)}
            />
          </div>
          <Divider
            style={{
              margin: 0,
            }}
          />
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
        </div>
      )}
    >
      <Form.Item label={t(label)} name={name} rules={rules}>
        <Input
          placeholder={t(placeholder)}
          size={size}
          disabled={disabled}
          onChange={({ target }) => {
            setSearchValue(target.value);
            setOnSearch(true);
          }}
        />
      </Form.Item>
    </Dropdown>
  );
};

export default FormItemInputSearchDropDown;

// @ts-check
import { RECEIPT_ERROR, RECEIPT_FETCHING, RECEIPT_SUCCESS } from "../constants";
import { mapLegacyReciptPayload, mapNewReciptPayload } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
/** @returns {AppReducer.ReceiptActionPayload} */

const setReceiptStateToFetching = () => ({
  type: RECEIPT_FETCHING,
});

/** @returns {AppReducer.ReceiptActionPayload} */
const setReceiptStateToSuccess = (data) => ({
  type: RECEIPT_SUCCESS,
  data,
});

/** @returns {AppReducer.ReceiptActionPayload} */
const setReceiptStateToError = (/** @type {Error} */error) => ({
  type: RECEIPT_ERROR,
  error,
});

export const getUserReceiptSettings = (companyId) => {
  return async (/** @type {BaskitApp.DispatchFunc} */dispatch) => {
    dispatch(setReceiptStateToFetching());
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL_NEW_V2}company/receipt-setting/${companyId}`)
      if (response.status >= 400) {
        throw new Error(response.data.message);
      }
      dispatch(setReceiptStateToSuccess(mapLegacyReciptPayload(response.data.data)));
    } catch (error) {
      return dispatch(setReceiptStateToError(error));
    }
  };
};

export const saveUserReceiptSettings = (/** @type {BaskitApp.ReceiptSettings} */settings, companyId) => {
  return async (/** @type {BaskitApp.DispatchFunc} */dispatch) => {
    dispatch(setReceiptStateToFetching());
    try {
      const response = await httpClient.patch(`${process.env.REACT_APP_API_URL_NEW_V2}company/receipt-setting/${companyId}`, mapNewReciptPayload(settings));
      if (response.status >= 400) {
        throw new Error(response.data.message);
      }
      dispatch(setReceiptStateToSuccess(mapLegacyReciptPayload(response.data.data)));
    } catch (error) {
      return dispatch(setReceiptStateToError(error));
    }
  };
};

import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import ProductSearchFilter from "../../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import ButtonDefault from "../../../components/atoms/ButtonDefault/ButtonDefault";
import MainContent from "../../../components/organisms/Content/Content";
import MainBreadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import AssignCoverageArea from "../../molecules/AssignCoverageArea/AssignCoverageArea";
import ProductCartConfirmationModal from "../../molecules/ProductCartConfirmationModal/ProductCartConfirmationModal";
import * as districtActions from "../../../actions/district.action";
import "./_style.scss";

const { Text } = Typography;

const SalesStore = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [detials, setDetails] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [params, setParams] = useState({ $limit: 0 });
  const [sellerSelectedDelete, setSellerSelectedDelete] = useState([]);
  const [isDeleteFromDistrictLoading, setIsDeleteFromDistrictLoading] = useState(false);
  const [assignedSellerId, setAssignedSellerId] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const { districtDetails, districtAssigned } = useSelector((state) => state.districtReducer);

  useEffect(() => {
    dispatch(districtActions.districtDetails(id));
    dispatch(districtActions.assigned(id, params));
  }, [id]);

  useEffect(() => {
    if (districtDetails) {
      setDetails(districtDetails?.data);
      setLoading(false);
    }
  }, [districtDetails]);

  useEffect(() => {
    if (districtAssigned) {
      setData(districtAssigned?.data);
    }
  }, [districtAssigned]);

  const column = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Delete"),
      dataIndex: "delete",
      key: "delete",
      align: "right",
      render: (data) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => {
            setSellerSelectedDelete(data);
            setModalOpen(true);
          }}
        />
      ),
    },
  ];

  const dataSource = data.map((data, index) => ({
    key: index,
    id: data?.id,
    name: data?.companyName,
    delete: data,
  }));

  const handleSellerSearch = (value) => {
    const _params = { ...params };

    if (value) _params.search = value;
    else delete _params.search;
    dispatch(districtActions.assigned(id, _params));
    setParams(_params);
  };

  const handleDeleteFromDistrict = async () => {
    setIsDeleteFromDistrictLoading(true);
    const value = { areaId: id };
    const isSuccess = await dispatch(
      districtActions.deleteFromDistrict(sellerSelectedDelete?.id, value),
    );
    if (isSuccess) {
      dispatch(districtActions.assigned(id, params));
      dispatch(districtActions.districtSearch());
      setIsDeleteFromDistrictLoading(false);
      setModalOpen(false);
    }
  };

  const headerProps = {
    buttonBack: { text: "Account Manager", status: true },
    description: "Manage your account",
  };

  const mainBreadcrumbItems = [
    {
      link: "/coverage-area",
      title: "coverageArea",
    },
    {
      title: "sellerList",
    },
  ];

  const sellerSearchFilterProps = {
    placeholder: "searchSeller",
    options: [],
    handleChange: handleSellerSearch,
  };

  const rowClassName = (value) => {
    if (value?.id === assignedSellerId) return "newly-inserted-row";
  };

  const tableProps = {
    loading: loading,
    columns: column,
    dataSource: dataSource,
    showHeader: false,
    pagination: false,
    scroll: { y: "60vh" },
    rowClassName,
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="sales-store-wrapper">
        <div>
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id="sales-store-content">
          <Row gutter={12}>
            <Col span={12}>
              <div className="right-content">
                <Row>
                  <ColItem label={t("districtName")} value={detials?.name} />
                  <ColItem label={t("totalSellers")} value={data.length || 0} />
                </Row>
                <Divider style={{ margin: "10px 0px" }} />
                <ProductSearchFilter {...sellerSearchFilterProps} />
                <Table {...tableProps} />
                <ProductCartConfirmationModal
                  title="deleteSeller"
                  message={t("deleteSellerMessage", { store: sellerSelectedDelete?.companyName })}
                  modalOpen={modalOpen}
                  isLoadingYes={isDeleteFromDistrictLoading}
                  handleYes={handleDeleteFromDistrict}
                  handleNo={() => setModalOpen(false)}
                />
              </div>
            </Col>

            <Col span={12}>
              <div className="left-content">
                <AssignCoverageArea id={id} setAssignedSellerId={setAssignedSellerId} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="float-button">
          <ButtonDefault text="Back" block={true} handleClick={() => history.goBack()} />
        </div>
      </div>
    </MainContent>
  );
};

const ColItem = ({ label, value }) => (
  <>
    <Col span={8}>
      <Text>{label}</Text>
    </Col>
    <Col span={16}>
      <Text strong>
        : <span className="ml-10">{value}</span>
      </Text>
    </Col>
  </>
);

export default SalesStore;

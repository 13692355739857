import { Modal, Button } from 'antd';

function Confirmation({ open, onOk, onClose }) {
  return (
    <Modal
      open={open}
      footer={null}
      maskClosable={true}
      closeIcon={<img src="/images/x-mark.svg" onClick={onClose} height={16} alt='x-mark' />}
      className='editOrderConfirmation__modal'
      closable
      centered
    >
      <div className="editOrderConfirmation">
        <div className="editOrder__header">
          <h3>Ubah Status Pesanan</h3>
        </div>
        <div className="main">
          Apakah Anda yakin semua data sudah sesuai dan ingin merubah status pesanan?
        </div>
        <div className="editOrderConfirmation__CTA">
          <Button type="primary" ghost onClick={onClose}>
            Cek lagi
          </Button>
          <Button type="primary" onClick={onOk}>
            Ya
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default Confirmation;

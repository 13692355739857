import {
  SALES_CLEAR,
  SALES_DETAIL_SUCCESS,
  SALES_FAILED,
  SALES_FETCHING,
  SALES_SUCCESS,
} from "../constants";
import { parseParamsString } from "../utils/Functions";
import { httpClient } from "../utils/HttpClient";
import { ErrorMessage, SuccessMessage } from "../utils/Message";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import * as salesAssigned from "./salesassigned.action";
import * as salesDetailActions from "./salesdetail.action";

export const setSalesStateToFetching = () => ({
  type: SALES_FETCHING,
});
export const setSalesStateToFailed = () => ({
  type: SALES_FAILED,
});
export const setSalesStateToClear = () => ({
  type: SALES_CLEAR,
});
export const setSalesStateToSuccess = (payload) => ({
  type: SALES_SUCCESS,
  payload,
});
export const setSalesDetailStateToSuccess = (payload) => ({
  type: SALES_DETAIL_SUCCESS,
  payload,
});

export const Index = (params) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/user${paramsString}`,
      );
      dispatch(setSalesStateToSuccess(response.data));
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const getDetail = (id) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL + `salesman/detail/${id}`,
      );
      dispatch(setSalesDetailStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const updateStatus = (id, values) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `users/salesman/${id}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const createSalesman = (values) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + "users/salesman",
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const assignUserToCompany = (values, id) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/user`,
        values,
      );
      dispatch(setSalesStateToSuccess(response.data.data));
      dispatch(salesAssigned.index("", id));
      dispatch(salesDetailActions.index(id));
      SuccessNotification(response);
      return true;
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const deleteAssignment = (roleId, values, id) => {
  return async (dispatch) => {
    dispatch(setSalesStateToFetching());
    try {
      const response = await httpClient.delete(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/user/role/${roleId}`,
      );
      dispatch(setSalesStateToSuccess(response.data.data));
      dispatch(salesAssigned.index(values, id));
      dispatch(salesDetailActions.index(id));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setSalesStateToFailed());
      ErrorNotification(error);
    }
  };
};

// export const getUnassigned = (values, id) => {
//   return async (dispatch) => {
//     dispatch(setSalesStateToFetching());
//     try {
//       const response = await httpClient.get(process.env.REACT_APP_API_URL + `user/sales/company/search/${id}?${values}`);
//       console.log({response})
//       dispatch(setSalesUnassignedStateToSuccess(response.data));
//       // dispatch(Index());
//       // SuccessNotification(response);
//     } catch (error) {
//       dispatch(setSalesStateToFailed());
//       ErrorNotification(error);
//     }
//   };
// };

// export const getAssigned = (values, id) => {
//   console.log(values, 'assigned')
//   return async (dispatch) => {
//     dispatch(setSalesStateToFetching());
//     try {
//       const response = await httpClient.get(process.env.REACT_APP_API_URL + `user/sales/assigned/${id}?${values}`);
//       dispatch(setSalesAssignedStateToSuccess(response.data));
//       // dispatch(Index());
//       // SuccessNotification(response);
//     } catch (error) {
//       dispatch(setSalesStateToFailed());
//       ErrorNotification(error);
//     }
//   };
// };

import { message } from "antd";

export const Message = (type, content) => {
  return message[type]({
    content: content,
  });
};

export const SuccessMessage = (response) => {
  const { result, message } = response.data;
  if (result || message) return Message("success", message);
  else Message("success", "Data fetching was successful");
};

const messageMapper = (data) => {
  switch (data.message) {
    case "body must have required property 'inventoryPriceTierId'":
      return 'The inventory price tier is empty';
    default:
      return data.message;
  }
}

export const ErrorMessage = (error) => {
  const { status, statusText, data } = error.response;
  if (status === 404) return Message("error", statusText);
  else return Message("error", messageMapper(data));
};

import React, { useEffect, useState } from "react";
import {
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { isEmpty } from "lodash-es";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { server } from "../../constants";
import Cookies from "js-cookie";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import LoginContent from "../../components/organisms/LoginContent/LoginContent";
import * as loginActions from "../../actions/login.action";
import * as userActions from "../../actions/user.action";
import "./_style.scss";

const { Title, Text } = Typography;

const isEmail = (value) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(value);
};

const checkPhone = (value) => {
  const localPhoneRegex = /^[0-9]{10,12}$/;
  const internationalPhoneRegex = /^\+\d+$/;
  const result = { isPhone: false, isInternationalPhone: false };

  if (localPhoneRegex.test(value)) return { ...result, isPhone: true };
  if (internationalPhoneRegex.test(value)) return { isPhone: true, isInternationalPhone: true };
  return result;
};

const separateCountryCodeAndPhoneNumber = (phoneNumber) => {
  const regex = /(\+\d{2})(\d+)/;
  const match = regex.exec(phoneNumber);

  if (match) {
    const result = {
      countryCode: match[1],
      phone: match[2],
    };

    return result;
  } else return null;
};

const constants = {
  CONFIRM_PHONE_EMAIL: "confirm-phone-email",
  SEND_OTP: "send_otp",
  INPUT_PASSWORD: "input_password",
  SUCCESS: "success",
  FAILED: "failed",
  RESEND: "resend",
  TIMEREMAINING: "time_remaining",
};

const countryCodes = [
  { code: "+60", image: "my.svg" },
  { code: "+62", image: "id.svg" },
  { code: "+65", image: "sg.svg" },
  { code: "+92", image: "pk.svg" },
];

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+62");
  const [validationStatus, setValidationStatus] = useState({});
  const [loginStep, setLoginStep] = useState(constants.CONFIRM_PHONE_EMAIL);
  const [form] = Form.useForm();

  useEffect(() => {
    loginActions.logout();
    form.setFieldValue("code_phone", "+62");
  }, []);

  const confirmFormProps = {
    setEmail,
    setCountryCode,
    setPhone,
    setLoginStep,
    validationStatus,
    setValidationStatus,
  };

  const emailFormProps = {
    email,
    setLoginStep,
  };

  const phoneFormProps = {
    countryCode,
    setCountryCode,
    phone,
    setLoginStep,
  };

  const colProps = {
    md: 24,
    lg: 12,
    xl: 12,
  };

  return (
    <LoginContent>
      <div id="login-wrapper" className="flex-center-justify">
        <div className="content">
          <Row gutter={[12, 32]}>
            <Col {...colProps}>
              <div className="login-tagline">
                <div className="mt-20 mb-20">
                  <Image
                    width={110}
                    preview={false}
                    src={`${window.location.origin}/images/baskit-logo-primary.svg`}
                  />
                </div>
                <Title level={2}>
                  <span className="text-primary">#1 {t("all-in-one platform")} </span>
                  {t("for distribution businesses in Indonesia")}
                </Title>
                <Text>
                  {t(
                    "Baskit partners with your distribution business to help you grow, providing access to financing & technology",
                  )}
                </Text>
              </div>
            </Col>
            <Col {...colProps}>
              <div className="login-card ml-20 mr-20">
                <div className="login-card-header">
                  <Title level={3}>{t("Login")}</Title>
                </div>
                <div>
                  <Divider className="login-divider" />
                </div>
                <div className="login-card-content">
                  {loginStep === constants.CONFIRM_PHONE_EMAIL && (
                    <ConfirmForm {...confirmFormProps} />
                  )}
                  {loginStep === constants.INPUT_PASSWORD && <EmailForm {...emailFormProps} />}
                  {loginStep === constants.SEND_OTP && <PhoneForm {...phoneFormProps} />}
                </div>
                <div className="login-card-footer">
                  <Row gutter={12} className="mt-20">
                    <Col span={12}>
                      <Text>{t("Or are you a new user")}?</Text>
                    </Col>
                    <Col span={12} className="text-right">
                      <a className="register-link" href="/register">
                        {t("Register here")}
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </LoginContent>
  );
};

const FormItemPhoneNumber = ({
  label,
  name,
  rules,
  size = "middle",
  disabled = false,
  countryCodes,
  validateStatus,
  help,
  handleChange,
}) => {
  const { t } = useTranslation();
  const prefixSelector = (
    <Form.Item name={"countryCode"} noStyle>
      <Select onChange={handleChange}>
        {countryCodes.map(({ code, image }) => (
          <Select.Option value={code} key={code}>
            <Space direction="horizontal" size="small">
              <Image
                width={20}
                preview={false}
                src={`${window.location.origin}/images/flags/${image}`}
              />
              <span>{code}</span>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const parseNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <Form.Item name={name} rules={rules} validateStatus={validateStatus} help={help}>
      <InputNumber
        addonBefore={prefixSelector}
        className="login-input-number"
        type="number"
        parser={parseNumber}
        placeholder={t(label)}
        style={{ width: "100%" }}
        size={size}
        disabled={disabled}
      />
    </Form.Item>
  );
};

const ConfirmForm = ({
  setEmail,
  setCountryCode,
  setPhone,
  setLoginStep,
  validationStatus,
  setValidationStatus,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleChange = () => {
    setValidationStatus({});
  };

  const handleSubmit = () => {
    const { confirm } = form.getFieldsValue();
    if (isEmail(confirm)) {
      setEmail(confirm);
      return setLoginStep(constants.INPUT_PASSWORD);
    }

    const phone = checkPhone(confirm);
    if (phone.isPhone) {
      let localPhone;

      if (phone.isInternationalPhone) {
        const seperate = separateCountryCodeAndPhoneNumber(confirm);
        setCountryCode(seperate?.countryCode);
        localPhone = seperate?.phone;
      } else {
        if (confirm.startsWith("0")) {
          localPhone = confirm.replace(/^0+/, "");
        } else {
          return setValidationStatus({
            status: constants.FAILED,
            message: "phoneInvalidMessage",
          });
        }
      }

      if (localPhone.length <= 9) {
        return setValidationStatus({
          status: constants.FAILED,
          message: "phoneInvalidDigitMessage",
        });
      }

      setPhone(localPhone);
      return setLoginStep(constants.SEND_OTP);
    }

    return setValidationStatus({
      status: constants.FAILED,
      message: "confirmInvalidMessage",
    });
  };

  return (
    <>
      <div className="mt-40 mb-10">
        <Text className={validationStatus.status === constants.FAILED && "text-danger"}>
          {t("confirmLabel")}
        </Text>
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="confirm"
          validateStatus={validationStatus.status === constants.FAILED && "error"}
          help={
            !isEmpty(validationStatus) ? (
              <span>
                <ExclamationCircleOutlined /> {" " + t(validationStatus.message)}
              </span>
            ) : (
              ""
            )
          }
          onChange={handleChange}
        >
          <Input
            placeholder={t("confirmPlaceholder")}
            className="login-input-confirm"
            size="large"
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <ButtonPrimary
            id="login-button-confirm"
            className="login-button-confirm"
            text="Next"
            block={true}
            htmlType="submit"
          />
        </Form.Item>
      </Form>
    </>
  );
};

const EmailForm = ({ email, setLoginStep }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldValue("email", email);
  }, []);

  const handleSubmit = async () => {
    const { password } = form.getFieldsValue();
    const values = {
      username: email,
      password,
    };

    setIsLoading(true);
    const isSuccess = await dispatch(loginActions.auth(values));
    if (isSuccess) {
      const isItsMeSuccess = await dispatch(userActions.itsMe());
      if (isItsMeSuccess) {
        setIsLoading(false);
        return (window.location.href = "/home");
      } else {
        setIsLoading(false);
        loginActions.logout();
      }
    } else setIsLoading(false);
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item label={t("emailLabel")} name="email">
        <Input className="login-input-email" size="large" disabled />
      </Form.Item>
      <Form.Item label={t("passwordLabel")} name="password">
        <Input.Password
          placeholder={t("passwordPlaceholder")}
          className="login-input-password"
          size="large"
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Row className="mt-50" gutter={4}>
          <Col span={3}>
            <ButtonDefault
              block={true}
              icon={<LeftOutlined />}
              handleClick={() => setLoginStep(constants.CONFIRM_PHONE_EMAIL)}
            />
          </Col>
          <Col span={21}>
            <ButtonPrimary id="login-button-email" loading={isLoading} text="Login" block={true} htmlType="submit" />
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

const PhoneForm = ({ countryCode, setCountryCode, phone, setLoginStep }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue({ countryCode, phone });
  }, []);

  const handleChange = (value) => {
    setCountryCode(value);
  };

  const handleSubmit = async () => {
    const value = { username: countryCode + phone };
    Cookies.remove(server.LOGIN_VALUES);
    Cookies.remove(constants.TIMEREMAINING);

    setIsLoading(true);
    const isSuccess = await dispatch(loginActions.sendOtp(value));
    if (isSuccess) {
      Cookies.set(server.LOGIN_VALUES, value?.username);
      setIsLoading(false);
      history.push("/login/otp");
    } else setIsLoading(false);
  };

  return (
    <div>
      <div className="mt-40 mb-10">
        <Text>{t("phoneLabel")}</Text>
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <FormItemPhoneNumber
          name="phone"
          size="large"
          // label="Enter your phone number here"
          countryCodes={countryCodes}
          disabled={true}
          handleChange={handleChange}
        />
        <Form.Item wrapperCol={{ span: 24 }}>
          <Row gutter={4} className="login-button-password">
            <Col span={3}>
              <ButtonDefault
                block={true}
                icon={<LeftOutlined />}
                handleClick={() => setLoginStep(constants.CONFIRM_PHONE_EMAIL)}
              />
            </Col>
            <Col span={21}>
              <ButtonPrimary id="login-button-phone" loading={isLoading} text="Login" block={true} htmlType="submit" />
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;

import React, { useEffect, useMemo, useState } from "react";
import { Alert, Layout } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { server } from "../../../constants";
import { motion, AnimatePresence } from "framer-motion";
import i18next from "../../../languages/i18n";
import Cookies from "js-cookie";
import MainHeader from "../Header/Header";
import "./Content.scss";

const { Content } = Layout;

const MainContent = (
  /** @type {React.PropsWithChildren<AppComponent.MainContentProps>} */ { children, headerProps },
) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const alertMessageCookie = Cookies.get(server.ALERT_MESSAGE);
  const successAddProduct = alertMessageCookie
    ? JSON.parse(alertMessageCookie)
    : {
        visible: false,
        amount_product: 0,
      };
  const { pathname, search } = useLocation();
  const currentPath = "/product-list";
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tokenParams = searchParams.get(server.TOKEN_KEY);
  const languageParams = searchParams.get(server.LANGUAGE);
  const modeTablet = tokenParams ? true : false;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (languageParams === "en" || languageParams === "bh") i18next.changeLanguage(languageParams);
  }, [languageParams]);

  const handleCloseAlert = () => {
    Cookies.remove(server.ALERT_MESSAGE);
  };


  const isInsideIframe = window.parent !== window;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Content
            id="main-content"
            style={{
              padding: isInsideIframe ? 20 : 'inherit',
              paddingBottom: isInsideIframe ? 0 : 'inherit'
            }}
          >
            <MainHeader {...headerProps} modeTablet={modeTablet} />
            {successAddProduct.visible && pathname === currentPath && (
              <section>
                <Alert
                  className="main-message-section"
                  message={
                    successAddProduct.amount_product +
                    " " +
                    t("New products have been successfully added to the list of main products") +
                    "."
                  }
                  type="info"
                  closable
                  onClose={handleCloseAlert}
                />
              </section>
            )}
            <section
              className="main-content-section"
              style={{
                overflowY: isInsideIframe && searchParams.get('openTabs') === 'add-product' ? 'hidden' : 'auto',
                maxHeight: isInsideIframe && searchParams.get('openTabs') === 'add-product' ? 'unset' : 'calc(100vh)'
              }}
            >
              {children}
            </section>
          </Content>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MainContent;

import { HISTORY_FETCHING, HISTORY_SUCCESS, HISTORY_FAILED, HISTORY_CLEAR } from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HISTORY_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case HISTORY_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case HISTORY_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case HISTORY_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    default:
      return state;
  }
};

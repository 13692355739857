import { notification } from "antd";
import { toUpper } from "lodash-es";

/**
 * @typedef {{
 *  duration?: number,
 * }} NotificationOptions
 */

export const Notification = (type, message = "", description,
/** @type {NotificationOptions} */ {
  duration = 1,
}) => {
  return notification[type]({
    message: toUpper(message),
    description,
    duration,
    placement: "topRight",
  });
};

export const SuccessNotification = (response, show = false,
/** @type {NotificationOptions} */ {
  duration = 1,
} = {}) => {
  // Date : 30 Juny 2023
  // Disabled Success Notification (Product team request)
  if (!show) return;
  console.log(response);
  if (typeof response === 'string') {
    return Notification('success', 'Success', response, {
      duration,
    });
  }
  const { result, message } = response.data;
  if (result || message) return Notification("success", result, message, {
    duration,
  });
  else Notification("success", "success", "Data fetching was successful", {
    duration,
  });
};

export const ErrorNotification = (error,
/** @type {NotificationOptions} */ {
  duration = 1,
} = {}) => {
  const notifType = "error";

  if (typeof error === 'string') {
    return Notification(notifType, 'error', error, {
      duration,
    });
  }

  const { status, statusText, data } = error.response;

  if (status === 600) return Notification(notifType, statusText, data.message, {
    duration,
  });
  if (status === 500) return Notification(notifType, data.result, data.message, {
    duration,
  });
  if (status === 409) return Notification(notifType, statusText, data.message, {
    duration,
  });
  if (status === 408) return Notification(notifType, statusText, data.message, {
    duration,
  });
  if (status === 404) return Notification(notifType, "Error", statusText, {
    duration,
  });
  if (status === 403) return Notification(notifType, "Error", data.message, {
    duration,
  });
  if (status === 400) {
    const message = data.message ?? statusText;
    return Notification(notifType, "Error", message, { duration });
  } 
};

export const ErrorNotificationFileUpload = (error) => {
  const { title, message } = error;
  return Notification("error", title, message);
};

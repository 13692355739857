import React from "react";
import { useTranslation } from "react-i18next";
import { UpdateStatusCell } from "../../components/organisms/UpdateStatusCell";
import MainContent from "../../components/organisms/Content/Content";
import OrderList from "../../components/templates/OrderList/OrderListOnline";

const TransactionManagement = () => {
  const { t } = useTranslation();

  return (
    <MainContent
      headerProps={{
        title: t("Transaction Management"),
        description: t("Kelola daftar transaksi Anda"),
      }}
    >
      <div>
        <OrderList
          columnComponents={{
            status: UpdateStatusCell,
          }}
          columns={{
            serialNo: true,
            date: true,
            updatedAt: true,
            merchantName: true,
            orderID: true,
            customer: true,
            total: true,
            marginValue: true,
            deliveryType: true,
            sales: true,
            status: true,
            transactionDetails: true,
          }}
          link={{
            invoice: (id) => `/transaction-management/invoice/${id}`,
            details: (id) => `/transaction-management/detail/${id}`,
          }}
          sticky={{
            status: "right",
          }}
          orderFilter={{
            $order: "createdAt",
            $sort: "DESC",
            orderType: ["ONLINE", "SHOP"],
          }}
        />
      </div>
    </MainContent>
  );
};

export default TransactionManagement;

// @ts-check
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { httpClient } from "../utils/HttpClient";
import { ErrorNotification } from "../utils/Notification";
/**
 * @param   {React.EffectCallback}  effect
 * @param   {React.DependencyList?}  deps
 * @param   {string}  target
 *
 * @return  {void}
 */
export const useLazyLoad = (effect, deps, target = '.ant-table-body') => {
  return useEffect(() => {
    const lazyElement = document.querySelector(target);
    const handleScroll = (/** @type {Event} */ event) => {
      if (event.target instanceof HTMLElement) {
        const maxScroll = event.target.scrollHeight - event.target.clientHeight - 1;
        const currentScroll = event.target.scrollTop;
        if (currentScroll >= maxScroll) {
          effect();
        }
      }
    };
    if (lazyElement) {
      lazyElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (lazyElement) {
        lazyElement.removeEventListener('scroll',  handleScroll);
      }
    };
  }, [ deps ]);
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useGetList = ({ page, limit, order, sort, search, type = "brands" }) => {
  const apiList = {
    brand: "brand/",
    principal: "principal/",
  };

  const [data, setData] = useState({
    data: [],
    statusCode: null,
    totalData: null,
    totalPage: null,
  });

  useEffect(() => {
    if (page || limit || order || sort || search) {
      const getData = async () => {
        try {
          const response = await httpClient.get(
            `${process.env.REACT_APP_API_URL_NEW_V2}${apiList[type]}`,
            {
              params: {
                $page: page,
                $limit: limit,
                $order: order,
                $sort: sort,
                search,
              },
            },
          );

          setData(response.data);
        } catch (error) {
          ErrorNotification(error);
        }
      };

      getData();
    }
  }, [page, limit, order, sort, search]);

  return {
    [type]: data.data,
    statusCode: data.statusCode,
    totalData: data.totalData,
    totalPage: data.totalPage,
  };
};

import React, { useEffect, useMemo, useState } from "react";
import { AppstoreOutlined, BarsOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Divider, Row, Space, Segmented, Typography } from "antd";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { columns as defaultColumns } from "./helpers";
import { getCurrentRole, toSentenceCase } from "../../../utils/Functions";
import { UserRoles } from "../../../constants";
import useSkuMasterData from "../../../hooks/useSkuMasterData";
import useCategoryData from "../../../hooks/useCategoryData";
import useLoggedInUser from "../../../hooks/useLoggedInUser";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ProductSearchFilter from "../../atoms/ProductSearchFilter/ProductSearchFilter";
import SpinLoaderContent from "../../atoms/SpinLoaderContent/SpinLoaderContent";
import AddMultipleProductModal from "../../molecules/AddMultipleProductModal/AddMultipleProductModal";
import AddProductMasterModal from "../../molecules/AddProductMasterModal/AddProductMasterModal";
import CategorySidebarMenu from "../../molecules/CategorySidebarMenu/CategorySidebarMenu";
import SkuMasterCard from "../../molecules/SkuMasterCard/SkuMasterCard";
import AddSkuMasterModal from "../../molecules/AddSkuMasterModal/AddSkuMasterModal";
import * as productActions from "../../../actions/product.action";
import * as uploadActions from "../../../actions/upload.action";
import * as inventoryActions from "../../../actions/inventory.action";
import "./_style.scss";
import CustomTable from "../../atoms/CustomTable";

const { Text } = Typography;

const segmentedContent = {
  LIST: "LIST",
  KANBAN: "KANBAN",
};

const skuMasterType = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

const SkuMaster = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    label: "All Categories",
    value: "all-categories",
  });
  const segmentedOptions = [
    {
      value: segmentedContent.LIST,
      icon: <BarsOutlined />,
    },
    {
      value: segmentedContent.KANBAN,
      icon: <AppstoreOutlined />,
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [singleProductModalOpen, setSingleProductModalOpen] = useState(false);
  const [productMasterModalOpen, setProductMasterModalOpen] = useState(false);
  const [loadingInfiniteScroll, setLoadingInfiniteScroll] = useState(false);
  const [selectShow, setSelectShow] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductIDs, setSelectedProductIDs] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddMultipleProductLoading, setIsAddMultipleProductLoading] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [isProductMasterLoading, setIsProductMasterLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);

  const params = useMemo(() => {
    const paramsConstruct = {
      $page: page,
      $limit: pageSize,
      companyId: id,
      search: searchValue,
      categoryId,
    };

    return paramsConstruct;
  }, [id, page, pageSize, searchValue, categoryId]);

  const columns = useMemo(() => {
    return defaultColumns.map((column) => ({ ...column, title: t(column?.title) }));
  }, [t]);

  const skuMaster = useSkuMasterData(params);
  const category = useCategoryData();
  const userRole = getCurrentRole();
  const { companyId } = useLoggedInUser();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const openTabs = searchParams.get("openSegments") ?? segmentedContent.KANBAN;
  const [activeTabKey, setActiveTabKey] = useState(openTabs);

  useEffect(() => {
    let newData = [];
    if (!isEmpty(skuMaster?.data)) {
      newData = skuMaster?.data.map((product) => {
        const {
          id = "",
          image = "",
          name = "",
          fullName = "",
          category = {},
          subCategory = {},
          isAdded = false,
          brand = {},
          ownershipStatus = "",
          packType = "",
          size = "",
          status = "",
          uom = "",
          uomLabel = "",
          variant = "",
        } = product;

        const newBrand = brand?.name || "";
        const principal = brand?.principal?.name || "";
        const newCategory = toSentenceCase(category?.name || "-");
        const newSubCategory = subCategory?.name || "";

        return {
          id,
          image,
          name,
          fullName,
          category: newCategory,
          subCategory: newSubCategory,
          isAdded,
          principal,
          brand: newBrand,
          ownershipStatus,
          packType: toSentenceCase(packType || "-"),
          size,
          status,
          uom,
          uomLabel,
          variant,
        };
      });
    }
    setData(newData);
    setLoadingInfiniteScroll(false);
    setIsRefresh(false);
  }, [skuMaster?.data]);

  useEffect(() => {
    if (!isEmpty(category?.data)) {
      const { data } = category;
      const _menuItems = data.map(({ id, name }) => ({ key: id, title: name }));
      const _categoryOptions = data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setMenuItems(_menuItems);
      setCategoryOptions(_categoryOptions);
    }
  }, [category?.data]);

  useEffect(() => {
    if (activeTabKey === segmentedContent.KANBAN) setPageSize(20);
    else setPageSize(10);
  }, [activeTabKey]);

  const handleChangeTabs = (value) => {
    searchParams.set("openSegments", value);
    history.replace({ search: searchParams.toString() });
    setActiveTabKey(value);
  };

  const handleClickCategory = ({ key, title }) => {
    setIsRefresh(true);
    setPage(1);
    let newCategoryId = key;
    if (key === "all-categories") newCategoryId = "";
    setCategoryId(newCategoryId);
    setSelectedCategory({ value: key, label: title });
  };

  const handleSelectMultipleProduct = () => {
    setSelectShow(true);
  };

  const handleCancelSelectMultipleProduct = () => {
    setSelectedProductIDs([]);
    setSelectedProducts({});
    setSelectShow(false);
  };

  const handleCheckboxProduct = (value, productID, productData = {}) => {
    const _selectedProducts = { ...selectedProducts };

    if (value) _selectedProducts[productID] = { ...productData, price: 0, stock: 0 };
    else delete _selectedProducts[productID];

    setSelectedProductIDs(Object.keys(_selectedProducts));
    setSelectedProducts(_selectedProducts);
  };

  const handleUpdateSelectedProducts = (value, field, productID) => {
    const _selectedProducts = { ...selectedProducts };
    const _productData = _selectedProducts[productID];

    if (value) _productData[field] = value;
    else _productData[field] = 0;

    _selectedProducts[productID] = { ..._productData };
    setSelectedProducts(_selectedProducts);
  };

  const handleSelectSku = (data) => {
    setSelectedProduct(data);
    setSingleProductModalOpen(true);
  };

  const handleAddSingleProduct = async (fields) => {
    const newCompanyId = id ?? companyId;
    const values = { ...fields, companyId: newCompanyId };

    setIsLoading(true);
    const isSuccess = await dispatch(inventoryActions.create(values));
    if (isSuccess) {
      setSingleProductModalOpen(false);
      dispatch(
        productActions.updateProductMaster({
          id: fields?.productId,
          key: "isAdded",
          value: true,
        }),
      );
    }
    setIsLoading(false);
  };

  const handleAddMultipleProduct = async () => {
    const newCompanyId = id ?? companyId;
    const _selectedProducts = [];
    if (Object.keys(selectedProducts).length !== 0) {
      Object.keys(selectedProducts).map((key) => {
        const product = selectedProducts[key];

        return _selectedProducts.push({
          stock: product.stock,
          basePrice: product.price,
          sellingPrice: 0,
          margin: 0,
          companyId: newCompanyId,
          productId: product?.id,
        });
      });

      const values = { data: _selectedProducts };

      setIsAddMultipleProductLoading(true);
      const isSuccess = await dispatch(inventoryActions.createBulk(values));
      if (isSuccess) {
        setModalOpen(false);
        handleCancelSelectMultipleProduct();
        _selectedProducts.map((product) => {
          dispatch(
            productActions.updateProductMaster({
              id: product.productId,
              key: "isAdded",
              value: true,
            }),
          );
        });
      }
      setIsAddMultipleProductLoading(false);
    }
  };

  const loadMoreData = () => {
    if (loadingInfiniteScroll) return;
    setLoadingInfiniteScroll(true);
    const newParams = { ...params, $page: page + 1 };
    skuMaster.fetchMoreData(newParams);
  };

  const handleCreateProductMaster = async (values) => {
    const type = userRole === UserRoles.BASKIT_ADMIN ? skuMasterType.PUBLIC : skuMasterType.PRIVATE;
    const isAddSuccess = await dispatch(productActions.createProductMaster(values, { type }));

    if (isAddSuccess) {
      skuMaster.refetchData();
      setProductMasterModalOpen(false);
    }
    setIsProductMasterLoading(false);
  };

  const handleAddProductMaster = async (fields) => {
    const {
      brand_name,
      principal_name,
      product_name,
      category,
      sub_category,
      product_variant,
      type_pack,
      size,
      pcs,
      unit,
      image,
    } = fields;

    let values = {
      brand: brand_name,
      principal: principal_name,
      name: product_name,
      categoryId: category,
      variant: product_variant,
      packType: type_pack,
      size: size,
      uomLabel: pcs,
      uomId: unit,
    };

    if (sub_category) values = { ...values, sub_category };

    setIsProductMasterLoading(true);
    if (!isEmpty(image)) {
      let imageValue = new FormData();
      imageValue.append("file", image);

      const { isSuccess, data } = await dispatch(
        uploadActions.upload({ folder: "baskit-products" }, imageValue),
      );

      if (isSuccess) {
        values = { ...values, image: data.fileName };
        handleCreateProductMaster(values);
      } else setIsProductMasterLoading(false);
    } else {
      handleCreateProductMaster(values);
    }
  };

  const productSearchFilterProps = {
    placeholder: "Search by product name",
    setValue: productSearch,
    handleChange: (value) => {
      setSearchValue(value);
    },
  };

  const addSkuMasterModalProps = {
    modalOpen: singleProductModalOpen,
    setModalOpen: setSingleProductModalOpen,
    data: selectedProduct,
    isLoading,
    handleSubmit: handleAddSingleProduct,
  };

  const addMultipleProductModalProps = {
    modalOpen,
    setModalOpen,
    selectedProductIDs,
    selectedProducts,
    isLoading: isAddMultipleProductLoading,
    handleUpdateSelectedProducts,
    handleSubmit: handleAddMultipleProduct,
  };

  const addProductMasterModal = {
    modalOpen: productMasterModalOpen,
    setModalOpen: setProductMasterModalOpen,
    categoryOptions,
    setProductSearch,
    isProductMasterLoading,
    handleSubmit: handleAddProductMaster,
  };

  const dataSource = useMemo(() => {
    const dataConstruct = data.map((data, index) => ({
      key: index,
      image: data?.image,
      name: `${data?.name} ${data?.variant}`,
      principal: data?.principal,
      brand: data?.brand,
      unit: data?.packType,
      uom: `${data?.size} ${data?.uom}`,
      qty: data?.uomLabel,
      isAdded: data?.isAdded,
      handleSelectSku: () => handleSelectSku(data),
    }));

    return dataConstruct;
  }, [data]);

  const tabelProps = {
    loading: skuMaster?.isLoading,
    dataSource,
    columns,
    scroll: { y: "75vh" },
    rowClassName: ({ isAdded }) => {
      if (isAdded) return "sku-master__list-row--disabled";
    },
    page,
    setPage,
    pageSize,
    setPageSize,
    total: skuMaster?.totalData,
  };

  const isInsideIframe = window.parent !== window;

  return (
    <div id="sku-master" className="sku-master">
      <Row gutter={10}>
        <Col span={4} flex={1}>
          <div className="sku-master__category" style={{ maxHeight: isInsideIframe ? 'calc(100vh - 30px)' : 'calc(100vh - 90px)' }}>
            {category?.isLoading ? (
              <div className="sku-master__spin-loader">
                <SpinLoaderContent />
              </div>
            ) : (
              <CategorySidebarMenu menuItems={menuItems} handleClick={handleClickCategory} />
            )}
          </div>
        </Col>

        <Col span={20}>
          <div className="sku-master__filter">
            <Row gutter={12}>
              <Col span={16}>
                <ProductSearchFilter {...productSearchFilterProps} />
              </Col>
              <Col span={4}>
                <Segmented
                  className="sku-master__segmented"
                  size="large"
                  block
                  options={segmentedOptions}
                  defaultValue={activeTabKey}
                  value={activeTabKey}
                  onChange={handleChangeTabs}
                />
              </Col>
              <Col span={4}>
                <ButtonPrimary
                  text="Add Product"
                  block={true}
                  handleClick={() => setProductMasterModalOpen(true)}
                />
              </Col>
            </Row>
          </div>

          {activeTabKey === segmentedContent.KANBAN && (
            <div className={`sku-master__header ${selectShow && "sku-master__header--selected"}`}>
              <Row>
                <Col span={8}>
                  <Space className="full-width" direction="horizontal">
                    <Text strong>{skuMaster?.totalData}</Text>
                    <Text>{t("Product")}</Text>
                    <Text strong>{t(selectedCategory.label)}</Text>
                  </Space>
                </Col>
                <Col span={16}>
                  {selectShow === false ? (
                    <Button
                      type="default"
                      className="button-select-multiple float-right"
                      onClick={handleSelectMultipleProduct}
                    >
                      {t("Select Multiple")}
                    </Button>
                  ) : (
                    <Space className="float-right" direction="horizontal" size="large">
                      <Space className="space-wrapper" direction="horizontal">
                        <Text strong>({selectedProductIDs.length})</Text>
                        <Text>{t("Selected Product")}</Text>
                      </Space>
                      <ConfigProvider theme={{ token: { colorPrimary: "#247cff" } }}>
                        <Button
                          disabled={selectedProductIDs.length ? false : true}
                          type="primary"
                          onClick={() => setModalOpen(true)}
                        >
                          {t("Add Product")}
                        </Button>
                      </ConfigProvider>
                      <Button danger onClick={handleCancelSelectMultipleProduct}>
                        <CloseOutlined />
                        {t("Cancel")}
                      </Button>
                    </Space>
                  )}
                </Col>
              </Row>
            </div>
          )}

          {activeTabKey === segmentedContent.LIST ? (
            <div className="sku-master__list">
              <CustomTable {...tabelProps} />
            </div>
          ) : (
            <div id="sku-master-kanban" className="sku-master__kanban">
              <InfiniteScroll
                dataLength={isRefresh ? [] : data?.length}
                next={loadMoreData}
                hasMore={data?.length < skuMaster?.totalData}
                endMessage={
                  <div className="full-width pl-10 pr-10">
                    <Divider plain>{t("It is all, nothing more")} !</Divider>
                  </div>
                }
                scrollableTarget="sku-master-kanban"
                loader={<SpinLoaderContent />}
              >
                <div className="card-wrapper">
                  {data.map((data, index) => (
                    <SkuMasterCard
                      key={index}
                      data={data}
                      handleCheckboxProduct={handleCheckboxProduct}
                      selectedProductIDs={selectedProductIDs}
                      selectShow={selectShow}
                      handleClick={() => handleSelectSku(data)}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          )}

          <div>
            <AddSkuMasterModal {...addSkuMasterModalProps} />
            <AddMultipleProductModal {...addMultipleProductModalProps} />
            <AddProductMasterModal {...addProductMasterModal} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SkuMaster;

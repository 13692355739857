// @ts-check

import { RECEIPT_ERROR, RECEIPT_FETCHING, RECEIPT_SUCCESS } from "../constants";

/** @type {AppReducer.ReceiptReducer} */
const initialState = {
  isFething: false,
  isError: false,
};

/**
 * @returns {AppReducer.ReceiptReducer}
 * */
export default (state = initialState,
  /** @type {AppReducer.ReceiptActionPayload} */
  { data, type, error },
) => {
  switch (type) {
    case RECEIPT_FETCHING:
      return { ...state, isFething: true, isError: false, data: undefined };
    case RECEIPT_SUCCESS:
      return { ...state, isFething: false, isError: false, data };
    case RECEIPT_ERROR:
      return { ...state, isFething: false, isError: true, data, error };
    default:
      return state;
  }
};
import React from "react";
import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

const FormItemInputNumberGeneral = ({
  label,
  name,
  size,
  placeholder,
  rules,
  disabled = false,
  handleChange = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item label={t(label)} name={name} rules={rules}>
      <InputNumber
        className="full-width"
        placeholder={t(placeholder)}
        size={size}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default FormItemInputNumberGeneral;

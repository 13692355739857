import React from "react";
import { Divider, Form, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./FormItemSelectCustomer.scss";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

const FormItemSelect = ({
  label,
  name,
  placeholder,
  defaultValue,
  rules,
  options,
  disabled = false,
  handleChange = () => {},
  setModalOpen,
}) => {
  const { t } = useTranslation();

  const renderOption = (option) => {
    return (
      <div className='option-item-wrapper'>
        <div className='selected-item-label'>
          <Text>{option.label}</Text>
        </div>
        <div className='selected-item-value'>
          <Text type='secondary'>{option.noPhone}</Text>
        </div>
      </div>
    );
  };

  return (
    <Form.Item className="form-item-select-customer" label={t(label)} name={name} rules={rules}>
      <Select
        defaultValue={defaultValue}
        showSearch
        allowClear
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLocaleLowerCase())
        }
        optionLabelProp="children"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <div
              style={{
                padding: "0 8px 4px",
              }}
            >
              <ButtonPrimary
                className="float-right"
                icon={<PlusOutlined />}
                text={"Add Customer"}
                handleClick={() => setModalOpen(true)}
              />
            </div>
          </>
        )}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            {renderOption(option)}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FormItemSelect;

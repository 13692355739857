/// <reference path="../molecules.d.ts"/>
// @ts-check
import { Col } from "antd";
import { isEmpty } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import useAmplitudeContext from "../../../hooks/useAmplitudeContext";
import { parseDayToDate } from "../../../utils/Functions";
import ButtonCoditional from "../../atoms/ButtonConditional";
import SingleRangePicker from "../../atoms/SingleRangePicker/SingleRangePicker";
import "./_style.scss";

/**
 * @typedef {React.Dispatch<React.SetStateAction<BaskitApp.PeriodeType>>} PeriodeDispatch
 * @typedef {{
 *  periodeState: [ BaskitApp.PeriodeType, PeriodeDispatch ],
 *  colSpan?: number,
 * }} PeriodeSelectorOptions
 */

const dateRangeRegex = /\d\d\/\d\d\/\d\d\d\d-\d\d\/\d\d\/\d\d\d\d/i;

/**
 * @param {React.PropsWithChildren<PeriodeSelectorOptions>} Props
 *
 * @returns {JSX.Element}
 */
const PeriodSelector = ({ periodeState, colSpan }) => {
  const { t } = useTranslation();

  const [periode, setPeriode] = periodeState;
  const periodeOptions = ["today", "yesterday", "lastweek", "lastmonth"];
  const { trackAmplitudeEvent } = useAmplitudeContext();

  return (
    <Col id="period-selector-button" span={colSpan ?? 16}>
      {periodeOptions.map((key) => (
        <ButtonCoditional
          key={key}
          text={t(key)}
          active={periode.label === key}
          handleClick={() => {
            setPeriode({ label: key, value: parseDayToDate(key) });
            trackAmplitudeEvent("ORDER_STATUS_FILTER_CLICK", { date_filter: key });
          }}
        />
      ))}
      <span
        id="selector-date-range"
        className={dateRangeRegex.test(periode) ? "active" : undefined}
      >
        <SingleRangePicker
          format="YYYY-MM-DD"
          handleChange={(date) => {
            console.log("current date: ", date);
            if (isEmpty(date))
              setPeriode({ label: periodeOptions[0], value: parseDayToDate(periodeOptions[0]) });
            else {
              const start = `${date[0]}T00:00:00+0700`;
              const end = `${date[1]}T23:59:59+0700`;
              setPeriode({ label: "rangepicker", value: { start, end } });
            }
            trackAmplitudeEvent("ORDER_DATE_PICKER_CLICK");
          }}
        />
      </span>
    </Col>
  );
};

export default PeriodSelector;

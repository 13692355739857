import React, { useEffect, useMemo, useState } from "react";
import { PlusOutlined, ShopOutlined } from "@ant-design/icons";
import { Col, Row, Switch, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { columns } from "./helpers";
import { filterParams } from "../../utils/Functions";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import SortProduct from "../../components/atoms/SortProduct/SortProduct";
import AddSalesmanModal from "../../components/molecules/AddSalesmanModal/AddSalesmanModal";
import MainContent from "../../components/organisms/Content/Content";
import * as salesActions from "../../actions/sales.action";
import "./_style.scss";

export const StatusComponent = ({ status, id }) => {
  const dispatch = useDispatch();
  const initialChecked = ["ACTIVE", "ACTIVATED"].includes(status.toUpperCase());
  const [isChecked, setIsChecked] = useState(initialChecked);

  const handleUpdateStatus = async (id, value) => {
    const values = value ? { status: "ACTIVE" } : { status: "INACTIVE" };
    const isSuccess = await dispatch(salesActions.updateStatus(id, values));
    if (!isSuccess) setIsChecked(!value);
  };

  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);

  return (
    <div className="antd-content-center">
      <Switch
        style={{ backgroundColor: isChecked ? "green" : "" }}
        checked={isChecked}
        onChange={(value) => {
          handleUpdateStatus(id, value);
          setIsChecked(value);
        }}
      />
      <span className="ml-10">{isChecked == true ? "Active" : "Inactive"}</span>
    </div>
  );
};

export const AssignmentComponent = ({ assignment }) => {
  const store = assignment > 1 ? "stores" : "store";
  assignment = assignment ? assignment : 0;

  return (
    <div className="antd-content-center">
      <Tag icon={<ShopOutlined />} color="orange">
        {assignment} {store}
      </Tag>
    </div>
  );
};

const Sales = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [salesList, setSalesList] = useState();
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const headerProps = {
    title: "Account Manager",
    description: "Manage your account",
  };

  const sales = useSelector((state) => state.salesReducer);

  const params = useMemo(() => {
    const paramsConstruct = {
      role: "SALESMAN",
      $page: page,
      $limit: pageSize,
      search: searchValue,
      status: sortValue,
    };
    return filterParams(paramsConstruct);
  }, [page, pageSize, searchValue, sortValue]);

  useEffect(() => {
    dispatch(salesActions.Index(params));
  }, [params]);

  useEffect(() => {
    if (sales?.result) {
      const { data, totalData, totalPage } = sales?.result;
      setSalesList(data);
      setMeta({ totalData, totalPage });
      setLoading(false);
    }
  }, [sales]);

  const dataSource =
    Array.isArray(salesList) &&
    salesList?.map((data, index) => ({
      key: index,
      name: data,
      phone: data?.phone,
      status: data,
      assignment: data,
    }));

  const salesSearchFilterProps = {
    placeholder: "Search sales by name",
    handleChange: (value) => setSearchValue(value),
  };

  const addSalesmanModalProps = {
    modalOpen,
    setModalOpen,
    buttonText: "Submit",
  };

  const sortProductProps = {
    placeholder: "Status",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "Inactive" },
    ],
    handleChange: (value) => setSortValue(value),
  };

  const tableProps = {
    loading: loading,
    dataSource: dataSource,
    columns,
    pagination: {
      pageSize: pageSize,
      current: page,
      total: meta?.totalData,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
    },
    scroll: { y: "60vh" },
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="my-account-sales-wrapper">
        <div id="my-account-sales-filter">
          <Row gutter={24}>
            <Col span={16}>
              <ProductSearchFilter {...salesSearchFilterProps} />
            </Col>
            <Col span={4}>
              <SortProduct {...sortProductProps} />
            </Col>
            <Col span={4}>
              <ButtonPrimary
                disabled={false}
                block={true}
                htmlType="submit"
                text={"Add Salesman"}
                icon={<PlusOutlined />}
                handleClick={() => setModalOpen(true)}
              />
            </Col>
          </Row>
        </div>

        <div id="my-account-sales-content">
          <Table async {...tableProps} />
        </div>
      </div>

      <AddSalesmanModal {...addSalesmanModalProps} />
    </MainContent>
  );
};

export default Sales;

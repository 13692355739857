import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTotalTransactionData } from "../../actions/dashboard.action";
import { useTranslation } from "react-i18next";
import { formatRupiah } from "../../utils/Functions";

const DashboardTotal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /** @type {DashboardState} */
  const { period, recordType, totalData } = useSelector((state) => state.dashboardReducer);

  useEffect(() => {
    dispatch(fetchTotalTransactionData(period, recordType));
  }, [period, recordType]);

  return (
    <div id="total-transaction-container">
      <div>
        <div>
          <div>{t("total transaction")}</div>
          <div className="total-data">
            {recordType === "transaction" ? totalData.total : formatRupiah(totalData.total)}
          </div>
        </div>
        <div>
          <div>{t("Pesanan Baskit")}</div>
          <div className="total-data">
            {recordType === "transaction" ? totalData.online : formatRupiah(totalData.online)}
          </div>
        </div>
        <div>
          <div>{t("Transaksi Toko")}</div>
          <div className="total-data">
            {recordType === "transaction" ? totalData.offline : formatRupiah(totalData.offline)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTotal;

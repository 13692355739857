import { UserRoles } from "../constants";
import AddProductIndex from "../pages/addProduct";
import Seller from "../pages/seller";
import SellerDetail from "../pages/seller/detail";
import ChooseProductIndex from "../pages/chooseProduct";
import EditOrder from "../pages/chooseProduct/editOrder";
import CoverageAreaIndex from "../pages/coverageArea";
import CoverageAreaDetail from "../pages/coverageArea/detail";
import CreateOrderIndex from "../pages/createOrder";
import CustomerIndex from "../pages/customer";
import CustomerCreate from "../pages/customer/create";
import CustomerDetail from "../pages/customer/detail";
import CustomerFinancials from "../pages/customer/financials";
import HomeIndex from "../pages/home";
import LoginIndex from "../pages/login";
import LoginOTP from "../pages/login/otp";
import MerchantListIndex from "../pages/merchantList";
import MerchantDetail from "../pages/merchantList/detail";
import MerchantDetailInvoice from "../pages/merchantList/invoice";
import MyAccountIndex from "../pages/myAccount";
import Sales from "../pages/sales";
import SalesStore from "../pages/sales/salesStore";
import MyAccountCompanyUpdate from "../pages/myAccount/updateCompany";
import MyAccountProfileUpdate from "../pages/myAccount/updateProfile";
import OrderListIndex from "../pages/orderList";
import OrderDetail from "../pages/orderList/detail";
// import OrderDetailOnline from "../pages/orderList/detailOnline";
import OrderDetailOnlineInvoice from "../pages/orderList/invoice";
import PaymentDetail from "../pages/payment/detail";
import ProductListIndex from "../pages/productList";
import RegisterIndex from "../pages/register";
import RegisterStepTwo from "../pages/register/stepTwo";
import RegisterTermAndConditions from "../pages/register/termAndConditions";
import ShoppingCartIndex from "../pages/shoppingCart";
import { ReceiptSetting } from "../pages/receiptSetting";
import { TransactionManagement } from "../pages/transactionManagement";
import TransactionDetail from "../pages/transactionManagement/detail";
import TransactionInvoice from "../pages/transactionManagement/invoice";
import BuyerManagementIndex from "../pages/buyerManagement";
import BuyerManagementDetail from "../pages/buyerManagement/detail";
import Auth from '../pages/auth';
import Logout from '../pages/logout';

export const routes = [
  // Public Routes
  { path: "/login", exact: true, component: LoginIndex },
  { path: "/login/otp", exact: true, component: LoginOTP },
  { path: "/register", exact: true, component: RegisterIndex },
  { path: "/register/step-two", exact: true, component: RegisterStepTwo },
  { path: "/register/term-conditions", exact: true, component: RegisterTermAndConditions },
  { path: "/auth/*", exact: true, component: Auth },
  { path: "/logout", exact: true, component: Logout },
  // Private Routes
  {
    path: "/home",
    exact: true,
    component: HomeIndex,
    permission: [UserRoles.ADMIN, UserRoles.SALESMAN],
  },
  {
    path: "/product-list",
    exact: true,
    component: ProductListIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/add-product",
    exact: true,
    component: AddProductIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/pos",
    exact: true,
    component: CreateOrderIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/choose-product",
    exact: true,
    component: ChooseProductIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/customer",
    exact: true,
    component: CustomerIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/customer/detail/:id",
    exact: true,
    component: CustomerDetail,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/customer/financials/:id",
    exact: true,
    component: CustomerFinancials,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/customer/create",
    exact: true,
    component: CustomerCreate,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/my-account",
    exact: true,
    component: MyAccountIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/my-account/profile/update/:id",
    exact: true,
    component: MyAccountProfileUpdate,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/my-account/company/update/:id",
    exact: true,
    component: MyAccountCompanyUpdate,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/setting-receipt",
    exact: true,
    component: ReceiptSetting,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/order-list",
    exact: true,
    component: OrderListIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/order-list/offline/detail/:id",
    exact: true,
    component: PaymentDetail,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/order-list/online/detail/:id",
    exact: true,
    component: TransactionDetail,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/shopping-cart",
    exact: true,
    component: ShoppingCartIndex,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/payment/detail/:id",
    exact: true,
    component: PaymentDetail,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/sales",
    exact: true,
    component: Sales,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/sales-store/:id",
    exact: true,
    component: SalesStore,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/merchant-list",
    exact: true,
    component: MerchantListIndex,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/merchant-list/detail/:id",
    exact: true,
    component: MerchantDetail,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/merchant-list/detail/:companyId/invoice/:orderId",
    exact: true,
    component: MerchantDetailInvoice,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/transaction-management",
    exact: true,
    component: TransactionManagement,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/transaction-management/detail/:id",
    exact: true,
    component: TransactionDetail,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/transaction-management/invoice/:id",
    exact: true,
    component: TransactionInvoice,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/order-list/online/detail/:companyId/invoice/:invoiceId",
    exact: true,
    component: OrderDetailOnlineInvoice,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/seller",
    exact: true,
    component: Seller,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/seller/:id",
    exact: true,
    component: SellerDetail,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/edit-order/:id",
    exact: true,
    component: EditOrder,
    permission: [UserRoles.ADMIN],
  },
  {
    path: "/coverage-area",
    exact: true,
    component: CoverageAreaIndex,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/coverage-area/:id",
    exact: true,
    component: CoverageAreaDetail,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/buyer-management",
    exact: true,
    component: BuyerManagementIndex,
    permission: [UserRoles.BASKIT_ADMIN],
  },
  {
    path: "/buyer-management/detail/:id",
    exact: true,
    component: BuyerManagementDetail,
    permission: [UserRoles.BASKIT_ADMIN],
  },
];

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Segmented } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import MainContent from "../../components/organisms/Content/Content";
import OrderListOnline from "../../components/templates/OrderList/OrderListOnline";
import OrderListOffline from "../../components/templates/OrderList/OrderListOffline";
import "./_style.scss";
import { columnsOnline } from "../../components/templates/OrderList/helper";

const OrderList = () => {
  const orderTypes = {
    ONLINE: "ONLINE",
    OFFLINE: "OFFLINE",
    SHOP: "SHOP",
  };
  const { t } = useTranslation();
  const { companyId } = useLoggedInUser();
  const { search, pathname } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [selectedTab, setSelectedTab] = useState(searchParams.get("type") ?? orderTypes.ONLINE);

  useEffect(() => {
    searchParams.set("type", selectedTab);
    history.replace({ pathname, search: searchParams.toString() });
  }, [selectedTab]);

  const headerProps = {
    title: "Order List",
    description: "Manage your orders",
  };

  const mainBreadcrumbItems = [
    {
      title: "Order List",
    },
  ];

  const getColumns = useCallback(() => {
    if (selectedTab === 'OFFLINE') {
      return ({
        serialNo: true,
        date: true,
        updatedAt: true,
        orderID: true,
        customer: true,
        status: true,
      });
    }

    return ({
      serialNo: true,
      orderID: true,
      customer: true,
      date: true,
      updatedAt: true,
      total: true,
      deliveryType: true,
      marginValue: false,
      sales: false,
      status: true,
      transactionDetails: true,
    });
  }, [selectedTab]);

  return (
    <MainContent headerProps={headerProps}>
      <div id="order-list-wrapper">
        <div id="order-list-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>
        <div id="tab-selector">
          <Segmented
            className="segmented-custom"
            options={[
              {
                value: orderTypes.ONLINE,
                label: t("Pesanan Baskit"),
              },
              {
                value: orderTypes.OFFLINE,
                label: t("Transaksi Toko"),
              },
            ]}
            defaultValue={selectedTab}
            value={selectedTab}
            onChange={setSelectedTab}
          />
        </div>

        {selectedTab === 'ONLINE' ? (
          <OrderListOnline
            companyId={companyId}
            columns={getColumns()}
            link={{
              details: (id) => `/order-list/${selectedTab.toLocaleLowerCase()}/detail/${id}`,
              invoice: (id) => `/transaction-management/invoice/${id}`,
            }}
            columnsList={columnsOnline}
            columnLabels={{
              customerPhone: t("Customer"),
            }}
            orderFilter={{
              orderType: [selectedTab, orderTypes.SHOP],
            }}
            scroll={{ y: 400 }}
          />
        ) : (
          <OrderListOffline
            companyId={companyId}
            columns={getColumns()}
            link={{
              details: (id) => `/order-list/${selectedTab.toLocaleLowerCase()}/detail/${id}`,
            }}
            columnLabels={{
              customerPhone: t("Customer"),
            }}
            orderFilter={{
              orderType: selectedTab,
            }}
            scroll={{ y: 400 }}
          />
        )}
      </div>
    </MainContent>
  );
};

export default OrderList;

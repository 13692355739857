import { useEffect, useState } from "react";
import { server } from "../constants";

function useRoleSwitcher() {
  const [options, setOptions] = useState([]);
  const defaultValue = localStorage.getItem(server.USER_ROLE);

  useEffect(() => {
    try {
      const roles = JSON.parse(localStorage.getItem(server.ROLES));

      if (roles) {
        const options = roles.map((role) => ({
          value: role,
          label: role.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase()),
        }));

        setOptions(options);
      }
    } catch(error) {
      setOptions([]);
    }
  }, []);

  const handleChange = (value) => {
    localStorage.setItem(server.USER_ROLE, value);
    window.location.reload();
  };

  return {
    options,
    defaultValue,
    handleChange,
    hasMultipleRoles: options.length > 1
  };
}

export default useRoleSwitcher;
import React from "react";
import { Image, Typography } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import "./SalesEmpty.scss";

const { Title, Text, Link } = Typography;

const SalesEmpty = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="sales-empty-wrapper">
      <div className="sales-empty">
        <div className="sales-empty__header">
          <Image width={80} preview={false} src={`${window.location.origin}/images/sales.svg`} />
        </div>
        <div className="sales-empty__content">
          <Title className="title" level={4}>
            {t("No Sales Yet")}
          </Title>
          <Text>{t("This merchant doesn't have any related sales yet") + "."}</Text>
          <br />
          <Text>{t("Please add sales through the menu") + "."}</Text>
          <br />
          <Link className="link-custom" onClick={() => history.push("/sales")}>
            {t("Sales Management")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SalesEmpty;

import React from "react";
import { t } from "../../../utils/Functions";
import { Tooltip, Typography } from "antd";
import { isEmpty } from "lodash-es";

const { Text } = Typography;

const findRowAndTier = (errors, targetRow, targetTier) => {
  if (isEmpty(errors)) return false;
  return errors.some((error) => {
    return error.row === targetRow && error.tier === targetTier;
  });
};

const getErrorMessage = (errors, targetRow, targetTier) => {
  const error = errors.find((error) => error.row === targetRow && error.tier === targetTier);
  if (!isEmpty(error)) {
    if (typeof error.message === "string") return error.message;
    return `An error occurred in input for cell tier ${targetTier}`;
  }
};

const cellClassName = (record, tier) => {
  const className = findRowAndTier(record?.errors, record?.key + 3, tier)
    ? "incorrect-inserted-cell"
    : "";
  return { className };
};

const cellRender = (text, record, tier) => {
  return findRowAndTier(record?.errors, record?.key + 3, tier) ? (
    <Tooltip title={getErrorMessage(record?.errors, record?.key + 3, tier)}>
      <div className="full-width">
        <Text className="full-width">{text ? text : "null"}</Text>
      </div>
    </Tooltip>
  ) : (
    <Text>{text}</Text>
  );
};

export const columns = [
  {
    title: t("SKU ID"),
    dataIndex: "skuId",
    key: "skuId",
    width: 200,
    // onCell: cellClassName,
    // render: cellRender,
  },
  {
    title: t("Stock"),
    dataIndex: "stock",
    key: "stock",
    width: 150,
    // onCell: cellClassName,
    // render: cellRender,
  },
  {
    title: t("Harga Dasar"),
    dataIndex: "basePrice",
    key: "basePrice",
    width: 150,
    // onCell: cellClassName,
    // render: cellRender,
  },
  {
    title: t("Tier 1 Min"),
    dataIndex: "firstTierMin",
    key: "firstTierMin",
    onCell: (record, rowIndex) => cellClassName(record, 1),
    render: (text, record) => cellRender(text, record, 1),
  },
  {
    title: t("Tier 1 Max"),
    dataIndex: "firstTierMax",
    key: "firstTierMax",
    onCell: (record) => cellClassName(record, 1),
    render: (text, record) => cellRender(text, record, 1),
  },
  {
    title: t("Tier 1 %"),
    dataIndex: "firstTierPercentage",
    key: "firstTierPercentage",
    onCell: (record) => cellClassName(record, 1),
    render: (text, record) => cellRender(text, record, 1),
  },
  {
    title: t("Tier 2 Min"),
    dataIndex: "secondTierMin",
    key: "secondTierMin",
    onCell: (record) => cellClassName(record, 2),
    render: (text, record) => cellRender(text, record, 2),
  },
  {
    title: t("Tier 2 Max"),
    dataIndex: "secondTierMax",
    key: "secondTierMax",
    onCell: (record) => cellClassName(record, 2),
    render: (text, record) => cellRender(text, record, 2),
  },
  {
    title: t("Tier 2 %"),
    dataIndex: "secondTierPercentage",
    key: "secondTierPercentage",
    onCell: (record) => cellClassName(record, 2),
    render: (text, record) => cellRender(text, record, 2),
  },
  {
    title: t("Tier 3 Min"),
    dataIndex: "thirdTierMin",
    key: "thirdTierMin",
    onCell: (record) => cellClassName(record, 3),
    render: (text, record) => cellRender(text, record, 3),
  },
  {
    title: t("Tier 3 Max"),
    dataIndex: "thirdTierMax",
    key: "thirdTierMax",
    onCell: (record) => cellClassName(record, 3),
    render: (text, record) => cellRender(text, record, 3),
  },
  {
    title: t("Tier 3 %"),
    dataIndex: "thirdTierPercentage",
    key: "thirdTierPercentage",
    onCell: (record) => cellClassName(record, 3),
    render: (text, record) => cellRender(text, record, 3),
  },
  {
    title: t("Tier 4 Min"),
    dataIndex: "fourthTierMin",
    key: "fourthTierMin",
    onCell: (record) => cellClassName(record, 4),
    render: (text, record) => cellRender(text, record, 4),
  },
  {
    title: t("Tier 4 Max"),
    dataIndex: "fourthTierMax",
    key: "fourthTierMax",
    onCell: (record) => cellClassName(record, 4),
    render: (text, record) => cellRender(text, record, 4),
  },
  {
    title: t("Tier 4 %"),
    dataIndex: "fourthTierPercentage",
    key: "fourthTierPercentage",
    onCell: (record) => cellClassName(record, 4),
    render: (text, record) => cellRender(text, record, 4),
  },
];

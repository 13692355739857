import { AutoComplete, Form, Input } from "antd";
import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePlacesService from "../../hooks/usePlacesService";

const FormItemAddress = ({
  label,
  name,
  rules,
  size = "middle",
  placeholder,
  defaultValue,
  handleSelect = () => {},
}) => {
  const { t } = useTranslation();
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    language: "id",
    debounce: 1000,
  });

  const [options, setOptions] = useState(defaultValue ? [{ value: defaultValue.display_name, label: defaultValue.display_name }] : []);

  useEffect(() => {
    if (!isEmpty(placePredictions)) {
      const options = placePredictions.map((place, index) => ({
        value: place.display_name,
        label: place.display_name,
        key: index,
      }));
      setOptions(options);
    }
  }, [placePredictions]);

  const handleGetPlaceDetails = (value) => {
    const detail = placePredictions.find((place) => place.display_name === value);
    if (!isEmpty(detail)) handleSelect(detail);
  };

  return (
    <Form.Item name={name} label={t(label)} rules={rules} style={{ flex: 'auto', margin: 0 }}>
      <AutoComplete
        defaultValue={defaultValue?.display_name}
        options={options}
        onSelect={handleGetPlaceDetails}
        onSearch={(value) => {
          if (value) getPlacePredictions({ input: value });
        }}
      >
        <Input.Search size={size} placeholder={placeholder} loading={isPlacePredictionsLoading} />
      </AutoComplete>
    </Form.Item>
  );
};

export default FormItemAddress;

import {
  DASHBOARD_CHART_ERROR, DASHBOARD_CHART_FETCHED, DASHBOARD_CHART_FETCHING,
  DASHBOARD_PERIOD_CHANGING, DASHBOARD_RECORD_CHANGING, DASHBOARD_STATE_CHANGED,
  DASHBOARD_STATUS_CHANGING,
  DASHBOARD_TOTAL_FETCHING,
  DASHBOARD_TOTAL_FETCHED,
  DASHBOARD_TOTAL_ERROR,
  DASHBOARD_SKU_TRANSACTION_FETCHING,
  DASHBOARD_SKU_TRANSACTION_FETCHED,
  DASHBOARD_SKU_TRANSACTION_ERROR,
  DASHBOARD_TOP_FIVE_SKU_FETCHING,
  DASHBOARD_TOP_FIVE_SKU_FETCHED,
  DASHBOARD_TOP_FIVE_SKU_ERROR,
  DASHBOARD_SKU_ON_BOARD_FETCHING,
  DASHBOARD_SKU_ON_BOARD_FETCHED,
  DASHBOARD_SKU_ON_BOARD_ERROR,
  DASHBOARD_SKU_ON_BOARD_APPENDED,
  DASHBOARD_SKU_ON_BOARD_CLEAR,
} from "../constants";
import { isSingleDay } from "../utils/Functions";

/**
 * @typedef {import("../actions/dashboard.action").PeriodType} PeriodType
 * @typedef {import("../actions/dashboard.action").RecordType} RecordType
 * @typedef {import("../pages/home/mainchart").DataPayload} ChartDataPayload
 * @typedef {{
 *  total: number,
 *  online: number,
 *  offline: number,
 * }} TotalDataPayload
 * 
 * @typedef {{
 *  isChanging: boolean,
 *  period: PeriodType,
 *  singleDay: boolean,
 *  recordType: RecordType,
 *  selectAll: boolean,
 *  selectOffline: boolean,
 *  selectOnline: boolean,
 *  chartFetching: boolean,
 *  chartData: ChartDataPayload[],
 *  totalFetching: boolean,
 *  totalData: TotalDataPayload,
 * }} DashboardState
  *
 * @typedef {{
 *  type: string,
 *  period: PeriodType,
 *  singleDay: boolean,
 *  recordType: RecordType,
 *  selectAll: boolean,
 *  selectOffline: boolean,
 *  selectOnline: boolean,
 *  chartFetching: boolean,
 *  chartData: ChartDataPayload[],
 *  totalFetching: boolean,
 *  totalData: TotalDataPayload,
 * }} DashboardUpdateInfo
 */


/** @type {DashboardState} */
const initialState = {
  isChanging: false,
  period: 'today',
  singleDay: true,
  recordType: 'transaction',
  selectAll: true,
  selectOnline: true,
  selectOffline: true,
  chartFetching: false,
  chartData: [],
  totalFetching: false,
  totalData: {
    total: 0,
    online: 0,
    offline: 0,
  },
  skuTransaction: {}, 
  topFiveSku: [],
  skuOnBoard: {
    data: [],
    meta: {
      totalData: 0,
      totalPage: 0,
    }
  },
};

/**
 * @function dashboardReducer
 *
 * @param {DashboardState} state
 * @param {DashboardUpdateInfo} updateInfo
 *
 * @return  {DashboardState}
 */
export default (state = initialState, {
  type, period, recordType, selectAll, selectOffline, selectOnline,
  chartData, totalData, skuTransaction, topFiveSku, skuOnBoard
}) => {
  switch (type) {
    case DASHBOARD_STATE_CHANGED:
      return { ...state, isChanging: false };
    case DASHBOARD_PERIOD_CHANGING:
      return { ...state, isChanging: true, period, singleDay: isSingleDay(period) };
    case DASHBOARD_RECORD_CHANGING:
      return { ...state, isChanging: true, recordType };
    case DASHBOARD_STATUS_CHANGING:
      return { ...state, isChanging: true, selectAll, selectOffline, selectOnline };
    case DASHBOARD_CHART_FETCHING:
      return { ...state, chartFetching: true };
    case DASHBOARD_CHART_FETCHED:
      return { ...state, chartFetching: false, chartData };
    case DASHBOARD_CHART_ERROR:
      return { ...state, chartFetching: false, chartData: [] };
    case DASHBOARD_TOTAL_FETCHING:
      return { ...state, totalFetching: true };
    case DASHBOARD_TOTAL_FETCHED:
      return { ...state, totalFetching: false, totalData };
    case DASHBOARD_TOTAL_ERROR:
      return { ...state, totalFetching: false, totalData: [] };
    case DASHBOARD_SKU_TRANSACTION_FETCHING:
      return { ...state, skuTransactionFetching: true };
    case DASHBOARD_SKU_TRANSACTION_FETCHED:
      return { ...state, skuTransactionFetching: false, skuTransaction };
    case DASHBOARD_SKU_TRANSACTION_ERROR:
      return { ...state, skuTransactionFetching: false, skuTransaction: [] };
    case DASHBOARD_TOP_FIVE_SKU_FETCHING:
      return { ...state, topFiveSkuFetching: true };
    case DASHBOARD_TOP_FIVE_SKU_FETCHED:
      return { ...state, topFiveSkuFetching: false, topFiveSku };
    case DASHBOARD_TOP_FIVE_SKU_ERROR:
      return { ...state, topFiveSkuFetching: false, topFiveSku: [] };
    case DASHBOARD_SKU_ON_BOARD_FETCHING:
      return { ...state, skuOnBoardFetching: true };
    case DASHBOARD_SKU_ON_BOARD_FETCHED:
      return { ...state, skuOnBoardFetching: false, skuOnBoard };
    case DASHBOARD_SKU_ON_BOARD_ERROR:
      return { ...state, skuOnBoardFetching: false };
    case DASHBOARD_SKU_ON_BOARD_APPENDED:
      return {
        ...state,
        skuOnBoardFetching: false,
        skuOnBoard: {
          ...state?.skuOnBoard,
          data: [
            ...state?.skuOnBoard?.data,
            ...skuOnBoard,
          ],
        }
      }
    case DASHBOARD_SKU_ON_BOARD_CLEAR:
      return {
        ...state,
        skuOnBoard: {
          data: [],
          meta: {
            totalData: 0,
            totalPage: 0,
          }
        }
      }
    default:
      return state;
  }
};

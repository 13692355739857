import { Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./TagCustom.scss";

const TagCustom = ({ icon = undefined, text, className, type, typeHTMl = "text" }, style = {}) => {
  const { t } = useTranslation();
  return (
    <Tag className={`tag-custom ${className} ${type}`} icon={icon} style={style}>
      {typeHTMl === "count" ? text : t(text)}
    </Tag>
  );
};

export default TagCustom;

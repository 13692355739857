import React from "react";
import { Typography, Tooltip } from "antd";

const { Text } = Typography;

const TooltipCell = ({ text }) => {
  return (
    <Tooltip placement="topLeft" title={text}>
      <Text>{text}</Text>
    </Tooltip>
  );
};

export default TooltipCell;

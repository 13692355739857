import React, { useMemo, useState } from "react";
import { Image, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MainContent from "../../components/organisms/Content/Content";
import Profile from "./profile";
import Company from "./company";
import Password from "./password";
import Payment from "./payment";
import Master from "./master";
import "./_style.scss";

const MyAccount = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const openTabs = searchParams.get("openTabs");
  const [activeTabKey, setActiveTabKey] = useState(openTabs ? openTabs : "profile");

  const items = [
    { key: "profile", label: t("Profile"), children: <Profile /> },
    { key: "company", label: t("Company"), children: <Company /> },
    { key: "password", label: t("Password"), children: <Password /> },
    { key: "payment", label: t("Payment"), children: <Payment /> },
    { key: "master", label: t("Master"), children: <Master /> },
  ];

  const headerProps = {
    title: "My Account",
    description: "Manage your account",
  };

  const handleChangeTabs = (value) => {
    searchParams.set("openTabs", value);
    history.replace({ search: searchParams.toString() });
    setActiveTabKey(value);
  };

  return (
    <MainContent headerProps={headerProps}>
      {/* <div id='my-account-wrapper'>
        <div id='my-account-content'>
          <Tabs defaultActiveKey={activeTabKey} activeKey={activeTabKey} items={items} onChange={handleChangeTabs} />
        </div>
      </div> */}
      <div className="flex-center" style={{ height: "100%" }}>
        <Image
          width={320}
          preview={false}
          src={`${window.location.origin}/images/coming-soon.svg`}
        />
      </div>
    </MainContent>
  );
};

export default MyAccount;

import {
  DISTRICT_SEARCH_FAILED,
  DISTRICT_SEARCH_FETCHING,
  DISTRICT_SEARCH_SUCCESS,
  DISTRICT_DETAILS_FAILED,
  DISTRICT_DETAILS_FETCHING,
  DISTRICT_DETAILS_SUCCESS,
  ACTIVE_DEACTIVE_DISTRICT_FETCHING,
  ACTIVE_DEACTIVE_DISTRICT_FAILED,
  DISTRICT_ASSIGNED_FAILED,
  DISTRICT_ASSIGNED_FETCHING,
  DISTRICT_ASSIGNED_SUCCESS,
  DISTRICT_UNASSIGNED_FAILED,
  DISTRICT_UNASSIGNED_FETCHING,
  DISTRICT_UNASSIGNED_SUCCESS,
  ASSIGN_TO_DISTRICT_FETCHING,
  ASSIGN_TO_DISTRICT_FAILED,
  DELETE_FROM_DISTRICT_FETCHING,
  DELETE_FROM_DISTRICT_FAILED,
} from "../constants";

const initialState = {
  isDistrictSearchFetching: false,
  isDistrictSearchError: false,
  districtSearch: null,
  isDistrictDetailsFetching: false,
  isDistrictDetailsError: false,
  districtDetails: null,
  isActiveDeactiveDistrictFetching: false,
  isActiveDeactiveDistrictError: false,
  isDistrictAssignedFetching: false,
  isDistrictAssignedError: false,
  districtAssigned: null,
  isDistrictUnassignedFetching: false,
  isDistrictUnassignedError: false,
  districtUnassigned: null,
  isAssignToDistrictFetching: false,
  isAssignToDistrictError: false,
  isDeleteFromDistrictFetching: false,
  isDeleteFromDistrictError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DISTRICT_SEARCH_FETCHING:
      return {
        ...state,
        isDistrictSearchFetching: true,
        isDistrictSearchError: false,
        districtSearch: null,
      };
    case DISTRICT_SEARCH_SUCCESS:
      return {
        ...state,
        isDistrictSearchFetching: false,
        isDistrictSearchError: false,
        districtSearch: payload,
      };
    case DISTRICT_SEARCH_FAILED:
      return {
        ...state,
        isDistrictSearchFetching: false,
        isDistrictSearchError: true,
        districtSearch: null,
      };
    case DISTRICT_DETAILS_FETCHING:
      return {
        ...state,
        isDistrictDetailsFetching: true,
        isDistrictDetailsError: false,
        districtDetails: null,
      };
    case DISTRICT_DETAILS_SUCCESS:
      return {
        ...state,
        isDistrictDetailsFetching: false,
        isDistrictDetailsError: false,
        districtDetails: payload,
      };
    case DISTRICT_DETAILS_FAILED:
      return {
        ...state,
        isDistrictDetailsFetching: false,
        isDistrictDetailsError: true,
        districtDetails: null,
      };
    case ACTIVE_DEACTIVE_DISTRICT_FETCHING:
      return {
        ...state,
        isActiveDeactiveDistrictFetching: true,
        isActiveDeactiveDistrictError: false,
      };
    case ACTIVE_DEACTIVE_DISTRICT_FAILED:
      return {
        ...state,
        isActiveDeactiveDistrictFetching: false,
        isActiveDeactiveDistrictError: true,
      };
    case DISTRICT_UNASSIGNED_FETCHING:
      return {
        ...state,
        isDistrictUnassignedFetching: true,
        isDistrictUnassignedError: false,
        districtUnassigned: null,
      };
    case DISTRICT_UNASSIGNED_SUCCESS:
      return {
        ...state,
        isDistrictUnassignedFetching: false,
        isDistrictUnassignedError: false,
        districtUnassigned: payload,
      };
    case DISTRICT_UNASSIGNED_FAILED:
      return {
        ...state,
        isDistrictUnassignedFetching: false,
        isDistrictUnassignedError: true,
        districtUnassigned: null,
      };
    case DISTRICT_ASSIGNED_FETCHING:
      return {
        ...state,
        isDistrictAssignedFetching: true,
        isDistrictAssignedError: false,
        districtAssigned: null,
      };
    case DISTRICT_ASSIGNED_SUCCESS:
      return {
        ...state,
        isDistrictAssignedFetching: false,
        isDistrictAssignedError: false,
        districtAssigned: payload,
      };
    case DISTRICT_ASSIGNED_FAILED:
      return {
        ...state,
        isDistrictAssignedFetching: false,
        isDistrictAssignedError: true,
        districtAssigned: null,
      };
    case ASSIGN_TO_DISTRICT_FETCHING:
      return {
        ...state,
        isAssignToDistrictFetching: true,
        isAssignToDistrictError: false,
      };
    case ASSIGN_TO_DISTRICT_FAILED:
      return {
        ...state,
        isAssignToDistrictFetching: false,
        isAssignToDistrictError: true,
      };
    case DELETE_FROM_DISTRICT_FETCHING:
      return {
        ...state,
        isDeleteFromDistrictFetching: true,
        isDeleteFromDistrictError: false,
      };
    case DELETE_FROM_DISTRICT_FAILED:
      return {
        ...state,
        isDeleteFromDistrictFetching: false,
        isDeleteFromDistrictError: true,
      };
    default:
      return state;
  }
};

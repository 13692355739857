import React from "react";
import { Col, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import "./ProductCartConfirmationModal.scss";

const { Title } = Typography;

const ProductCartConfirmationModal = ({ title, message, modalOpen, isLoadingYes = false, handleYes, handleNo }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className='product-cart-confirmation-modal'
      centered
      open={modalOpen}
      onCancel={handleNo}
      width={390}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row gutter={24}>
        <Col span={24} className='flex-center'>
          <Title level={4}>{t(title)}</Title>
        </Col>
        <Col span={24} className='mb-20'>
          <div className='flex-center'>
            <div className='text-wrapper'>{t(message)}</div>
          </div>
        </Col>
        <Col span={12}>
          <ButtonDefault block={true} handleClick={handleYes} text='Yes' loading={isLoadingYes} />
        </Col>
        <Col span={12}>
          <ButtonPrimary block={true} handleClick={handleNo} text='No' />
        </Col>
      </Row>
    </Modal>
  );
};

export default ProductCartConfirmationModal;

import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as salesActions from "../../../actions/sales.action";
import "./AddSalesmanModal.scss";
const { Text } = Typography;

const AddSalesmanModal = ({ modalOpen, setModalOpen = "Submit" }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(true);

  // const handleSubmit = (form) => {
  //   console.log({form})
  //   const {phone, first_name, last_name} = form
  //   dispatch(salesActions.createSalesman({phone, first_name, last_name}))
  //   setModalOpen(false);
  // };
  const phone = Form.useWatch('phone', form);
  const firstname = Form.useWatch('first_name', form);
  const lastname = Form.useWatch('last_name', form);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    if (phone && firstname && lastname) setDisableButton(hasErrors);
  }

  const handleFinish = async (values) => {
    const { phone, first_name: firstName, last_name: lastName } = values;
    const isSuccess = await dispatch(salesActions.createSalesman({ phone: `+62${phone}`, firstName, lastName }))
    if(isSuccess) setModalOpen(false);
  }
  return (
    <Modal
      className="add-salesman-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={400}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row>
        <Col span={24}></Col>
        <Col span={24}>
          <div className="modal-title-wrapper">
            <Text>Add New Salesman</Text>
          </div>
        </Col>
        <Col span={24}>
          <Form
            id="update-product"
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFieldsChange={handleFormChange}
          >
            <Form.Item
              label={"Phone Number"}
              name={"phone"}
              className="form-item-number"
              rules={[
                { required: true, message: "Please input your number" },
                {
                  message: "Phone number cannot be start with 0",
                  validator: (_, value) => {
                    if (value.charAt(0) == 0) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input
                addonBefore={"+62"}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={"First Name"}
              name={"first_name"}
              className="form-item-number"
              rules={[
                { required: true, message: "Please enter a valid first name" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Last Name"}
              name={"last_name"}
              className="form-item-number"
              rules={[
                { required: true, message: "Please enter a valid last name" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item labelAlign="center">
              <Button
                htmlType="submit"
                onClick={() => setModalOpen(false)}
                style={{ margin: "5px" }}
                size="large"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "5px" }}
                size="large"
                disabled={disableButton}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {/* <Col span={12}>
          <Button
            style={{ height: 44 }}
            htmlType="submit"
            block
            onClick={() => setModalOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            style={{ height: 44 }}
            htmlType="submit"
            block
            onClick={() => handleSubmit(form.getFieldsValue())}
          >
            {t(buttonText)}
          </Button>
        </Col> */}
      </Row>
    </Modal>
  );
};

export default AddSalesmanModal;

import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Table } from "antd";
import { filterParams, getSerialNumber } from "../../utils/Functions";
import { columns as defaultColumns } from "./helpers";
import { useTranslation } from "react-i18next";
import MainContent from "../../components/organisms/Content/Content";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import MultipleSelect from "../../components/molecules/MultipleSelect/MultipleSelect";
import useBuyerData from "../../hooks/useBuyerData";
import useCompanyTypeData from "../../hooks/useCompanyTypeData";
import useInternalRegionData from "../../hooks/useInternalRegionData";
import MultipleTreeSelect from "../../components/molecules/MultipleTreeSelect/MultipleTreeSelect";
import "./_style.scss";

const BuyerManagement = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [internalRegionFilter, setInternalRegionFilter] = useState([]);
  const [companyTypeFilter, setCompanyTypeFilter] = useState([]);

  const params = useMemo(() => {
    const paramsConstruct = {
      $page: page,
      $limit: pageSize,
      $order: "companyName",
      $sort: "ASC",
      internalRegion: internalRegionFilter,
      companyType: companyTypeFilter,
      search: searchValue,
    };

    return filterParams(paramsConstruct);
  }, [page, pageSize, internalRegionFilter, companyTypeFilter, searchValue]);

  const companyTypeParams = useMemo(() => {
    return {
      type: "BUYER",
    };
  }, []);

  const columns = useMemo(() => {
    return defaultColumns.map((column) => ({ ...column, title: t(column?.title) }));
  }, [t]);

  const { data, totalData, isLoading } = useBuyerData(params);
  const companyType = useCompanyTypeData(companyTypeParams);
  const internalRegion = useInternalRegionData();

  useEffect(() => {
    if (companyType?.data) {
      const newOptions = companyType?.data.map(({ id, name, children }) => {
        const newChildren = children.map(({ id, name }) => ({ title: name, value: id, key: id }));
        return { title: name, value: id, key: id, children: newChildren };
      });

      setTreeData(newOptions);
    }
  }, [companyType?.data]);

  useEffect(() => {
    if (internalRegion?.data) {
      const newOptions = internalRegion?.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setOptions(newOptions);
    }
  }, [internalRegion?.data]);

  const dataSource = useMemo(() => {
    const dataConstruct = data.map((data, index) => ({
      key: index,
      no: getSerialNumber(page, index),
      storeName: data,
      ownerName: data?.detail?.ownerName ?? "",
      internalRegion: data?.internalRegion?.name ?? "",
      phone: data?.phone ?? "",
      storeType: data?.companyType?.name ?? "",
    }));

    return dataConstruct;
  }, [data]);

  const headerProps = {
    title: "buyerManagement",
    description: "buyerManagementDescription",
  };

  const salesSearchFilterProps = {
    placeholder: "Search store name or internal region",
    handleChange: (value) => {
      setPage(1);
      setSearchValue(value);
    },
  };

  const multipleSelectProps = {
    label: "Filter by internal region",
    options,
    handleChange: (values) => {
      setPage(1);
      setInternalRegionFilter(values);
    },
  };

  const treeSelectProps = {
    label: "Filter by store type",
    treeData,
    handleChange: (values) => {
      setPage(1);
      setCompanyTypeFilter(values);
    },
  };

  const tabelProps = {
    loading: isLoading,
    dataSource,
    columns,
    scroll: { y: "60vh" },
    pagination: {
      pageSize: pageSize,
      current: page,
      total: totalData,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
      showTotal: (total, range) => (
        <span style={{ left: 0, position: "absolute" }}>
          Displaying {range[0]} - {range[1]} of {total}
        </span>
      ),
    },
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="buyer-management-wrapper">
        <div id="buyer-management-filter">
          <Row gutter={12}>
            <Col span={14}>
              <ProductSearchFilter {...salesSearchFilterProps} />
            </Col>
            <Col span={5}>
              <MultipleSelect {...multipleSelectProps} />
            </Col>
            <Col span={5}>
              <MultipleTreeSelect {...treeSelectProps} />
            </Col>
          </Row>
        </div>
        <div id="buyer-management-content">
          <Table async {...tabelProps} />
        </div>
      </div>
    </MainContent>
  );
};

export default BuyerManagement;

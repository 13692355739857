import React from "react";
import { PaymentStatus } from "../../../constants";
import TagCustom from "../../atoms/TagCustom/TagCustom";

const getStatusLabel = (status) => PaymentStatus[status]?.label ?? PaymentStatus.WAITING_FOR_PAYMENT.label;

const getColorStatus = (status) => {
  if (status) {
    return status.toLowerCase().replaceAll(" ", "-");
  }

  return status;
};

const PaymentStatusTag = ({ status, className = "" }) => {
  console.log(status, 'woi')
  return (
    <div className={className} id="update-status-cell">
      <TagCustom
        className="strong w-full text-center"
        text={getStatusLabel(status)}
        type={getColorStatus(status)}
      />
    </div>
  );
};

export default PaymentStatusTag;

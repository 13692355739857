import React, { useMemo } from "react";
import { Image, Typography } from "antd";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { server } from "../../../constants";
import { parseParamsString } from "../../../utils/Functions";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import "./ProductEmpty.scss";

const { Title, Text } = Typography;

const ProductEmpty = ({ title, description, button, withCTA }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const getTokenParams = searchParams.get(server.TOKEN_KEY);
  const tokenParams = getTokenParams ? { token: getTokenParams } : {};
  const path = `/add-product${parseParamsString(tokenParams)}`;

  return (
    <div className='product-empty-wrapper'>
      <div className='product-empty'>
        <div className='product-empty__header'>
          <Image width={80} preview={false} src={`${window.location.origin}/images/product.svg`} />
        </div>
        <div className='product-empty__content'>
          <Title className='title' level={4}>
            {t(title ? title : "Your product list is still empty")}
          </Title>
          <Text className='mb-20'>
            {t(
              description
                ? description
                : "You don't have any products listed yet. Let's fill up your store with product listings from Baskit"
            ) + "."}
          </Text>
        </div>
        {withCTA ? (
          <div className='product-empty__footer'>
            <ButtonPrimary
              block={true}
              icon={<PlusOutlined />}
              text={t(button ? button.text : "Add Product")}
              handleClick={button ? button.handleClick : () => history.push(path)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductEmpty;

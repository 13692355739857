/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Area, CartesianGrid, ComposedChart, ResponsiveContainer, Scatter, XAxis, YAxis } from "recharts";
import { fetchChartData } from "../../actions/dashboard.action";
import { formatRupiah, scientificRupiahFormat } from "../../utils/Functions";

/**
 * @typedef {import("recharts/types/component/DefaultTooltipContent").Props} TooltipProps
 * @typedef {{
 *  time: string,
 *  transaction: number,
 *  income: number,
 * }} ChartDataType
 * @typedef {import("./periodselector").DashboardState} DashboardState
 *
 * @type {ChartDataType[]}
 */

const data = [
  {
    time: '06:00',
    transaction: 100,
    income: 1700000,
  },
  {
    time: '07:00',
    transaction: 200,
    income: 1000000,
  },
  {
    time: '08:00',
    transaction: 130,
    income: 1300000,
  },
  {
    time: '09:00',
    transaction: 0,
    income: 2000000,
  },
];

const MainchartContext = createContext({
  width: 0,
  height: 0,
  showTooltip: false,
  /** @type {(show:boolean) => void} */
  setShowTooltip: () => undefined,
  /** @type {DataPayloadProps} */
  tooltipProps: undefined,
  /** @type {(props:DataPayloadProps) => void} */
  setTooltipProps: () => undefined,
});

const dataOnline = [...data].sort(() => .5 - Math.random());
const dataOffline = [...data].sort(() => .5 - Math.random());

const color = {
  all: {
    primary: '#2492F8',
    secondary: '#146EC2',
  },
  online: {
    primary: "#e89b4c",
    secondary: "#b16c24",
  },
  offline: {
    primary: "#0a8080",
    secondary: "#005057",
  },
};

/**
 * Return value if it's between the boundary,
 * else return the boundary instead
 *
 * @param   {number}  x     Lower Boundary
 * @param   {number}  y     Upper Boudnary
 * @param   {number}  z     Tested Value
 *
 * @return  {number}  Returned value
 */
const getWithBoundary = (x, y, z) => Math.max(x, Math.min(y, z));

/**
 * @typedef {{
 *  time: string,
 *  transaction: {
 *    all: number,
 *    online: number,
 *    offline: number,
 *  },
 *  income: {
 *    all: number,
 *    online: number,
 *    offline: number,
 *  },
 *  edge: true,
 * }} DataPayload
 *
 * @type {DataPayload[]}
 */
const dataCombined = [];
for (let index = 0; index < data.length; index++) {
  const recordOnline = dataOnline[index];
  const recordOffline = dataOffline[index];
  dataCombined.push({
    time: recordOnline.time,
    transaction: {
      all: recordOnline.transaction + recordOffline.transaction,
      online: recordOnline.transaction,
      offline: recordOffline.transaction,
    },
    income: {
      all: recordOnline.income + recordOffline.income,
      online: recordOnline.income,
      offline: recordOffline.income,
    },
  });
}

/**
 * @typedef {{
 *  cx:number, cy: number,
 *  x: number, y:number,
 *  primary: string,
 *  secondary: string,
 *  recordType: 'transaction'|'income',
 *  transactionType: 'all'|'online'|'offline',
 *  payload: DataPayload,
 *  style: React.CSSProperties,
 * } & DataPayload} DataPayloadProps
 */

/** @type {() => JSX.Element} */
const CustomTooltip = () => {
  const { t } = useTranslation();
  const { width, height, showTooltip, tooltipProps } = useContext(MainchartContext);
  /** @type {DashboardState} */
  const { singleDay } = useSelector(( state ) => state.dashboardReducer);
  if (!showTooltip) return;
  const { cx, cy, primary, payload, transactionType } = tooltipProps;
  const { time, transaction, income } = payload;
  const tooltipPos = cx + 256 > width ? 'left' : 'right';
  const tooltipY = getWithBoundary(0, cy - 48, height - 96);
  const tooltipX = tooltipPos === 'left' ? cx - 256 : cx + 32;
  const points = [
    [cx + (tooltipPos === 'left' ? -16 : 16), cy],
    [cx + (tooltipPos === 'left' ? -32 : 32), getWithBoundary( 0, cy - 8, height - 16)],
    [cx + (tooltipPos === 'left' ? -32 : 32), getWithBoundary(16, cy + 8, height)],
  ].map(val => val.join(',')).join(' ');
  return (
    <>
      <foreignObject x={tooltipX} y={tooltipY}
        fill={primary}
        width={224} height={96}
        radius={4}
      >
        <div id="data-tooltip-container">
          <div id="transaction-type-label">
            {(transactionType === 'all') ? t('all transaction') : t(transactionType)}
          </div>
          <div className="flex-row-between">
            <span>{(singleDay) ? t('hour') : t('date')}</span>
            <span>{time}</span>
          </div>
          <div className="flex-row-between">
            <span>{t('transaction amount')}</span>
            <span>{transaction[transactionType]}</span>
          </div>
          <div className="flex-row-between">
            <span>{t('income')}</span>
            <span>{formatRupiah(income[transactionType])}</span>
          </div>
        </div>
      </foreignObject>
      <polygon
        points={points} fill="black" stroke="black"
      />
    </>
  );
};

/** @type {(props:DataPayloadProps) => JSX.Element} */
const CustomShape = (props) => {
  const { setTooltipProps, setShowTooltip } = useContext(MainchartContext);
  /** @type {DashboardState} */
  const { singleDay } = useSelector(( state ) => state.dashboardReducer);
  const { cx, cy, primary, secondary, style, edge, transactionType } = props;
  if (!edge && transactionType === 'online' && singleDay) return;
  return (
    <g style={style}>
      <circle id="data-dot" cx={cx} cy={cy} r={4} fill={primary} />
      <g id="data-hover-dot"
        onMouseEnter={() => [
          setShowTooltip(true),
          setTooltipProps(props),
        ]}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <circle cx={cx} cy={cy} r={8} fill={primary} />
        <circle cx={cx} cy={cy} r={6} fill={secondary} />
      </g>
    </g>
  );
};

const DashboardMainchart = () => {
  const dispatch = useDispatch();
  const [ renderChart, setRenderChart ] = useState(false);
  const [ width, setWidth ] = useState(0);
  const [ height, setHeight ] = useState(0);
  const [ showTooltip, setShowTooltip ] = useState(false);
  /** @type {ReturnType<typeof useState<DataPayloadProps>} */
  const [ tooltipProps, setTooltipProps ] = useState({
    primary: '#ffffff', secondary: '#000000',
    cx: 0, cy: 0,
  });
  /** @type {import("react").MutableRefObject<HTMLElement>} */
  const ref = useRef();
  /** @type {DashboardState} */
  const {
    period, recordType, chartData, singleDay,
    selectAll, selectOffline, selectOnline,
  } = useSelector(( state ) => state.dashboardReducer);

  useEffect(() => setRenderChart(true));
  useEffect(() => {
    if (ref.current) {
      const { offsetWidth, offsetHeight } = ref.current.current;
      if (!isNaN(offsetWidth)) setWidth(Number(offsetWidth));
      if (!isNaN(offsetHeight)) setHeight(Number(offsetHeight));
    }
  }, [
    renderChart, chartData,
    ref?.current?.current?.offsetWidth,
    ref?.current?.current?.offsetHeight,
  ]);

  useEffect(() => {
    // dispatch(fetchChartData(period, singleDay));
  }, [ period ]);

  return (
    <MainchartContext.Provider
      value={{
        width, height,
        tooltipProps, setTooltipProps,
        showTooltip, setShowTooltip,
      }}
    >
      <div id="mainchart-container">
        {/* { renderChart && chartData.length > 0 && */}
        { true &&
        <ResponsiveContainer ref={ref}>
          <ComposedChart data={chartData} margin={{ right: 32, top: 16 }}>
            <defs>
              <linearGradient id="gradientAll" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#2492f8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#2492f8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="gradientOnline" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#e89b4c" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#e89b4c" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="gradientOffline" gradientTransform="rotate(90)">
                <stop offset="5%" stopColor="#0a8080" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0a8080" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid />
            <XAxis dataKey="time" angle={singleDay ? 0 : -45} textAnchor={singleDay ? "middle" : "end"}
              height={48}
            />
            <YAxis tickCount={6} interval={0}
              width={48}
              tickFormatter={(recordType === 'income') ?
                (value) => scientificRupiahFormat(value) :
                undefined
              }
            />
            <Area dataKey={`${recordType}.all`} type="monotone"
              fill="url(#gradientAll)" stroke="#2492F8"
              style={{ display: selectAll ? 'initial' : 'none' }}
            />
            <Area dataKey={`${recordType}.online`} type="monotone"
              fill="url(#gradientOnline)" stroke="#e89b4c"
              style={{ display: selectOnline ? 'initial' : 'none' }}
            />
            <Area dataKey={`${recordType}.offline`} type="monotone"
              fill="url(#gradientOffline)" stroke="#0a8080"
              style={{ display: selectOffline ? 'initial' : 'none' }}
            />
            <Scatter dataKey={`${recordType}.all`} fill="#2492F8"
              shape={<CustomShape {...color.all} transactionType="all" />}
              style={{ display: selectAll ? 'initial' : 'none' }}
            />
            <Scatter dataKey={`${recordType}.online`} fill="#e89b4c"
              shape={<CustomShape {...color.online} transactionType="online" />}
              style={{ display: selectOnline ? 'initial' : 'none' }}
            />
            <Scatter dataKey={`${recordType}.offline`} fill="#0A8080"
              shape={<CustomShape {...color.offline} transactionType="offline" />}
              style={{ display: selectOffline ? 'initial' : 'none' }}
            />
            <g id="data-tooltip"
              style={{
                display: showTooltip ? 'initial' : 'none',
              }}
            >
              <CustomTooltip />
            </g>
          </ComposedChart>
        </ResponsiveContainer>
        }
      </div>
    </MainchartContext.Provider>
  );
};

export default DashboardMainchart;

import React from "react";
import Link from "antd/es/typography/Link";
import "./CustomLink.scss";

const CustomLink = ({ className, text, path, onClick }) => {
  return (
    <Link href={path} onClick={onClick} className={`custom-link ${className}`}>
      {text}
    </Link>
  );
};

export default CustomLink;

import {
  SHOP_UPDATE_ORDER,
  SHOP_UPDATE_PAYMENT,
  SHOP_FETCHING,
  SHOP_FAILED,
  SHOP_SUCCESS,
  SHOP_CLEAR,
  SHOP_LIST_SUCCESS,
  SHOP_PAYMENT_TYPE_FAILED,
  SHOP_PAYMENT_TYPE_SUCCESS,
  SHOP_PAYMENT_TYPE_FETCHING,
} from "../constants";

const initialState = {
  mOrderLines: [],
  mTotalPrice: 0,
  mTaxAmt: 0,
  mIsPaymentMade: false,
  mGiven: 0,
  mSubtotal: 0,
  isFetching: false,
  isError: false,
  result: null,
  data: null,
  paymentType: [],
  paymentTypeIsFetching: false,
  paymentTypeIsError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOP_UPDATE_ORDER:
      return {
        ...state,
        mOrderLines: payload.orderLines,
        mTotalPrice: payload.totalPrice,
        mTaxAmt: payload.taxAmt,
        mSubtotalPrice: payload.subtotalPrice,
      };
    case SHOP_UPDATE_PAYMENT:
      return {
        ...state,
        mIsPaymentMade: payload.isPaymentMade,
        mGiven: payload.given,
      };
    case SHOP_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case SHOP_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case SHOP_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case SHOP_LIST_SUCCESS:
      return { ...state, isFetching: false, isError: false, data: payload };
    case SHOP_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case SHOP_PAYMENT_TYPE_FAILED:
      return { ...state, paymentTypeIsError: true };
    case SHOP_PAYMENT_TYPE_FETCHING:
      return { ...state, paymentTypeIsFetching: true };
    case SHOP_PAYMENT_TYPE_SUCCESS:
      return { ...state, paymentType: payload };
    default:
      return state;
  }
};

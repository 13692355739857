import swal from "sweetalert";
import { httpClient } from "../utils/HttpClient";
import {
  COMPANY_FETCHING,
  COMPANY_SUCCESS,
  COMPANY_FAILED,
  COMPANY_CLEAR,
  COMPANY_UPDATE,
  COMPANY_SELLER_FETCHING,
  COMPANY_SELLER_FAILED,
  COMPANY_SELLER_SUCCESS,
  COMPANY_SELLER_DETAILS_FETCHING,
  COMPANY_SELLER_DETAILS_FAILED,
  COMPANY_SELLER_DETAILS_SUCCESS,
  COMPANY_RELATED_SALES_FETCHING,
  COMPANY_RELATED_SALES_FAILED,
  COMPANY_RELATED_SALES_SUCCESS,
  COMPANY_APPROVAL_FETCHING,
  COMPANY_APPROVAL_FAILED,
  COMPANY_BUYERS_FETCHING,
  COMPANY_BUYERS_FAILED,
  COMPANY_BUYERS_SUCCESS,
  COMPANY_BUYER_FETCHING,
  COMPANY_BUYER_FAILED,
  COMPANY_BUYER_SUCCESS,
  COMPANY_TYPES_FETCHING,
  COMPANY_TYPES_FAILED,
  COMPANY_TYPES_SUCCESS,
} from "../constants";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { parseParamsString } from "../utils/Functions";
import { SuccessMessage } from "../utils/Message";

export const setCompanyStateToFetching = () => ({
  type: COMPANY_FETCHING,
});

export const setCompanyStateToFailed = () => ({
  type: COMPANY_FAILED,
});

export const setCompanyStateToClear = () => ({
  type: COMPANY_CLEAR,
});

export const setCompanyStateToSuccess = (payload) => ({
  type: COMPANY_SUCCESS,
  payload,
});

export const setCompanyStateToUpdate = (payload) => ({
  type: COMPANY_UPDATE,
  payload,
});

export const setCompanySellerStateToFetching = () => ({
  type: COMPANY_SELLER_FETCHING,
});

export const setCompanySellerStateToFailed = () => ({
  type: COMPANY_SELLER_FAILED,
});

export const setCompanySellerStateToSuccess = (payload) => ({
  type: COMPANY_SELLER_SUCCESS,
  payload,
});

export const setCompanySellerDetailsStateToFetching = () => ({
  type: COMPANY_SELLER_DETAILS_FETCHING,
});

export const setCompanySellerDetailsStateToFailed = () => ({
  type: COMPANY_SELLER_DETAILS_FAILED,
});

export const setCompanySellerDetailsStateToSuccess = (payload) => ({
  type: COMPANY_SELLER_DETAILS_SUCCESS,
  payload,
});

export const setCompanyRelatedSalesStateToFetching = () => ({
  type: COMPANY_RELATED_SALES_FETCHING,
});

export const setCompanyRelatedSalesStateToFailed = () => ({
  type: COMPANY_RELATED_SALES_FAILED,
});

export const setCompanyRelatedSalesStateToSuccess = (payload) => ({
  type: COMPANY_RELATED_SALES_SUCCESS,
  payload,
});

export const setCompanyApprovalStateToFetching = () => ({
  type: COMPANY_APPROVAL_FETCHING,
});

export const setCompanyApprovalStateToFailed = () => ({
  type: COMPANY_APPROVAL_FAILED,
});

export const setCompanyBuyersStateToFetching = () => ({
  type: COMPANY_BUYERS_FETCHING,
});

export const setCompanyBuyersStateToFailed = () => ({
  type: COMPANY_BUYERS_FAILED,
});

export const setCompanyBuyersStateToSuccess = (payload) => ({
  type: COMPANY_BUYERS_SUCCESS,
  payload,
});

export const setCompanyBuyerStateToFetching = () => ({
  type: COMPANY_BUYER_FETCHING,
});

export const setCompanyBuyerStateToFailed = () => ({
  type: COMPANY_BUYER_FAILED,
});

export const setCompanyBuyerStateToSuccess = (payload) => ({
  type: COMPANY_BUYER_SUCCESS,
  payload,
});

export const setCompanyTypesStateToFetching = () => ({
  type: COMPANY_TYPES_FETCHING,
});

export const setCompanyTypesStateToFailed = () => ({
  type: COMPANY_TYPES_FAILED,
});

export const setCompanyTypesStateToSuccess = (payload) => ({
  type: COMPANY_TYPES_SUCCESS,
  payload,
});

export const getCategories = async () => {
  const response = await httpClient.get(process.env.REACT_APP_API_URL + "company");
  if (response.data.result === "success") {
    return response.data.data;
  } else if (response.data.result === "error") {
    swal("Error!", response.data.message, "error");
  }
};

export const Index = () => {
  return async (dispatch) => {
    dispatch(setCompanyStateToFetching());
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "company");
    if (response.data.result === "success") {
      dispatch(setCompanyStateToSuccess(response.data.data));
    } else if (response.data.result === "error") {
      dispatch(setCompanyStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

export const edit = (values, history) => {
  return async (dispatch) => {
    dispatch(setCompanyStateToFetching());
    const response = await httpClient.put(process.env.REACT_APP_API_URL + "company/edit", values);
    if (response.data.result === "success") {
      swal("Success!", response.data.message, "success").then((value) => {
        dispatch(setCompanyStateToClear());
        // dispatch(Index());
        // dispatch(getCompanyByUser());
        // history.push("/my-account/company");
        window.location.href = "my-account/company";
      });
    } else if (response.data.result === "error") {
      dispatch(setCompanyStateToFailed());
      swal("Error!", response.data.message, "error");
    }
  };
};

// export const getCompanyByUser = () => {
//   return async (dispatch) => {
//     dispatch(setCompanyStateToFetching());
//     const response = await apiGetCompanyByUser();
//     if (response.data.result === "success") {
//       if (response.data.data) localStorage.setItem("company_id", response.data.data._id);
//       else localStorage.removeItem("company_id");
//       dispatch(setCompanyStateToUpdate(response.data.data));
//     } else if (response.data.result === "error") {
//       dispatch(setCompanyStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const getCompanyDetails = () => {
  return async (dispatch) => {
    dispatch(setCompanyStateToFetching());
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "company/user");
      dispatch(setCompanyStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCompanyStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const apiGetCompanyByUser = async () => {
  return await httpClient.get(process.env.REACT_APP_API_URL + "company/user");
};

// export const update = (values, history) => {
//   console.log('data update: ', values)
//   return async (dispatch) => {
//     dispatch(setCompanyStateToFetching());
//     const response = await httpClient.put(process.env.REACT_APP_API_URL + "company/edit2", values);
//     console.log('response udpate: ', response);
//     if (response.data.result === "success") {
//       swal("Success!", response.data.message, "success").then(() => {
//         dispatch(setCompanyStateToClear());
//         history.goBack();
//       });
//     } else if (response.data.result === "error") {
//       dispatch(setCompanyStateToFailed());
//       swal("Error!", response.data.message, "error");
//     }
//   };
// };

export const update = (values, history) => {
  return async (dispatch) => {
    dispatch(setCompanyStateToFetching());
    try {
      const response = await httpClient.put(
        process.env.REACT_APP_API_URL + "company/edit2",
        values,
      );
      dispatch(setCompanyStateToClear());
      SuccessNotification(response);
      history.goBack();
    } catch (error) {
      dispatch(setCompanyStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const seller = (params = null) => {
  return async (dispatch) => {
    dispatch(setCompanySellerStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/seller${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setCompanySellerStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCompanySellerStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const sellerDetails = (id) => {
  return async (dispatch) => {
    dispatch(setCompanySellerDetailsStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/seller/${id}`,
      );
      dispatch(setCompanySellerDetailsStateToSuccess(response.data.data));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCompanySellerDetailsStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const relatedSales = (id, params) => {
  return async (dispatch) => {
    dispatch(setCompanyRelatedSalesStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/related-sales/${id}${paramsString}`,
      );
      const { data, totalData, totalPage } = response.data;
      dispatch(setCompanyRelatedSalesStateToSuccess({ data, meta: { totalData, totalPage } }));
      SuccessNotification(response);
    } catch (error) {
      dispatch(setCompanyRelatedSalesStateToFailed());
      ErrorNotification(error);
    }
  };
};

export const approval = (id, values) => {
  return async (dispatch) => {
    dispatch(setCompanyApprovalStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/approval/${id}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCompanyApprovalStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const buyers = (params = null) => {
  return async (dispatch) => {
    dispatch(setCompanyBuyersStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/buyer${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setCompanyBuyersStateToSuccess({ data, totalData, totalPage }));
      return true;
    } catch (error) {
      dispatch(setCompanyBuyersStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const buyer = (id) => {
  return async (dispatch) => {
    dispatch(setCompanyBuyerStateToFetching());
    try {
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/buyer/${id}`,
      );
      dispatch(setCompanyBuyerStateToSuccess(response?.data?.data));
      return true;
    } catch (error) {
      dispatch(setCompanyBuyerStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const updateBuyer = (id, values) => {
  return async (dispatch) => {
    dispatch(setCompanyBuyerStateToFetching());
    try {
      const response = await httpClient.patch(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/buyer/${id}`,
        values,
      );
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setCompanyBuyerStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

export const types = (params = null) => {
  return async (dispatch) => {
    dispatch(setCompanyTypesStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.get(
        process.env.REACT_APP_API_URL_NEW_V2 + `company/type${paramsString}`,
      );
      const { data, totalData, totalPage } = response?.data;
      dispatch(setCompanyTypesStateToSuccess({ data, totalData, totalPage }));
      return true;
    } catch (error) {
      dispatch(setCompanyTypesStateToFailed());
      ErrorNotification(error);
      return false;
    }
  };
};

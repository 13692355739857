import React from "react";
import { Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import i18next from "../../../languages/i18n";
import "./LoginContent.scss";

const LoginContent = ({ children }) => {
  const bahasaOptions = [
    { value: "en", label: "English" },
    { value: "bh", label: "Bahasa" },
  ];

  const handleChangeLanguage = (value) => {
    i18next.changeLanguage(value);
  };

  return (
    <section id='login-content'>
      <div className='header-section'>
        <GlobalOutlined />
        <Select
          defaultValue='en'
          style={{ width: 110 }}
          bordered={false}
          options={bahasaOptions}
          onChange={handleChangeLanguage}
        />
      </div>

      <div className='ellipse-icon-top'></div>
      <div className='ellipse-icon-bottom'>
        <img alt='ellipse' src={`${window.location.origin}/images/login/ellipse-bottom.svg`} />
      </div>

      <div className='content-section'>{children}</div>
    </section>
  );
};

export default LoginContent;

import React from "react";
import { Form, Input, AutoComplete } from "antd";
import { useTranslation } from "react-i18next";

const FormItemInput = ({
  label,
  name,
  size,
  placeholder,
  rules,
  disabled = false,
  handleChange = () => {},
  isUseAutoComplete = false,
  autocompleteOptions = [],
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item label={t(label)} name={name} rules={rules}>
      {isUseAutoComplete ? (
        <AutoComplete
          style={{ height: 40 }}
          className="autocomplete"
          placeholder={t(placeholder)}
          options={autocompleteOptions}
          size={size}
        ></AutoComplete>
      ) : (
        <Input
          placeholder={t(placeholder)}
          size={size}
          disabled={disabled}
          onChange={handleChange}
        />
      )}
    </Form.Item>
  );
};

export default FormItemInput;

import {
  UOM_FETCHING,
  UOM_SUCCESS,
  UOM_FAILED,
  UOM_CLEAR,
  UOM_UNIT_FETCHING,
  UOM_UNIT_SUCCESS,
  UOM_UNIT_FAILED,
  UOM_PACK_TYPE_FETCHING,
  UOM_PACK_TYPE_SUCCESS,
  UOM_PACK_TYPE_FAILED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  isUomUnitFetching: false,
  isUomUnitError: false,
  uomUnit: null,
  isUomPackTypeFetching: false,
  isUomPackTypeError: false,
  uomPackType: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UOM_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case UOM_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case UOM_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case UOM_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case UOM_UNIT_FETCHING:
      return { ...state, isUomUnitFetching: true, isUomUnitError: false, uomUnit: null };
    case UOM_UNIT_FAILED:
      return { ...state, isUomUnitFetching: false, isUomUnitError: true, uomUnit: null };
    case UOM_UNIT_SUCCESS:
      return { ...state, isUomUnitFetching: false, isUomUnitError: false, uomUnit: payload };
    case UOM_PACK_TYPE_FETCHING:
      return {
        ...state,
        isUomPackTypeFetching: true,
        isUomPackTypeError: false,
        uomPackType: null,
      };
    case UOM_PACK_TYPE_FAILED:
      return {
        ...state,
        isUomPackTypeFetching: false,
        isUomPackTypeError: true,
        uomPackType: null,
      };
    case UOM_PACK_TYPE_SUCCESS:
      return {
        ...state,
        isUomPackTypeFetching: false,
        isUomPackTypeError: false,
        uomPackType: payload,
      };
    default:
      return state;
  }
};

import { InboxOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  List,
  Modal,
  Row,
  Spin,
  Typography,
  Upload,
  Space,
  Tag,
  Table,
  Divider,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { handleValidationDocument } from "../../../utils/Functions";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import { columns } from "./helpers";
import "./UploadCSVModal.scss";

const { Title, Text } = Typography;

const UploadCSVModal = ({
  modalOpen,
  setModalOpen,
  isBulkUploadLoading,
  isShowBulkUploadInfo,
  setIsShowBulkUploadInfo,
  data,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState("600px");
  const [file, setFile] = useState({});
  const [form] = Form.useForm();

  const handleAfterClose = () => {
    setIsShowBulkUploadInfo(false);
    setWidth("600px");
    setFile({});
    form.resetFields();
  };

  useEffect(() => {
    if (!isBulkUploadLoading && isShowBulkUploadInfo) {
      setWidth("80%");
    }
  }, [isBulkUploadLoading, isShowBulkUploadInfo]);

  return (
    <Modal
      className="upload-csv-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      afterClose={handleAfterClose}
      width={width}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose
      // closable={isBulkUploadProgress ? false : true}
    >
      <Row gutter={12}>
        {isShowBulkUploadInfo ? (
          <>
            <Col span={12}>
              <Title level={4}>Doc: {data?.name}</Title>
            </Col>
            <Col span={11} className="text-right">
              <Space>
                <Text strong>{t("updateSKU", { amount: data?.data?.length })}</Text>
                {data?.success && (
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    {data?.success} {t("success")}
                  </Tag>
                )}

                {data?.errors && (
                  <Tag icon={<ExclamationCircleOutlined />} color="error">
                    {data?.errors} {t("failed")}
                  </Tag>
                )}
              </Space>
            </Col>
          </>
        ) : (
          <Col span={24} className="text-center">
            <Title level={4}>{t("Form Bulk Update")}</Title>
          </Col>
        )}
        <Col span={24} className="mb-20">
          {isBulkUploadLoading ? (
            <div className="full-width flex-center" style={{ height: "40vh" }}>
              <Spin tip="Loading" size="large"></Spin>
            </div>
          ) : (
            <>
              {isShowBulkUploadInfo ? (
                <TableCSVInfo setModalOpen={setModalOpen} data={data} />
              ) : (
                <UploadCSV form={form} setFile={setFile} />
              )}
            </>
          )}
        </Col>
        {isShowBulkUploadInfo ? (
          <Col span={4} offset={20}>
            <ButtonDefault block={true} text="Close" handleClick={() => setModalOpen(false)} />
          </Col>
        ) : (
          <>
            <Col span={12}>
              <ButtonDefault text="Cancel" block={true} handleClick={() => setModalOpen(false)} />
            </Col>
            <Col span={12}>
              <ButtonPrimary
                className={isEmpty(file) ? "button-disabled" : ""}
                text="Upload CSV"
                disabled={isEmpty(file) ? true : false}
                isBulkUploadLoading={isBulkUploadLoading}
                block={true}
                handleClick={() => handleSubmit(file)}
              />
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
};

const UploadCSV = ({ form, setFile }) => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical" form={form} requiredMark="optional">
      <Form.Item
        name="upload-csv"
        className="mt-20 mb-40"
        rules={[{ required: true, message: t("Please select your CSV file!") }]}
      >
        <Dragger
          name="bulk-update"
          listType="picture"
          maxCount={1}
          beforeUpload={(file) => {
            const validate = handleValidationDocument(file, 2, "csv");
            if (validate) {
              setFile(file);
              return false;
            } else {
              return Upload.LIST_IGNORE;
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for upload bulk SKUs (CSV, Max. 2 MB)</p>
        </Dragger>
      </Form.Item>
    </Form>
  );
};

const TableCSVInfo = ({ data }) => {
  const { t } = useTranslation();

  const dataSource =
    !isEmpty(data) &&
    data?.data.map((data, index) => ({
      key: index,
      skuId: data?.sku,
      stock: data?.stock,
      basePrice: data?.price,
      firstTierMin: data?.firstTierMin,
      firstTierMax: data?.firstTierMax,
      firstTierPercentage: data?.firstTierPercentage,
      secondTierMin: data?.secondTierMin,
      secondTierMax: data?.secondTierMax,
      secondTierPercentage: data?.secondTierPercentage,
      thirdTierMin: data?.thirdTierMin,
      thirdTierMax: data?.thirdTierMax,
      thirdTierPercentage: data?.thirdTierPercentage,
      fourthTierMin: data?.fourthTierMin,
      fourthTierMax: data?.fourthTierMax,
      fourthTierPercentage: data?.fourthTierPercentage,
      errors: data?.errors,
    }));

  const tableProps = {
    dataSource,
    columns,
    pagination: false,
    rowClassName: "no-hover",
    size: "small",
    scroll: { x: 1500, y: "60vh" },
  };

  return <Table {...tableProps} />;
};

export default UploadCSVModal;

import {
  LOGIN_FETCHING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_AUTH_FETCHING,
  LOGIN_AUTH_FAILED,
  LOGIN_SEND_OTP_FETCHING,
  LOGIN_SEND_OTP_FAILED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  isLoginAuthFetching: false,
  isLoginAuthError: false,
  loginAuth: null,
  isLoginSendOtpFetching: false,
  isLoginSendOtpError: false,
  loginSendOtp: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case LOGIN_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case LOGIN_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case LOGIN_AUTH_FETCHING:
      return { ...state, isLoginAuthFetching: true, isLoginAuthError: false, loginAuth: null };
    case LOGIN_AUTH_FAILED:
      return { ...state, isLoginAuthFetching: false, isLoginAuthError: true, loginAuth: null };
    case LOGIN_SEND_OTP_FETCHING:
      return {
        ...state,
        isLoginSendOtpFetching: true,
        isLoginSendOtpError: false,
        loginSendOtp: null,
      };
    case LOGIN_SEND_OTP_FAILED:
      return {
        ...state,
        isLoginSendOtpFetching: false,
        isLoginSendOtpError: true,
        loginSendOtp: null,
      };
    default:
      return state;
  }
};

import React, { useEffect, useMemo, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Layout, Row, Table, Divider, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { filterParams } from "../../utils/Functions";
import ButtonDefault from "../../components/atoms/ButtonDefault/ButtonDefault";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import AssignCompany from "../../components/molecules/AssignCompany/AssignCompany";
import MainContent from "../../components/organisms/Content/Content";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import * as salesActions from "../../actions/sales.action";
import * as salesAssignedActions from "../../actions/salesassigned.action";
import * as salesDetailActions from "../../actions/salesdetail.action";

const { Text } = Typography;

const SalesStore = () => {
  const headerProps = {
    buttonBack: { text: "Salesman Detail", status: true },
    description: "Manage your salesman's store",
  };

  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState();
  const [salesDetail, setSalesDetail] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);

  const detail = useSelector((state) => state.salesDetailReducer);
  const salesassigned = useSelector((state) => state.salesAssignedReducer);

  const params = useMemo(() => {
    const paramsConstruct = {
      $limit: 0,
      search: searchValue,
    };
    return filterParams(paramsConstruct);
  }, [searchValue]);

  useEffect(() => {
    dispatch(salesAssignedActions.index(params, id));
    dispatch(salesDetailActions.index(id));
  }, [params, id]);

  useEffect(() => {
    if (salesassigned?.result != null) {
      setLoading(false);
    }
    setCompanyList(salesassigned?.result?.data);
    setTotalData(salesassigned?.result?.totalData);

    setSalesDetail(detail?.result);
  }, [salesassigned, detail]);

  const DeleteComponent = (roleId) => {
    return (
      <Button
        icon={<DeleteOutlined />}
        onClick={() => dispatch(salesActions.deleteAssignment(roleId, params, id))}
      />
    );
  };

  const dataSource = companyList?.map((data, index) => ({
    key: index,
    name: data?.companyName,
    delete: DeleteComponent(data?.roleId),
  }));

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Delete"),
      dataIndex: "delete",
      key: "delete",
      align: "right",
    },
  ];

  const mainBreadcrumbItems = [
    {
      link: "/sales",
      title: "Sales",
    },
    {
      title: "Sales Store",
    },
  ];

  const companySearchFilterProps = {
    placeholder: "Search company",
    handleChange: (value) => setSearchValue(value),
  };

  const tableProps = {
    loading,
    columns,
    dataSource,
    showHeader: false,
    pagination: false,
    scroll: { y: "60vh" },
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="my-account-sales-store-wrapper">
        <div>
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div id="my-account-sales-store-content">
          <Row gutter={12}>
            <Col span={12}>
              <div className="right-content">
                <Row>
                  <ColItem
                    label={t("Member Name")}
                    value={`${salesDetail?.profile?.firstName} ${salesDetail?.profile?.lastName}`}
                  />
                  <ColItem label={t("Total Assignment")} value={totalData ?? 0} />
                </Row>
                <Divider style={{ margin: "10px 0px" }} />
                <ProductSearchFilter {...companySearchFilterProps} />
                <Table {...tableProps} />
              </div>
            </Col>

            <Col span={12}>
              <div className="left-content">
                <AssignCompany id={id} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="float-button">
          <ButtonDefault text="back" block={true} handleClick={() => history.goBack()} />
        </div>
      </div>
    </MainContent>
  );
};

const ColItem = ({ label, value }) => (
  <>
    <Col span={8}>
      <Text>{label}</Text>
    </Col>
    <Col span={16}>
      <Text strong>
        : <span className="ml-10">{value}</span>
      </Text>
    </Col>
  </>
);

export default SalesStore;

import {
  PRICE_TIER_TEMPLATE_FETCHING,
  PRICE_TIER_TEMPLATE_FAILED,
  PRICE_TIER_FETCHING,
  PRICE_TIER_FAILED,
  PRICE_TIER_SUCCESS,
  PRICE_TIER_UPDATE_BULK_FETCHING,
  PRICE_TIER_UPDATE_BULK_FAILED,
  PRICE_TIER_UPDATE_FETCHING,
  PRICE_TIER_UPDATE_FAILED,
} from "../constants";

const initialState = {
  isPriceTierTemplateFetching: false,
  isPriceTierTemplateError: false,
  isFetching: false,
  isError: false,
  result: null,
  data: null,
  isPriceTierUpdateBulkFetching: false,
  isPriceTierUpdateBulkError: false,
  isUpdateFetching: false,
  isUpdateError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PRICE_TIER_TEMPLATE_FETCHING:
      return { ...state, isPriceTierTemplateFetching: true, isPriceTierTemplateError: false };
    case PRICE_TIER_TEMPLATE_FAILED:
      return { ...state, isPriceTierTemplateFetching: false, isPriceTierTemplateError: true };
    case PRICE_TIER_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case PRICE_TIER_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case PRICE_TIER_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case PRICE_TIER_UPDATE_BULK_FETCHING:
      return { ...state, isPriceTierUpdateBulkFetching: true, isPriceTierUpdateBulkError: false };
    case PRICE_TIER_UPDATE_BULK_FAILED:
      return { ...state, isPriceTierUpdateBulkFetching: false, isPriceTierUpdateBulkError: true };
    case PRICE_TIER_UPDATE_FETCHING:
      return { ...state, isUpdateFetching: true, isUpdateError: false };
    case PRICE_TIER_UPDATE_FAILED:
      return { ...state, isUpdateFetching: false, isUpdateError: true };
    default:
      return state;
  }
};

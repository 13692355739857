import {
  MERCHANT_DETAIL_FAILED,
  MERCHANT_DETAIL_FETCHING,
  MERCHANT_DETAIL_SUCCESS,
  MERCHANT_GMV_FAILED,
  MERCHANT_GMV_FETCHING,
  MERCHANT_GMV_SUCCESS,
  MERCHANT_LIST_FAILED,
  MERCHANT_LIST_FETCHING,
  MERCHANT_LIST_SUCCESS,
  MERCHANT_PRODUCT_FAILED,
  MERCHANT_PRODUCT_FETCHING,
  MERCHANT_PRODUCT_MASTER_FAILED,
  MERCHANT_PRODUCT_MASTER_FETCHING,
  MERCHANT_PRODUCT_MASTER_SUCCESS,
  MERCHANT_PRODUCT_SUCCESS,
  MERCHANT_SALES_FAILED,
  MERCHANT_SALES_FETCHING,
  MERCHANT_SALES_SUCCESS,
  MERCHANT_UPLOAD_SKU_FAILED,
  MERCHANT_UPLOAD_SKU_FETCHING,
  MERCHANT_UPLOAD_SKU_SUCCESS,
} from "../constants";

const initialState = {
  isMerchantListFetching: false,
  isMerchantListError: false,
  merchantList: null,
  isMerchantDetailFetching: false,
  isMerchantDetailError: false,
  merchantDetail: null,
  isMerchantGmvFetching: false,
  isMerchantGmvError: false,
  merchantGmv: null,
  isMerchantSalesFetching: false,
  isMerchantSalesError: false,
  merchantSales: null,
  isMerchantProductFetching: false,
  isMerchantProductError: false,
  merchantProduct: null,
  isMerchantProductFetching: false,
  isMerchantProductError: false,
  merchantProduct: null,
  isMerchantUploadSkuFetching: false,
  isMerchantUploadSkuError: false,
  merchantUploadSku: null,
  merchantProductMaster: null,
  isMerchantProductMasterFetching: false,
  isMerchantProductMasterError: false,
};

export default (
  state = initialState,
  {
    type,
    merchantList,
    merchantDetail,
    merchantGmv,
    merchantSales,
    merchantProduct,
    merchantUploadSku,
    merchantProductMaster,
  },
) => {
  switch (type) {
    case MERCHANT_LIST_FETCHING:
      return {
        ...state,
        isMerchantListFetching: true,
        isMerchantListError: false,
        merchantList: null,
      };
    case MERCHANT_LIST_SUCCESS:
      return { ...state, isMerchantListFetching: false, isMerchantListError: false, merchantList };
    case MERCHANT_LIST_FAILED:
      return {
        ...state,
        isMerchantListFetching: false,
        isMerchantListError: true,
        merchantList: null,
      };
    case MERCHANT_DETAIL_FETCHING:
      return {
        ...state,
        isMerchantDetailFetching: true,
        isMerchantDetailError: false,
        merchantDetail: null,
      };
    case MERCHANT_DETAIL_SUCCESS:
      return {
        ...state,
        isMerchantDetailFetching: false,
        isMerchantDetailError: false,
        merchantDetail,
      };
    case MERCHANT_DETAIL_FAILED:
      return {
        ...state,
        isMerchantDetailFetching: false,
        isMerchantDetailError: true,
        merchantDetail: null,
      };
    case MERCHANT_GMV_FETCHING:
      return {
        ...state,
        isMerchantGmvFetching: true,
        isMerchantGmvError: false,
        merchantGmv: null,
      };
    case MERCHANT_GMV_SUCCESS:
      return { ...state, isMerchantGmvFetching: false, isMerchantGmvError: false, merchantGmv };
    case MERCHANT_GMV_FAILED:
      return {
        ...state,
        isMerchantGmvFetching: false,
        isMerchantGmvError: true,
        merchantGmv: null,
      };
    case MERCHANT_SALES_FETCHING:
      return {
        ...state,
        isMerchantSalesFetching: true,
        isMerchantSalesError: false,
        merchantSales: null,
      };
    case MERCHANT_SALES_SUCCESS:
      return {
        ...state,
        isMerchantSalesFetching: false,
        isMerchantSalesError: false,
        merchantSales,
      };
    case MERCHANT_SALES_FAILED:
      return {
        ...state,
        isMerchantSalesFetching: false,
        isMerchantSalesError: true,
        merchantSales: null,
      };
    case MERCHANT_PRODUCT_FETCHING:
      return {
        ...state,
        isMerchantProductFetching: true,
        isMerchantProductError: false,
        merchantProduct: null,
      };
    case MERCHANT_PRODUCT_SUCCESS:
      return {
        ...state,
        isMerchantProductFetching: false,
        isMerchantProductError: false,
        merchantProduct,
      };
    case MERCHANT_PRODUCT_FAILED:
      return {
        ...state,
        isMerchantProductFetching: false,
        isMerchantProductError: true,
        merchantProduct: null,
      };
    case MERCHANT_UPLOAD_SKU_FETCHING:
      return {
        ...state,
        isMerchantUploadSkuFetching: true,
        isMerchantUploadSkuError: false,
        merchantUploadSku: null,
      };
    case MERCHANT_UPLOAD_SKU_SUCCESS:
      return {
        ...state,
        isMerchantUploadSkuFetching: false,
        isMerchantUploadSkuError: false,
        merchantUploadSku,
      };
    case MERCHANT_UPLOAD_SKU_FAILED:
      return {
        ...state,
        isMerchantUploadSkuFetching: false,
        isMerchantUploadSkuError: true,
        merchantUploadSku: null,
      };
    case MERCHANT_PRODUCT_MASTER_FETCHING:
      return {
        ...state,
        isMerchantProductMasterFetching: true,
        isMerchantProductMasterError: false,
        merchantProductMaster: null,
      };
    case MERCHANT_PRODUCT_MASTER_SUCCESS:
      return {
        ...state,
        isMerchantProductMasterFetching: false,
        isMerchantProductMasterError: false,
        merchantProductMaster,
      };
    case MERCHANT_PRODUCT_MASTER_FAILED:
      return {
        ...state,
        isMerchantProductMasterFetching: false,
        isMerchantProductMasterError: true,
        merchantProductMaster: null,
      };
    default:
      return state;
  }
};

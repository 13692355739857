import React from "react";
import MainContent from "../../components/organisms/Content/Content";
import SkuMaster from "../../components/templates/SkuMaster/SkuMaster";

const AddProduct = () => {
  const headerProps = {
    title: "Product List",
    description: "Manage your product list",
  };

  return (
    <MainContent headerProps={headerProps}>
      <div className="mb-10">
        <SkuMaster />
      </div>
    </MainContent>
  );
};

export default AddProduct;

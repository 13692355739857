import React, { useEffect, useState } from "react";
import { Divider, Progress, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopFiveSKU } from "../../actions/dashboard.action";
import { isEmpty } from "lodash-es";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";

const { Title } = Typography;

const TopFiveSKU = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { period, topFiveSku } = useSelector((state) => state.dashboardReducer);

  useEffect(() => {
    // if (period) dispatch(fetchTopFiveSKU({ periode: period }));
    // else
      setData([
        { name: "", amount: 0, total: 0 },
        { name: "", amount: 0, total: 0 },
        { name: "", amount: 0, total: 0 },
        { name: "", amount: 0, total: 0 },
        { name: "", amount: 0, total: 0 },
      ]);
  }, [period]);

  useEffect(() => {
    if (!isEmpty(topFiveSku)) {
      setData(topFiveSku);
      setLoading(false);
    }
  }, [topFiveSku]);

  return (
    <div id="top-five-sku">
      <Title level={4} className="text-center">
        {t("Top 5 SKUs")}
      </Title>
      <Divider />
      {loading ? (
        <SpinLoaderContent />
      ) : (
        data.map(({ name, total, amount }, index) => (
          <div key={index} className={`progress-bar-container-${index}`}>
            <Progress percent={total} format={() => `${amount}`} status="active" />
            <span className="progress-text">{name}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default TopFiveSKU;

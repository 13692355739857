import React from "react";
import { Col, Row } from "antd";
import { UpdateStatusCell } from "../../components/organisms/UpdateStatusCell";
import OrderList from "../../components/templates/OrderList/OrderListOnline";

const Transaction = ({ companyId, columns }) => {
  const orderListOptions = {
    lazyLoad: true,
    baseLimit: 50,
    companyId,
    columns,
    columnComponents: {
      status: UpdateStatusCell,
    },
    link: {
      details: (id) => `/transaction-management/detail/${id}`,
    },
    orderFilter: {
      $order: "createdAt",
      $sort: "DESC",
      orderType: ["ONLINE", "SHOP"],
    },
    scroll: { y: 400 },
  };

  return (
    <Row>
      <Col span={24}>
        <OrderList {...orderListOptions} />
      </Col>
    </Row>
  );
};

export default Transaction;

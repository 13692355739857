import {
  AREA_INTERNAL_REGION_FETCHING,
  AREA_INTERNAL_REGION_SUCCESS,
  AREA_INTERNAL_REGION_FAILED,
  AREA_PROVINCES_FETCHING,
  AREA_PROVINCES_SUCCESS,
  AREA_PROVINCES_FAILED,
  AREA_CITIES_FETCHING,
  AREA_CITIES_SUCCESS,
  AREA_CITIES_FAILED,
  AREA_DISTRICTS_FETCHING,
  AREA_DISTRICTS_SUCCESS,
  AREA_DISTRICTS_FAILED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  options: null,
  data: null,
  isInternalRegionFetching: false,
  isInternalRegionError: false,
  internalRegion: null,
  isProvincesFetching: false,
  isProvincesError: false,
  provinces: null,
  isCitiesFetching: false,
  isCitiesError: false,
  cities: null,
  isDistrictsFetching: false,
  isDistrictsError: false,
  districts: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AREA_INTERNAL_REGION_FETCHING:
      return {
        ...state,
        isInternalRegionFetching: true,
        isInternalRegionError: false,
        internalRegion: null,
      };
    case AREA_INTERNAL_REGION_FAILED:
      return {
        ...state,
        isInternalRegionFetching: false,
        isInternalRegionError: true,
        internalRegion: null,
      };
    case AREA_INTERNAL_REGION_SUCCESS:
      return {
        ...state,
        isInternalRegionFetching: false,
        isInternalRegionError: false,
        internalRegion: payload,
      };
    case AREA_PROVINCES_FETCHING:
      return {
        ...state,
        isProvincesFetching: true,
        isProvincesError: false,
        provinces: null,
      };
    case AREA_PROVINCES_FAILED:
      return {
        ...state,
        isProvincesFetching: false,
        isProvincesError: true,
        provinces: null,
      };
    case AREA_PROVINCES_SUCCESS:
      return {
        ...state,
        isProvincesFetching: false,
        isProvincesError: false,
        provinces: payload,
      };
    case AREA_CITIES_FETCHING:
      return {
        ...state,
        isCitiesFetching: true,
        isCitiesError: false,
        cities: null,
      };
    case AREA_CITIES_FAILED:
      return {
        ...state,
        isCitiesFetching: false,
        isCitiesError: true,
        cities: null,
      };
    case AREA_CITIES_SUCCESS:
      return {
        ...state,
        isCitiesFetching: false,
        isCitiesError: false,
        cities: payload,
      };
    case AREA_DISTRICTS_FETCHING:
      return {
        ...state,
        isDistrictsFetching: true,
        isDistrictsError: false,
        districts: null,
      };
    case AREA_DISTRICTS_FAILED:
      return {
        ...state,
        isDistrictsFetching: false,
        isDistrictsError: true,
        districts: null,
      };
    case AREA_DISTRICTS_SUCCESS:
      return {
        ...state,
        isDistrictsFetching: false,
        isDistrictsError: false,
        districts: payload,
      };
    default:
      return state;
  }
};

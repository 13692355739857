import { ArrowLeftOutlined, CloudUploadOutlined, EnvironmentFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FormItemAddress from "../../components/atoms/FormItemAddress";
import LoginContent from "../../components/organisms/LoginContent/LoginContent";
import "./_style.scss";
import { httpClient } from "../../utils/HttpClient";
import DefaultLeafletContainer from "../../components/molecules/NominatimLeafletContainer";

const { Title, Text } = Typography;

const Register = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [companyImage, setCompanyImage] = useState({});
  const [address, setAddress] = useState({});
  const [location, setLocation] = useState({});

  const [form] = Form.useForm();
  const history = useHistory();

  const props = {
    name: 'filename',
    multiple: true,
    action: async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      const url = process.env.REACT_APP_API_URL_NEW_V2 + "/upload?folder=baskit-products";
      const response = await httpClient.post(url, formData);
      setCompanyImage(response.data.data);
      return response.data.data;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
          // setCompanyImage(e.target.result);
        };
        // setCompanyImage(info.file.originFileObj.name);
        reader.readAsDataURL(info.file.originFileObj);
      }

      if (info.file.status === "done") {
        // Handle response from API
        setCompanyImage(info.file.response);
      }

      if (info.file.status === "done") {
        // Handle response from API
        setCompanyImage(info.file.response);
      }
    },
  };

  useEffect(() => {
    const getState = localStorage.getItem("registerItem");
    form.setFieldValue("code_phone", "+62");

    if (getState) {
      const { companyName, email, address, avatar, lat, long } = JSON.parse(getState);
      form.setFieldValue("companyName", companyName);
      form.setFieldValue("email", email);
      form.setFieldValue("address", address);
      setAddress({ display_name: address });
      setLocation({ lat, long });
      form.setFieldValue("avatar", avatar || companyImage.url);
    } else {
      form.setFieldsValue();
    }
  }, [companyImage?.url]);

  const handleNextStep = async () => {
    try {
      const values = await form.validateFields();
      localStorage.setItem(
        "registerItem",
        JSON.stringify({
          ...values,
          avatar: companyImage.url,
          latitude: location.lat ? location.lat : -6.200000, // default to Jakarta
          longitude: location.lng ? location.lng : 106.816666, // default to Jakarta
        }),
      );

      history.push("/register/step-two");
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <LoginContent>
      <div id="register-wrapper" className="flex-center-justify">
        <div className="content">
          <Row gutter={24} className="register-card ml-20 mr-20">
            <Col span={24}>
              <div className="button-title-back" onClick={() => history.goBack()}>
                <Space direction="horizontal" style={{ marginLeft: "1rem" }}>
                  <ArrowLeftOutlined />
                  <Title level={3} style={{ margin: 0 }}>
                    {t("Register")}
                  </Title>
                </Space>
              </div>
              <div>
                <Divider className="register-divider" />
              </div>
            </Col>
            <Col span={20}>
              <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                {t("Informasi Toko")}
              </Title>
            </Col>
            <Col span={4}>
              <Title level={5} style={{ margin: 0, textAlign: "right" }}>
                1/2
              </Title>
            </Col>
            <Col span={12}>
              <div className="register-card-content">
                <Form form={form} onFinish={handleNextStep}>
                  <div className="mt-20 mb-10">
                    <Text>
                      {t("StoreName")}
                    </Text>
                  </div>
                  <Form.Item
                    name="companyName"
                    rules={[{ required: true, message: "Please input Nama Toko" }]}
                  >
                    <Input placeholder="Masukan nama toko" className="register-number" />
                  </Form.Item>

                  <div className="mt-20 mb-10">
                    <Text>
                      {t("StoreAddress")}
                    </Text>
                  </div>

                  <div style={{ display: 'flex', gap: '10px'}}>
                    <FormItemAddress
                      name="address"
                      size="large"
                      handleSelect={(place) => {
                        setAddress(place);
                        setLocation({ lat: Number(place.lat), lng: Number(place.lon) })
                      }}
                    />
                    <Button
                      key="submit"
                      type="primary"
                      onClick={showModal}
                      style={{ backgroundColor: "#0A8080", height: 39 }}
                      icon={<EnvironmentFilled />}
                    >
                      View on Maps
                    </Button>
                  </div>
                  <div className="mt-20 mb-10">
                    <Text>
                      {t("EmailAddress")}
                    </Text>
                  </div>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Please input your email" }]}
                  >
                    <Input placeholder="Masukan alamat email" className="register-input-number" />
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col span={12}>
              <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                {t("Foto Tokomu")}
              </Title>

              <Card style={{ margin: "5px", background: "#F4F4F4", border: "2px dotted #8A8A8A" }}>
                <div>
                  {image ? (
                    <Image src={image}></Image>
                  ) : (
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <Upload accept=".jpg, .jpeg, .png" {...props}>
                          <Button
                            type="primary"
                            icon={<CloudUploadOutlined />}
                            size="large"
                            style={{ backgroundColor: "#0A8080" }}
                          >
                            Unggah Foto
                          </Button>
                        </Upload>
                      </div>
                      <div style={{ textAlign: "center", color: "#8A8A8A" }}>Maksimal file 1MB</div>
                      <div style={{ textAlign: "center", color: "#8A8A8A" }}>
                        File tipe jpeg, jpg atau png
                      </div>
                    </div>
                  )}
                </div>
              </Card>

              <Row gutter={24}>
                <Col span={12}></Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => handleNextStep()}
                    style={{ marginTop: "2rem" }}
                    htmlType="submit"
                  >
                    Selanjutnya
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={900} footer={[]}>
          <Row style={{ padding: 20 }}>
            <DefaultLeafletContainer
              address={{ address: address.display_name }}
              setLocation={setLocation}
            />
          </Row>
        </Modal>
      </div>
    </LoginContent>
  );
};

export default Register;

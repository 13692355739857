import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./ButtonPrimary.scss";

/**
 * @typedef {{
 *  id?: string;
 *  text?: string;
 *  icon?: React.ReactNode;
 *  block?: boolean;
 *  handleClick?: React.MouseEventHandler<HTMLElement>;
 *  className?: string;
 *  disabled?: boolean;
 * }} ButtonPrimaryProps
 * @param {ButtonPrimaryProps} props
 */
const ButtonPrimary = ({
  id,
  text,
  block = false,
  disabled,
  className = "",
  htmlType = "button",
  danger = false,
  icon = undefined,
  loading = false,
  handleClick,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      id={id}
      className={"button-primary " + className}
      type='primary'
      disabled={disabled}
      htmlType={htmlType}
      block={block}
      danger={danger}
      onClick={handleClick}
      icon={icon}
      loading={loading}
    >
      {t(text)}
    </Button>
  );
};

export default ButtonPrimary;

import React from "react";
import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import "./InputNumberIDR.scss";

const InputNumberIDR = ({ placeholder, defaultValue = 0, disabled = false, handleChange = () => {} }) => {
  const { t } = useTranslation();

  const formatter = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    const number = parseFloat(value);
    if (isNaN(number)) {
      return "";
    }
    return number.toLocaleString("id-ID");
  };

  const parser = (value) => {
    return value.replace(/\D/g, "");
  };

  return (
    <InputNumber
      className='input-number-idr'
      placeholder={t(placeholder)}
      defaultValue={defaultValue}
      formatter={formatter}
      parser={parser}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

export default InputNumberIDR;

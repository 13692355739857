import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row, Table, Typography } from "antd";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";
import { digitFormat, formatRupiahNotRounded } from "../../../utils/Functions";
import ImageCustom from "../../atoms/Image";
import * as pricetierActions from "../../../actions/pricetier.action";
import "./PriceTieringModal.scss";

const { Title, Text } = Typography;

const PriceTieringModal = ({ modalOpen, setModalOpen, details }) => {
  const { id } = details;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  
  const pricetier = useSelector((state) => state.pricetierReducer);

  useEffect(() => {
    const queryParams = { 
      inventoryId: id, 
      $order: 'tierNo', 
      $sort: 'ASC' 
    };
    if(id && modalOpen) dispatch(pricetierActions.index(queryParams));
  }, [id, modalOpen]);
  
  useEffect(() => {
    if(pricetier?.result) {
      setData(pricetier?.result?.data);
    }
  }, [pricetier]);

  const dataSource =
    !isEmpty(data) &&
    data?.map((data, index) => ({
      key: index,
      no: `${data?.tierNo}`,
      minAmount: data?.min,
      maxAmount: data?.max,
      margin: data?.margin ? data?.margin.toFixed(2) : 0,
      sellingPrice: data?.sellingPrice,
    }));

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: t("minAmount"),
      dataIndex: "minAmount",
      key: "minAmount",
      render: (text) => digitFormat(text)
    },
    {
      title: t("maxAmount"),
      dataIndex: "maxAmount",
      key: "maxAmount",
      render: (text) => digitFormat(text)
    },
    {
      title: t("margin"),
      dataIndex: "margin",
      key: "margin",
      render: (value) => <Text>{value}%</Text>,
    },
    {
      title: t("sellingPrice"),
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      render: (text) => formatRupiahNotRounded(text)
    },
  ];

  const tableProps = {
    dataSource,
    columns,
    pagination: false,
    size: "small",
  };

  return (
    <Modal
      className="price-tiering-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={700}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose
    >
      <Row>
        <Col span={24}>
          <Title level={5}>{t("Price Tiering")}</Title>
        </Col>
        <Col span={7}>
          <ImageCustom className="sku-image" width={180} height={180} alt="sku inventory image" />
        </Col>
        <Col span={17}>
          <div className="description">
            <ListItem label="SKU ID" value={details?.sku ?? "-"} />
            <ListItem label="SKU Name" value={details?.fullName ?? "-"} />
          </div>
        </Col>
        <Col span={24} className="mt-20">
          <Table {...tableProps} />
        </Col>
      </Row>
    </Modal>
  );
};

const ListItem = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-10">
      <Col span={7}>
        <Text strong>{t(label)}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={16}>
        <Text>{value}</Text>
      </Col>
    </Row>
  );
};

export default PriceTieringModal;

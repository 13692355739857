import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Typography, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from "@ant-design/icons";
import { handleValidationDocument } from "../../../utils/Functions";
import { useDispatch, useSelector } from "react-redux";
import { useGetList } from "../../../helper/hooks";
import ImgCrop from "antd-img-crop";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import FormItemInput from "../../atoms/FormItemInput";
import FormItemSelect from "../../atoms/FormItemSelect";
import FormItemInputNumberGeneral from "../../atoms/FormItemInputNumberGeneral";
import FormItemInputSearchDropDown from "../../atoms/FormItemInputSearchDropdown";
import * as subcategoryActions from "../../../actions/subcategory.action";
import * as uomActions from "../../../actions/uom.action";
import * as productActions from "../../../actions/product.action";
import * as categoryActions from "../../../actions/category.action";
import "./AddProductMasterModal.scss";

const { Title } = Typography;

const AddProductMasterModal = ({
  modalOpen,
  setModalOpen,
  setProductSearch,
  isLoading,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [file, setFile] = useState({});
  const [fileList, setFileList] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [uomUnitOptions, setUomUnitOptions] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [isSearchProduct, setIsSearchProduct] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryParams, setCategoryParams] = useState({
    $limit: 10,
    type: "CATEGORY",
  });
  const brandName = Form.useWatch("brand_name", form);
  const principalName = Form.useWatch("principal_name", form);
  const { brand } = useGetList({ search: brandName, type: "brand" });
  const { principal } = useGetList({ search: principalName, type: "principal" });
  const [autocompleteOptions, setAutocompleteOptions] = useState({
    brandList: [],
    principalList: [],
  });

  const [subcategoryParams, setSubcategoryParams] = useState({
    $limit: 10,
  });

  const { dropdown: productMaster } = useSelector((state) => state.productReducer);
  const category = useSelector((state) => state.categoryReducer);
  const subcategory = useSelector((state) => state.subcategoryReducer);
  const uom = useSelector((state) => state.uomReducer);
  const dispatch = useDispatch();

  const transformOptions = (list = [], type) => {
    const transformedList = list.map((item) => {
      return {
        value: item.name,
      };
    });

    setAutocompleteOptions((prevState) => {
      return {
        ...prevState,
        [type]: transformedList,
      };
    });
  };

  useEffect(() => {
    dispatch(uomActions.Index());
  }, []);

  useEffect(() => {
    dispatch(categoryActions.options(categoryParams));
  }, [categoryParams]);

  useEffect(() => {
    dispatch(subcategoryActions.options(subcategoryParams));
  }, [subcategoryParams]);

  useEffect(() => {
    if (productMaster && isSearchProduct) {
      const { data } = productMaster;
      const result = data.map((product) => ({
        key: product.fullName,
        label: product.fullName,
      }));
      setSearchOptions(result);
    } else setSearchOptions([]);
  }, [productMaster, isSearchProduct]);

  useEffect(() => {
    const { options } = category;
    if (options) {
      const result = options.map(({ id, name }) => ({ value: id, label: name }));
      setCategoryOptions(result);
    }
  }, [category]);

  useEffect(() => {
    if (brand.length) {
      transformOptions(brand, "brandList");
    }
  }, [brand]);

  useEffect(() => {
    if (principal.length) {
      transformOptions(principal, "principalList");
    }
  }, [principal]);

  useEffect(() => {
    const { options } = subcategory;
    if (options) {
      const result = options.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSubcategoryOptions(result);
    }
  }, [subcategory]);

  useEffect(() => {
    if (uom?.result) {
      const result = uom?.result.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setUomUnitOptions(result);
    }
  }, [uom]);

  const handleSearchProduct = (value) => {
    let params = {
      $limit: 10,
    };

    if (value) {
      params.search = value;
      setIsSearchProduct(true);
    } else {
      delete params.search;
      setIsSearchProduct(true);
    }
    dispatch(productActions.productMasterDropdown(params));
  };

  const handleSearchCategory = (value) => {
    let _params = { ...categoryParams };
    if (value) _params.name = value;
    else delete _params.name;
    setCategoryParams(_params);
  };

  const handleSearchSubcategory = (value) => {
    let _params = {
      ...subcategoryParams,
      parentId: categoryId,
    };
    if (value) _params.name = value;
    else delete _params.name;
    setSubcategoryParams(_params);
  };

  const handleFinish = () => {
    const fields = { ...form.getFieldsValue(), image: file };
    handleSubmit(fields);
  };

  const handleResetImage = () => {
    setFile({});
    setFileList([]);
  };

  const handleResetAutocomplete = () => {
    setAutocompleteOptions({
      brandList: [],
      principalList: [],
    });
    setModalOpen(false);
  };

  const { brandList, principalList } = autocompleteOptions;

  return (
    <Modal
      className="add-product-master-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1000}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      afterClose={() => {
        form.resetFields();
        handleResetImage();
        handleResetAutocomplete();
      }}
      destroyOnClose
    >
      <Row>
        <Col span={24} className="text-center">
          <Title level={4}>{t("Add Product")}</Title>
        </Col>
        <Col span={24} className="mt-10">
          <div className="add-product-master-modal-content">
            <Form className="mb-20" layout="vertical" form={form} onFinish={handleFinish}>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItemInput
                    name="brand_name"
                    label="Brand Name"
                    placeholder="Input your brand name"
                    size="large"
                    rules={[{ required: true, message: t("Please input your brand name!") }]}
                    isUseAutoComplete
                    autocompleteOptions={brandList}
                  />

                  <FormItemInput
                    name="principal_name"
                    label="Principal Name"
                    placeholder="Input your principal name"
                    size="large"
                    rules={[{ required: true, message: t("Please input your principal name!") }]}
                    isUseAutoComplete
                    autocompleteOptions={principalList}
                  />

                  <FormItemInputSearchDropDown
                    name="product_name"
                    label="Product Name"
                    placeholder="Input your product name"
                    size="large"
                    rules={[{ required: true, message: t("Please input your product name!") }]}
                    setProductSearch={setProductSearch}
                    setModalOpen={setModalOpen}
                    options={searchOptions}
                    handleChange={handleSearchProduct}
                  />

                  <FormItemSelect
                    name="category"
                    label="Category"
                    placeholder="Select product category"
                    size="large"
                    rules={[{ required: true, message: t("Please input your category!") }]}
                    options={categoryOptions}
                    filterOption={false}
                    handleSearch={handleSearchCategory}
                    handleChange={(value) =>
                      setSubcategoryParams({ ...subcategoryParams, parentId: value })
                    }
                  />

                  <FormItemSelect
                    name="sub_category"
                    label="Sub Category"
                    placeholder="Select product subcategory"
                    size="large"
                    showSearch={true}
                    filterOption={false}
                    handleSearch={handleSearchSubcategory}
                    options={subcategoryOptions}
                  />

                  <FormItemInput
                    name="product_variant"
                    label="Product Variant"
                    placeholder="Input your product name"
                    size="large"
                    rules={[{ required: true, message: t("Please input your product variant!") }]}
                  />
                </Col>
                <Col span={12}>
                  <Row gutter={12}>
                    <Col span={12}>
                      <FormItemSelect
                        name="type_pack"
                        label="Type Pack"
                        placeholder="Select type pack"
                        size="large"
                        rules={[{ required: true, message: t("Please select type pack!") }]}
                        options={[
                          { value: "CARTON", label: "Carton" },
                          { value: "RENCENG", label: "Renceng" },
                          { value: "DUS", label: "Dus" },
                        ]}
                      />
                    </Col>
                    <Col span={12}>
                      <FormItemInput
                        name="pcs"
                        label="Pcs"
                        placeholder="Input your pcs"
                        size="large"
                        rules={[{ required: true, message: t("Please input pcs!") }]}
                      />
                    </Col>
                    <Col span={12}>
                      <FormItemInputNumberGeneral
                        name="size"
                        label="Size"
                        placeholder="0"
                        size="large"
                        rules={[{ required: true, message: t("Please input size!") }]}
                      />
                    </Col>
                    <Col span={12}>
                      <FormItemSelect
                        name="unit"
                        label="Unit"
                        placeholder="Kg"
                        size="large"
                        rules={[{ required: true, message: t("Please select unit!") }]}
                        options={uomUnitOptions}
                      />
                    </Col>
                    <Col span={24}>
                      <Form.Item label={t("Product Image")}>
                        <Form.Item name="product-image" noStyle>
                          <ImgCrop rotationSlider>
                            <Upload.Dragger
                              onRemove={() => handleResetImage()}
                              name="files"
                              listType="picture"
                              maxCount={1}
                              fileList={fileList}
                              beforeUpload={(file, fileList) => {
                                const validate = handleValidationDocument(file, 1, "image");
                                if (validate) {
                                  setFile(file);
                                  setFileList(fileList);
                                  return false;
                                } else {
                                  return Upload.LIST_IGNORE;
                                }
                              }}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">{t("Upload Product Image")}</p>
                              <p className="ant-upload-hint">
                                {t("Support for upload image")} (png/jpg/jpeg, Max. 1 MB)
                              </p>
                            </Upload.Dragger>
                          </ImgCrop>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <ButtonDefault
                        text="Cancel"
                        block={true}
                        handleClick={() => setModalOpen(false)}
                      />
                    </Col>
                    <Col span={12}>
                      <ButtonPrimary
                        text="Add"
                        block={true}
                        loading={isLoading}
                        htmlType="submit"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddProductMasterModal;

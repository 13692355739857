import React from "react";
import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./ProductNotFound.scss";

const { Title, Text } = Typography;

const ProductNotFound = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <div className='product-not-found-wrapper'>
      <div className='product-not-found'>
        <div className='product-not-found__header'>
          <Image width={80} preview={false} src={`${window.location.origin}/images/search.svg`} />
        </div>
        <div className='product-not-found__content'>
          <Title className='title' level={4}>
            {t(title ? title : "Product not found")}
          </Title>
          <Text>
            {t(description ? description : "Please try another keyword to find the product you are looking for") + "."}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ProductNotFound;

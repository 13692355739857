import {
  PRODUCT_FETCHING,
  PRODUCT_SUCCESS,
  PRODUCT_FAILED,
  PRODUCT_CLEAR,
  FETCH_OPTION_SUCCESS,
  PRODUCT_UPDATE,
  CREATE_PRODUCT_MASTER_FETCHING,
  CREATE_PRODUCT_MASTER_FAILED,
  PRODUCT_MASTER_FETCHING,
  PRODUCT_MASTER_SUCCESS,
  PRODUCT_MASTER_FAILED,
  PRODUCT_MASTER_APPENDED,
  UPDATE_PRODUCT_MASTER,
  PRODUCT_MASTER_DROPDOWN_FETCHING,
  PRODUCT_MASTER_DROPDOWN_SUCCESS,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: null,
  data: null,
  isCreateProductMasterFetching: false,
  isCreateProductMasterError: false,
  isProductMasterFetching: false,
  isProductMasterError: false,
  isProductDropdownFetching: false,
  productMaster: {
    data: [],
    meta: {
      totalPage: 0,
      totalData: 0,
    }
  },
  dropdown: {
    data: [],
    meta: {
      totalPage: 0,
      totalData: 0,
    }
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PRODUCT_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case PRODUCT_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case PRODUCT_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case PRODUCT_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case FETCH_OPTION_SUCCESS:
      return { ...state, isFetching: false, isError: false, options: payload };
    case PRODUCT_UPDATE:
      return { ...state, isFetching: false, isError: false, data: payload };
    case CREATE_PRODUCT_MASTER_FETCHING:
      return { ...state, isCreateProductMasterFetching: true, isCreateProductMasterError: false };
    case CREATE_PRODUCT_MASTER_FAILED:
      return { ...state, isCreateProductMasterFetching: false, isCreateProductMasterError: true };
    case PRODUCT_MASTER_FETCHING:
      return {
        ...state,
        isProductMasterFetching: true,
        isProductMasterError: false,
      };
    case PRODUCT_MASTER_FAILED:
      return {
        ...state,
        isProductMasterFetching: false,
        isProductMasterError: true,
      };
    case PRODUCT_MASTER_SUCCESS:
      return {
        ...state,
        isProductMasterFetching: false,
        isProductMasterError: false,
        productMaster: payload,
      };
    case PRODUCT_MASTER_DROPDOWN_SUCCESS:
      return {
        ...state,
        isProductDropdownFetching: false,
        dropdown: payload,
      };
    case PRODUCT_MASTER_DROPDOWN_FETCHING:
      return {
        ...state,
        isProductDropdownFetching: true,
      };
    case PRODUCT_MASTER_APPENDED:
      return {
        ...state,
        isProductMasterFetching: false,
        isProductMasterError: false,
        productMaster: {
          ...state.productMaster,
          data: [...state?.productMaster?.data, ...payload]
        }
      };
    case UPDATE_PRODUCT_MASTER:
      const {id , key, value} = payload;
      const newData = state?.productMaster?.data.map((data) => {
        if (data.id === id) {
          return ({
            ...data,
            [key]: value,
          });
        }

        return data;
      });

      return {
        ...state,
        productMaster: {
          ...state?.productMaster,
          data: newData,
        },
      };
    default:
      return state;
  }
};

import {
    CATALOG_FETCHING,
    CATALOG_SUCCESS,
    CATALOG_FAILED,
    CATALOG_CLEAR,
} from "../constants";

const initialState = {
    isFetching: false,
    isError: false,
    result: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CATALOG_FETCHING:
            return { ...state, isFetching: true, isError: false, result: null };
        case CATALOG_FAILED:
            return { ...state, isFetching: false, isError: true, result: null };
        case CATALOG_SUCCESS:
            return { ...state, isFetching: false, isError: false, result: payload };
        case CATALOG_CLEAR:
            return { ...state, result: null, isFetching: false, isError: false };
        default:
            return state;
    }
};

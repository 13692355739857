import { combineReducers } from "redux";
// import { reducer as sematable } from "sematable";
import branchReducer from "./branch.reducer";
import catalogReducer from "./catalog.reducer";
import categoryReducer from "./category.reducer";
import companyReducer from "./company.reducer";
import customerReducer from "./customer.reducer";
import dashboardReducer from "./dashboard.reducer";
import forgotpasswordReducer from "./forgotpassword.reducer";
import inventoryReducer from "./inventory.reducer";
import loginReducer from "./login.reducer";
import merchantReducer from "./merchant.reducer";
import orderReducer from "./order.reducer";
import posmachineReducer from "./posmachine.reducer";
import productReducer from "./product.reducer";
import receiptReducer from "./receipt.reducer";
import registerReducer from "./register.reducer";
import resetpasswordReducer from "./resetpassword.reducer";
import salesReducer from "./sales.reducer";
import salesAssignedReducer from "./salesassigned.reducer";
import salesDetailReducer from "./salesdetail.reducer";
import salesUnassignedReducer from "./salesunassigned.reducer";
import sellerReducer from "./seller.reducer";
import shopReducer from "./shop.reducer";
import statReducer from "./stat.reducer";
import subcategoryReducer from "./subcategory.reducer";
import subscriptionReducer from "./subscription.reducer";
import supplierReducer from "./supplier.reducer";
import uomReducer from "./uom.reducer";
import userReducer from "./user.reducer";
import districtReducer from "./district.reducer";
import uploadReducer from "./upload.reducer";
import pricetierReducer from "./pricetier.reducer";
import historyReducer from "./history.reducer";
import areaReducer from "./area.reducer";

export default combineReducers({
  // sematable,
  loginReducer,
  registerReducer,
  forgotpasswordReducer,
  resetpasswordReducer,
  posmachineReducer,
  branchReducer,
  supplierReducer,
  customerReducer,
  productReducer,
  orderReducer,
  shopReducer,
  statReducer,
  inventoryReducer,
  companyReducer,
  subscriptionReducer,
  uomReducer,
  categoryReducer,
  subcategoryReducer,
  catalogReducer,
  userReducer,
  salesReducer,
  salesAssignedReducer,
  salesUnassignedReducer,
  salesDetailReducer,
  sellerReducer,
  dashboardReducer,
  merchantReducer,
  receiptReducer,
  districtReducer,
  uploadReducer,
  pricetierReducer,
  historyReducer,
  areaReducer,
});

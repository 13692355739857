import deepDiff from "deep-diff";
import { cloneDeep } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { determineMarginValue, determineSellingPrice } from "./utils";
import { produce } from "immer";
import { Form } from "antd";
import useSWR from "swr";
import { httpClient } from "../../../utils/HttpClient";
import { useDispatch } from "react-redux";
import * as orderActions from "../../../actions/order.action";

function useEditOrder({ data, onCancel, orderId, onFinish, forceEnableEditing }) {
  const [changedOrderDetail, setChangedOrderDetail] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [form] = Form.useForm();
  const [errorFields, setErrorFields] = useState([]);
  const dispatch = useDispatch();

  const reasonKey = 'order/reason';
  const reason = useSWR(reasonKey, async (key) => {
    const response = await httpClient.get(`${process.env.REACT_APP_API_URL_NEW_V2}${key}`, { params: { type: 'SKU' }})
    return response.data.data;
  });

  const adjustedOrderDetail = useMemo(() => {
    const detail = data?.orderDetail?.map((detail) => ({
      ...detail,
      reasonId: detail?.reason?.id,
      originalMarginValue: determineMarginValue(
        detail?.initial?.initialQty,
        detail?.priceTiers?.length > 0 ? detail?.priceTiers : detail?.inventoryPriceTiers,
        detail?.sellingPrice - detail?.marginValue,
      )
    }));

    return detail;
  }, [data]);


  useEffect(() => {
    if (adjustedOrderDetail) {
      setChangedOrderDetail(cloneDeep(adjustedOrderDetail));
      form.setFieldsValue({ orderDetail: cloneDeep(adjustedOrderDetail) })
    }
  }, [adjustedOrderDetail, form])


  const onError = (error) => {
    const { errorFields } = error;
    setErrorFields(errorFields);
  };

  const onReset = useCallback(
    () => {
      form.resetFields();
      setErrorFields([]);
      setChangedOrderDetail(
        produce((draft) => {
          draft.forEach((datum) => {
            const originalOrder = data.orderDetail.find((item) => item.id === datum.id); 
            const basePrice = originalOrder.sellingPrice - originalOrder.marginValue;
            datum.qty = datum?.initial?.initialQty;
            datum.sellingPrice = datum?.initial?.initialPrice;
            datum.reasonId = null;
            datum.reason = null;
            datum.notes = null;
            datum.marginValue =  determineMarginValue(
              originalOrder?.initial?.initialQty,
              originalOrder?.priceTiers?.length > 0 ? originalOrder?.priceTiers : originalOrder?.inventoryPriceTiers,
              basePrice
            );
          });
          
          form.setFieldsValue({ orderDetail: draft })
        })
      );
      setIsReset(true);
    },
    [data, adjustedOrderDetail, setChangedOrderDetail, form]
  );

  const onRefresh = useCallback(
    () => {
      setChangedOrderDetail(adjustedOrderDetail);
      setErrorFields([]);
      form.resetFields();
    },
    [data, form]
  );

  const isEditing = useMemo(() => {
    if (forceEnableEditing) {
      return true
    }

    if (isReset) {
      return true;
    }

    if (adjustedOrderDetail && changedOrderDetail) {
      const before = adjustedOrderDetail?.map((beforeItem) => {
        return ({
          qty: beforeItem?.initial?.initialQty,
          sellingPrice: beforeItem?.initial?.initialPrice,
          reason: beforeItem?.reason,
          notes: beforeItem?.notes,
        });
      })

      const after = changedOrderDetail?.map((afterItem) => {
        return ({
          qty: afterItem.qty,
          sellingPrice: afterItem.sellingPrice,
          reason: afterItem?.reason,
          notes: afterItem?.notes,
        });
      })

      const diff = deepDiff.diff(before, after);
      return diff && Array.isArray(diff) && diff.length > 0;
    }

    return false;
  }, [adjustedOrderDetail, changedOrderDetail, forceEnableEditing, isReset]);

  const totalPayment = useMemo(() => {
    if (changedOrderDetail) {
      return changedOrderDetail?.reduce?.((prev, curr) => {
        return prev + (curr.sellingPrice * curr.qty);
      }, 0)
    }

    return 0;
  }, [changedOrderDetail]);

  const totalMargin = useMemo(() => {
    if (changedOrderDetail) {
      return changedOrderDetail?.reduce?.((prev, curr) => {
        return prev + (curr.marginValue * curr.qty)
      }, 0)
    }

    return 0;
  }, [changedOrderDetail])

  const createOnChangeQty = (id) => (e) => {
    setChangedOrderDetail(
      produce((draft) => {
        const order = draft.find((item) => item.id === id);
        const originalOrder = data.orderDetail.find((item) => item.id === id); 
        const basePrice = originalOrder.sellingPrice - originalOrder.marginValue;
        const priceTiers = order.priceTiers.length > 0 ? order.priceTiers : order.inventoryPriceTiers;
        order.qty = Number(e.target.value || 0);
        const diff =  deepDiff.diff({ qty: originalOrder?.initial?.initialQty, sellingPrice: originalOrder?.initial?.initialPrice }, { qty: order.qty, sellingPrice: order.sellingPrice });
        order.sellingPrice = determineSellingPrice(order.qty, priceTiers);
        order.marginValue = determineMarginValue(order.qty, priceTiers, basePrice);
        order.reason = diff?.length > 0 ? originalOrder?.reason : null;
        order.reasonId = diff?.length > 0 ? originalOrder?.reasonId || originalOrder?.reason?.id : null;
        order.notes = diff?.length > 0 ? originalOrder?.notes : null;
        form.setFieldsValue({ orderDetail: draft })
        setIsReset(true);
      })
    );
  };

  const createOnChangePrice = (id) => (e) => {
    setChangedOrderDetail(
      produce((draft) => {
        const order = draft.find((item) => item.id === id);
        const originalOrder = data.orderDetail.find((item) => item.id === id); 
        const basePrice = originalOrder.sellingPrice - originalOrder.marginValue;
        order.sellingPrice = Number(e.target.value || 0);
        const diff =  deepDiff.diff({ qty: originalOrder?.initial?.initialQty, sellingPrice: originalOrder?.initial?.initialPrice }, { qty: order.qty, sellingPrice: order.sellingPrice });
        order.reason = diff?.length > 0 ? originalOrder?.reason : null;
        order.reasonId = diff?.length > 0 ? originalOrder?.reasonId || originalOrder?.reason?.id : null;
        order.notes = diff?.length > 0 ? originalOrder?.notes : null;
        order.marginValue = order.sellingPrice - basePrice;
        form.setFieldsValue({ orderDetail: draft })
        setIsReset(true);
      })
    );
  };

  const createOnChangeReason = (id) => (value) => {
    setChangedOrderDetail(
      produce((draft) => {
        const order = draft.find((item) => item.id === id);
        order.reasonId = value;
        form.setFieldsValue({ orderDetail: draft })
      })
    );
  };

  const createOnChangeNotes = (id) => (e) => {
    setChangedOrderDetail(
      produce((draft) => {
        const order = draft.find((item) => item.id === id);
        order.notes = e.target.value;
        form.setFieldsValue({ orderDetail: draft })
      })
    );
  };

  const createOnCancel = (id) => () => {
    setChangedOrderDetail(
      produce((draft) => {
        const order = draft.find((item) => item.id === id);
        const originalOrder = data.orderDetail.find((item) => item.id === id);
        order.qty = 0;
        order.sellingPrice = 0;
        order.marginValue = originalOrder.marginValue;
        form.setFieldsValue({ orderDetail: draft })
      })
    );
  };

  const onClose = () => {
    onRefresh();
    onCancel();
    setIsReset(false);
  };

  const onSave = useCallback(async (values) => {
    const { orderDetail } = values;
    const payload = orderDetail.map((detail) => ({
      qty: detail.qty,
      id: detail.id,
      price: detail.sellingPrice,
      reasonId: detail.reasonId,
      notes: detail.notes,
    }));

    if (data) {
      await httpClient.patch(`${process.env.REACT_APP_API_URL_NEW_V2}order/detail/bulk/${orderId}`, payload);
      dispatch(orderActions.getOrderDetail(orderId));
      onClose();
    }

    if (onFinish) {
      onFinish();
    }
  }, [data, orderId]);

  return {
    onClose,
    createOnCancel,
    createOnChangeNotes,
    createOnChangePrice,
    changedOrderDetail,
    orderDetail: adjustedOrderDetail,
    createOnChangeQty,
    createOnChangeReason,
    totalPayment,
    totalMargin,
    isEditing,
    onSave,
    onReset,
    isReset,
    onError,
    errorFields,
    form,
    reason: reason.data ?? [],
  }
}

export default useEditOrder;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterParams } from "../utils/Functions";
import * as areaActions from "../actions/area.action";

const useInternalRegionData = (params) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { internalRegion } = useSelector(({ areaReducer }) => areaReducer);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const isSuccess = await dispatch(areaActions.internalRegion(filterParams(params)));
    if (isSuccess) setIsLoading(false);
    else setIsLoading(false);
  }, [dispatch, params]);

  useEffect(() => {
    fetchData();
  }, [dispatch, params]);

  useEffect(() => {
    if (internalRegion) {
      const { data, totalData, totalPage } = internalRegion;
      setData(data);
      setTotalData(totalData);
      setTotalPage(totalPage);
    }
    setIsLoading(false);
  }, [internalRegion]);

  return { data, totalData, totalPage, isLoading };
};

export default useInternalRegionData;

// @ts-check
import React from "react";
import ButtonDefault from "../ButtonDefault/ButtonDefault";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";

const ButtonCoditional = ({
  text,
  block = false,
  disabled = false,
  className = "",
  htmlType = "button",
  danger = false,
  icon = '',
  handleClick,
  active = false,
}) => {
  return active ?
    <ButtonPrimary
      {...{text, block, disabled, className, htmlType, danger, icon, handleClick}}
    /> :
    <ButtonDefault
      {...{text, block, disabled, className, htmlType, danger, icon, handleClick, children: undefined }}
    />
};

export default ButtonCoditional;

import React from "react";
import { Image } from "antd";
import MainContent from "../../components/organisms/Content/Content";
import "./_style.scss";

const BoaHome = () => {
  const headerProps = {
    title: "Home",
    description: "Manage your home",
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="boa-home" className="flex-center">
        <Image
          className="coming-soon-image"
          width={300}
          preview={false}
          src={`${window.location.origin}/images/coming-soon.svg`}
        />
      </div>
    </MainContent>
  );
};

export default BoaHome;

import { ClockCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Image, Modal, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { OrderStatus } from "../../../constants";
import { formatRupiah } from "../../../utils/Functions";
import ButtonDefault from "../../atoms/ButtonDefault/ButtonDefault";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import "./PaymentSuccessModal.scss";

const { Text, Title } = Typography;

const PaymentSuccessModal = ({
  modalOpen,
  setModalOpen,
  orderDetail,
  customer,
  productList,
  receiptDetail,
  paymentStatus,
  setLabelPaymentStatus,
  isPrintReceiptLoading,
  handlePrintStruck,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Modal
      className="payment-success-modal"
      centered
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={780}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
    >
      <Row gutter={24}>
        <Col span={12}>
          <div className="payment-success-modal-receipt-wrapper">
            <div className="payment-success-modal-receipt">
              <div className="pl-20 pr-20">
                <Row>
                  <Col span={24} className="text-center">
                    <Text strong>{receiptDetail?.storeName}</Text>
                  </Col>
                  <Col span={24} className="text-center">
                    <Text>{receiptDetail?.storeAddress}</Text>
                  </Col>
                  <Col span={24} className="text-center mb-10">
                    <Text>{receiptDetail?.storePhone}</Text>
                  </Col>

                  <Col span={12}>
                    <Text strong>{t("No Struk")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text strong>
                      {receiptDetail?.storeInitial} {orderDetail?.receiptNumber || "00000"}-
                      {dayjs(orderDetail?.payment?.timestamp).format("DD/MM/YY")}
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>{t("Order ID")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text strong>#{orderDetail?.order_id}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>{t("Status")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text strong>
                      {t(
                        orderDetail?.payment_status?.toLowerCase() === "order_finished"
                          ? "paid"
                          : paymentStatus === OrderStatus.CANCELED ||
                            paymentStatus === OrderStatus.FAILED
                          ? "canceled"
                          : "unpaid",
                      )}
                    </Text>
                  </Col>

                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Text strong>{t("Name")}</Text>
                    <br />
                    <Text>{customer?.name}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>{t("Address")}</Text>
                    <br />
                    <Text>{customer?.address}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>{t("Phone No.")}</Text>
                    <br />
                    <Text>{customer?.noPhone}</Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>{t("Date")}</Text>
                    <br />
                    <Text>{dayjs(orderDetail?.timestamp).format("DD MMM YYYY HH:mm:ss")}</Text>
                  </Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col span={14}>
                    <Text strong>{t("Item")}</Text>
                  </Col>
                  <Col span={3} className="text-center">
                    <Text strong>{t("Qty")}</Text>
                  </Col>
                  <Col span={7} className="text-center">
                    <Text strong>{t("Price")}</Text>
                  </Col>
                  {!isEmpty(productList) &&
                    productList.map((item, index) => (
                      <PrintReceiptItem
                        key={index}
                        title={item?.name}
                        qty={item?.qty}
                        total={item?.sellingPrice}
                      />
                    ))}
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col span={12}>
                    <Text>{t("Sub total")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(orderDetail?.subTotal)}</Text>
                  </Col>
                  <Col span={12}>
                    <Text>{t("Total discount")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(orderDetail?.discount)}</Text>
                  </Col>
                </Row>

                <Row className="total-payment-label pl-20 pr-20">
                  <Col span={12}>
                    <Text strong>{t("Total Payment")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text className="custom-typography">{formatRupiah(orderDetail?.total)}</Text>
                  </Col>
                </Row>

                <Row className="pl-20 pr-20">
                  <Col span={12}>
                    <Text>{t("Cash")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(orderDetail?.paid)}</Text>
                  </Col>
                  <Col span={12}>
                    <Text>{t("Change")}</Text>
                  </Col>
                  <Col span={12} className="text-right">
                    <Text>{formatRupiah(orderDetail?.change)}</Text>
                  </Col>
                  <Col span={24} className="text-center">
                    <Text italic className="mr-10">
                      {t("Powered By")}
                    </Text>
                    <Image
                      width={35}
                      preview={false}
                      src={`${window.location.origin}/images/baskit-gray-logo.svg`}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <Row gutter={12}>
            <Col span={24} className="text-center">
              <Image
                width={80}
                preview={false}
                src={`${window.location.origin}/images/${
                  orderDetail?.payment_status === OrderStatus.PAID.value
                    ? "payment-success"
                    : orderDetail?.payment_status === OrderStatus.CANCELED.value ||
                      orderDetail?.payment_status === OrderStatus.FAILED
                    ? "order-canceled"
                    : "payment-warning"
                }.svg`}
              />
              <Title className="mt-10" level={4}>
                {t(
                  orderDetail?.payment_status === OrderStatus.PAID.value
                    ? "paymentSuccess"
                    : orderDetail?.payment_status === OrderStatus.CANCELED.value ||
                      orderDetail?.payment_status === OrderStatus.FAILED
                    ? "orderCanceled"
                    : "waitingPayment",
                )}
              </Title>
              <Divider />
            </Col>

            <Col span={24} className="mb-20">
              <Space direction="vertical" className="full-width">
                <ListItem value={`#${orderDetail?.order_id}`} label="Order ID" />
                <ListItem value={customer?.name} label="Customer Name" />
                <ListItem value={customer?.address} label="Address" />
                <ListItem value={customer?.noPhone} label="Phone No." />
                <ListItem
                  value={setLabelPaymentStatus(orderDetail?.payment_status)}
                  label="Payment Status"
                />
                {paymentStatus === OrderStatus.PAID && (
                  <Row>
                    <Col className="text-right" span={16} offset={8}>
                      <ClockCircleOutlined className="mr-10" />
                      <span>
                        {dayjs(orderDetail?.timestamp).format("DD MMMM YYYY") +
                          " | " +
                          dayjs(orderDetail?.timestamp).format("HH:mm")}
                      </span>
                    </Col>
                  </Row>
                )}
              </Space>
            </Col>
            <Col span={12}>
              <ButtonDefault
                text="setReceiptFormat"
                block={true}
                handleClick={(event) => {
                  const isInsideIframe = window.parent !== window;
                  console.log("Clickeddddd");
                  if (isInsideIframe) {
                    event.preventDefault();
                    window?.parent?.postMessage(
                      {
                        type: "receipt-setting",
                        payload: {},
                      },
                      process.env.REACT_APP_NEW_CMS,
                    );
                  }
                }}
              />
            </Col>
            <Col span={12}>
              <ButtonPrimary
                text="printReceipt"
                block={true}
                loading={isPrintReceiptLoading}
                handleClick={() => {
                  window.open(`${process.env.REACT_APP_NEW_CMS}/order-list/offline/${orderDetail.order_id}/receipt`);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

const PrintReceiptItem = ({ title, qty, total }) => {
  return (
    <>
      <Col span={14}>
        <Text>{title}</Text>
      </Col>
      <Col span={3} className="text-center">
        <Text>{qty}</Text>
      </Col>
      <Col span={7} className="text-right">
        <Text>{formatRupiah(total)}</Text>
      </Col>
    </>
  );
};

const ListItem = ({ value, label }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12}>
      <Col span={12}>
        <Text>{t(label)}</Text>
      </Col>
      <Col span={12} className="text-right">
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

export default PaymentSuccessModal;

import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import "./SortProduct.scss";

const SortProduct = ({ placeholder, options, handleChange, getPopupContainer = false }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("");
  const [isOnClick, setIsOnClick] = useState(false);

  useEffect(() => {
    if (!isOnClick) return;

    const debouncedAction = debounce(() => {
      handleChange(selectedValue);
    }, 100);

    debouncedAction();

    return () => {
      debouncedAction.cancel();
    };
  }, [selectedValue, isOnClick]);

  return (
    <Select
      className="sort-product"
      placeholder={t(placeholder)}
      getPopupContainer={getPopupContainer}
      allowClear
      showSearch
      options={options.map((item) => ({ value: item.value, label: t(item.label) }))}
      onChange={(value) => {
        setIsOnClick(true);
        setSelectedValue(value);
      }}
    />
  );
};

export default SortProduct;

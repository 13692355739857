import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, List, Row, Space, Typography, Form, Input, Button } from "antd";
import { formatRupiah } from "../../utils/Functions";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { OrderStatus, OrderType, server } from "../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Cookies from "js-cookie";
import MainContent from "../../components/organisms/Content/Content";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import SpinLoaderContent from "../../components/atoms/SpinLoaderContent/SpinLoaderContent";
import ProductPOSCard from "../../components/molecules/ProductPOSCard/ProductPOSCard";
import CartEmpty from "../../components/atoms/CartEmpty/CartEmpty";
import ButtonPrimary from "../../components/atoms/ButtonPrimary/ButtonPrimary";
import MainBreadcrumb from "../../components/molecules/Breadcrumb/Breadcrumb";
import DeleteProductCartModal from "../../components/molecules/DeleteProductCartModal/DeleteProductCartModal";
import CartListItem from "../../components/molecules/CartListItem/CartListItem";
import MultipleSelectCategory from "../../components/molecules/MultipleSelectCategory/MultipleSelectCategory";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import useInventoryData from "../../hooks/useInventoryData";
import useOrderData from "../../hooks/useOrderData";
import * as cartActions from "../../actions/cart.action";
import * as orderActions from "../../actions/order.action";
import * as shopActions from "../../actions/shop.action";
import "./_style.scss";
import useCategoryData from "../../hooks/useCategoryData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

const ChooseProduct = () => {
  const { t } = useTranslation();
  // Bayar Tunai (COD) id
  const history = useHistory();
  const [selectedProductDelete, setSelectedProductDelete] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [unavailableProducts, setUnavailableProducts] = useState({});
  const [isProccedButtonDisabled, setIsProccedButtonDisabled] = useState(true);
  const [isCancelDeleteProduct, setIsCancelDeleteProduct] = useState(false);
  const [isCreateOrderLoading, setCreateOrderLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [carts, setCarts] = useState([]);
  const [products, setProducts] = useState([]);
  const [page] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const userId = localStorage.getItem(server.USER_ID);
  const orderType = OrderType.OFFLINE.value;
  const companyId = loggedInUser?.companyId;

  const params = useMemo(
    () => ({
      $page: page,
      $limit: pageSize,
      search: searchValue,
      categoryId,
      companyId,
    }),
    [page, pageSize, searchValue, categoryId, companyId],
  );

  const orderParams = useMemo(
    () => ({
      orderType: orderType || OrderType.OFFLINE.value,
      createdBy: userId,
    }),
    [userId, orderType],
  );

  const shop = useSelector((state) => state.shopReducer);
  const inventory = useInventoryData(params);
  const order = useOrderData(orderParams);
  const category = useCategoryData({});

  useEffect(() => {
    dispatch(shopActions.getPaymentType());
  }, []);

  useEffect(() => {
    if (isEmpty(order?.data)) {
      setCarts([]);
      setTotalPrice(0);
      setTotalProduct(0);
      return;
    }

    const { total, product, totalQty } = order?.data[0];

    setTotalPrice(total);
    setTotalProduct(totalQty);
    setCarts(product);
  }, [order]);

  useEffect(() => {
    if (isEmpty(inventory?.data)) {
      setProducts([]);
      return;
    }

    const result = inventory?.data.map((product) => ({
      ...product,
      isAlreadyCart: checkProductInCart(product?.productId, carts),
    }));
    setProducts(result);
  }, [inventory?.data, carts]);

  useEffect(() => {
    if (isEmpty(carts) || !isEmpty(unavailableProducts)) setIsProccedButtonDisabled(true);
    else setIsProccedButtonDisabled(false);
  }, [carts, unavailableProducts]);

  const handleDeleteProductCart = async () => {
    const { id } = selectedProductDelete;
    const isSuccess = await dispatch(cartActions.remove(id));
    if (isSuccess) order?.refetchData();
    setModalOpen(false);
    setNotificationModalOpen(false);
  };

  const handleCancelDeleteProduct = () => {
    setIsCancelDeleteProduct(true);
    setNotificationModalOpen(false);
  };

  const loadMoreData = () => {
    if (inventory?.isLoading) return;
    setPageSize(pageSize + 20);
  };

  const getPriceTierId = (qty, priceTier) => {
    if (!priceTier || priceTier.length == 0) {
      return { id: undefined };
    }

    const lastPriceTier = priceTier.slice(-1)[0];
    const result = priceTier.find(({ max }) => qty <= max);
    return result === undefined ? lastPriceTier : result;
  };

  const checkProductInCart = (productId, carts) => {
    if (isEmpty(carts)) return false;

    const result = carts.find((cart) => cart?.productId === productId);
    return isEmpty(result) ? false : true;
  };

  const handleAddToCart = async (selectedInventory) => {
    const { id, companyId, productId, priceTier } = selectedInventory;
    let values = {
      userId: loggedInUser?.id,
      inventoryId: id,
      companyId,
      productId,
      inventoryPriceTierId: getPriceTierId(1, priceTier)?.id,
      qty: 1,
      wareHouse: 0,
    };

    const isSuccess = await dispatch(cartActions.create(values));
    if (isSuccess) order?.refetchData();
  };

  const handleUpdateCart = async (selectedCart) => {
    const { cartId, qty, inventoryPriceTiers } = selectedCart;
    const values = {
      userId,
      qty,
      inventoryPriceTierId: getPriceTierId(qty, inventoryPriceTiers)?.id,
    };
    const isSuccess = await dispatch(cartActions.update(cartId, values));
    if (isSuccess) order?.refetchData();
  };

  const handleSubmit = useCallback(async () => {
    setCreateOrderLoading(true);
    const offlineCustomerId = localStorage.getItem(server.CUSTOMER_ID);
    const { totalQty, subTotal, total, product } = order?.data[0];
    const newProduct = product.map(
      ({ id, productId, qty, inventoryId, sellingPrice, companyId, inventoryPriceTiers }) => ({
        cartId: id,
        inventoryId,
        productId,
        inventoryPriceTierId: getPriceTierId(qty, inventoryPriceTiers)?.id,
        qty,
        price: sellingPrice, // selling price but used base price from BE
        companyId,
      }),
    );

    const codId = shop.paymentType[0].id;

    let values = {
      paymentTypeId: codId,
      totalQty,
      subTotal,
      total,
      userId: loggedInUser?.id,
      orderType: OrderType.OFFLINE.value,
      status: OrderStatus.ORDER_CREATED.value,
      companyId: loggedInUser?.companyId,
      wareHouse: 1,
      product: newProduct,
    };

    if (offlineCustomerId) values.userId = offlineCustomerId;

    const response = await dispatch(orderActions.create(values));
    if (response?.isSuccess) {
      Cookies.remove(server.CUSTOMER_ID);
      history.push(`/payment/detail/${response.orderId}`);
    }
    setCreateOrderLoading(false);
  }, [shop, order]);

  const headerProps = {
    buttonBack: { text: "Choose Product", status: true },
    description: "POS",
  };

  const mainBreadcrumbItems = [
    {
      link: "/pos",
      title: "Create Order",
    },
    {
      title: "Choose Product",
    },
  ];

  const productSearchFilterProps = {
    placeholder: "Search product name",
    handleChange: (value) => setSearchValue(value),
  };

  const multipleSelectCategoryProps = {
    options: category.data.map(({ id, name}) => ({
      value: id,
      label: name,
    })),
    handleChange: (value) => setCategoryId(value.join(",")),
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="choose-product-wrapper">
        <div id="choose-product-breadcrumb">
          <MainBreadcrumb items={mainBreadcrumbItems} />
        </div>

        <div id="choose-product-content">
          <Row gutter={12}>
            <Col span={16}>
              <div id="choose-product-filter">
                <Row gutter={24}>
                  <Col span={8}>
                    <MultipleSelectCategory {...multipleSelectCategoryProps} />
                  </Col>
                  <Col span={16}>
                    <ProductSearchFilter {...productSearchFilterProps} />
                  </Col>
                </Row>
              </div>

              <div id="choose-product-cards">
                <InfiniteScroll
                  dataLength={products.length}
                  next={loadMoreData}
                  hasMore={products.length < inventory?.totalData}
                  scrollableTarget="choose-product-cards"
                  loader={<SpinLoaderContent />}
                >
                  <div className="card-wrapper">
                    {products.map((product) => (
                      <ProductPOSCard
                        key={product?.id}
                        data={product}
                        handleClick={handleAddToCart}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            </Col>
            <Col span={8}>
              <Form form={form} onFinish={handleSubmit}>
                <div id="choose-product-sidebar-summary">
                  <div id="choose-product-cart">
                    <Space direction="horizontal">
                      <Text strong>{t("Order")}</Text>
                      {!isEmpty(carts) && (
                        <div id="choose-product-number-order">
                          <Text strong>{totalProduct}</Text>
                        </div>
                      )}
                    </Space>
                    {!isEmpty(carts) ? (
                      <List
                        id="choose-product-cart-list"
                        itemLayout="horizontal"
                        dataSource={carts}
                        loading={order?.isLoading}
                        renderItem={(item) => (
                          <CartListItem
                            key={item?.id}
                            data={item}
                            setModalOpen={setModalOpen}
                            refetchOrder={order.refetchData}
                            setNotificationModalOpen={setNotificationModalOpen}
                            selectedProductDelete={selectedProductDelete}
                            setSelectedProductDelete={setSelectedProductDelete}
                            unavailableProducts={unavailableProducts}
                            setUnavailableProducts={setUnavailableProducts}
                            setCreateOrderLoading={setCreateOrderLoading}
                            isCancelDeleteProduct={isCancelDeleteProduct}
                            setIsCancelDeleteProduct={setIsCancelDeleteProduct}
                            handleUpdateCart={handleUpdateCart}
                          />
                        )}
                      />
                    ) : (
                      <CartEmpty />
                    )}
                    <DeleteProductCartModal
                      title="Delete product"
                      message="Are you sure you want to remove this product from the shopping list?"
                      modalOpen={modalOpen}
                      handleDeleteProductCart={handleDeleteProductCart}
                      handleCancel={() => setModalOpen(false)}
                    />
                    <DeleteProductCartModal
                      title="Are you sure?"
                      message="The product will be automatically removed from your shopping cart if the total item count reaches 0."
                      modalOpen={notificationModalOpen}
                      handleDeleteProductCart={handleDeleteProductCart}
                      handleCancel={handleCancelDeleteProduct}
                    />
                  </div>

                  <div id="choose-product-discount">
                    <Text strong>{t("Discount")}</Text>
                    <Row gutter={12}>
                      <Col span={16}>
                        <Input disabled />
                      </Col>
                      <Col span={8}>
                        <Button block disabled>
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div id="choose-product-summary">
                    <Text strong>{t("Shopping Summary")}</Text>
                    <Row className="mt-10">
                      <Col span={16}>
                        <Space direction="vertical" size="small">
                          <Text>{t("Total products")}</Text>
                          <Text>{t("Total discounts")}</Text>
                        </Space>
                      </Col>
                      <Col span={8} className="text-right">
                        <Space direction="vertical" size="small">
                          <Text strong>{totalProduct}</Text>
                          <Text strong>{formatRupiah(0)}</Text>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Divider style={{ margin: "10px 0px" }} />
                      </Col>
                      <Col span={16}>
                        <Text strong>{t("Total Price")}</Text>
                      </Col>
                      <Col span={8} className="text-right">
                        <Text strong>{formatRupiah(totalPrice)}</Text>
                      </Col>
                      <Col span={24} className="mt-20">
                        <ButtonPrimary
                          className={isProccedButtonDisabled && "button-disabled"}
                          disabled={isProccedButtonDisabled}
                          block={true}
                          htmlType="submit"
                          text={"next"}
                          loading={isCreateOrderLoading}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </MainContent>
  );
};

export default ChooseProduct;

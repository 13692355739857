import React, { useEffect, useState } from "react";
import { Button, Col, Image, List, Row, Typography } from "antd";
import { formatRupiah, getCurrentRole } from "../../../utils/Functions";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { isNumber } from "lodash-es";
import "./PaymentDetailListItem.scss";
import Cookies from "js-cookie";
import { server } from "../../../constants";

const { Text } = Typography;

const PaymentDetailListItem = ({
  data,
  isDeleteDisabled,
  setModalOpen = () => {},
  setSelectedProductDelete = () => {},
}) => {
  const { t } = useTranslation();
  const { fullName, sellingPrice, qty, image, marginValue } = data;
  const [total, setTotal] = useState(0);
  const [marginTotal, setMarginTotal] = useState(0);
  const currentRole = getCurrentRole();

  useEffect(() => {
    if (isNumber(qty) && isNumber(sellingPrice)) setTotal(qty * sellingPrice);
  }, [qty, sellingPrice]);

  useEffect(() => {
    if (isNumber(qty) && isNumber(marginValue)) setMarginTotal(qty * marginValue);
  }, [qty, marginValue]);

  return (
    <List.Item className="payment-detail-list-item">
      <Row className="full-width">
        <Col span={4}>
          <Image
            className="payment-detail-list-item-image"
            width={90}
            height={90}
            preview={false}
            src={image ?? `${window.location.origin}/images/baskit-product.jpg`}
            fallback={`${window.location.origin}/images/baskit-product.jpg`}
          />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={16}>
              <div className="payment-detail-list-item-title">
                <Text className="ant-typography-ellipsis-multiple-line">{fullName}</Text>
              </div>
            </Col>
            <Col span={8} className="text-right">
              <Text strong>{formatRupiah(sellingPrice ?? 0)}</Text>
            </Col>
            {/* <Col span={2}>
              {!isDeleteDisabled && (
                <Button
                  className="text-gray float-right"
                  size="small"
                  type="default"
                  htmlType="button"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setSelectedProductDelete({ product_id: id });
                    setModalOpen(true);
                  }}
                />
              )}
            </Col> */}
            <Col span={24} className="mt-10">
              <div className="payment-detail-list-item-description">
                <Row>
                  <Col span={5}>
                    <Text>{t("Qty")} : </Text>
                    <Text strong>{qty}</Text>
                  </Col>
                  <Col span={1}>|</Col>
                  <Col span={18}>
                    <Text>{t("Total")} : </Text>
                    <Text strong>{formatRupiah(total)}</Text>
                  </Col>
                  {currentRole === 'BASKIT_ADMIN' ? (
                    <Col span={24} className="mt-10">
                      <Text>{t("marginValue")} : </Text>
                      <Text strong>{formatRupiah(marginTotal)}</Text>
                    </Col>
                  ): null}
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </List.Item>
  );
};

export default PaymentDetailListItem;

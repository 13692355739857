import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "antd";
import { columns } from "./helpers";
import { filterParams } from "../../utils/Functions";
import { SellerStatus } from "../../constants";
import MainContent from "../../components/organisms/Content/Content";
import ProductSearchFilter from "../../components/atoms/ProductSearchFilter/ProductSearchFilter";
import SortProduct from "../../components/atoms/SortProduct/SortProduct";
import * as companyActions from "../../actions/company.action";
import "./_style.scss";

const SellerList = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");

  const headerProps = {
    title: "Account Manager",
    description: "Manage your account",
  };

  const { companySeller } = useSelector((state) => state.companyReducer);

  const params = useMemo(() => {
    const paramsConstruct = {
      $page: page,
      $limit: pageSize,
      search: searchValue,
      status: sortValue,
    };

    return filterParams(paramsConstruct);
  }, [page, pageSize, searchValue, sortValue]);

  useEffect(() => {
    dispatch(companyActions.seller(params));
  }, [params]);

  useEffect(() => {
    if (companySeller) {
      const { data, meta } = companySeller;
      setData(data);
      setMeta(meta);
      setLoading(false);
    }
  }, [companySeller]);

  const dataSource =
    Array.isArray(data) &&
    data?.map((data, index) => ({
      key: index,
      name: data?.ownerName ?? "",
      status: data?.status ?? "",
      storeName: data,
      registrationDate: data.created,
    }));

  const salesSearchFilterProps = {
    placeholder: "Search seller by store name",
    handleChange: (value) => setSearchValue(value),
  };

  const sortProductProps = {
    placeholder: "Status",
    options: [
      SellerStatus.ACTIVE,
      SellerStatus.INACTIVE,
      SellerStatus.CHURNED,
      SellerStatus.DROPPED,
    ],
    handleChange: (value) => setSortValue(value),
  };

  const tabelProps = {
    loading,
    dataSource,
    columns,
    pagination: {
      pageSize: pageSize,
      current: page,
      total: meta?.totalData,
      showSizeChanger: true,
      onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
      onChange: (page) => setPage(page),
    },
    scroll: { y: "60vh" },
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="seller-wrapper">
        <div id="seller-filter">
          <Row gutter={24}>
            <Col span={20}>
              <ProductSearchFilter {...salesSearchFilterProps} />
            </Col>
            <Col span={4}>
              <SortProduct {...sortProductProps} />
            </Col>
          </Row>
        </div>
        <div id="seller-content">
          <Table async {...tabelProps} />
        </div>
      </div>
    </MainContent>
  );
};

export default SellerList;

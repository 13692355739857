import {
  LOGIN_FETCHING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_AUTH_FETCHING,
  LOGIN_AUTH_FAILED,
  LOGIN_SEND_OTP_FETCHING,
  LOGIN_SEND_OTP_FAILED,
  server,
} from "../constants";
import { ErrorMessage, SuccessMessage } from "../utils/Message";
import { httpClient } from "./../utils/HttpClient";

export const setLoginStateToFetching = () => ({
  type: LOGIN_FETCHING,
});

export const setLoginStateToFailed = () => ({
  type: LOGIN_FAILED,
});

export const setLoginStateToSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const setLoginAuthStateToFetching = () => ({
  type: LOGIN_AUTH_FETCHING,
});

export const setLoginAuthStateToFailed = () => ({
  type: LOGIN_AUTH_FAILED,
});

export const setLoginSendOtpStateToFetching = () => ({
  type: LOGIN_SEND_OTP_FETCHING,
});

export const setLoginSendOtpStateToFailed = () => ({
  type: LOGIN_SEND_OTP_FAILED,
});

export const login = (value, history, setLoginStatus = null) => {
  return async (dispatch) => {
    dispatch(setLoginStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_V2 + "check-number",
        value,
      );
      localStorage.setItem(server.LOGIN_VALUES, JSON.stringify(value));
      history.push("/login/otp");
      return SuccessMessage(response);
    } catch (error) {
      dispatch(setLoginStateToFailed());
      ErrorMessage(error);
      if (error.response) {
        const { status, statusText } = error.response;
        if (status === 400) {
          setLoginStatus({ status, message: statusText });
        }
      }
    }
  };
};

export const resendOTP = (value, setOtpStatus, setTimeRemaining) => {
  return async (dispatch) => {
    dispatch(setLoginStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_V2 + "check-number",
        value,
      );
      setTimeRemaining(300);
      setOtpStatus("success");
      return SuccessMessage(response);
    } catch (error) {
      dispatch(setLoginStateToFailed());
      ErrorMessage(error);
    }
  };
};

export const authenticate = (value, setOtpStatus) => {
  return async (dispatch) => {
    dispatch(setLoginStateToFetching());
    try {
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_V2 + "authenticate",
        value,
      );
      const { token, data } = response.data;
      localStorage.setItem(server.TOKEN_KEY, token);
      localStorage.setItem(server.REFRESH_TOKEN_KEY, token);
      localStorage.setItem(server.USER_DATA, JSON.stringify({ ability: data.role }));
      localStorage.setItem(server.USER_ROLE, data.roleUser);
      window.location.href = "/home";
      return SuccessMessage(response);
    } catch (error) {
      dispatch(setLoginStateToFailed());
      ErrorMessage(error);
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          setOtpStatus("failed");
        }
      }
    }
  };
};

export const auth = (values) => {
  return async (dispatch) => {
    dispatch(setLoginAuthStateToFetching());
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL_NEW_V2 + "auth", values);
      const { accessToken, refreshToken, id } = response.data.data;

      localStorage.setItem(server.TOKEN_KEY, accessToken);
      localStorage.setItem(server.REFRESH_TOKEN_KEY, refreshToken);
      localStorage.setItem(server.USER_ID, id);

      return true;
    } catch (error) {
      dispatch(setLoginAuthStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const sendOtp = (value) => {
  return async (dispatch) => {
    dispatch(setLoginSendOtpStateToFetching());
    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL_NEW_V2 + "auth", value);
      SuccessMessage(response);
      return true;
    } catch (error) {
      dispatch(setLoginSendOtpStateToFailed());
      ErrorMessage(error);
      return false;
    }
  };
};

export const logout = () => {
  localStorage.clear();
};

import React from "react";
import { Col, Row } from "antd";
import PeriodSelector from "./periodselector";
import RecordTypeSelector from "./recordtypeselector";
import DashboardMainchart from "./mainchart";
import DashboardTotal from "./totaltransaction";
import SKUTransaction from "./skuTransaction";
import TopFiveSKU from "./topFiveSku";
import SKUOnBoard from "./skuOnBoard";
import MainContent from "../../components/organisms/Content/Content";
import "./_style.scss";

const Home = () => {
  const headerProps = {
    title: "Home",
    description: "Manage your home",
  };

  return (
    <MainContent headerProps={headerProps}>
      <div id="home-wrapper">
        <div id="home-multiple-selection-container">
          <PeriodSelector />
          <RecordTypeSelector />
          <DashboardMainchart />
          <DashboardTotal />
          <Row>
            <Col span={8}>
              <SKUTransaction />
            </Col>
            <Col span={16}>
              <TopFiveSKU />
            </Col>
          </Row>
          <SKUOnBoard />
        </div>
      </div>
    </MainContent>
  );
};

export default Home;

import { httpClient } from "../utils/HttpClient";
import { UPLOAD_FETCHING, UPLOAD_FAILED } from "../constants";
import { ErrorNotification, SuccessNotification } from "../utils/Notification";
import { parseParamsString } from "../utils/Functions";

export const setUploadStateToFetching = () => ({
  type: UPLOAD_FETCHING,
});

export const setUploadStateToFailed = () => ({
  type: UPLOAD_FAILED,
});

export const upload = (params = null, value) => {
  return async (dispatch) => {
    dispatch(setUploadStateToFetching());
    try {
      const paramsString = parseParamsString(params);
      const response = await httpClient.post(
        process.env.REACT_APP_API_URL_NEW_V2 + `upload${paramsString}`,
        value,
      );
      SuccessNotification(response);
      return { isSuccess: true, data: response.data.data };
    } catch (error) {
      dispatch(setUploadStateToFailed());
      ErrorNotification(error);
      return { isSuccess: false, data: {} };
    }
  };
};

import React from "react";
import { Spin } from "antd";
import "./LoadingPage.scss";

const LoadingPage = () => {
  return (
    <div id="loading-page-wrapper">
      <Spin size="large" id="spin-loading-page" tip="Loading..." />
    </div>
  );
};

export default LoadingPage;

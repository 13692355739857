import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { formatRupiah, toSentenceCase } from "../../../utils/Functions";
import "./ProductPOSCard.scss";

const { Text } = Typography;

const ProductListCard = ({ data, handleClick }) => {
  const { t } = useTranslation(); // for translation

  const { isAlreadyCart } = data;

  const handleSubmit = () => {
    handleClick(data);
  };

  return (
    <>
      <Card
        className={`product-pos-card ${isAlreadyCart && "cart-item"}`}
        key={data?._id}
        hoverable
      >
        <Row>
          <Col span={24}>
            <div className="card-image-wrapper">
              <Image
                width="100%"
                height={200}
                preview={false}
                src={data?.image ?? `${window.location.origin}/images/baskit-product.jpg`}
                fallback={`${window.location.origin}/images/baskit-product.jpg`}
              />
            </div>
          </Col>

          <Col span={24}>
            <div className="card-title">
              <Text className="ant-typography-ellipsis-multiple-line">{data?.fullName}</Text>
            </div>
          </Col>

          <Col span={12}>
            <div className="ml-10">
              <div>
                <Text strong>{formatRupiah(data.basePrice ? data.basePrice : 0)}</Text>
              </div>
              {/* <div>
                <Text className='font-size-12' type='secondary' delete>
                  {formatRupiah(data.price ? data.price : 0)}
                </Text>
              </div> */}
            </div>
          </Col>
          <Col span={12}>
            <div className="card-right-content-wrapper">
              <div className="text-right mr-10">
                <div>
                  <Text strong className="font-size-12">
                    {t("Stock")} : {data.stock ? data.stock : 0}
                  </Text>
                </div>
                {/* <div>
                  <Text className='font-size-12'>
                    {t("Packaging")} : {data.uoms ? toSentenceCase(data.uoms.label) : "-"}
                  </Text>
                </div> */}
              </div>
            </div>
          </Col>

          <Col span={24} className="text-right">
            <Text className="font-size-12 mr-10">
              {t("Packaging")} : {data.uoms ? toSentenceCase(data.uoms.label) : "-"}
            </Text>
          </Col>

          <Col span={24}>
            <div className="card-button-wrapper">
              {isAlreadyCart ? (
                <p className="cart-item-label">{t("alreadyAdded")}</p>
              ) : (
                <Button
                  type="default"
                  htmlType="button"
                  block
                  className={data?.stock <= 0 || data?.isActive === false ? "button-disabled" : ""}
                  disabled={data?.stock <= 0 || data?.isActive === false ? true : false}
                  onClick={handleSubmit}
                >
                  {t("Buy")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ProductListCard;

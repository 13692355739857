import {
  INVENTORY_FETCHING,
  INVENTORY_SUCCESS,
  INVENTORY_FAILED,
  INVENTORY_CLEAR,
  INVENTORY_UPDATE,
  INVENTORY_BULK_UPDATE_FETCHING,
  INVENTORY_BULK_UPDATE_FAILED,
  CREATE_INVENTORY_FETCHING,
  CREATE_INVENTORY_FAILED,
  UPDATE_INVENTORY_FETCHING,
  UPDATE_INVENTORY_FAILED,
  CREATE_BULK_INVENTORY_FETCHING,
  CREATE_BULK_INVENTORY_FAILED,
  EXPORT_INVENTORY_TO_CSV_FETCHING,
  EXPORT_INVENTORY_TO_CSV_FAILED,
  INVENTORY_APPENDED,
} from "../constants";

const initialState = {
  isFetching: false,
  isError: false,
  result: {
    data: [],
    meta: {
      totalPage: 0,
      totalData: 0,
    },
  },
  data: null,
  isFetchingBulkUpdate: false,
  isErrorBulkUpdate: false,
  isFetchingCreate: false,
  isErrorCreate: false,
  isFetchingCreateBulk: false,
  isErrorCreateBulk: false,
  isFetchingCreateBulk: false,
  isErrorCreateBulk: false,
  isFetchingExportInventoryToCsv: false,
  isErrorExportInventoryToCsv: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INVENTORY_FETCHING:
      return { ...state, isFetching: true, isError: false };
    case INVENTORY_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case INVENTORY_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case INVENTORY_CLEAR:
      return { ...state, result: null, isFetching: false, isError: false };
    case INVENTORY_UPDATE:
      return { ...state, isFetching: false, isError: false, data: payload };
    case INVENTORY_BULK_UPDATE_FETCHING:
      return { ...state, isFetchingBulkUpdate: true, isErrorBulkUpdate: false };
    case INVENTORY_BULK_UPDATE_FAILED:
      return { ...state, isFetchingBulkUpdate: false, isErrorBulkUpdate: true };
    case CREATE_INVENTORY_FETCHING:
      return { ...state, isFetchingCreate: true, isErrorCreate: false };
    case CREATE_INVENTORY_FAILED:
      return { ...state, isFetchingCreate: false, isErrorCreate: true };
    case UPDATE_INVENTORY_FETCHING:
      return { ...state, isFetchingUpdate: true, isErrorUpdate: false };
    case UPDATE_INVENTORY_FAILED:
      return { ...state, isFetchingUpdate: false, isErrorUpdate: true };
    case CREATE_BULK_INVENTORY_FETCHING:
      return { ...state, isFetchingCreateBulk: true, isErrorCreateBulk: false };
    case CREATE_BULK_INVENTORY_FAILED:
      return { ...state, isFetchingCreateBulk: false, isErrorCreateBulk: true };
    case EXPORT_INVENTORY_TO_CSV_FETCHING:
      return { ...state, isFetchingExportInventoryToCsv: true, isErrorExportInventoryToCsv: false };
    case EXPORT_INVENTORY_TO_CSV_FAILED:
      return { ...state, isFetchingExportInventoryToCsv: false, isErrorExportInventoryToCsv: true };
    case INVENTORY_APPENDED:
      return {
        ...state,
        result: {
          ...state?.result,
          data: [
            ...state?.result?.data,
            ...payload,
          ],
        }
      };
    default:
      return state;
  }
};

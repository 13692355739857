import { ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ErrorMessage, SuccessMessage } from "../../utils/Message";
import LoginContent from "../../components/organisms/LoginContent/LoginContent";
import { httpClient } from "../../utils/HttpClient";
import "./_style.scss";

const { Title, Text } = Typography;

const Register = () => {
  const constant = {
    SUCCESS: "success",
    FAILED: "failed",
    RESEND: "resend",
    TIMEREMAINING: "time_remaining",
  };
  const countryPhones = [{ code: "+60" }, { code: "+62" }, { code: "+65" }, { code: "+92" }];
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [validationStatus, setValidationStatus] = useState({});
  const [dataStepOne, setDataStepOne] = useState({});
  const [, setValue] = useState(1);
  const [registerData, setRegisterData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.setFieldValue("code_phone", "+62");
    setDataStepOne(JSON.parse(localStorage.getItem("registerItem")));
  }, []);

  const onChangeSetData = async () => {
    try {
      const { code_phone } = form.getFieldsValue();

      await form.validateFields();
      setDisabled(!disabled);
      setRegisterData({
        firstName: form.getFieldValue("firstName"),
        lastName: form.getFieldValue("lastName") || "-",
        phone: code_phone + form.getFieldValue("phone"),
        email: dataStepOne.email,
        companyName: dataStepOne.companyName,
        address: dataStepOne.address,
        avatar: dataStepOne.avatar,
        longitude: dataStepOne.longitude,
        latitude: dataStepOne.latitude,
      });
    } catch (error) {
      console.log("Failed:", error);
    }
  };

  const handleSubmit = async () => {
    await httpClient
      .post(process.env.REACT_APP_API_URL_NEW_V2 + "auth/register", registerData)
      .then(() => {
        SuccessMessage({ data: { message: "Register a successfully!" } });
        localStorage.removeItem("registerItem");
        history.push("/login");
      })
      .catch((error) => {
        ErrorMessage(error);
      });
  };

  const handleChange = (value) => {
    const phoneNumber = value ? value.toString() : "";
    const phoneNumberLength = (form.getFieldValue("code_phone") + phoneNumber).length;
    if (phoneNumberLength > 11) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
    setValidationStatus({});
  };

  return (
    <LoginContent>
      <div id="register-wrapper" className="flex-center-justify">
        <div className="content">
          <Row gutter={24} className="register-card ml-20 mr-20">
            <Col span={24}>
              <div className="button-title-back" onClick={() => history.goBack()}>
                <Space direction="horizontal" style={{ marginLeft: "1rem" }}>
                  <ArrowLeftOutlined />
                  <Title level={3} style={{ margin: 0 }}>
                    {t("Register")}
                  </Title>
                </Space>
              </div>
              <div>
                <Divider className="register-divider" />
              </div>
            </Col>
            <Col span={20}>
              <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                {t("Informasi Toko")}
              </Title>
            </Col>
            <Col span={4}>
              <Title level={5} style={{ margin: 0, textAlign: "right" }}>
                2/2
              </Title>
            </Col>
            <Col span={12}>
              <div className="register-card-content">
                <Form form={form} onFinish={handleSubmit}>
                  <div className="mt-20 mb-10">
                    <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                      {t("ownerFirstName")}
                    </Text>
                  </div>
                  <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: "Please input your first name" }]}
                  >
                    <Input placeholder="Masukan Nama Anda" className="register-number" />
                  </Form.Item>

                  <div className="mt-20 mb-10">
                    <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                      {t("ownerLastName")}
                    </Text>
                  </div>
                  <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: "Please input your last name" }]}
                  >
                    <Input placeholder="Masukan Nama Anda" className="register-number" />
                  </Form.Item>

                  <div className="mt-40 mb-10">
                    <Text className={validationStatus.status === constant.FAILED && "text-danger"}>
                      {t("Enter your phone number")}
                    </Text>
                  </div>
                  <FormItemPhoneNumber
                    name="phone"
                    size="large"
                    label="Enter your phone name here"
                    countryPhones={countryPhones}
                    validateStatus={validationStatus.status === constant.FAILED && "error"}
                    help={
                      !isEmpty(validationStatus) ? (
                        <span>
                          <ExclamationCircleOutlined /> {" " + t(validationStatus.message)}
                        </span>
                      ) : (
                        ""
                      )
                    }
                    handleChange={handleChange}
                  />
                </Form>
              </div>
            </Col>
            <Col span={12}>
              <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                {t("Foto Tokomu")}
              </Title>
              <Card style={{ margin: "5px", background: "#F4F4F4", border: "2px dotted #8A8A8A" }}>
                <Image src={dataStepOne ? dataStepOne.avatar : ""}></Image>
              </Card>
              <Row gutter={24}>
                <Col span={24} style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Checkbox onChange={() => onChangeSetData()}>
                    Dengan mendaftar, Anda menyetujui <strong>Syarat & Ketentuan</strong> serta
                    &nbsp;
                    <strong>Kebijakan privasi</strong>.
                  </Checkbox>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Button size="large" block onClick={() => history.push("/register")}>
                    Kembali
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    disabled={disabled}
                    size="large"
                    block
                    onClick={() => handleSubmit()}
                  >
                    Daftar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </LoginContent>
  );
};

const FormItemPhoneNumber = ({
  label,
  name,
  rules,
  size = "middle",
  disabled = false,
  countryPhones,
  validateStatus,
  help,
  handleChange,
}) => {
  const { t } = useTranslation();
  const prefixSelector = (
    <Form.Item name={"code_" + name} noStyle>
      <Select>
        {countryPhones.map(({ code }) => (
          <Select.Option value={code} key={code}>
            {code}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const parseNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <Form.Item name={name} rules={rules} validateStatus={validateStatus} help={help}>
      <InputNumber
        addonBefore={prefixSelector}
        className="register-input-number"
        type="number"
        parser={parseNumber}
        placeholder={t(label)}
        style={{ width: "100%" }}
        size={size}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default Register;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { index } from "../actions/history.action";
import { filterParams } from "../utils/Functions";

const useHistoryData = (params) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const { result } = useSelector(({ historyReducer }) => historyReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(index(filterParams(params)));
  }, [dispatch, params]);

  useEffect(() => {
    if (result) {
      const { data, totalData, totalPage } = result;
      setData(data);
      setTotalData(totalData);
      setTotalPage(totalPage);
    }
    setLoading(false);
  }, [result]);

  return { data, totalData, totalPage, loading };
};

export default useHistoryData;

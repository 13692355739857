import CustomLink from "../../components/atoms/CustomLink/CustomLink";
import { formatPhoneNumberWithSpaces, t } from "../../utils/Functions";
import { isEmpty } from "lodash-es";
import dayjs from "dayjs";
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export const columns = [
  {
    title: "number",
    dataIndex: "no",
    key: "no",
    width: 64,
  },
  {
    title: "storeName",
    dataIndex: "storeName",
    key: "storeName",
    render: (data) => (
      <CustomLink text={data?.companyName} path={`/buyer-management/detail/${data?.id}`} />
    ),
  },
  {
    title: "ownerName",
    dataIndex: "ownerName",
    key: "ownerName",
  },
  {
    title: "internalRegion",
    dataIndex: "internalRegion",
    key: "internalRegion",
  },
  {
    title: "Phone No.",
    dataIndex: "phone",
    key: "phone",
    render: (text) => formatPhoneNumberWithSpaces(text),
  },
  {
    title: "storeType",
    dataIndex: "storeType",
    key: "storeType",
  },
];

export const roadAccessOptions = [
  { value: "1 Motor", label: "1 Motor" },
  { value: "1 Mobil", label: "1 Mobil" },
  { value: "2 Mobil", label: "2 Mobil" },
];

export const getInternalRegion = (id, data) => {
  const internalRegion = data?.find((item) => item?.id === id);
  return internalRegion?.name ?? "";
};

export const parseToDayjs = (minutes) => {
  const duration = dayjs.duration(minutes, "minutes");
  return dayjs().startOf("day").add(duration);
};

export const parseToDuration = (duration) => {
  return duration.diff(dayjs().startOf("day"), "minutes");
};

export const filterFields = (fields) => {
  if (isEmpty(fields)) return {};
  return Object.fromEntries(Object.entries(fields).filter(([_, value]) => Boolean(value)));
};
